import React from 'react';
import { isEmpty, keys, noop, some } from 'lodash';
import { bool, number, object, oneOfType, string } from 'prop-types';
import NumberFormat from 'react-number-format';
import PopupTooltipFormatter from './PopupTooltipFormatter';

class InputFormatter extends PopupTooltipFormatter {
	constructor(props) {
		super(props, InputFormatter.instances);

		this.tooltipClass = 'datatooltip--fake';
	}

	static instances = [];

	get tooltip() {
		const {
			row: {
				feeAndTaxPermissions: { includeConvenience, includeSalesTax },
			},
			dependentValues: { isTax, columnName },
		} = this.props;
		const value = isTax ? includeSalesTax : includeConvenience;

		return `${value ? 'Exclude' : 'Include'} ${columnName}`;
	}

	get className() {
		const {
			dependentValues: { inputType, label },
		} = this.props;
		let className = 'input input--sml';

		if (inputType === 'checkbox') {
			className = `input--check ${label ? '' : 'input--check--no-label'}`;
		}

		if (this.checkIfErrored()) {
			className = `${className} is-invalid`;
		}

		return className;
	}

	get componentProps() {
		const {
			value,
			row: { handleChange = noop, currency },
			dependentValues: { dependentValue, inputType, checked, placeholder, name, isNumberFormat },
		} = this.props;
		const uniqueId = `${name}.${dependentValue}`;
		let componentProps = {
			type: inputType,
			className: this.className,
			id: uniqueId,
			value,
			name: uniqueId,
			placeholder,
			onChange: e => handleChange(dependentValue, e),
		};

		if (inputType === 'checkbox') {
			componentProps.value = checked;
			componentProps.checked = checked;
			componentProps.className = this.className;
			componentProps.onClick = e => handleChange(dependentValue, e);
			componentProps.onChange = noop;
		}

		if (isNumberFormat) {
			componentProps = {
				className: this.className,
				id: uniqueId,
				name: uniqueId,
				prefix: currency,
				placeholder,
				value,
				thousandSeparator: true,
				decimalScale: 2,
				allowNegative: false,
				onValueChange: ({ floatValue }) =>
					handleChange(dependentValue, { target: { value: floatValue, name: uniqueId } }),
			};

			this.mapFeeOrTaxProps(componentProps);
		}

		return componentProps;
	}

	mapFeeOrTaxProps = componentProps => {
		const {
			row: { feeAndTaxPermissions, isSalesTaxAllowed, amount },
			dependentValues: { isFee, isTax },
		} = this.props;

		if (!isEmpty(feeAndTaxPermissions)) {
			const {
				allowConvFeeExclude,
				allowSalesTaxOverride,
				allowSalesTaxExclude,
				includeSalesTax,
				includeConvenience,
			} = feeAndTaxPermissions;

			if (isFee || (isTax && !allowSalesTaxOverride)) {
				componentProps.displayType = 'text';
				componentProps.className = '';
			}

			if (isTax && allowSalesTaxOverride) {
				componentProps.disabled = !includeSalesTax;
				componentProps.isAllowed = ({ floatValue: value }) => isSalesTaxAllowed(value, amount);
			}

			if ((isFee && allowConvFeeExclude) || (isTax && allowSalesTaxExclude)) {
				componentProps.displayIncludeCheckbox = true;
			}

			if ((isFee && !includeConvenience) || (isTax && !includeSalesTax)) {
				componentProps.value = 0;
			}
		}
	};

	checkIfErrored = () => {
		const {
			row: { errors },
			dependentValues: { name },
		} = this.props;

		return some(keys(errors), item => item === name || item === `${name}Required` || item === `${name}Invalid`);
	};

	renderInputComponent = () => {
		const {
			dependentValues: { dependentValue, label, name, isNumberFormat, disabled },
		} = this.props;
		const uniqueId = `${name}.${dependentValue}`;
		const InputComponent = isNumberFormat ? NumberFormat : 'input';
		const { displayIncludeCheckbox, ...rest } = this.componentProps;

		return (
			<span className="display--ib spc--right--xsml" onClick={e => e.stopPropagation()}>
				<div className="flex--primary flex--nowrap">
					{displayIncludeCheckbox && this.renderIncludeCheckbox()}
					<span className="display--ib">
						<InputComponent {...rest} disabled={disabled} />
						<label htmlFor={uniqueId}>{label}</label>
					</span>
				</div>
			</span>
		);
	};

	renderIncludeCheckbox = () => {
		const {
			row: {
				feeAndTaxPermissions: { includeConvenience, includeSalesTax },
				handleChange,
			},
			dependentValues: { isTax, dependentValue, name, disabled },
		} = this.props;
		const uniqueId = `${name}.${dependentValue}`;
		const value = isTax ? includeSalesTax : includeConvenience;

		return (
			<span
				ref={this.elementRef}
				onMouseEnter={this.displayPopup}
				onMouseLeave={this.closePopup}
				className="spc--right--tny display--ib"
			>
				<input
					type="checkbox"
					id={uniqueId}
					name={`include${isTax ? 'SalesTax' : 'Convenience'}.${name}`}
					className="input--check input--check--no-label"
					value={value}
					checked={value}
					onChange={e => handleChange(dependentValue, e)}
					disabled={disabled}
				/>
				<label htmlFor={uniqueId}></label>
			</span>
		);
	};

	render() {
		const {
			value,
			dependentValues: { displayColumnValue },
		} = this.props;

		return (
			<div className="flex--primary">
				{this.renderInputComponent()}
				{displayColumnValue && <span className="display--ib">{value}</span>}
			</div>
		);
	}
}

InputFormatter.defaultProps = {
	row: {},
	value: '',
	dependentValues: {
		dependentValue: '',
		label: '',
		inputType: 'text',
		placeholder: '',
		name: '',
		value: '',
		displayColumnValue: false,
		isNumberFormat: false,
	},
};

InputFormatter.propTypes = {
	value: oneOfType([string, number, bool]).isRequired,
	row: object,
	dependentValues: object,
};

export default InputFormatter;
