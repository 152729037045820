import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'common/components/modal';
import { cloneDeep, filter, uniqueId } from 'lodash';

const PaymentEngineNewComputerModal = ({ isOpen, onClose, onSave, softwareName, isLoading }) => {
	const [newComputer, setNewComputer] = useState([]);
	const [additionalFields, setAdditionalFields] = useState([{ key: '', value: '', id: uniqueId() }]);

	useEffect(() => {
		if (isOpen) {
			setAdditionalFields([{ key: '', value: '', id: uniqueId() }]);
		} else {
			setNewComputer([]);
		}
	}, [isOpen]);

	const onChange = (e, key, index = null) => {
		const { value } = e.target;
		if (index === null) {
			setNewComputer(prevData => ({
				...prevData,
				[key]: value,
			}));
		} else {
			const updatedFields = cloneDeep(additionalFields);
			updatedFields[index][key] = value;
			setAdditionalFields(updatedFields);
		}
	};

	const addField = () => {
		setAdditionalFields([...additionalFields, { key: '', value: '', id: uniqueId() }]);
	};

	const removeField = removeId => {
		const updatedFields = filter(additionalFields, ({ id }) => id !== removeId);
		setAdditionalFields(updatedFields);
	};

	const handleSave = () => {
		const mergedData = { ddb_revision: 0 };
		additionalFields.forEach(field => {
			const { id, ...fieldData } = field;
			if (fieldData.key && fieldData.value) {
				mergedData[fieldData.key] = fieldData.value;
			}
		});
		onSave({ ...mergedData }, softwareName, newComputer.xUserName);
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose} isLoading={isLoading}>
			<div className="modal__header">
				{isLoading && <div className="loader"></div>}
				<h1 className="modal__header__title">New Computer</h1>
			</div>
			<div className="modal__body">
				<div className="spc--bottom--xlrg">
					<div className="form__group__header">
						<span className="form__group__label">x UserName</span>
						<span className="form__group__required">*</span>
					</div>
					<input
						type="text"
						className="input input--med"
						value={newComputer.xUserName}
						onChange={e => onChange(e, 'xUserName')}
					/>
				</div>
				<table className="table table--primary">
					<thead>
						<th>Setting</th>
						<th>Value</th>
						{additionalFields.length > 1 && <th></th>}
					</thead>
					<tbody>
						{additionalFields.map((field, index) => (
							<tr key={field.id}>
								<td>
									<input
										type="text"
										className="input input--sml"
										value={field.key}
										onChange={e => onChange(e, 'key', index)}
									/>
								</td>
								<td>
									<input
										type="text"
										className="input input--sml"
										value={field.value}
										onChange={e => onChange(e, 'value', index)}
									/>
								</td>
								{additionalFields.length > 1 && (
									<td>
										<button className="btn btn--link" onClick={() => removeField(field.id)}>
											<i className="icon icon--sml icon--delete"></i>
										</button>
									</td>
								)}
							</tr>
						))}
					</tbody>
				</table>
				<div className="separator--primary"></div>
				<button className="btn btn--link spc--top--lrg spc--bottom--huge" onClick={addField}>
					<i className="icon icon--sml icon--add--primary"></i>
					<span>Add Field</span>
				</button>
			</div>
			<div className="modal__footer">
				<button className="btn btn--med btn--primary" onClick={handleSave}>
					Save
				</button>
			</div>
		</Modal>
	);
};

PaymentEngineNewComputerModal.propTypes = {
	isOpen: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	onSave: PropTypes.func.isRequired,
	softwareName: PropTypes.string,
	isLoading: PropTypes.bool,
};

export default PaymentEngineNewComputerModal;
