import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { AddEditCustomer } from 'common/components/customers/popup';

const GridHeader = ({
	refreshGridData,
	expanded,
	hasSelectedRows,
	isViewOnly,
	modalRef,
	notificationRef,
	isDuplicate,
	toggleMigrationTool,
	hasMigrationToolAccess,
}) => {
	const [isNavPopoverOpen, setIsNavPopoverOpen] = useState(false);

	const hasIfields = !!document.querySelector('.newtransaction__iframe') && expanded;

	if (isViewOnly) return null;

	const toggleNavPopover = () => {
		setIsNavPopoverOpen(!isNavPopoverOpen);
	};
	const onBeforeToggleMigrationTool = () => {
		toggleNavPopover();
		toggleMigrationTool();
	};

	return (
		<div className="flex--primary flex--nowrap flex--gap--sml--alt">
			{!hasMigrationToolAccess ? (
				<Link className="btn btn--med btn--secondary spc--left--sml--alt" to={`/customers/upload-customer-preview`}>
					<i className="icon icon--sml icon--upload--primary" />
					<span>Upload</span>
				</Link>
			) : (
				<div className="pos--rel">
					<button className="btn btn--action btn--action--secondary spc--left--sml--alt" onClick={toggleNavPopover}>
						<i className="icon icon--sml icon--more"></i>
					</button>
					{isNavPopoverOpen && (
						<div className="popover popover--w--400 popover--down">
							<ul className="popover__list">
								<li className="item">
									<Link to={`/customers/upload-customer-preview`}>
										<i className="icon icon--lrg icon--upload--light" />
										<span>Upload Customers and Schedules</span>
									</Link>
								</li>
								<li className="item">
									<button onClick={onBeforeToggleMigrationTool}>
										<i className="icon icon--lrg icon--reload--light" />
										<span>Migrate Customers and Payment Methods</span>
									</button>
								</li>
							</ul>
						</div>
					)}
				</div>
			)}
			<AddEditCustomer
				ref={modalRef}
				advancedView={false}
				refreshGrid={refreshGridData}
				notificationRef={notificationRef}
				isDuplicate={isDuplicate}
				triggerProps={{ hasIfields, hasSelectedRows }}
				trigger={renderTriggerButton}
			/>
		</div>
	);
};

function renderTriggerButton(props) {
	return (
		<button className="btn btn--primary btn--med" disabled={props.hasIfields || props.hasSelectedRows} {...props}>
			<i className="icon icon--sml icon--add--white"></i>
			<span>New Customer</span>
		</button>
	);
}

renderTriggerButton.propTypes = {
	hasIfields: PropTypes.bool,
	hasSelectedRows: PropTypes.bool,
};

GridHeader.propTypes = {
	refreshGridData: PropTypes.func.isRequired,
	toggleMigrationTool: PropTypes.func.isRequired,
	expanded: PropTypes.object.isRequired,
	hasSelectedRows: PropTypes.bool.isRequired,
	isViewOnly: PropTypes.bool.isRequired,
	modalRef: PropTypes.any,
	notificationRef: PropTypes.any,
	isDuplicate: PropTypes.func.isRequired,
	hasMigrationToolAccess: PropTypes.bool,
};

export default GridHeader;
