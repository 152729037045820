import { has, toLower } from 'lodash';

class CardTypeImage {
	get cardNames() {
		return {
			visa: 'visa',
			amex: 'amex',
			mastercard: 'mastercard',
			mc: 'mastercard',
			diners: 'diners',
			discover: 'discover',
			ebt: 'ebt',
			giftcard: 'giftcard',
			gift: 'gift',
			jcb: 'jcb',
			debit: 'debit',
			check: 'check',
			ebtw: 'wic',
			ewic: 'wic',
			pledger: 'pledger',
			donorsfund: 'donors-fund',
			ojc: 'ojc',
			matbia: 'matbia',
		};
	}

	getPath(cardType, folder = '') {
		const cardPath = `/static/media/credit-cards/${folder}`;
		const lower = toLower(cardType);
		if (has(this.cardNames, lower)) {
			return `${cardPath}${this.cardNames[lower]}.svg`;
		} else {
			return `${cardPath}unknown.svg`;
		}
	}
}

const cardTypeImagePath = new CardTypeImage();

export default cardTypeImagePath;
