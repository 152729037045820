import React from 'react';
import { bool } from 'prop-types';

import { withContainer, itemTypes } from './../drag-and-drop';

const draggableCreate = ({ isExpanded, isDisabled }) => {
	return (
		<button
			className={`btn btn--link type--left cursor--move ${isExpanded ? 'expand' : 'collapse'}`}
			key="key"
			disabled={isDisabled}
			type="button"
		>
			<i className="icon icon--nano icon--dragdots icon--middle spc--right--tny"></i>
			<i className="icon icon--sml icon--section--grey icon--middle spc--right--sml"></i>
			<label className="display--ib type--wgt--medium type--color--text--medium align--v--middle" key="key">
				New Section
			</label>
		</button>
	);
};

draggableCreate.propTypes = {
	isExpanded: bool,
	isDisabled: bool,
};

const mapItemToId = ({ key }) => key;

export default withContainer(draggableCreate, mapItemToId, {
	hideWhileDragging: false,
	dragType: itemTypes.CREATABLE_SECTION_ITEM,
});
