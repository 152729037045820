import React, { Component, createRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Data } from 'react-data-grid-addons';
import {
	cloneDeep,
	some,
	each,
	find,
	unionBy,
	findIndex,
	map,
	clone,
	sortBy,
	includes,
	isEmpty,
	get,
	toNumber,
	findKey,
	every,
	filter,
	noop,
	startsWith,
	lowerFirst,
	replace,
	memoize,
} from 'lodash';
import { parse, stringify } from 'query-string';

import { ActionsModal, modalNames } from 'common/components/transaction-actions';
import { CustomerColumns as Columns } from 'common/components/customers/column-filter/customerColumns';
import { MainFilterComponent } from 'common/components/filter';
import { customersFilter as Filter, compileFilter } from 'common/components/customers/filter/customersFilter';
import { customerService, kvaasService, principalService } from 'common/services';
import { CustomerRowDetails } from './details';
import { ZebraRenderer } from 'common/components/row';
import { AddEditCustomer } from 'common/components/customers/popup';
import { GridComponent } from 'common/components/grid';
import { withCancelable } from 'common/components/cancelable';
import { withError } from 'common/components/error';
import { kvaasResources, checkIfCanadian } from '../../Common/utilities';
import { exportService } from '../../Common/components/export/exportService';
import { Notification } from '../../Common/components/notifications';
import { mapDefaultOrder } from '../../Common/utilities/map-default-order';
import { GridTooltip } from 'common/components/tooltips';
import { RecurringActions } from 'common/components/columns/formatters';
import { Tour } from 'common/components/tour';
import { Modal } from 'common/components/modal';
import { SelectAllContext, SidebarContext } from 'common/contexts';
import { addCustomDataType, queryFilterValues } from 'common/components/customers/popup/utils';
import BulkProgressModal from 'common/components/bulkProgressModal/BulkProgressModal';
import GridHeader from './components/grid-header';
import CustomersMigrationTool from 'components/migrate-customers/CustomersMigrationTool';
import sectionKeys from 'routing/sections';
import title from 'common/components/grid/title';

const { recurringApiRateLimit } = ApplicationSettings;
const requestKeys = {
	FETCH: 'fetch',
	KVAAS: 'kvaas',
};

let focusScheduleIndex = 1;
const tourConfig = {
	version: 1, // increase this every time you make changes to the tourConfig,
	key: 'bulkChargeDifferentAmounts',
	steps: [
		{
			selector: '#bulkChargeDifferentAmounts',
			content: 'You can now bulk charge customers different amounts.',
		},
	],
};

class CustomerGrid extends Component {
	static contextType = SidebarContext;
	constructor(props) {
		super(props);
		const principal = principalService.get();
		this.state = {
			bulkProgressModal: {
				isOpen: false,
				progress: {
					completed: 0,
					total: 0,
					errored: 0,
				},
				mappedErrors: [],
				errors: [],
			},
			...this.addFiltersToState(clone(this.initialState)),
			isViewOnly: get(principal, 'isViewOnly', false),
			hasMigrationToolAccess: principal.hasAccess[sectionKeys.dropIn],
			columns: Columns(),
			defaultColumns: cloneDeep(Columns()),
			bulkChargeType: 'sameAmount',
			isOpenBulkChargeTypesModal: false,
			unmappedCustomDisplayLabels: {},
			customerHiddenFields: {},
			customerRequiredFields: {},
			showMigrationTool: false,
		};

		this.gridRef = createRef();
		this.modalRefs = {
			header: createRef(),
			gridHeader: createRef(),
		};
		this.notificationRef = createRef();

		this.components = {
			header: this.renderHeader,
			gridHeader: this.renderGridHeader,
			modal: ActionsModal,
			filter: MainFilterComponent,
			rowRenderer: ZebraRenderer,
			tooltip: GridTooltip,
			rowDetails: CustomerRowDetails,
			rowActions: RecurringActions,
			selectedEntriesActions: this.renderSelectedEntriesActions,
			title: title,
		};

		this.classes = {
			filter: '',
		};
	}

	get initialState() {
		return {
			data: null,
			filteredRows: [],
			expanded: {},
			fetchingData: true,
			fetchingAdditionalData: false,
			lastApiRefNum: null,
			customerId: this.props.location.customerId,
			showPaymentMethods: this.props.location.showPaymentMethods,
			initiallyOpenNewCustomer: false,
			expandedRow: '',
			hasSelectedRows: false,
		};
	}
	get isOpenMigrationTool() {
		return this.state.hasMigrationToolAccess && this.state.showMigrationTool;
	}

	componentWillUnmount() {
		this.context.toggleSidebarExpandedClass(false);
		this.context.enableDisableNewCustomerSidebar(false);
	}

	componentDidMount = async () => {
		try {
			const getKvaas = kvaasService.get(
				kvaasResources.transactionDisplayLabels,
				kvaasResources.customerReportDefaultColumns,
				kvaasResources.customerReportHiddenFields,
				kvaasResources.customerReportOrder,
				kvaasResources.customerRequiredFields,
				kvaasResources.customerHiddenFields
			);
			const [
				unmappedCustomDisplayLabels,
				customerDefaultColumns,
				hiddenColumns,
				columnsOrder,
				customerRequiredFields,
				customerHiddenFields,
			] = await this.props.makePendingRequest(getKvaas, requestKeys.KVAAS);
			const isCanadian = checkIfCanadian();
			const order = mapDefaultOrder(kvaasResources.customerReportOrder.defaultData, columnsOrder);
			const columns = this.addCustomData(this.state.columns, {
				customerDefaultColumns,
				hiddenColumns,
				order,
				isCanadian,
			});
			const defaultColumns = this.addCustomData(this.state.defaultColumns, {
				customerDefaultColumns,
				hiddenColumns,
				order,
				isCanadian,
			});
			this.mapCustomDisplayLabels(columns, unmappedCustomDisplayLabels);
			this.mapCustomDisplayLabels(defaultColumns, unmappedCustomDisplayLabels);
			let filters = this.addCustomData(this.state.filters, { hiddenColumns });
			let activeFilters = this.addCustomData(this.state.activeFilters, { hiddenColumns });

			const queryParams = parse(this.props.location.search);
			filters = this.parseQueryFilters(filters, queryParams);
			activeFilters = this.parseQueryFilters(activeFilters, queryParams);
			this.handleCustomersQueryFilterValues(filters, this.props.location.search, true);

			this.setState(
				{
					columns,
					defaultColumns,
					filters,
					activeFilters,
					hasSelectedRows: false,
					customerRequiredFields,
					unmappedCustomDisplayLabels,
					customerHiddenFields,
				},
				() => {
					if (this.gridRef.current) {
						this.gridRef.current.reset();
					}
				}
			);
		} catch (e) {
			if (this.props.handleError(e)) {
				this.setState({
					fetchingData: false,
				});
			}
		}
	};

	componentDidUpdate = prevProps => {
		if (prevProps.location.key !== this.props.location.key && includes(this.props.location.search, 'refresh=true')) {
			let state = clone(this.initialState);
			if (this.props.location.customerId) {
				state = this.addFiltersToState(state);
			}
			this.setState(state, () => {
				if (this.gridRef.current) {
					this.gridRef.current.clearFilters();
					this.gridRef.current.reset();
				}
			});
		}
		if (this.props.location && this.props.location.hasOwnProperty('openNewCustomer')) {
			if (!this.state.initiallyOpenNewCustomer && this.props.location.openNewCustomer) {
				const isOpen = some(this.modalRefs, ({ current }) => current && current.state.isModalOpen);
				if (!isOpen) {
					const ref = find(this.modalRefs, ({ current }) => current);
					if (ref) {
						ref.current.handleOpenModal(this.props.location.advancedView);
					}
				}
				this.props.history.replace({ openNewCustomer: false });
			}
		}
		if (this.props.history.location.customerId) {
			const row = find(
				this.state.filteredRows,
				({ customerId }) => customerId === this.props.history.location.customerId
			);

			if (row) {
				this.gridRef.current.onRowClick(row.index, row);
				this.setState({ expandedRow: null });
			}
		}
	};
	getContextValue = memoize(checked => {
		return {
			toggle: this.selectAllRows,
			checked,
		};
	});
	setStateAsync = state => new Promise(resolve => this.setState(state, resolve));
	parseQueryFilters = (filters, params) => {
		if (isEmpty(params)) {
			return filters;
		}
		const newFilters = cloneDeep(filters);
		let anyChanged = false;

		each(newFilters, ({ values, defaultValues, defaultHasSelection }, index) => {
			each(values, (_, key) => {
				const value = params[key];
				if (value) {
					let hasSelection = true;

					newFilters[index].hasSelection = hasSelection;
					newFilters[index].values[key] = hasSelection ? value : defaultValues[key];
					anyChanged = true;
				} else {
					newFilters[index].hasSelection = !!defaultHasSelection;
					let defaultValue = defaultValues[key];

					if (!newFilters[index].hasSelection) {
						if (typeof defaultValue === 'string') {
							defaultValue = '';
						} else if (typeof defaultValue === 'boolean') {
							defaultValue = false;
						} else if (typeof defaultValue === 'object') {
							defaultValue = null;
						}
					}

					newFilters[index].values[key] = defaultValue;
				}
			});
		});

		return anyChanged ? newFilters : filters;
	};
	updateCustomerQueryFilterState = newState => {
		this.setState({ ...newState });
	};

	handleCustomersQueryFilterValues = (customerFilters, query = '', initialExpandRow = false) => {
		queryFilterValues(customerFilters, this.props, this.updateCustomerQueryFilterState, query, initialExpandRow);
	};

	addFiltersToState = state => {
		const filters = unionBy(this.props.location.filters, Filter, 'key') || Filter;
		return {
			...state,
			filters: filters,
			activeFilters: cloneDeep(filters),
			inlineFilters: {},
		};
	};

	fetchData = async (filters, customerId, showPaymentMethods, maxRecords = 1000) => {
		const { expandedRow, columns, inlineFilters } = this.state;
		this.setState({
			fetchingData: true,
			data: null,
			filteredRows: [],
			expanded: {},
			lastApiRefNum: null,
		});
		let lastApiRefNum = null;

		try {
			const filter = await this.props.makePendingRequest(compileFilter(filters), requestKeys.FETCH);
			filter.PageSize = 100;
			const [data, paymentMethods] = await this.props.makePendingRequest(
				Promise.all([customerService.filterCustomersAll(null, filter), customerService.getPaymentMethods()]),
				requestKeys.FETCH
			);

			lastApiRefNum = data.refNum;
			if (data && data.xReportData) {
				data.xReportData = map(data.xReportData, this.mapRow);
				customerService.mapCustomerPaymentMethods(data, paymentMethods);
			}

			if (maxRecords === 0) {
				data.xRecordsReturned = data.xReportData.length;
				data.xReportingMaxCustomers = data.xReportData.length + 1; // +1 = quick fix
			}

			const formattedColumns = this.formatColumns(columns, cloneDeep(filter));
			this.mapData(data);
			const filteredRows =
				data && data.xReportData
					? Data.Selectors.getRows({
							rows: data.xReportData,
							filters: inlineFilters,
					  })
					: [];
			if (this.gridRef.current) {
				this.gridRef.current.scrollTo({ top: 0, left: 0 });
			}

			this.setState(
				{
					data,
					originalData: cloneDeep(data),
					filteredRows,
					fetchingData: false,
					columns: formattedColumns,
					lastApiRefNum: lastApiRefNum,
					customerId: null,
					showPaymentMethods: null,
				},
				() => {
					if (this.gridRef.current) {
						if (customerId) {
							const index = findIndex(this.state.data.xReportData, row => row.customerId === customerId);
							this.gridRef.current.onRowClick(
								index,
								this.gridRef.current.rowGetter(index),
								undefined,
								undefined,
								undefined,
								showPaymentMethods
							);
						} else if (expandedRow) {
							const row = find(filteredRows, ({ customerId }) => customerId === expandedRow);
							if (row) {
								this.gridRef.current.onRowClick(row.index, row);
								this.setState({ expandedRow: null });
							}
						}
						this.gridRef.current.handleInitialSort();
						this.gridRef.current.calculateColumnWidths();
					}
				}
			);
		} catch (e) {
			if (this.props.handleError(e)) {
				this.setState({
					fetchingData: false,
				});
			}
		}
	};

	addCustomData = (data, { customerDefaultColumns, hiddenColumns, order, isCanadian }) => {
		let newData = cloneDeep(data);
		let anyChanged = false;
		newData = addCustomDataType(newData, customerDefaultColumns, (item, _, index) => {
			if (index === undefined) {
				item.visible = true;
				anyChanged = true;
				return true;
			}
		});
		newData = addCustomDataType(newData, hiddenColumns, (item, value, index) => {
			if (!value) return false;
			if (index !== undefined) {
				item.props.hiddenFields[index] = true;
			} else {
				item.hideable = false;
				item.visible = false;
			}
			anyChanged = true;
			return true;
		});
		newData = addCustomDataType(
			newData,
			order,
			(item, value, index) => {
				if (index === undefined) {
					item.order = value;
					anyChanged = true;
					return true;
				}
			},
			updatedData => sortBy(updatedData, 'order')
		);
		if (isCanadian) {
			anyChanged = this.onIsCanadian(newData);
		}
		return anyChanged ? newData : data;
	};
	onIsCanadian = newData => {
		let changed = false;
		const zip = find(newData, ({ customSettingsKey }) => customSettingsKey === 'zip');
		const avsZip = find(newData, ({ customSettingsKey }) => customSettingsKey === 'avsZip');
		if (avsZip) {
			avsZip.name = 'AVS Postal Code';
			changed = true;
		}
		if (zip) {
			zip.name = 'Postal Code';
			changed = true;
		}
		const state = find(newData, ({ customSettingsKey }) => customSettingsKey === 'state');
		if (state) {
			state.name = 'Province';
			changed = true;
		}
		return changed;
	};

	removePrefix = key => {
		let newKey = key;
		if (startsWith(key, 'bill')) {
			newKey = replace(key, /^(bill)/, ''); // remove the 'bill'prefix
		}
		return lowerFirst(newKey);
	};

	mapCustomDisplayLabels = (columns, unmappedCustomDisplayLabels) => {
		const customDisplayLabels = get(unmappedCustomDisplayLabels, 'data', {});
		each(columns, column => {
			let { key } = column;
			const customDisplayName = customDisplayLabels[this.removePrefix(key)];
			if (customDisplayName) {
				column.name = customDisplayName;
			}
		});
	};

	mapData = data => {
		let i = 0;
		if (data && data.xReportData && data.xReportData.length > 0) {
			each(data.xReportData, item => {
				item.gridRowNumber = i;
				item.index = i + 1;
				item._meta = {
					isSelected: false,
				};
				i++;
			});
		}
	};

	formatColumns = (columns, appliedFilter = null) => {
		if (appliedFilter) {
			for (let prop in appliedFilter) {
				if (appliedFilter.hasOwnProperty(prop)) {
					let column = find(columns, i => {
						return i.key.toLowerCase() === prop.toLowerCase() && !i.visible;
					});

					if (column) {
						column.visible = true;
					}
				}
			}
		}
		return columns;
	};

	showLoader = fetchingAdditionalData => this.setState({ fetchingAdditionalData });

	mapRow = row => ({
		...row,
		refreshGridData: this.gridRef.current ? this.gridRef.current.refreshGridData : null,
		onRowClick: this.gridRef.current ? this.gridRef.current.onRowClick : null,
		handleChange: this.selectRow,
		onInfoHover: this.onInfoHover,
		updateRows: this.updateRows,
		isExpandable: true,
		showLoader: this.showLoader,
		openCloseModal: this.gridRef.current ? this.gridRef.current.openCloseModal : null,
		history: this.props.history,
		handleError: this.props.handleError,
		deleteAction: {
			key: 'deleteCustomer',
			tooltip: 'Delete customer',
		},
	});

	mapOldPaymentMethodsToRow = (oldRow, row) => {
		if (!row.paymentMethod && oldRow.paymentMethod) {
			row.paymentMethod = oldRow.paymentMethod;
			row.paymentMethodDetails = oldRow.paymentMethodDetails;
			row.paymentMethodExpiry = oldRow.paymentMethodExpiry;
			row.paymentMethodExpiryMoment = oldRow.paymentMethodExpiryMoment;
			row.isPaymentMethodExpired = oldRow.isPaymentMethodExpired;
			row.paymentMethod = oldRow.paymentMethod;
			row.maskedCardNumber = oldRow.maskedCardNumber;
		}
	};

	updateRows = rows => {
		const { data, filteredRows } = this.state;
		const newData = clone(data);
		const newFilteredRows = clone(filteredRows);
		each(rows, row => {
			const mappedRow = this.mapRow(row);
			newData.xReportData = clone(newData.xReportData);
			const dirtiedIndex = findIndex(newData.xReportData, item => item.customerId === row.customerId);
			if (dirtiedIndex > -1) {
				const oldRow = newData.xReportData[dirtiedIndex];
				mappedRow.gridRowNumber = oldRow.gridRowNumber;
				mappedRow.index = oldRow.index;
				mappedRow.isDetails = oldRow.isDetails;
				mappedRow._meta = oldRow._meta;
				this.mapOldPaymentMethodsToRow(oldRow, mappedRow);
				newData.xReportData[dirtiedIndex] = mappedRow;
			}
			const dirtiedFilteredIndex = findIndex(
				newFilteredRows,
				item => (item.customerID || item.customerId) === row.customerId
			);
			if (dirtiedFilteredIndex > -1) {
				newFilteredRows[dirtiedFilteredIndex] = mappedRow;
			}
		});
		this.setState({
			data: newData,
			filteredRows: newFilteredRows,
		});
	};

	openDeleteSelectedRowsModal = () => {
		if (this.gridRef.current) {
			this.gridRef.current.openCloseModal({
				name: modalNames.confirmAction,
				data: {
					loadingMessage: 'Deleting Customers',
					question: (
						<Fragment>
							<h4 className="spc--bottom--lrg">Delete Customer</h4>
							<p className="type--p2 spc--bottom--sml">
								When a Customer is deleted, all associated recurring schedules (including those that are active) are
								deleted as well. <span className="type--p2 type--wgt--bold">This action cannot be undone.</span>
							</p>
							<p>Are you sure you want to delete the selected Customer(s)?</p>
						</Fragment>
					),
					onConfirm: this.deleteSelectedRows,
				},
			});
		}
	};
	toggleMigrationTool = () => {
		this.setState({
			showMigrationTool: !this.state.showMigrationTool,
		});
	};

	openBulkChargeSelectedRowsModal = () => {
		if (this.gridRef.current) {
			const { bulkChargeType } = this.state;
			const isMultiAmount = bulkChargeType === 'differentAmount';
			const data = {
				selectedRows: this.getRowsWithDefaultPaymentMethod(),
				addNotification: get(this.notificationRef, 'current.addNotification', noop),
				refreshGridData: get(this.gridRef, 'current.refreshGridData', noop),
				enableDisableSidebar: this.enableDisableSidebar,
				isMultiAmount,
				mapCustomDisplayLabels: this.mapCustomDisplayLabels,
			};

			data.modalClassName = 'modal__content modal--lrg';

			this.handleCloseBulkChargeTypeModal();
			this.gridRef.current.openCloseModal({
				name: modalNames.bulkCharge,
				data,
			});
		}
	};

	deactivateActiveSchedules = async (schedules, requestCount, windowStartTime) => {
		const REQUEST_INTERVAL = 300000; // 5 minutes in milliseconds

		for (const { scheduleId, isActive } of schedules) {
			if (isActive) {
				if (requestCount >= recurringApiRateLimit - 100) {
					const currentTime = Date.now();
					const timeElapsed = currentTime - windowStartTime;

					if (timeElapsed < REQUEST_INTERVAL) {
						const timeToWait = REQUEST_INTERVAL - timeElapsed;
						await new Promise(resolve => setTimeout(resolve, timeToWait)); // Wait for the remaining time
						windowStartTime = Date.now(); // Reset the window start time
					} else {
						// If the time elapsed is greater than 5 minutes, reset the count
						windowStartTime = currentTime;
					}
					requestCount = 0;
				}

				requestCount++;
				await customerService.activateCustomerRecurringSchedule(scheduleId, false);
			}
		}

		return { requestCount, windowStartTime };
	};

	getRowsWithDefaultPaymentMethod = () =>
		filter(
			get(this.state.data, 'xReportData', []),
			({ _meta: { isSelected }, defaultPaymentMethodId, paymentMethod }) =>
				isSelected && defaultPaymentMethodId && paymentMethod
		);
	deleteSelectedRows = async () => {
		if (this.gridRef.current) {
			this.gridRef.current.openCloseModal({
				name: modalNames.bulkProcess,
				data: { loadingMessage: 'Deleting Customers' },
			});
		}
		const pauseIdleTimer = get(this.props, 'idleTimerRef.current.pause', noop);
		const resumeIdleTimer = get(this.props, 'idleTimerRef.current.resume', noop);
		const REQUEST_INTERVAL = 300000; // 5 minutes in milliseconds
		let requestCount = 0;
		let windowStartTime = Date.now();

		try {
			pauseIdleTimer();
			const selectedRows = filter(get(this.state.data, 'xReportData', []), { _meta: { isSelected: true } });

			// Open the bulkProgressModal and set the total number of rows to be deleted
			await this.setStateAsync({
				bulkProgressModal: {
					...this.state.bulkProgressModal,
					isOpen: true,
					progress: {
						...this.state.bulkProgressModal.progress,
						total: selectedRows.length,
					},
				},
			});

			for (const { customerId, basicScheduleData } of selectedRows) {
				try {
					if (!this.state.bulkProgressModal.isOpen) break; // If the modal is closed, stop the loop
					if (requestCount >= recurringApiRateLimit - 100) {
						const currentTime = Date.now();
						const timeElapsed = currentTime - windowStartTime;

						if (timeElapsed < REQUEST_INTERVAL) {
							const timeToWait = REQUEST_INTERVAL - timeElapsed;
							await new Promise(resolve => setTimeout(resolve, timeToWait)); // Wait for the remaining time
							windowStartTime = Date.now(); // Reset the window start time
						} else {
							// If the time elapsed is greater than 5 minutes, reset the count
							windowStartTime = currentTime;
						}
						requestCount = 0;
					}

					if (!isEmpty(basicScheduleData)) {
						// Retrieve schedules for the customer
						requestCount++;
						const schedules = await customerService.getCustomerRecurringSchedules(customerId);

						// Deactivate schedules with rate limiting applied
						const result = await this.deactivateActiveSchedules(
							get(schedules, 'xReportData', []),
							requestCount,
							windowStartTime
						);
						requestCount = result.requestCount;
						windowStartTime = result.windowStartTime;
					}
					await customerService.deleteCustomer(customerId);
					requestCount++;

					// Update the progress in the bulkProgressModal
					this.setState({
						bulkProgressModal: {
							...this.state.bulkProgressModal,
							progress: {
								...this.state.bulkProgressModal.progress,
								completed: this.state.bulkProgressModal.progress.completed + 1,
							},
						},
					});
				} catch (error) {
					// Update the errors and errored count in the bulkProgressModal
					this.setState({
						bulkProgressModal: {
							...this.state.bulkProgressModal,
							progress: {
								...this.state.bulkProgressModal.progress,
								errored: this.state.bulkProgressModal.progress.errored + 1,
							},
							errors: [...this.state.bulkProgressModal.errors, `${customerId}: ${error.message}`],
						},
					});
				}
			}
			resumeIdleTimer();
		} catch (e) {
			resumeIdleTimer();
			this.props.handleError(e);
		}
	};
	selectAllRows = () => {
		const { filteredRows, data } = this.state;
		const selectAll = isEmpty(filteredRows) ? false : every(filteredRows, { _meta: { isSelected: true } });
		const newFilteredRows = map(filteredRows, row => ({ ...row, _meta: { ...row._meta, isSelected: !selectAll } }));
		const newData = { ...data };

		each(newFilteredRows, ({ customerId, _meta: { isSelected } }) => {
			if (isSelected === !selectAll) {
				const index = findIndex(newData.xReportData, { customerId });

				if (index > -1) {
					newData.xReportData[index]._meta = {
						...newData.xReportData[index]._meta,
						isSelected: !selectAll,
					};
				}
			}
		});

		this.setState({ filteredRows: newFilteredRows, data: newData, hasSelectedRows: !selectAll });
		this.enableDisableSidebar(!selectAll);
	};

	selectRow = customerId => {
		const { filteredRows, data } = this.state;
		const newState = {};
		const filteredRowIndex = findIndex(filteredRows, { customerId });
		const rowIndex = findIndex(data.xReportData, { customerId });

		if (filteredRowIndex > -1) {
			const rows = [...filteredRows];
			rows[filteredRowIndex] = {
				...filteredRows[filteredRowIndex],
				_meta: {
					...filteredRows[filteredRowIndex]._meta,
					isSelected: !filteredRows[filteredRowIndex]._meta.isSelected,
				},
			};
			newState.filteredRows = rows;
		}

		if (rowIndex > -1) {
			const newData = { ...data };
			newData.xReportData[rowIndex] = {
				...newData.xReportData[rowIndex],
				_meta: {
					...newData.xReportData[rowIndex]._meta,
					isSelected: !filteredRows[filteredRowIndex]._meta.isSelected,
				},
			};
			newState.data = newData;
		}
		const hasSelectedRows = !isEmpty(find(get(newState.data, 'xReportData', []), { _meta: { isSelected: true } }));
		newState.hasSelectedRows = hasSelectedRows;
		this.enableDisableSidebar(hasSelectedRows);
		this.setState(newState);
	};

	mapCellArgs = (rowId, row, _, openOnly = false, focusSchedule = false, focusPayments = false) => {
		const { unmappedCustomDisplayLabels, customerHiddenFields, customerRequiredFields } = this.state;
		if (rowId < 0) {
			return; // header row
		}
		const args = {
			rowData: row,
			expandArgs: {
				children: [
					{
						isDetails: true,
						row: row,
					},
				],
			},
			openOnly: openOnly,
			expandedRowProps: {
				customDisplayLabels: unmappedCustomDisplayLabels,
				customerHiddenFields,
				customerRequiredFields,
				focusSchedule,
				focusPayments,
				focusScheduleIndex,
			},
		};
		focusScheduleIndex++;
		return args;
	};

	onInfoHover = (infoDimensions, tooltip) => {
		this.setState({ tooltipProps: { infoDimensions, tooltip } });
	};

	refetchData = () => {
		this.fetchData(this.state.activeFilters, this.state.customerId, this.state.showPaymentMethods);
	};

	hasMoreData = data => !!get(data, 'nextToken');

	handleChange = changes => {
		const { filters, data } = this.state;
		const newState = {};
		each(changes, ({ key, value }) => {
			if (key === 'expanded') {
				const expandedIndex = toNumber(findKey(value, item => !!item));
				const expandedRow = find(data.xReportData, ({ index }) => index === expandedIndex);
				const query = parse(this.props.location.search);
				if (expandedRow) {
					query.expandedRow = expandedRow.customerId;
				} else {
					delete query.expandedRow;
				}
				this.handleCustomersQueryFilterValues(filters, stringify(query));
			}
			if (key === 'data' || key === 'inlineFilters') {
				let filters, data;
				if (key === 'data') {
					filters = this.state.inlineFilters;
					data = value;
				} else {
					filters = value;
					data = this.state.data;
				}
				newState.filteredRows =
					data && data.xReportData
						? Data.Selectors.getRows({
								rows: data.xReportData,
								filters,
						  })
						: [];
			}
			newState[key] = value;
		});
		return new Promise(resolve => {
			this.setState(newState, resolve);
		});
	};

	handleBulkChargeTypeChange = ({ target: { value } }) => this.setState({ bulkChargeType: value });

	enableDisableSidebar = hasSelectedRows => {
		this.context.enableDisableNewCustomerSidebar(hasSelectedRows);
		this.setState({ hasSelectedRows });
	};

	resolveColumnName = column => {
		switch (column) {
			case 'paymentMethodExpiry':
				return 'paymentMethodExpiryMoment';
			default:
				return column;
		}
	};

	handleVisibleColumnChange = (oldVisibleColumns, newVisibleColumns) => {
		const hasNewPaymentMethodColumn = some(newVisibleColumns, ({ key }) => {
			if (key === 'paymentMethodExpiry') return !includes(oldVisibleColumns, key);
			else return false;
		});
		if (hasNewPaymentMethodColumn) this.refetchData();
	};

	isDuplicate = (newCustomerNumber, currentCustomerId) => {
		const data = get(this.state.data, 'xReportData', []);
		if (!data) return false;
		const duplicateCustomer = find(
			filter(data, ({ customerId }) => customerId !== currentCustomerId),
			({ customerNumber }) => customerNumber && customerNumber === newCustomerNumber
		);
		return !!duplicateCustomer;
	};

	handleOpenBulkChargeTypeModal = () =>
		this.setState({ isOpenBulkChargeTypesModal: true, bulkChargeType: 'sameAmount' });

	handleCloseBulkChargeTypeModal = () => {
		this.setState({ isOpenBulkChargeTypesModal: false });
	};

	handleClosebulkProgressModal = () =>
		this.setState(
			{
				bulkProgressModal: {
					isOpen: false,
					progress: {
						completed: 0,
						total: 0,
						errored: 0,
					},
					mappedErrors: [],
					errors: [],
				},
			},
			() => {
				this.enableDisableSidebar(false);
				this.gridRef.current.refreshGridData();
			}
		);
	renderBulkChargeTypesModal = () => {
		const { bulkChargeType, isOpenBulkChargeTypesModal } = this.state;

		return (
			<Modal
				isOpen={isOpenBulkChargeTypesModal}
				onClose={this.handleCloseBulkChargeTypeModal}
				className="modal__content"
			>
				<div>
					<div className="modal__header">
						<div className="modal__header__title">Select a Bulk Charge Type:</div>
					</div>
					<div className="modal__body">
						<div className="spc--bottom--tny">
							<input
								type="radio"
								className="input input--radio"
								id="bulkChargeType.sameAmount"
								name="bulkChargeType.sameAmount"
								onChange={this.handleBulkChargeTypeChange}
								value="sameAmount"
								checked={bulkChargeType === 'sameAmount'}
							/>
							<label htmlFor="bulkChargeType.sameAmount">Charge the same amount</label>
						</div>
						<div id="bulkChargeDifferentAmounts">
							<input
								type="radio"
								className="input input--radio"
								id="bulkChargeType.differentAmount"
								name="bulkChargeType.differentAmount"
								onChange={this.handleBulkChargeTypeChange}
								value="differentAmount"
								checked={bulkChargeType === 'differentAmount'}
							/>
							<label htmlFor="bulkChargeType.differentAmount">Charge different amounts</label>
						</div>
						<Tour tourConfig={tourConfig} />
					</div>
					<div className="modal__footer">
						<button
							type="button"
							tabIndex="-1"
							className="btn btn--med btn--primary"
							onClick={this.openBulkChargeSelectedRowsModal}
						>
							Confirm
						</button>
					</div>
				</div>
			</Modal>
		);
	};

	renderSelectedEntriesActions = () => {
		const selectedRows = filter(get(this.state.data, 'xReportData', []), { _meta: { isSelected: true } });
		const hasSelectedRows = !isEmpty(selectedRows);
		const rowsWithDefaultPaymentMethod = this.getRowsWithDefaultPaymentMethod();
		const rowsCount = selectedRows.length;
		const disableBulkCharge = isEmpty(rowsWithDefaultPaymentMethod);
		let tooltipData = 'Bulk Charge Customers with Payment Methods';

		if (disableBulkCharge) {
			tooltipData = 'You can only charge customers that have a payment method set up';
		}
		return (
			hasSelectedRows && (
				<div className="l--main__footer">
					<p className="type--p2">
						Selected:{' '}
						{rowsCount && (
							<span className="type--p2--medium">
								{rowsCount} {rowsCount === 1 ? 'customer' : 'customers'}
							</span>
						)}
					</p>
					<div className="flex--primary flex--gap--sml--alt flex--nowrap">
						<button
							data-tooltip="Delete selected entries"
							onClick={this.openDeleteSelectedRowsModal}
							className="btn btn--med btn--secondary"
						>
							Delete
						</button>
						<div data-tooltip={tooltipData}>
							<button
								id="bulkCharge"
								onClick={this.handleOpenBulkChargeTypeModal}
								className="btn btn--med btn--primary"
								disabled={disableBulkCharge}
							>
								Bulk Charge
							</button>
						</div>
					</div>
				</div>
			)
		);
	};

	renderHeader = ({ refreshGridData }) => {
		const { expanded, hasSelectedRows, isViewOnly } = this.state;
		const hasIfields = !!document.querySelector('.newtransaction__iframe') && !isEmpty(expanded);
		if (isViewOnly) return null;
		return (
			<div className="filter__container__header__item">
				<AddEditCustomer
					ref={this.modalRefs.header}
					advancedView={false}
					refreshGrid={refreshGridData}
					notificationRef={this.notificationRef}
					isDuplicate={this.isDuplicate}
					trigger={props => (
						<button className="btn btn--primary btn--med" disabled={hasIfields || hasSelectedRows} {...props}>
							New
						</button>
					)}
				/>
			</div>
		);
	};

	renderGridHeader = ({ refreshGridData }) => {
		const { expanded, hasSelectedRows, isViewOnly } = this.state;
		if (isViewOnly) return null;
		return (
			<GridHeader
				refreshGridData={refreshGridData}
				expanded={expanded}
				hasSelectedRows={hasSelectedRows}
				isViewOnly={isViewOnly}
				modalRef={this.modalRefs.gridHeader}
				notificationRef={this.notificationRef}
				isDuplicate={this.isDuplicate}
				hasMigrationToolAccess={this.state.hasMigrationToolAccess}
				toggleMigrationTool={this.toggleMigrationTool}
			/>
		);
	};

	render() {
		const {
			fetchingData,
			fetchingAdditionalData,
			filteredRows,
			columns,
			data,
			inlineFilters,
			expanded,
			filters,
			activeFilters,
			lastApiRefNum,
			defaultColumns,
			tooltipProps,
			hasSelectedRows,
		} = this.state;
		let isOpenCustomerModal = false;
		if (this.modalRefs.gridHeader.current && this.modalRefs.gridHeader.current.state.isModalOpen) {
			isOpenCustomerModal = true;
		}

		if (this.modalRefs.header.current && this.modalRefs.header.current.state.isModalOpen) {
			isOpenCustomerModal = true;
		}

		return (
			<SelectAllContext.Provider
				value={this.getContextValue(
					isEmpty(filteredRows) ? false : every(filteredRows, { _meta: { isSelected: true } })
				)}
			>
				<CustomersMigrationTool isOpen={this.isOpenMigrationTool} toggleMigrationTool={this.toggleMigrationTool} />
				{this.state.bulkProgressModal.isOpen ? (
					<BulkProgressModal
						className="modal--process--delete"
						action="Deleted"
						allSuccessMessage="All Customers have been successfully deleted."
						successMessage="All Customers except for the below have been successfully deleted."
						failureMessage="All customers failed to delete. See below."
						someErrorMessage="Some customers couldn't be deleted"
						isOpen={this.state.bulkProgressModal.isOpen}
						onClose={this.handleClosebulkProgressModal}
						progress={this.state.bulkProgressModal.progress}
						errors={this.state.bulkProgressModal.errors}
						mappedErrors={this.state.bulkProgressModal.errors.map((error, index) => (
							<tr key={error + index}>
								<td>{error}</td>
							</tr>
						))}
					/>
				) : null}
				<Notification ref={this.notificationRef} />
				{this.renderBulkChargeTypesModal()}

				<GridComponent
					emptyMessage="You should change your filter options"
					fetchingData={fetchingData}
					fetchingAdditionalData={fetchingAdditionalData}
					filteredRows={filteredRows}
					columns={columns}
					data={data}
					inlineFilters={inlineFilters}
					components={this.components}
					classes={this.classes}
					onChange={this.handleChange}
					isExpandable={true}
					expanded={expanded}
					title="Customers"
					enableExport={true}
					enablePrint={true}
					printTitle="Customer report"
					type="customers"
					filters={filters}
					activeFilters={activeFilters}
					enableFilters={!hasSelectedRows}
					fetchData={this.refetchData}
					lastApiRefNum={lastApiRefNum}
					showResults={true}
					mapCellArgs={this.mapCellArgs}
					showPrintDropdown={false}
					filterColumns={true}
					defaultColumns={defaultColumns}
					ref={this.gridRef}
					initialFetch={false}
					columnFilterType="/settings/user-settings/customers"
					kvaasResourceType="customer"
					useInlineFilters={true}
					fetchExportData={{ current: exportService.mapCustomerData, all: exportService.getCustomerData }}
					hasPaging={true}
					hasMoreData={this.hasMoreData}
					expandInSidebar={true}
					tooltipProps={tooltipProps}
					syncQueryFilters={true}
					queryFilterValues={this.handleCustomersQueryFilterValues}
					resolveColumnName={this.resolveColumnName}
					onVisibleColumnsChange={this.handleVisibleColumnChange}
					rowDetailsProps={{ isDuplicate: this.isDuplicate }}
					headerMenuTreshold={Infinity}
					ignoreHeaderMenuTreshold={isOpenCustomerModal}
					modalOverlayClassName={'modal__overlay modal__overlay--flex'}
				/>
			</SelectAllContext.Provider>
		);
	}
}

CustomerGrid.propTypes = {
	makePendingRequest: PropTypes.func,
	handleError: PropTypes.func,
	location: PropTypes.object,
	history: PropTypes.object,
	tabs: PropTypes.array.isRequired,
};

export default withError(withCancelable(CustomerGrid));
