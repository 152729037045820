import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { includes, isEmpty, map } from 'lodash-es';

export default class UploadInvoice extends Component {
	constructor(props) {
		super(props);
	}
	renderDropzoneBody = () => {
		return (
			<div className="upload__text">
				<p className="type--p3 type--p3--medium spc--bottom--tny">Upload Files or Drag and Drop</p>
				<p className="type--p4 type--color--text--light">
					Recommended image width is 842px.
					<br />
					Allowed formats are .jpg, .jpeg.
				</p>
			</div>
		);
	};

	displayAttachments = () => {
		if (isEmpty(this.props.uploadedFile)) return;
		const files = this.props.acceptedFiles;
		return map(this.props.files, fileData => {
			return (
				includes(fileData.type, 'image') && (
					<div className="upload__list__item spc--top--med">
						<div className="upload__list__item__thumbnail">
							<img alt="Uploaded file thumbnail" src={fileData.file} />
						</div>
						{files}
					</div>
				)
			);
		});
	};
	renderFileSizeError = () => {
		if (this.props.fileSizeError) {
			return (
				<p className="type--validation spc--bottom--med">File size limit is 15MB for emails sent through the portal</p>
			);
		}
		return null;
	};

	render = () => {
		return (
			<div className="card spc--bottom--lrg">
				<div className="card__header">
					<h5>Attach An Invoice</h5>
					<i
						className="icon icon--lrg icon--info datatooltip--top-left"
						data-tooltip="Max 5 Invoices can be uploaded."
					></i>
				</div>
				<div className="card__body">
					{this.renderFileSizeError()}
					<Dropzone onDrop={this.props.onDrop} multiple={true} maxFiles={5}>
						{({ getRootProps, getInputProps }) => (
							<section className="upload" {...getRootProps()}>
								<input {...getInputProps()} />
								<div className="upload__icon"></div>
								{this.renderDropzoneBody()}
							</section>
						)}
					</Dropzone>
					{this.displayAttachments()}
				</div>
			</div>
		);
	};
}

UploadInvoice.propTypes = {
	acceptedFiles: PropTypes.array,
	onDrop: PropTypes.func,
	files: PropTypes.any,
	uploadedFile: PropTypes.any,
	fileSizeError: PropTypes.bool.isRequired,
};
