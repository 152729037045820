import React, { Component } from 'react';

import sectionKeys from '../../routing/sections';
import NestedRoutes from 'routing/NestedRoutes';
import UploadCustomerPreview from 'components/upload-customer-preview/UploadCustomerPreview';
import { CustomerGrid } from 'components/customer-grid';

const routes = [
	{
		path: '/report',
		component: CustomerGrid,
		section: sectionKeys.customers,
		title: 'Customers',
		mobileTitle: 'Customers',
		linkTitle: 'Customers',
	},
	{
		path: '/upload-customer-preview',
		component: UploadCustomerPreview,
		section: sectionKeys.dashboard,
		title: 'Upload Customers and Schedules',
		mobileTitle: 'Upload Customers and Schedules',
		linkTitle: 'Upload Customers and Schedules',
	},
];

class Customers extends Component {
	render() {
		return <NestedRoutes tabs={routes} />;
	}
}

export default Customers;
