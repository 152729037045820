import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withContainer } from './../drag-and-drop';

const draggableUsersColumn = ({
	item: { dba, dba_alias, sortorder, mid },
	onChange,
	isPreview,
	handleArrowColumnMove,
	getModifier,
	sortDisabled,
	disabledSortOrders,
}) => {
	const disableUp = sortorder === disabledSortOrders.up;
	const disableDown = sortorder === disabledSortOrders.down;

	const arrowUpClassName = `btn btn--med btn--clear${getModifier(isPreview, disableUp)}`;

	const arrowDownClassName = `btn btn--med btn--clear${getModifier(isPreview, disableDown)}`;
	return (
		<React.Fragment key={dba}>
			<div className="table--draggable__td">
				<p className="type--p2">{dba}</p>
			</div>
			<div className="table--draggable__td">
				<p className="type--p2">{mid}</p>
			</div>
			<div className="table--draggable__td">
				<div className="inputgroup">
					<div className="inputgroup--main">
						<div className="spc--right--sml">
							<input
								type="text"
								id={mid}
								placeholder="Alias"
								value={dba_alias}
								name={mid}
								onChange={onChange}
								className="input input--sml"
							/>
						</div>
					</div>

					{!sortDisabled && (
						<Fragment>
							<div className="inputgroup--aside hide--from--lrg">
								<button className={arrowUpClassName} onClick={() => handleArrowColumnMove(mid, sortorder, true)}>
									<i className="icon icon--sml icon--arrow icon--arrow--top"></i>
								</button>
							</div>

							<div className="inputgroup--aside hide--from--lrg">
								<button className={arrowDownClassName} onClick={() => handleArrowColumnMove(mid, sortorder)}>
									<i className="icon icon--sml icon--arrow icon--arrow--down"></i>
								</button>
							</div>
						</Fragment>
					)}
				</div>
			</div>
			<div className="table--draggable__td right">
				<i className="icon icon--sml icon--move"></i>
			</div>
		</React.Fragment>
	);
};

draggableUsersColumn.defaultProps = {
	onChange: () => {},
};

draggableUsersColumn.propTypes = {
	onChange: PropTypes.func,
	item: PropTypes.object,
	isPreview: PropTypes.bool,
	onMouseOver: PropTypes.func,
	handleArrowColumnMove: PropTypes.func,
	getModifier: PropTypes.func.isRequired,
	sortDisabled: PropTypes.any.isRequired,
	disabledSortOrders: PropTypes.object.isRequired,
};

const mapItemToId = ({ mid }) => mid;

export default withContainer(draggableUsersColumn, mapItemToId, {
	dragStyle: {
		height: '66px',
		marginBottom: '6px',
		border: '1px dashed #c7ced5',
		backgroundColor: '#f8fafd',
		borderRadius: '6px',
		overflow: 'hidden',
	},
});
