import React, { Component, createRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
	map,
	transform,
	filter,
	join,
	split,
	range,
	concat,
	difference,
	get,
	cloneDeep,
	forEach,
	set,
	every,
	findKey,
} from 'lodash';

import { withError } from 'common/components/error';
import { withCancelable } from 'common/components/cancelable';
import { withBlock } from 'common/components/block';
import { Notification } from 'common/components/notifications';
import { withLoader } from 'common/components/loader';
import settingService from 'common/services/settingsService';
import { principalService } from 'common/services';
import FooterComponent from 'components/settings/components/FooterComponent';
import WebhookCard from './components/WebhookCard';

class WebhookSettings extends Component {
	constructor(props) {
		super(props);

		this.transactionKeys = [
			'xCommand',
			'xMaskedAccountNum',
			'xAccountType',
			'xCardLastFour',
			'xExp',
			'xCardType',
			'xAuthCode',
			'xEntryMethod',
			'xCurrency',
			'xDigitalWalletType',
			'xCashbackAmount',
			'xStatus',
			'xResponseError',
			'xResponseRefnum',
			'xResponseAuthCode',
			'xResponseAVSCode',
			'xResponseEmailVerificationCode',
			'xSoftwareName',
			'xUsername',
			'xComputerName',
			'xClientIP',
			'xIP',
			'xKey',
			'xSourceKey',
			'xDescription',
			'xInvoice',
			'xPONum',
			'xComments',
		];

		const principal = principalService.get();
		const isGoPlus = get(principal, 'idInfo.xIsGoPlusAccount', false);
		this.transactionKeys = isGoPlus ? [...this.transactionKeys, 'xProcessing'] : this.transactionKeys;

		this.billingKeys = [
			'xName',
			'xBillFirstName',
			'xBillMiddleName',
			'xBillLastName',
			'xBillCompany',
			'xEmail',
			'xBillPhone',
			'xBillMobile',
			'xBillStreet',
			'xBillStreet2',
			'xBillCity',
			'xBillState',
			'xBillZip',
			'xBillCountry',
			'xStreet',
			'xZip',
			'xWebsite',
		];

		this.shippingKeys = [
			'xShipFirstName',
			'xShipMiddleName',
			'xShipLastName',
			'xShipCompany',
			'xShipEmail',
			'xShipPhone',
			'xShipMobile',
			'xShipStreet',
			'xShipStreet2',
			'xShipCity',
			'xShipState',
			'xShipZip',
			'xShipCountry',
		];

		this.orderCustomkeys = [
			'xOrderID',
			'xOrderType',
			'xOrderDate',
			'xSubtotal',
			'xTip',
			'xTax',
			'xCoupon',
			'xShipMethod',
			'xShipAmount',
			'xScheduleID',
			'xCustNumber',
			'xCustID',
			'xCustomerID',
			'xExistingCustomer',
		];

		this.customKeys = range(1, 21).map(i => `xCustom${i.toString().padStart(2, '0')}`);

		this.otherKeys = [
			'xCVMResult',
			'xGatewayAVS',
			'xGatewayError',
			'xGatewayRefNum',
			'xGatewayResult',
			'xMerchantID',
			'xStatusReason',
		];

		this.fieldKeysMapping = {
			allBilling: this.billingKeys,
			allShipping: this.shippingKeys,
			allTransaction: this.transactionKeys,
			allOrderCustomer: this.orderCustomkeys,
			allCustom: this.customKeys,
			allOther: this.otherKeys,
		};

		this.requiredFields = [
			'xRefnum',
			'xAmount',
			'xEnteredDate',
			'xMaskedCardNumber',
			'xRequestAmount',
			'xResponseResult',
			'xReviewed',
			'xToken',
		];
		const allFields = concat(
			this.billingKeys,
			this.shippingKeys,
			this.transactionKeys,
			this.orderCustomkeys,
			this.customKeys,
			this.otherKeys
		);
		this.optionalFields = difference(allFields, this.requiredFields);

		this.state = {
			webhookSettings: {
				webhookUrl: 'https://',
				pin: '',
				optionalFields: transform(
					this.optionalFields,
					(result, field) => {
						result[field] = false;
					},
					{}
				),
			},
			disableSave: true,
		};

		this.notificationRef = createRef();
	}

	async componentDidMount() {
		this.props.showLoader(true);
		try {
			const result = await this.props.makePendingRequest(settingService.loadSettings('IPN'));
			const optionalFields = transform(
				filter(split(result.cardknoxSettings.IPN_CustomTransactionFields, ','), x => !this.requiredFields.includes(x)),
				(result, field) => {
					result[field] = true;
				},
				{ ...this.state.optionalFields }
			);

			const allOther = this.checkAllFieldsToggled(this.otherKeys, optionalFields);
			const allCustom = this.checkAllFieldsToggled(this.customKeys, optionalFields);
			const allOrderCustomer = this.checkAllFieldsToggled(this.orderCustomkeys, optionalFields);
			const allShipping = this.checkAllFieldsToggled(this.shippingKeys, optionalFields);
			const allBilling = this.checkAllFieldsToggled(this.billingKeys, optionalFields);
			const allTransaction = this.checkAllFieldsToggled(this.transactionKeys, optionalFields);

			this.setState({
				webhookSettings: {
					webhookUrl: result.cardknoxSettings.IPN_URL,
					pin: result.cardknoxSettings.IPN_Pin,
					optionalFields,
				},
				disableSave: false,
				allOther,
				allCustom,
				allOrderCustomer,
				allShipping,
				allBilling,
				allTransaction,
			});
		} catch (e) {
			this.props.handleError(e);
		}
		this.props.showLoader(false);
	}
	checkAllFieldsToggled = (keys, optionalFields) => {
		return every(keys, key => optionalFields[key] === true);
	};
	handleChange = ({ target: { name, value } }) => {
		this.props.handleBlockChange(true);
		this.setState({ webhookSettings: { ...this.state.webhookSettings, [name]: value } });
	};
	handleSelectOptionalField = field => {
		this.props.handleBlockChange(true);

		const updatedOptionalFields = {
			...this.state.webhookSettings.optionalFields,
			[field]: !this.state.webhookSettings.optionalFields[field],
		};

		this.setState(
			{
				webhookSettings: {
					...this.state.webhookSettings,
					optionalFields: updatedOptionalFields,
				},
			},
			() => {
				// ensure that the "all" fields checkbox is checked if all fields are selected
				const fieldCategory = findKey(this.fieldKeysMapping, fields => fields.includes(field));

				if (fieldCategory) {
					const allFieldsChecked = this.checkAllFieldsToggled(
						this.fieldKeysMapping[fieldCategory],
						this.state.webhookSettings.optionalFields
					);
					this.setState({ [fieldCategory]: allFieldsChecked });
				}
			}
		);
	};

	toggleAllFields = ({ target: { name, checked } }) => {
		const webhookSettings = cloneDeep(this.state.webhookSettings);
		const fields = this.fieldKeysMapping[name];

		if (fields) {
			forEach(fields, field => {
				set(webhookSettings, `optionalFields.${field}`, checked);
			});

			this.setState({
				[name]: checked,
				webhookSettings,
			});
		}

		this.props.handleBlockChange(true);
	};
	updateData = async setToDefault => {
		this.props.showLoader(true);
		try {
			let transactionFields = setToDefault
				? this.requiredFields
				: [
						...this.requiredFields,
						...filter(this.optionalFields, field => this.state.webhookSettings.optionalFields[field]),
				  ];
			if (this.state.webhookSettings.pin !== '') transactionFields = [...transactionFields, 'xSignature'];
			const joinedFields = join(transactionFields, ',');
			const result = await this.props.makePendingRequest(
				settingService.updateSettings('IPN', {
					IPN_URL: setToDefault ? '' : this.state.webhookSettings.webhookUrl,
					IPN_Pin: setToDefault ? '' : this.state.webhookSettings.pin,
					IPN_CustomTransactionFields: joinedFields,
					IPN_CustomAdjustFields: joinedFields,
				})
			);
			this.props.handleBlockChange(false);
			this.notificationRef.current.addNotification({
				message: setToDefault ? 'Settings reset successfully' : 'Settings saved successfully',
				success: true,
				ref: result.refNum,
			});
		} catch (e) {
			this.props.handleError(e);
		}
		this.props.showLoader(false);
	};

	save = async () => {
		await this.updateData(false);
	};

	shouldDisableSaveButton = () => {
		return this.props.isLoading || this.state.disableSave;
	};

	renderWebhookSettings = () => {
		const cardsData = [
			{
				name: 'Billing Fields',
				keys: this.billingKeys,
				allToggled: this.state.allBilling,
				toggleAllName: 'allBilling',
			},
			{
				name: 'Shipping Fields',
				keys: this.shippingKeys,
				allToggled: this.state.allShipping,
				toggleAllName: 'allShipping',
			},
			{
				name: 'Transaction Fields',
				keys: this.transactionKeys,
				allToggled: this.state.allTransaction,
				toggleAllName: 'allTransaction',
			},
			{
				name: 'Order/Customer Details',
				keys: this.orderCustomkeys,
				allToggled: this.state.allOrderCustomer,
				toggleAllName: 'allOrderCustomer',
			},
			{
				name: 'Custom Fields',
				keys: this.customKeys,
				allToggled: this.state.allCustom,
				toggleAllName: 'allCustom',
			},
			{
				name: 'Other Fields',
				keys: this.otherKeys,
				allToggled: this.state.allOther,
				toggleAllName: 'allOther',
			},
		];

		const cards = map(cardsData, card => (
			<WebhookCard
				key={card.name}
				isLoading={this.props.isLoading}
				webhookSettings={this.state.webhookSettings}
				handleSelectOptionalField={this.handleSelectOptionalField}
				toggleAllFields={this.toggleAllFields}
				{...card}
			/>
		));
		return cards;
	};
	render = () => {
		return (
			<Fragment>
				<div className="settings__header">
					<h3 className="settings__title">Webhook Settings</h3>
				</div>

				<Notification ref={this.notificationRef} />

				<div>
					<div className="flex--primary flex--gap--tny spc--bottom--lrg">
						<h5>Configure Webhook</h5>
						<i
							className="icon icon--tny icon--regular--info"
							data-tooltip="Configure webhook for your Sola account"
						></i>
					</div>

					<p className="type--p4 type--color--text--light spc--bottom--sml--alt">
						Enter the URL to receive webhook notifications
					</p>
					<div className="form__group">
						<div className="form__group__header">
							<label htmlFor="webhookSettings.webhookUrl" className="form__group__label">
								Postback URL
							</label>
						</div>
						<input
							type="text"
							id="webhookSettings.webhookUrl"
							name="webhookUrl"
							onChange={this.handleChange}
							value={this.state.webhookSettings.webhookUrl}
							disabled={this.props.isLoading}
							className="input input--med"
						/>
					</div>

					<p className="type--p4 type--color--text--light spc--bottom--sml--alt">
						Adding a PIN allows for verification that the webhook originated from Sola. For more info, refer to our docs{' '}
						<a
							className="btn btn--link btn--link--underline"
							href="https://docs.cardknox.com/cardknox-products/webhooks#webhook-pin"
						>
							here
						</a>
						{/*
						 */}
						.
					</p>
					<div className="form__group">
						<div className="form__group__header">
							<label htmlFor="webhookSettings.pin" className="form__group__label">
								PIN
							</label>
						</div>
						<input
							onChange={this.handleChange}
							value={this.state.webhookSettings.pin}
							type="text"
							id="webhookSettings.pin"
							name="pin"
							disabled={this.props.isLoading}
							className="input input--med"
						/>
					</div>
				</div>
				{this.renderWebhookSettings()}
				<FooterComponent
					save={this.save}
					disabled={this.shouldDisableSaveButton()}
					isLoading={this.props.isLoading}
					hideReset={true}
				/>
			</Fragment>
		);
	};
}

WebhookSettings.propTypes = {
	isExpanded: PropTypes.bool.isRequired,
	toggleExpand: PropTypes.func.isRequired,
	handleError: PropTypes.func.isRequired,
	handleBlockChange: PropTypes.func.isRequired,
	makePendingRequest: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
	showLoader: PropTypes.func.isRequired,
};

export default withLoader(withError(withCancelable(withBlock(WebhookSettings))));
