import React from 'react';
import PropTypes from 'prop-types';
const SegmentedControl = ({ selectedTab, isCanadian, handleSwitchPaymentMethod }) => (
	<ul className="segmented-control">
		<li className="segmented-control__item">
			<button
				name="cc"
				onClick={handleSwitchPaymentMethod}
				type="button"
				className={`segmented-control__link${selectedTab === 'cc' ? ' is-active' : ''}`}
				tabIndex="-1"
			>
				Credit Card
			</button>
		</li>
		{!isCanadian && (
			<li className="segmented-control__item">
				<button
					name="check"
					onClick={handleSwitchPaymentMethod}
					type="button"
					className={`segmented-control__link${selectedTab === 'check' ? ' is-active' : ''}`}
					tabIndex="-1"
				>
					Check
				</button>
			</li>
		)}
	</ul>
);
SegmentedControl.propTypes = {
	selectedTab: PropTypes.string.isRequired,
	isCanadian: PropTypes.bool.isRequired,
	handleSwitchPaymentMethod: PropTypes.func.isRequired,
};
export default SegmentedControl;
