import React, { Component, createRef, Fragment } from 'react';
import { get, noop } from 'lodash';
import { bool, func, string } from 'prop-types';

import { emailService } from 'common/services';
import { withError } from 'common/components/error';
import { withCancelable } from 'common/components/cancelable';
import { withLoader } from 'common/components/loader';
import { emailTemplates } from 'common/utilities';
import { getMyPlan } from 'common/utilities/plans';
import { Modal } from 'common/components/modal';
import { Notification } from 'common/components/notifications';

class EnrollWithPaymentSiteComponent extends Component {
	constructor() {
		super();

		const { monthlyFee, setupFee } = getMyPlan();

		this.state = {
			isOpenSignUpModal: false,
			monthlyFee,
			setupFee,
		};

		this.notificationRef = createRef();
	}

	handleSignUp = async () => {
		const { makePendingRequest, handleError } = this.props;
		const addNotification = get(this.notificationRef, 'current.addNotification', noop);
		const emailProps = {
			TemplateName: emailTemplates.paymentSite,
		};
		try {
			this.props.showLoader(true);
			await makePendingRequest(emailService.sendEmail(emailProps), 'sendEmail');
			addNotification({
				success: true,
				message: (
					<div>
						<p className="spc--bottom--sml">
							We are confirming your request to add a Hosted PaymentSITE to your merchant account.  You will receive an
							email confirmation within the next 48 hours, once your account has been set up.
						</p>
						<p>—The Cardknox Team</p>
					</div>
				),
			});
		} catch (e) {
			handleError(e);
		}
		this.props.showLoader(false);
		this.closeSignUpModal();
	};

	closeSignUpModal = () => this.setState({ isOpenSignUpModal: false });

	openSignUpModal = (isOpenSignUpModal = false) => this.setState({ isOpenSignUpModal });

	renderSignUpModal = () => {
		const { isOpenSignUpModal } = this.state;

		return (
			<Modal
				isOpen={isOpenSignUpModal}
				onClose={this.closeSignUpModal}
				shouldCloseOnOverlayClick={false}
				className="modal__content modal--sml"
			>
				<div className="modal">
					<div className="modal__header">
						<div className="flex flex--tertiary">
							<div className="modal__header__title">Sign Up for PaymentSITE</div>
						</div>
					</div>
					<div className="modal__body">
						<div className="message message--default spc--bottom--sml">
							<p>
								Thank you for your interest in setting up a Hosted PaymentSITE for your business. Please confirm you'd
								like to add this to your merchant account by clicking the Sign Up button below.
							</p>
						</div>
					</div>
					<div className="modal__footer">
						<button
							type="button"
							tabIndex="-1"
							className="btn btn--med btn--primary"
							onClick={this.handleSignUp}
							disabled={this.props.isLoading}
						>
							Sign Up
						</button>
					</div>
				</div>
			</Modal>
		);
	};

	render() {
		const { monthlyFee, setupFee } = this.state;
		const { disclaimer, displayHeader, bannerCut } = this.props;

		return (
			<Fragment>
				<Notification ref={this.notificationRef} />
				{this.renderSignUpModal()}
				{displayHeader && (
					<div className="settings__header">
						<h3 className="settings__header__title">PaymentSITE Management</h3>
					</div>
				)}
				<div className="get-started">
					<div className="flex--tertiary flex--gap--sml spc--bottom--xxlrg">
						<p className="type--p2 type--color--text--light">{disclaimer}</p>
						<button onClick={this.openSignUpModal} className="btn btn--med btn--primary">
							Sign Up
						</button>
					</div>

					<h4>Looking for an easier way to collect outstanding balances?</h4>
					<div
						className={`get-started__banner${
							bannerCut === true ? ' get-started__banner--cut spc--top--lrg spc--bottom--lrg' : ''
						}`}
					></div>
					<p className="type--p2 type--color--text--light spc--bottom--xlrg">
						PaymentSITE is a secure online form that can be customized and shared with your customers via a unique URL.
						<br />
						<br />
						Once you're set up with a PaymentSITE, you'll be able to configure the display and required fields (billing,
						shipping and transaction info) right here in this tab. You can then send your custom PaymentSITE to your
						client with the click of a button using the Send Payment Request module!
					</p>

					<div className="get-started__pricing spc--bottom--xlrg">
						<div className="get-started__pricing__item label">Pricing</div>
						<div className="get-started__pricing__item">Setup Fee: {setupFee}</div>
						<div className="get-started__pricing__item">Monthly Fee: {monthlyFee}</div>
					</div>
					<button onClick={this.openSignUpModal} className="btn btn--med btn--primary spc--bottom--xxlrg">
						Sign Up
					</button>
				</div>
			</Fragment>
		);
	}
}

EnrollWithPaymentSiteComponent.defaultProps = {
	displayHeader: false,
	bannerCut: false,
};

EnrollWithPaymentSiteComponent.propTypes = {
	handleError: func.isRequired,
	makePendingRequest: func.isRequired,
	isLoading: bool.isRequired,
	showLoader: func.isRequired,
	displayHeader: bool,
	bannerCut: bool,
	disclaimer: string,
};

export default withError(withCancelable(withLoader(EnrollWithPaymentSiteComponent)));
