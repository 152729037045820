export const makeCancelable = promise => {
	let hasCanceled_ = false;

	const wrappedPromise = (async () => {
		try {
			const val = await promise;
			if (hasCanceled_) {
				throw { isCanceled: true };
			}
			return val;
		} catch (error) {
			if (hasCanceled_) {
				throw { isCanceled: true };
			}
			throw error;
		}
	})();

	return {
		promise: wrappedPromise,
		cancel() {
			hasCanceled_ = true;
		},
	};
};
