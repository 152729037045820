const cognitoErrorMap = {
	AliasExistsException: { message: 'An account with this email already exists.' },
	CodeMismatchException: { message: 'The provided code does not match the expected value.' },
	ExpiredCodeException: { message: 'The provided code has expired.' },
	TooManyFailedAttemptsException: { message: 'You have made too many failed attempts.' },
	UserNotFoundException: { message: 'User with provided email does not exist.' },
	NotAuthorizedException: { message: 'Too many failed attempts.' },
	InvalidParameterException: { message: 'Code mismatch' },
	EnableSoftwareTokenMFAException: { message: 'Code mismatch' },
};

export default cognitoErrorMap;
