import { map, uniq, join, split, toLower, each, filter as filterMethod } from 'lodash';
import moment from 'moment';
import { apiToLocalMoment } from './../utilities';

import httpService from './httpService';
import principalService from './principalService';
import transactionService from './transactionService';

const { apiDateTimeFormat, apiResponseDateTimeFormat } = ApplicationSettings;
const transferFields =
	'xRefNum,xCurrency,xAmount,xName,xEnteredDate,xResponseResult,xCommand,xResponseRefNum,xStatus,xTransferMerchantName';

class TransferService {
	constructor(httpService, principalService, transactionService) {
		this.httpService = httpService;
		this.principalService = principalService;
		this.transactionService = transactionService;
	}

	getTransferFields = () => {
		return transferFields;
	};

	filterTransactions = async (filter, fields, maxRecords = 1000) => {
		if (fields !== null && fields.length > 0) {
			filter.xFields = join(uniq(split(fields, ',')), ',');
		} else {
			filter.xFields = this.getTransferFields();
		}

		return maxRecords > 1000
			? await this.filterTransactionsAll(filter, fields)
			: await this.filterTransactionsRequest(filter, fields, maxRecords);
	};

	filterTransactionsRequest = async (filter, fields, maxRecords = 1000, displayOlderTransactionsFirst = false) => {
		const result = await this.transactionService.filterTransactionsRequest(
			filter,
			maxRecords,
			fields,
			displayOlderTransactionsFirst,
			false
		);
		return await this.parseResult(result);
	};

	filterTransactionsAll = async (filter, fields, displayOlderTransactionsFirst = false) => {
		const result = await this.transactionService.filterTransactionsAll(
			filter,
			fields,
			displayOlderTransactionsFirst,
			false
		);
		return await this.parseResult(result);
	};

	parseResult = async result => {
		if (result) {
			result.xReportingMaxTransactions = parseInt(result.xReportingMaxTransactions);
			result.xRecordsReturned = parseInt(result.xRecordsReturned);
			const principal = this.principalService.get();
			const defaultCurrency = (principal && principal.idInfo && principal.idInfo.xMerchantCurrency) || 'USD';
			result.xReportData = filterMethod(result.xReportData, ({ xCommand }) => toLower(xCommand).startsWith('xfer'));
			result.xReportData = await Promise.all(
				map(result.xReportData, async item => {
					if (item) {
						await this.parseItem(item, defaultCurrency);
					}
					return item;
				})
			);
		}
		return result;
	};

	parseItem = async (item, defaultCurrency) => {
		item.xCommand = this.calcTransactionType(item.xCommand);
		item.xEnteredDate = await apiToLocalMoment(item.xEnteredDate, ApplicationSettings.apiResponseDateTimeFormat);
		item.xAmount = transactionService.toNumber(item.xAmount);
		item.xCurrency = item.xCurrency || defaultCurrency;
	};

	calcTransactionType = command => {
		switch (toLower(command)) {
			case 'xfer:out':
				return 'Transfer Out';
			case 'xfer:in':
				return 'Transfer In';
			default:
				return 'NA';
		}
	};

	getMinOrMaxDateFromData = (data, getMin = true) => {
		let refNums = [];
		let foundDate = null;
		each(data, ({ xEnteredDate, xRefNum }) => {
			const date = moment(xEnteredDate, apiResponseDateTimeFormat);
			if (!foundDate || (getMin ? date.isBefore(foundDate) : date.isAfter(foundDate))) {
				foundDate = date;
				refNums = [xRefNum];
			} else if (date.isSame(foundDate)) {
				refNums.push(xRefNum);
			}
		});

		return { foundDate, refNums };
	};

	getNewStartEndDates = (startDate, endDate, data, displayOlderTransactionsFirst = false) => {
		const { foundDate, refNums } = this.getMinOrMaxDateFromData(data, !displayOlderTransactionsFirst);
		let start = displayOlderTransactionsFirst ? foundDate : moment(startDate, apiDateTimeFormat);
		let end = displayOlderTransactionsFirst ? moment(endDate, apiDateTimeFormat) : foundDate;

		if (foundDate && !displayOlderTransactionsFirst) {
			end.add(999, 'ms');
		}

		return {
			start,
			end,
			refNums,
		};
	};
}

const transferService = new TransferService(httpService, principalService, transactionService);

export default transferService;
