import React from 'react';
import PropTypes from 'prop-types';
const FormGroupPreview = ({ label, value, previewField }) => (
	<div className={`grid-sidebar__field${previewField === true ? ' is-preview' : ''}`}>
		<label className="type--p2 type--color-text--secondary">{label}</label>
		<p
			className={`${previewField === true ? 'type--h4' : 'type--p2 type--p2--medium'} ${
				value === '---' ? 'type--color--text--opaque' : ''
			}`}
		>
			{value}
		</p>
	</div>
);
FormGroupPreview.propTypes = {
	label: PropTypes.string,
	value: PropTypes.string,
	previewField: PropTypes.bool,
};

export default FormGroupPreview;
