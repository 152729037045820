import React from 'react';
import PropTypes from 'prop-types';

import { renderIf } from '../../utilities';

const emptyGrid = ({ fetchingData, emptyMessage }) => (
	<React.Fragment>
		{fetchingData ? (
			<React.Fragment>
				<div className="loader--progress" />
				<p className="react-grid-Empty-text">Loading...</p>
			</React.Fragment>
		) : (
			<React.Fragment>
				<div className="react-grid-Empty-img"></div>
				<div className="react-grid-Empty-title">0 Results</div>
				{renderIf(emptyMessage)(<p className="react-grid-Empty-text">{emptyMessage}</p>)}
			</React.Fragment>
		)}
	</React.Fragment>
);

emptyGrid.propTypes = {
	fetchingData: PropTypes.bool,
	emptyMessage: PropTypes.string,
};

export default emptyGrid;
