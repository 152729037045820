import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { clone, every, head, map } from 'lodash';
import { DraggableColumn } from 'common/components/settings';
import { kvaasService } from 'common/services';
import handleInvalidRevision from '../utils/invalidRevision';
import { withLoader } from 'common/components/loader';
import { withError } from 'common/components/error';
import { withCancelable } from 'common/components/cancelable';
import { withBlock } from 'common/components/block';
import BaseSettings from 'components/settings/pages/BaseSettings';
import { Notification } from 'common/components/notifications';
import { kvaasResources } from 'common/utilities';
import { Select } from 'common/components/select';
import { predefinedDates } from 'common/components/date-picker';

const predefinedDateOptions = map(predefinedDates, ({ key: name, displayValue: label }) => ({ name, label }));

class UserSettingsDisputes extends BaseSettings {
	constructor(props) {
		super(props);
		this.state = this.initialState;
	}

	get initialState() {
		return {
			reportType: 'disputeReport',
			disputeReport: {
				fields: [...this.disputeInitialFields],
				defaultValues: {
					date: '7',
					limit: '20',
					approved: false,
					includeVoidStatus: false,
					multiKeyReporting: false,
				},
				oldData: {
					isDefault: null,
					order: null,
					defaultValues: null,
				},
			},
			sectionsExpanded: {
				columns: false,
			},
		};
	}

	componentDidMount = async () => {
		this.props.showLoader(true);
		await this.fetchData();
		this.props.showLoader(false);
	};
	requiredKvaasResources() {
		return kvaasService.get(
			...[
				{ ...kvaasResources.disputeReportDefaultColumns, throwError: true },
				{ ...kvaasResources.disputeReportOrder, throwError: true },
				{ ...kvaasResources.disputeReportDefaultValues, throwError: true },
			]
		);
	}

	getData = async () => {
		const getKvaas = this.requiredKvaasResources();
		const [
			disputeReportDefaultColumns,
			disputeReportOrder,
			disputeReportDefaultValues,
		] = await this.props.makePendingRequest(getKvaas, 'kvaas');

		return {
			disputeReportDefaultColumns,
			disputeReportOrder,
			disputeReportDefaultValues,
		};
	};

	fetchData = async setToDefault => {
		if (setToDefault) {
			this.setState(this.initialState);
		}
		this.props.showLoader(true);
		try {
			const { disputeReportDefaultColumns, disputeReportOrder, disputeReportDefaultValues } = await this.getData();

			const newState = this.mapResponseToState(
				disputeReportDefaultColumns,
				disputeReportOrder,
				null,
				null,
				null,
				null,
				disputeReportDefaultValues
			);

			this.setState({
				disputeReport: newState,
			});
		} catch (e) {
			if (this.props.handleError(e)) {
				this.props.handleKvaasLoadError();
			}
		}
		this.props.showLoader(false);
	};
	setToDefaultValues = async () => {
		return [
			this.mapStateToFields('disputeReport', 'order', kvaasResources.disputeReportOrder, true),
			this.mapStateToFields('disputeReport', 'isDefault', kvaasResources.disputeReportDefaultColumns, true),
			{
				newData: {
					revision: 0,
					data: { date: '7', limit: '20', approved: false },
				},
				oldData: this.state.disputeReport.oldData.defaultValues,
				...kvaasResources.disputeReportDefaultValues,
			},
		];
	};

	mapReportToState = () => {
		return [
			this.mapStateToFields('disputeReport', 'order', kvaasResources.disputeReportOrder),
			this.mapStateToFields('disputeReport', 'isDefault', kvaasResources.disputeReportDefaultColumns),
			{
				newData: { revision: 0, data: this.state.disputeReport.defaultValues },
				oldData: this.state.disputeReport.oldData.defaultValues,
				...kvaasResources.disputeReportDefaultValues,
			},
			{
				newData: {
					revision: 0,
					data: {
						displayTokenColumn: this.state.disputeReport.displayTokenColumn,
					},
				},
				oldData: this.state.disputeReport.oldData.userSettings,
				...kvaasResources.userSettings,
			},
		];
	};

	save = async setToDefaults => {
		this.props.showLoader(true);
		let err;
		let refNum;

		try {
			const mappedState = setToDefaults ? await this.setToDefaultValues() : this.mapReportToState();
			const response = await this.props.makePendingRequest(kvaasService.save(...mappedState), 'save');
			refNum = head(response).refNum;
			this.props.handleBlockChange(false);
			await this.fetchData(setToDefaults);
		} catch (e) {
			this.props.showLoader(false);
			err = this.props.handleError(e, { delayMessage: true });
			if (err) {
				await this.fetchData();
			}
		}
		this.notifySuccess(err, setToDefaults, 'Dispute settings', refNum);
		this.props.showLoader(false);
	};

	handleDefaultLoadLimitChange = ({ label: limit }) => {
		const defaultValues = clone(this.state.disputeReport.defaultValues);
		defaultValues.limit = limit;
		this.setState({
			disputeReport: {
				...this.state.disputeReport,
				defaultValues: defaultValues,
			},
		});
		this.props.handleBlockChange(true);
	};
	toggleExpandCollapseSection = section => () => {
		this.setState({
			sectionsExpanded: { ...this.state.sectionsExpanded, [section]: !this.state.sectionsExpanded[section] },
		});
	};

	renderManageColumns = columns => (
		<button className="card__header card__header--expandable" onClick={this.toggleExpandCollapseSection('columns')}>
			<h5>Manage Columns</h5>
			<i className={`icon icon--sml icon--chevron--${columns ? 'top' : 'down'}--primary`}></i>
		</button>
	);

	renderSelectAll = (selectAllLabel, selectAllValue) => (
		<div className="table--draggable__header">
			<div className="table--draggable__th">
				<input
					type="checkbox"
					name="selectAll"
					id="selectAll"
					checked={selectAllValue}
					className="input--check"
					onChange={this.handleChange}
				/>
				<label htmlFor="selectAll" className="type--color--text--medium type--wgt--medium type--base">
					{selectAllLabel}
				</label>
			</div>
			<div className="table--draggable__th"></div>
		</div>
	);

	render = () => {
		const { fields, defaultValues } = this.state.disputeReport;
		const {
			sectionsExpanded: { columns },
		} = this.state;
		const selectAllValue = every(fields, i => i.hide || i.isDefault);
		const selectAllLabel = selectAllValue ? 'Unselect all' : 'Select all';

		return (
			<Fragment>
				<div className="settings--main settings--main--alt">
					<div className="settings__header">
						<h3 className="settings__title">User Settings</h3>
						<h5>Dispute Settings</h5>
					</div>

					<div ref={top}></div>

					<div className="card spc--bottom--lrg">
						{this.renderManageColumns(columns)}
						{columns && (
							<div className="card__body">
								<div className="table table--draggable table--draggable--disputes">
									{this.renderSelectAll(selectAllLabel, selectAllValue)}
									<DraggableColumn
										items={fields}
										disable={false}
										onChange={this.handleChange}
										removeCustomField={this.removeCustomField}
										onHover={this.moveColumn}
									/>
								</div>
							</div>
						)}
					</div>

					<div className="form__group">
						<div className="form__group__header">
							<span className="form__group__label">Default Date Range</span>
						</div>
						<Select
							name="defaultDateRange"
							id="defaultDateRange"
							reportType="disputeReport"
							value={this.getDate(defaultValues.date)}
							options={predefinedDateOptions}
							onChange={e => this.handleDefaultDateRangeChange(e, 'disputeReport')}
							getOptionValue={option => option.name}
						/>
					</div>
					<div className="form__group">
						<div className="form__group__header">
							<span className="form__group__label">Default Load Limit</span>
						</div>
						<Select
							name="defaultLoadLimit"
							id="defaultLoadLimit"
							reportType="disputeReport"
							value={this.getLimit(defaultValues.limit)}
							options={this.loadMoreOptionsWithAll}
							onChange={e => this.handleDefaultLoadLimitChange(e, 'disputeReport')}
							getOptionValue={option => option.name}
						/>
					</div>
				</div>

				<div className="settings__footer">{this.renderSaveButton()}</div>

				<Notification ref={this.notification} />
			</Fragment>
		);
	};
}

UserSettingsDisputes.propTypes = {
	makePendingRequest: PropTypes.func,
	handleError: PropTypes.func,
	showLoader: PropTypes.func,
	history: PropTypes.object,
	match: PropTypes.object,
	location: PropTypes.object,
	isLoading: PropTypes.bool,
	handleBlockChange: PropTypes.func,
};

export default withError(withLoader(withCancelable(withBlock(UserSettingsDisputes))), handleInvalidRevision);
