import React, { Component, Fragment } from 'react';
import { object, func, array, bool } from 'prop-types';
import { map, cloneDeep, find } from 'lodash-es';
import Select from 'react-select';

class PaymentSiteAdditionalSettings extends Component {
	constructor(props) {
		super(props);
		this.state = { expanded: cloneDeep(props.expanded) };
	}
	handleExpand = key => {
		const { expanded } = this.props;
		expanded[key] = !expanded[key];
		this.setState({
			expanded,
		});
	};

	renderTypeComponent = setting => {
		const { data, handleAdditionalSettingsDataChange } = this.props;
		const { key, options } = setting;
		if (setting.type === 'select') {
			const value = find(options, option => option.value === data[key]) || options[1];
			return (
				<Select
					{...setting}
					value={value}
					onChange={e => handleAdditionalSettingsDataChange({ target: { value: e.value } }, key)}
				/>
			);
		}

		return <input onChange={e => handleAdditionalSettingsDataChange(e, key)} value={data[key]} {...setting} />;
	};
	renderStandaloneComponent = setting => {
		const { expanded } = this.state;
		const { label, key } = setting;
		const { handleAdditionalSettingsClear, internalSetting, hideHeader, groupedSetting } = this.props;
		const wrapperClassName = internalSetting || groupedSetting ? '' : 'paymentsite__form__main__card clearfix';
		const spacingClassName = hideHeader ? '' : 'spc--bottom--med';
		return (
			<Fragment key={key}>
				<form className={wrapperClassName}>
					<div className="spc--bottom--lrg">
						<h5>{label}</h5>
					</div>
					{!hideHeader && (
						<div>
							<button
								type="button"
								className="btn btn--sml btn--clear"
								onClick={() => handleAdditionalSettingsClear(key, label)}
								data-tooltip={'Clear'}
							>
								<i className="icon icon--sml icon--delete align--v--neg--5"></i>
							</button>
							<button
								type="button"
								className="btn btn--sml btn--clear"
								onClick={() => this.handleExpand(key)}
								data-tooltip={expanded[key] ? 'Collapse' : 'Expand'}
							>
								<i
									className={`icon icon--sml icon--arrow icon--arrow--right--grey align--v--neg--5 ${
										expanded[key] ? 'is-expanded' : ''
									}`}
								></i>
							</button>
						</div>
					)}
					<div>
						{expanded[key] && (
							<Fragment>
								{!hideHeader && <div className="separator separator--grey1 spc--bottom--med"></div>}
								<div className={spacingClassName}>{this.renderTypeComponent(setting)}</div>
							</Fragment>
						)}
					</div>
				</form>
			</Fragment>
		);
	};
	render() {
		return map(this.props.settings, setting => this.renderStandaloneComponent(setting));
	}
}

PaymentSiteAdditionalSettings.propTypes = {
	handleAdditionalSettingsDataChange: func.isRequired,
	handleAdditionalSettingsClear: func.isRequired,
	updateExpanded: func,
	expanded: object.isRequired,
	data: object.isRequired,
	settings: array.isRequired,
	internalSetting: bool,
	groupedSetting: bool,
	hideHeader: bool,
};

export default PaymentSiteAdditionalSettings;
