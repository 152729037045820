import React from 'react';
import PropTypes from 'prop-types';

const TransactionRowGridActionsTooltip = ({ tooltip, infoDimensions }) => {
	if (!tooltip) {
		return null;
	}
	return (
		<table
			className="action-icons datatooltip--no-wrap datatooltip--top-left"
			style={{
				display: 'none',
				// left: `${infoDimensions.width || 0}px`,
				right: 21,
				bottom: `calc(100% - ${infoDimensions.height || 0}px)`,
				position: 'fixed',
				backgroundColor: '#fff',
				zIndex: 99,
				transform: 'translate(-50px, -7px)',
			}}
		>
			<tbody>{tooltip}</tbody>
		</table>
	);
};

TransactionRowGridActionsTooltip.propTypes = {
	tooltip: PropTypes.any,
	infoDimensions: PropTypes.object,
};

export default TransactionRowGridActionsTooltip;
