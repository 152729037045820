import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { invokeIfFunction } from 'common/utilities';
import { SidebarContext } from 'common/contexts';

class AdvancedAddOn extends Component {
	constructor(props) {
		super(props);
	}
	static contextType = SidebarContext;
	componentDidMount() {
		this.context.toggleSidebarExpandedClass(true);
	}
	closeAdvancedAddOn = () => {
		this.context.toggleSidebarExpandedClass(false);
		this.props.history.push('/features');
	};
	render() {
		const { feature, onBeforeActivate, isLoading } = this.props;
		const {
			templateId,
			successMessage,
			path,
			question,
			activateLabel,
			additionalContent,
			advancedDetails: { title, subtitle, details, learnMoreLink },
		} = feature;
		return isLoading ? null : (
			<div className="add-ons__sidebar">
				<div className="add-ons__sidebar__header">
					<h5>{title}</h5>
					<button tabIndex="-1" className="btn btn--action btn--action--secondary" onClick={this.closeAdvancedAddOn}>
						<i className="icon icon--sml icon--close"></i>
					</button>
				</div>

				<div className="add-ons__sidebar__body">
					<label className="add-ons__sidebar__body__title">{subtitle}</label>
					<p className="add-ons__sidebar__body__description">{invokeIfFunction(details)}</p>
					<button
						className="btn btn--med btn--link btn--link--underline"
						onClick={() => {
							window.open(learnMoreLink, '_blank');
						}}
					>
						Learn More
					</button>
				</div>

				<div className="add-ons__sidebar__footer">
					<button
						onClick={() => onBeforeActivate(templateId, successMessage, path, question, additionalContent)}
						className="btn btn--med btn--primary"
					>
						{activateLabel || 'Activate'}
					</button>
				</div>
			</div>
		);
	}
}

AdvancedAddOn.propTypes = {
	feature: PropTypes.object.isRequired,
	onBeforeActivate: PropTypes.func.isRequired,
	history: PropTypes.object,
	isLoading: PropTypes.bool,
};

export default AdvancedAddOn;
