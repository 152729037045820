import { Authorize } from 'common/utilities';
import { map } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { NavLink, withRouter } from 'react-router-dom';

const Tabs = ({ tabs, match: { url }, useRoutes, onTabChange, activeTab }) => {
	const [showLinksDropDown, setShowLinksDropDown] = useState(false);
	function toggleShowLinksDropdown() {
		return setShowLinksDropDown(!showLinksDropDown);
	}
	const handleTabChange = useCallback(
		({ target }) => {
			onTabChange(target.dataset.path);
			if (showLinksDropDown) {
				toggleShowLinksDropdown();
			}
		},
		[onTabChange, showLinksDropDown]
	);
	if (!tabs) return null;
	const baseUrl = url.substr(0, url.lastIndexOf('/'));
	return (
		<div className="tabs spc--bottom--med">
			{map(tabs, ({ path, linkTitle, section }) =>
				path ? (
					<Authorize key={path} section={section}>
						{useRoutes ? (
							<NavLink activeClassName="is-active" className="tabs__link" to={`${baseUrl}${path}`}>
								{linkTitle}
							</NavLink>
						) : (
							<button
								data-path={path}
								onClick={handleTabChange}
								className={`tabs__link${linkTitle === activeTab ? ' is-active' : ''}`}
							>
								{linkTitle}
							</button>
						)}
					</Authorize>
				) : null
			)}
		</div>
	);
};

Tabs.defaultProps = {
	useRoutes: true,
};

Tabs.propTypes = {
	tabs: PropTypes.arrayOf(
		PropTypes.shape({
			path: PropTypes.string.isRequired,
			linkTitle: PropTypes.string.isRequired,
			section: PropTypes.string,
		})
	),
	match: PropTypes.object,
	onTabChange: PropTypes.func,
	useRoutes: PropTypes.bool,
	activeTab: PropTypes.string,
};

export default withRouter(Tabs);
