import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { map, isEmpty } from 'lodash';

import { exportTypesByKey } from './export-options';

function ExportItemComponent({ title, type: { key, name }, all, selectedExport }) {
	return (
		<li className="buttondropdown__item" onClick={() => selectedExport({ key, all })}>
			{title} ({name})
		</li>
	);
}

ExportItemComponent.propTypes = {
	title: PropTypes.string,
	type: PropTypes.object,
	all: PropTypes.bool,
	selectedExport: PropTypes.func,
};

function ExportDropdownComponent({ title, allTitle, selectedExport, exportTypes, children, hasMoreData }) {
	return (
		<ul className="buttondropdown__list">
			{map(exportTypes, type => (
				<Fragment key={type.key}>
					<ExportItemComponent title={title} type={type} all={false} selectedExport={selectedExport} />
					{!isEmpty(allTitle) && hasMoreData && (
						<ExportItemComponent title={allTitle} type={type} all={true} selectedExport={selectedExport} />
					)}
				</Fragment>
			))}
			{children != null ? <li>{children}</li> : null}
		</ul>
	);
}

ExportDropdownComponent.propTypes = {
	title: PropTypes.string,
	allTitle: PropTypes.string,
	selectedExport: PropTypes.func.isRequired,
	exportTypes: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string,
			name: PropTypes.string,
		})
	),
	children: PropTypes.any,
	hasMoreData: PropTypes.bool,
};

ExportDropdownComponent.defaultProps = {
	title: 'Export',
	exportTypes: [exportTypesByKey.csv],
};

export default ExportDropdownComponent;
