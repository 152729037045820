import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { each, clone, isEmpty, map, take, takeRight, findIndex, times, size, transform, every } from 'lodash';

import { CurrencyMap } from 'common/utilities';
import { withError } from 'common/components/error';
import { BatchGrid, BatchGridHeader, BatchGridHeaderItem, BatchGridBody, BatchGridRow } from './grid';
import tabs from './batchTabs';

const defaultColumns = [
	{
		key: 'xBatch',
		name: 'ID #',
		sortable: true,
		toggleExpandAllColumn: true,
	},
	{
		key: 'xBatchDate',
		name: 'Date and Time',
		isDefaultSorter: true,
		defaultSortDirection: 'DESC',
		sortable: true,
		className: 'flex--right',
	},
	{
		key: 'xTotalAmount',
		name: 'Total',
		className: 'type--right',
		sortable: true,
		alignHeaderRight: true,
	},
	{
		key: 'xSaleAmount',
		name: 'Sale',
		className: 'type--right',
		sortable: true,
		alignHeaderRight: true,
	},
	{
		key: 'xCreditAmount',
		name: 'Credit',
		className: 'type--right',
		sortable: true,
		alignHeaderRight: true,
	},
	{
		key: 'actions',
		name: '',
		className: 'type--right',
	},
];

const salesIndex = findIndex(defaultColumns, { key: 'xSaleAmount' }) + 1;

const voidColumns = [
	...take(defaultColumns, salesIndex),
	{
		key: 'xVoidAmount',
		name: 'Void',
		className: 'type--right',
	},
	...takeRight(defaultColumns, defaultColumns.length - salesIndex),
];

const initExpanded = (input, currentExpanded = {}) => {
	const expanded = {};
	if (!isEmpty(input)) {
		each(input, ({ xBatch, index }) => {
			const key = xBatch || index;
			expanded[key] = currentExpanded[key] !== undefined ? currentExpanded[key] : false;
		});
	}
	return expanded;
};
class BatchDataGrid extends Component {
	constructor(props) {
		super(props);

		this.state = {
			currency: CurrencyMap.resolveCurrency(),
			expanded: {},
			allExpanded: false,
			columns: this.columns,
		};
		this.rowPortals = [];
	}

	static getDerivedStateFromProps = (nextProps, prevState) => {
		const expanded = initExpanded(nextProps.rows, prevState.expanded);
		const allExpanded = every(nextProps.rows, row => prevState.expanded[row.xBatch || row.index] === true);

		if (nextProps.rows !== prevState.rows || allExpanded !== prevState.allExpanded) {
			const state = {
				rows: nextProps.rows,
				expanded: expanded,
				allExpanded: allExpanded,
			};

			nextProps.updateExpandedRows(state.expanded);
			return state;
		}
		return null;
	};

	componentDidUpdate(prevProps) {
		if (prevProps.rows !== this.props.rows) {
			this.rowPortals = times(size(prevProps.rows), () => null);
		}
		if (prevProps.activeTab !== this.props.activeTab) {
			this.setState({ columns: this.columns });
		}
	}

	get columns() {
		let columns = [...(this.props.showVoid ? voidColumns : defaultColumns)];
		if (this.props.showProcessingFee) {
			columns = [
				...take(columns, salesIndex - 1),
				{
					key: 'xTotalProcessingFeeAmount',
					name: 'Processing Fee',
					className: 'type--right',
					sortable: true,
				},
				{
					key: 'xNetTotalAmount',
					name: 'Net Total',
					className: 'type--right',
					sortable: true,
				},
				...takeRight(columns, columns.length - salesIndex + 1),
			];
		}
		if (this.props.isFundedReport || this.props.isFundedReportOpenBatches) {
			columns.shift();
		}
		return columns;
	}

	toggleExpandAllRows = () => {
		this.toggleExpand('all', this.state.allExpanded);
		this.setState({ allExpanded: !this.state.allExpanded });
	};
	toggleExpand = (index, allExpanded) => {
		const expanded = clone(this.state.expanded);

		const newExpanded = transform(
			expanded,
			(result, value, key) => {
				if (index === 'all') {
					result[key] = !allExpanded;
				} else if (index == key) {
					result[key] = !value;
				} else {
					result[key] = value;
				}
			},
			{}
		);
		this.setState(
			{
				expanded: newExpanded,
			},
			() => {
				this.props.updateExpandedRows(newExpanded);
			}
		);
	};
	renderEmptyGrid = () => {
		const { showVoid, showProcessingFee } = this.props;
		let colSpan = 5;
		if (showVoid && showProcessingFee) {
			colSpan = 7;
		} else if (showVoid || showProcessingFee) {
			colSpan = 6;
		}

		return (
			<tbody>
				<tr>
					<td colSpan={colSpan}>
						<div className="table--emptystate">
							{showVoid ? (
								<Fragment>
									<div className="table--emptystate--img"></div>
									<div className="table--emptystate--title">There are currently no open batches</div>
								</Fragment>
							) : (
								<Fragment>
									<div className="table--emptystate--img"></div>
									<div className="table--emptystate--title">0 Results</div>
									<p className="table--emptystate--text">You should change your filter options</p>
								</Fragment>
							)}
						</div>
					</td>
				</tr>
			</tbody>
		);
	};

	onGridSort = (key, sortDirection) => {
		const columns = clone(this.state.columns);
		each(columns, column => {
			if (key !== column.key) {
				column.sortDirection = 'NONE';
			} else {
				column.sortDirection = sortDirection;
			}
		});
		this.setState({ columns }, () => this.props.onGridSort(key, sortDirection));
	};

	render() {
		const { rows, expanded, columns } = this.state;
		const {
			showVoid,
			showProcessingFee,
			batches,
			isFundedReport,
			isFundedReportOpenBatches,
			handleBreakdownOpen,
			activeTab,
			exportButtons,
			handleError,
			filters,
			activeFilters,
			onFilterUpdate,
			toggleSummary,
			switchTab,
			isLoading,
		} = this.props;

		const openBatches = isFundedReportOpenBatches ? batches : [];

		return (
			<Fragment>
				{map(rows, (_, index) => (
					<div
						key={index}
						className="display--n"
						ref={el => {
							this.rowPortals[index] = el;
						}}
					></div>
				))}

				<BatchGrid
					filters={filters}
					activeFilters={activeFilters}
					onFilterUpdate={onFilterUpdate}
					expanded={expanded}
					handleBreakdownOpen={handleBreakdownOpen}
					toggleExpandAll={this.toggleExpand}
					caption="Batches"
					exportButtons={exportButtons}
					toggleSummary={toggleSummary}
					activeTab={activeTab}
					switchTab={switchTab}
					isLoading={isLoading}
				>
					<BatchGridHeader>
						{map(columns, (column, i) => {
							const lastClass = columns.length === i + 1 ? 'right' : '';
							return (
								<BatchGridHeaderItem
									key={i}
									column={column}
									className={lastClass}
									onGridSort={this.onGridSort}
									displaySorting={activeTab === tabs.closed}
									toggleExpandAllRows={this.toggleExpandAllRows}
									allExpanded={this.state.allExpanded}
								/>
							);
						})}
					</BatchGridHeader>
					{!isEmpty(rows) ? (
						<BatchGridBody>
							{map(rows, (row, index) => (
								<BatchGridRow
									key={row.xBatch || row.index}
									row={row}
									columns={columns}
									expanded={expanded}
									toggleExpand={this.toggleExpand}
									showVoid={showVoid}
									showProcessingFee={showProcessingFee}
									batches={isFundedReport ? batches[row.xBatchDate] : openBatches}
									displayEmptyIdColumn={activeTab == tabs.closed ? !isFundedReport : !isFundedReportOpenBatches}
									handleError={handleError}
									portal={this.rowPortals[index]}
								/>
							))}
						</BatchGridBody>
					) : (
						this.renderEmptyGrid()
					)}
				</BatchGrid>
			</Fragment>
		);
	}
}

BatchDataGrid.propTypes = {
	rows: PropTypes.array,
	showVoid: PropTypes.bool,
	showProcessingFee: PropTypes.bool,
	isFundedReport: PropTypes.bool,
	isFundedReportOpenBatches: PropTypes.bool,
	batches: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
	handleBreakdownOpen: PropTypes.func,
	activeTab: PropTypes.string.isRequired,
	exportButtons: PropTypes.any,
	handleError: PropTypes.func,
	onGridSort: PropTypes.func.isRequired,
	updateExpandedRows: PropTypes.func,
	filters: PropTypes.array.isRequired,
	activeFilters: PropTypes.array.isRequired,
	onFilterUpdate: PropTypes.func.isRequired,
	toggleSummary: PropTypes.func.isRequired,
	switchTab: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
};

export default withError(BatchDataGrid);
