import React from 'react';

const AccountNumberTooltipComponent = props => {
	return (
		<i {...props} className="icon icon--tny icon--regular--info spc--left--tny newtransaction__tooltip__trigger">
			<span className="newtransaction__tooltip">
				<div className="newtransaction__tooltip__image account"></div>
				<p>
					Your checking account number, also called the demand deposit account (DDA) number, is located to the right of
					the ABA number on the bottom center of your bank checks.
				</p>
			</span>
		</i>
	);
};

export default AccountNumberTooltipComponent;
