import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ModalComponent from 'common/components/modal/modal';
import { isEmpty } from 'lodash';

const UploadModal = ({ isOpen, onClose, progress, mappedErrors, failedResponses }) => {
	const uploadHasErrors = !isEmpty(mappedErrors);
	const allCustomersFailed = progress.completed === 0 && progress.errored === progress.total;
	const someCustomersFailed = progress.errored > 0 && !allCustomersFailed;
	const processComplete = progress.completed + process.errored === progress.total;
	const renderUploadStatus = () => {
		if (uploadHasErrors) {
			return (
				<table className="table table--primary">
					<tbody>
						<tr>
							<td className="counter" colSpan={3}>
								Errors ({failedResponses.length})
							</td>
						</tr>
						{mappedErrors}
					</tbody>
				</table>
			);
		}

		return (
			<Fragment>
				<h4 className="type--center type--wgt--regular">
					Uploading {progress.completed} of {progress.total} customers
				</h4>
				{someCustomersFailed && (
					<h4 className="type--center type--center type--wgt--regular">
						{progress.errored} {progress.errored === 1 ? 'customer failed' : 'customers failed'}
					</h4>
				)}
				<p className="type--p2 type--center type--color--text--light spc--top--sml--alt">
					Do not leave this page until the upload is completed
				</p>
			</Fragment>
		);
	};

	return (
		<ModalComponent isOpen={isOpen} onClose={onClose}>
			<div className="modal__body">
				<div
					className={`customer--process customer--process--progress ${
						!processComplete && !uploadHasErrors ? 'is-loading' : ''
					} ${uploadHasErrors ? 'has-error' : ''}`}
				>
					<div className="icon"></div>
				</div>
				{
					<h4 className="type--center type--wgt--regular">
						{uploadHasErrors && !allCustomersFailed
							? 'All Customers except for the below have been successfully uploaded.'
							: allCustomersFailed
							? 'All customers failed to upload. See below.'
							: ''}
					</h4>
				}

				{renderUploadStatus()}
			</div>
			<div className="modal__footer"></div>
		</ModalComponent>
	);
};

UploadModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	progress: PropTypes.shape({
		completed: PropTypes.number.isRequired,
		total: PropTypes.number.isRequired,
		errored: PropTypes.number.isRequired,
	}).isRequired,
	mappedErrors: PropTypes.node, // Assuming mappedErrors can be a React node
	failedResponses: PropTypes.array,
};

export default UploadModal;
