import React, { Component, createRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withError } from 'common/components/error';
import { withLoader } from 'common/components/loader';
import { withCancelable } from 'common/components/cancelable';
import { UserAccountPanel } from 'common/components/user-account-panel';
import AddOn from './components/add-on';
import { get, map } from 'lodash';
import features from './constants/featureConstants';
import AdvancedAddOn from './components/advanced-add-on';
import Route from 'routing/Route';
import { emailService, principalService } from 'common/services';
import { Notification } from 'common/components/notifications';
import { ActionsModal, modalNames } from 'common/components/transaction-actions';
import digitalWalletFeatureConstant from './constants/digitalWalletFeatureConstant';
import { SidebarContext } from 'common/contexts';

class FeatureAddOns extends Component {
	static contextType = SidebarContext;
	constructor(props) {
		super(props);
		this.state = {
			availableFeatures: features,
			modal: {},
		};
		this.notificationRef = createRef();
	}
	componentWillUnmount() {
		this.context.toggleSidebarExpandedClass(false);
	}
	componentDidMount = async () => {
		const principal = principalService.get();
		const supportsDigitalWallet = get(principal, 'idInfo.xSupportsDigitalWallet', false);

		this.props.showLoader(true);

		if (supportsDigitalWallet) {
			this.setState({
				availableFeatures: [...features, digitalWalletFeatureConstant(principal)],
			});
		}
		this.props.showLoader(false);
	};
	closeActionsPopup = () => {
		this.setState({ modal: {}, popupActive: false });
	};
	onBeforeActivate = async (templateId, successMessage, path, question, additionalContent) => {
		const state = {
			popupActive: true,
			modal: {
				name: modalNames.confirmAction,
				data: {
					question,
					onConfirm: () => this.handleActivate(templateId, successMessage, path, additionalContent),
				},
			},
		};
		this.setState(state);
	};
	handleActivate = async (templateId, successMessage, path, additionalContent = {}) => {
		const currentPathName = get(this.props.history, 'location.pathname');
		const urlUpdateData = { search: '?activate=true' };
		if (currentPathName === '/features') {
			urlUpdateData.pathname = `features/${path}`;
		}
		this.props.history.push(urlUpdateData);
		this.props.showLoader(true);
		if (this.state.activating) return;
		if (!this.state.activating) {
			this.setState({ activating: true });
		}

		const emailProps = {
			TemplateName: templateId,
			Template: {
				...additionalContent,
			},
		};

		try {
			await this.props.makePendingRequest(emailService.sendEmail(emailProps), 'sendEmail');
			if (this.notificationRef.current) {
				this.notificationRef.current.addNotification({
					message: (
						<div>
							<div className="spc--bottom--sml">{successMessage}</div>
						</div>
					),
					success: true,
				});
			}
		} catch (e) {
			this.props.handleError(e);
		} finally {
			this.props.history.push('/features');
			this.props.showLoader(false);
			this.setState({ activating: false });
		}
	};
	getIsExpanded = path => {
		const currentPath = get(this.props, 'history.location.pathname');
		return currentPath.includes(path);
	};
	render() {
		const { modal, popupActive, availableFeatures } = this.state;
		return (
			<Fragment>
				<header className="header">
					<div className="header__breadcrumbs">Feature Add-Ons</div>
					<div className="header__menu">
						<UserAccountPanel />
					</div>
				</header>
				<div className="l--content">
					<h3 className="spc--bottom--lrg">Feature Add-Ons</h3>
					<div className="add-ons__list">
						{this.state.activating
							? null
							: map(availableFeatures, feature => (
									<Fragment key={feature.path + feature.title}>
										<Route
											path={`/features/${feature.path}`}
											component={AdvancedAddOn}
											componentProps={{
												feature,
												...this.props,
												onBeforeActivate: this.onBeforeActivate,
												isLoading: this.state.activating,
											}}
										/>

										<AddOn
											data={feature}
											onBeforeActivate={this.onBeforeActivate}
											{...this.props}
											isActive={this.getIsExpanded(feature.path)}
										/>
									</Fragment>
							  ))}
					</div>
					{popupActive && (
						<ActionsModal modal={modal} onModalClose={this.closeActionsPopup} isLoading={this.props.isLoading} />
					)}
					<Notification ref={this.notificationRef} />
				</div>
			</Fragment>
		);
	}
}

FeatureAddOns.propTypes = {
	handleError: PropTypes.func,
	makePendingRequest: PropTypes.func,
	showLoader: PropTypes.func,
	isLoading: PropTypes.bool,
	history: PropTypes.object,
};

export default withError(withLoader(withCancelable(FeatureAddOns)));
