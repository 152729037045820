import React, { createRef } from 'react';
import { cloneDeep, each, get, isEmpty, map, toLower } from 'lodash';
import { Data } from 'react-data-grid-addons';
import PropTypes from 'prop-types';

import PopupGrid from '../grid/PopupGrid';
import ViewPaymentsGrid from 'components/recurring-transactions/ViewPaymentsGrid';
import { withError } from '../error';
import { withCancelable } from '../cancelable';
import { withRouter } from 'react-router-dom';
import { customerService } from 'common/services';
import { ZebraRenderer } from '../row';
import { PopupGridTooltip } from '../tooltips';
import { toCurrency } from 'components/new-transaction/helpers';
import { CurrencyMap } from 'common/utilities';

const requestKeys = {
	FETCH: 'fetch',
};

class TotalChargesPopupComponent extends ViewPaymentsGrid {
	constructor(props) {
		super(props);

		this.popupRef = createRef();

		this.components = {
			rowRenderer: ZebraRenderer,
			title: this.renderTitle,
			tooltip: PopupGridTooltip,
		};
	}

	get currency() {
		return get(this.props, 'row.currency');
	}

	get currencyCode() {
		return CurrencyMap.resolveCurrency(this.currency);
	}

	fetchData = async (maxRecords = 0) => {
		this.setState({
			fetchingData: true,
			data: null,
			filteredRows: [],
			expanded: {},
			lastApiRefNum: null,
		});
		const {
			row: { scheduleId },
			handleError,
			makePendingRequest,
		} = this.props;
		let lastApiRefNum = null;

		try {
			const data = await makePendingRequest(
				maxRecords
					? customerService.getRecurringTransactions(scheduleId)
					: customerService.getRecurringTransactionsAll(scheduleId),
				requestKeys.FETCH
			);
			lastApiRefNum = data.xRefNum;
			if (data && data.xReportData) {
				data.xReportData = map(data.xReportData, this.mapRow);
			}

			if (maxRecords === 0) {
				data.xRecordsReturned = data.xReportData.length;
				data.xReportingMaxCustomers = data.xReportData.length + 1; // +1 = quick fix
			}
			this.mapData(data);
			const filteredRows =
				data && data.xReportData
					? Data.Selectors.getRows({
							rows: data.xReportData,
							filters: this.state.inlineFilters,
					  })
					: [];
			if (this.gridRef.current) {
				this.gridRef.current.scrollTo({ top: 0, left: 0 });
			}

			this.setState(
				{
					data,
					originalData: cloneDeep(data),
					filteredRows,
					fetchingData: false,
					lastApiRefNum: lastApiRefNum,
				},
				() => {
					if (this.gridRef.current) {
						this.gridRef.current.handleInitialSort();
					}
				}
			);
		} catch (e) {
			if (handleError(e)) {
				this.setState({
					fetchingData: false,
				});
			}
		}
	};

	renderTitle = () => {
		const {
			row: { scheduleName, scheduleId },
		} = this.props;
		const { filteredRows } = this.state;
		let totalAmount = 0;
		let totalCount = 0;

		each(filteredRows, ({ amountToUse, xResponseResult }) => {
			if (toLower(xResponseResult) === 'approved') {
				totalAmount += amountToUse;
				totalCount += 1;
			}
		});

		return (
			<div>
				<h4 className="type--wgt--medium spc--bottom--tny">
					Total Charges: {toCurrency(totalAmount)}
					{this.currencyCode} ({totalCount})
				</h4>
				<p className="type--sml type--color--text--light type--break-word">
					Schedule {scheduleName ? 'Name' : 'ID'}: {scheduleName || scheduleId}
				</p>
			</div>
		);
	};

	render() {
		const {
			fetchingData,
			fetchingAdditionalData,
			filteredRows,
			columns,
			data,
			inlineFilters,
			expanded,
			filters,
			activeFilters,
			lastApiRefNum,
			defaultColumns,
			tooltipProps,
		} = this.state;
		const {
			row: { scheduleName, scheduleId },
		} = this.props;

		return (
			<div ref={this.popupRef}>
				<div className="modal__body">
					<PopupGrid
						title="Total Charges"
						emptyMessage={
							isEmpty(get(data, 'xReportData')) && !fetchingData
								? `No transactions found for schedule: ${scheduleName || scheduleId}`
								: 'You should change your filter options.'
						}
						fetchingData={fetchingData}
						fetchingAdditionalData={fetchingAdditionalData}
						filteredRows={filteredRows}
						columns={columns}
						data={data}
						resolveColumnName={this.resolveColumnName}
						inlineFilters={inlineFilters}
						components={this.components}
						classes={this.classes}
						onChange={this.handleChange}
						expanded={expanded}
						type="transactions"
						filters={filters}
						activeFilters={activeFilters}
						fetchData={this.refetchData}
						lastApiRefNum={lastApiRefNum}
						displayRowDetails={true}
						showPrintDropdown={false}
						defaultColumns={defaultColumns}
						ref={this.gridRef}
						initialFetch={true}
						columnFilterType="/settings/user-settings/new-transaction"
						kvaasResourceType="transaction"
						useInlineFilters={true}
						showGridHeader={true}
						displayHeaderMenu={false}
						displayShowHideSelectedFilters={false}
						showResults={true}
						showPanel={false}
						showGridFooter={true}
						showGridFooterLoadAllResults={true}
						enableFilters={true}
						renderGridHeaderViewInTransactionReport={this.renderViewInTransactionReport}
						renderGridFooterLoadAllResults={this.renderViewAllPayments}
						tooltipProps={tooltipProps}
						popupRef={this.popupRef}
					/>
				</div>
				<div className="modal__footer"></div>
			</div>
		);
	}
}

TotalChargesPopupComponent.propTypes = {
	makePendingRequest: PropTypes.func.isRequired,
	handleError: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
};

export default withError(withCancelable(withRouter(TotalChargesPopupComponent)));
