import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
	cloneDeep,
	clone,
	each,
	unionBy,
	startsWith,
	toLower,
	concat,
	includes,
	filter,
	map,
	isEmpty,
	find,
	get,
	uniqBy,
} from 'lodash';
import { Data } from 'react-data-grid-addons';

import { giftService, principalService } from 'common/services';
import { MainFilterComponent } from 'common/components/filter';
import { giftActivityFilter as Filter, compileFilter } from 'common/components/gift-report/filter/giftActivityFilter';
import { giftActivityColumns, GridFooterComponent } from 'common/components/gift-report';
import { GridComponent } from 'common/components/grid';
import { withCancelable } from 'common/components/cancelable';
import { withError } from 'common/components/error';
import { exportService } from 'common/components/export/exportService';
import { ZebraRenderer } from 'common/components/row';
import { Modal } from 'common/components/modal';
import GiftActivityBreakdown from './GiftActivityBreakdown';
import { LoadMoreOptions } from 'common/utilities';
import { GridTooltip } from 'common/components/tooltips';
import sectionKeys from 'routing/sections';
import GiftActivityBreakdownGridHeader from './GiftActivityBreakdownGridHeader';
import { withLoader } from 'common/components/loader';

const requestKeys = {
	FETCH: 'fetch',
	LOAD_ALL: 'loadAll',
	LOAD_MORE: 'loadMore',
};

const { apiDateTimeFormat, apiResponseDateTimeFormat, displayDateTimeFormat } = ApplicationSettings;

class GiftActivity extends Component {
	constructor(props) {
		super(props);
		const principal = principalService.get();
		const filters = unionBy(props.location.filters, Filter, 'key') || Filter;
		const activeFilters = props.activeFilters || cloneDeep(filters);
		this.csRep = principal.hasAccess[sectionKeys.portalManagement];
		this.state = {
			filters: filters,
			activeFilters: activeFilters,
			inlineFilters: {},
			data: null,
			originalData: null,
			filteredRows: [],
			fetchingData: true,
			fetchingAdditionalData: false,
			columns: cloneDeep(giftActivityColumns),
			initialRecordsLimit: 20,
			loadMoreLimit: 50,
			lastApiRefNum: null,
			giftCardNumber: '',
			maskedCardNumber: '',
			remainingBalance: null,
			error: '',
		};

		this.breakdownGridRef = React.createRef();
		this.gridRef = React.createRef();

		this.components = {
			filter: MainFilterComponent,
			rowRenderer: ZebraRenderer,
			gridFooter: this.renderGridFooter,
			gridHeader: this.renderGridHeader,
			header: this.renderGridHeader,
			tooltip: this.renderTooltip,
		};

		this.classes = {
			wrapper: '',
			header: 'header header__btn__holder',
			headerMenu: 'header__menu',
			headerGroup: 'flex flex--primary',
			title: 'header__breadcrumbs',
			print: 'giftprint__table',
			filter: 'spc--right--sml flex--grow--1',
			gridHeader: 'flex--primary',
		};
	}

	getSnapshotBeforeUpdate = prevProps => prevProps.location.key !== this.props.location.key;

	get loadMoreOptionsWithAll() {
		return concat(LoadMoreOptions, ['All']);
	}
	get breakdownApplyDisabled() {
		return !this.state.maskedCardNumber || this.state.error || this.props.isLoading;
	}

	componentDidUpdate = (_, __, snapshot) => {
		if (snapshot) {
			if (this.gridRef.current) {
				this.gridRef.current.clearFilters();
			}
			this.refetchData();
		}
	};

	fetchData = async (filters, filterDateFormat, maxRecords = 1000) => {
		if (this.state.displayErrorRefNum) return;
		this.setState({
			data: null,
			filteredRows: [],
			lastApiRefNum: null,
			fetchingData: true,
		});
		let lastApiRefNum = null;
		const parser = giftService.parseGiftReportResult;
		const fields = giftService.getGiftReportFields();
		try {
			const giftFilter = await this.props.makePendingRequest(
				compileFilter(filters, filterDateFormat),
				requestKeys.FETCH
			);
			const giftData = await this.props.makePendingRequest(
				giftService.filterGiftRequest(giftFilter, maxRecords > 1000 ? 1000 : maxRecords, fields, parser),
				requestKeys.FETCH
			);
			lastApiRefNum = giftData.xRefNum;

			this.mapData(giftData);
			const filteredRows =
				giftData && giftData.xReportData
					? Data.Selectors.getRows({
							rows: giftData.xReportData,
							filters: this.state.inlineFilters,
					  })
					: [];
			if (this.gridRef.current) {
				this.gridRef.current.scrollTo({ top: 0, left: 0 });
			}

			this.setState(
				{
					originalData: cloneDeep(giftData),
					filteredRows,
					fetchingData: false,
					columns: this.state.columns,
					data: giftData,
					lastApiRefNum: lastApiRefNum,
				},
				() => {
					if (this.gridRef.current) {
						this.gridRef.current.handleInitialSort();
					}
				}
			);
		} catch (e) {
			const error = this.props.handleError(e, { delayMessage: true });
			if (error) {
				this.setState({
					fetchingData: false,
				});
				if (!startsWith(toLower(error.message), toLower('License Required'))) {
					error.show();
				} else {
					this.setState(
						{
							displayErrorRefNum: e.ref,
						},
						() => {
							if (this.gridRef.current) {
								this.gridRef.current.reset();
							}
						}
					);
				}
			}
		}
	};

	fetchAllGiftActivityData = async (maxRecords = 1000) => {
		const giftFields = giftService.getGiftReportFields();
		const giftParser = giftService.parseGiftReportResult;
		try {
			const giftFilters = await this.props.makePendingRequest(
				compileFilter(this.state.activeFilters, apiDateTimeFormat),
				requestKeys.LOAD_ALL
			);
			const allGiftData = await this.props.makePendingRequest(
				giftService.filterGiftRequest(giftFilters, maxRecords > 1000 ? 1000 : maxRecords, giftFields, giftParser),
				requestKeys.LOAD_ALL
			);
			this.mapData(allGiftData);
			return allGiftData.xReportData;
		} catch (e) {
			this.props.handleError(e);
		}
	};
	removeDuplicates = data => {
		return uniqBy(data, 'xRefNum');
	};
	combineData = (baseData, additionalData, refNums) => {
		baseData.xReportData = this.removeDuplicates(concat(baseData.xReportData, additionalData.xReportData));
		baseData.xRecordsReturned += additionalData.xRecordsReturned - refNums.length;
		baseData.xReportingMaxTransactions += additionalData.xReportingMaxTransactions - refNums.length;
	};

	loadMore = async () => {
		const { loadMoreLimit, originalData } = this.state;
		const hasData = originalData && originalData.xReportData && originalData.xReportData.length > 0;
		const fetchAll = loadMoreLimit == 'All';
		if (fetchAll) {
			do {
				await this.loadMoreData(hasData, this.state.loadMoreLimit == 'All' ? 1000 : this.state.loadMoreLimit);
			} while (this.hasMoreData(this.state.data || originalData) && this.state.loadMoreLimit);
		} else {
			await this.loadMoreData(hasData);
		}
	};
	loadMoreData = async (hasData, loadAll) => {
		const { data, originalData, activeFilters } = this.state;
		let loadMoreLimit = loadAll == 'All' ? 1000 : this.state.loadMoreLimit;

		if (hasData) {
			const filters = cloneDeep(activeFilters);
			const dateFilter = find(filters, { key: 'date' });

			const { start, end, refNums } = giftService.getNewStartEndDates(
				dateFilter.values.endDate,
				originalData.xReportData
			);
			dateFilter.values.startDate = start;
			dateFilter.values.endDate = end;
			let compiledFilter;
			try {
				compiledFilter = await this.props.makePendingRequest(
					compileFilter(filters, apiDateTimeFormat),
					requestKeys.LOAD_MORE
				);
			} catch (e) {
				this.props.handleError(e, { additionalInfo: { dateFilter: dateFilter.values, apiDateTimeFormat } });
				return;
			}
			if ((compiledFilter.xBeginDate && compiledFilter.xEndDate, loadMoreLimit)) {
				this.setState({
					fetchingAdditionalData: true,
					lastApiRefNum: null,
				});

				try {
					const pendingGiftActivityData = await this.props.makePendingRequest(
						giftService.filterGiftRequest(
							compiledFilter,
							loadMoreLimit == 'All' ? 1000 : Math.min(loadMoreLimit + refNums.length, 1000)
						),
						requestKeys.LOAD_MORE
					);
					if (pendingGiftActivityData.xReportData && compiledFilter.xBeginDate) {
						const additionalFilter = await this.props.makePendingRequest(
							compileFilter(filters, apiDateTimeFormat),
							requestKeys.LOAD_MORE
						);
						const additionalData = await this.props.makePendingRequest(
							giftService.filterGiftRequest(additionalFilter, refNums.length + refNums.length),
							requestKeys.LOAD_MORE
						);
						additionalData.xReportData = filter(
							additionalData.xReportData,
							({ xRefNum }) => !includes(refNums, xRefNum)
						);
						this.combineData(pendingGiftActivityData, additionalData, refNums);
					}
					pendingGiftActivityData.xReportData = filter(
						pendingGiftActivityData.xReportData,
						({ xRefNum }) => !includes(refNums, xRefNum)
					);

					if (!isEmpty(pendingGiftActivityData.xReportData)) {
						const updatedData = clone(data);
						this.combineData(updatedData, pendingGiftActivityData, refNums);
						updatedData.xReportData = map(updatedData.xReportData, this.mapRow);
						if (!loadMoreLimit) {
							updatedData.xReportingMaxTransactions += 1;
						}

						this.mapData(updatedData);

						const filteredRows =
							updatedData && updatedData.xReportData
								? Data.Selectors.getRows({
										rows: updatedData.xReportData,
										filters: this.state.inlineFilters,
								  })
								: [];
						this.setState(
							{
								originalData: cloneDeep(updatedData),
								data: updatedData,
								filteredRows,
								fetchingAdditionalData: false,
								lastApiRefNum: pendingGiftActivityData.xRefNum,
								loadMoreLimit,
							},
							() => {
								if (this.gridRef.current) {
									this.gridRef.current.handleInitialSort();
									this.gridRef.current.calculateColumnWidths();
								}
							}
						);
					} else {
						this.setState({
							fetchingAdditionalData: false,
							lastApiRefNum: pendingGiftActivityData.xRefNum,
						});
					}
				} catch (e) {
					if (this.props.handleError(e, { additionalInfo: { compiledFilter } })) {
						this.setState({
							fetchingAdditionalData: false,
						});
					}
				}
			}
		}
	};
	onLoadMoreLimitChange = value => {
		this.setState(
			{
				loadMoreLimit: value,
			},
			() => {
				this.loadMore();
			}
		);
	};

	resolveColumnName = column => {
		let key = column;
		switch (column) {
			case 'CardData':
				key = 'xMaskedCardNumber';
				break;
			case 'AmountData':
				key = 'xAmount';
				break;
			case 'RefNumData':
				key = 'xRefNum';
				break;
			case 'xEnteredDate':
				key = 'xEnteredDateMoment';
				break;
			default:
				break;
		}
		return key;
	};

	mapData = data => {
		let i = 0;
		if (data && data.xReportData && data.xReportData.length > 0) {
			each(data.xReportData, item => {
				const clonedItem = clone(item);

				clonedItem.xEnteredDateMoment = moment(clonedItem.xEnteredDate, apiResponseDateTimeFormat);
				item.xEnteredDate = clonedItem.xEnteredDateMoment.format(displayDateTimeFormat);
				item.xEnteredDateMoment = clonedItem.xEnteredDateMoment;

				if (clonedItem && clonedItem.xMaskedCardNumber && clonedItem.xMaskedCardNumber.includes('xxx')) {
					item.xMaskedCardNumber = clonedItem.xMaskedCardNumber.replace(/x/g, '');
				}
				item.onInfoHover = this.onInfoHover;
				item.onClick = () => this.showBreakdown(item.xMaskedCardNumber);

				// include gridRowNumber as a simple counter for zebra
				item.gridRowNumber = i;
				item.index = i + 1; // for initial sort
				i++;
			});
		}
	};

	onInfoHover = (infoDimensions, tooltip) => {
		this.setState({ tooltipProps: { ...this.state.tooltipProps, infoDimensions, tooltip } });
	};

	showBreakdown = giftCardNumber => {
		this.setState({ tooltipProps: { giftCardNumber } });
	};

	updateStandaloneFilter = ({ key, values }) => {
		if (key === 'giftCardNumber') {
			if (values.giftCardNumber) {
				this.showBreakdown(values.giftCardNumber);
			}
		}
	};
	hasMoreData = data => {
		return (
			get(data, 'xReportData.length', 0) > 0 &&
			this.state.loadMoreLimit &&
			data.xRecordsReturned >= data.xReportingMaxTransactions
		);
	};
	handleGridHeaderChange = async state => {
		this.setState(state);
	};
	handleGetRemainingBalance = async e => {
		const breakdownComponent = get(this.breakdownGridRef, 'current', null);
		if (breakdownComponent) {
			await breakdownComponent.handleApply(e);
		}
	};
	handleChange = changes => {
		const newState = {};
		each(changes, ({ key, value }) => {
			if (key === 'data' || key === 'inlineFilters') {
				let filters, data;
				if (key === 'data') {
					filters = this.state.inlineFilters;
					data = value;
				} else {
					filters = value;
					data = this.state.data;
				}
				newState.filteredRows =
					data && data.xReportData
						? Data.Selectors.getRows({
								rows: data.xReportData,
								filters,
						  })
						: [];
			}
			newState[key] = value;
		});
		return new Promise(resolve => {
			this.setState(newState, resolve);
		});
	};

	refetchData = () => {
		this.setState({ loadMoreLimit: this.state.initialRecordsLimit }, () =>
			this.fetchData(this.state.activeFilters, apiDateTimeFormat, this.state.initialRecordsLimit)
		);
	};

	renderGridHeader = () => {
		return (
			<button
				type="button"
				className="btn btn--med btn--primary"
				onClick={() =>
					this.setState({
						balance: !this.state.balance,
					})
				}
			>
				Gift Balance
			</button>
		);
	};

	renderTooltip = ({ giftCardNumber, ...rest }) => {
		return (
			<Fragment>
				<GridTooltip {...rest} />
				{giftCardNumber && (
					<Modal
						className="modal--aside modal--aside--gift giftreport__aside"
						isOpen={true}
						onClose={() => this.showBreakdown(null)}
					>
						<GiftActivityBreakdown giftCardNumber={giftCardNumber} />
					</Modal>
				)}
			</Fragment>
		);
	};

	shouldDisplayErrorRefNum = () => {
		const { displayErrorRefNum } = this.state;
		return displayErrorRefNum && this.csRep;
	};

	getCustomFooter = () => (
		<p className="type--p2 type--color--text--light spc--bottom--sml--alt">#{this.state.displayErrorRefNum}</p>
	);

	renderGridFooter = props =>
		this.shouldDisplayErrorRefNum() ? this.getCustomFooter() : <GridFooterComponent {...props} />;

	renderGiftModal = () => (
		<Modal isOpen={this.state.balance} onClose={() => this.setState({ balance: false })}>
			<div className="modal__header">
				<h4 className="modal__header__title">Gift Balance</h4>
			</div>
			<div className="modal__body">
				<GiftActivityBreakdownGridHeader
					ref={this.breakdownGridRef}
					handleGridHeaderChange={this.handleGridHeaderChange}
					setParentState={this.setState}
					parentState={this.state}
					{...this.props}
				/>
			</div>
			<div className="modal__footer">
				<button
					type="submit"
					className="btn btn--med btn--primary"
					onClick={this.handleGetRemainingBalance}
					disabled={this.breakdownApplyDisabled}
				>
					Apply
				</button>
			</div>
		</Modal>
	);

	render = () => {
		const { balance } = this.state;

		return (
			<Fragment>
				{balance && this.renderGiftModal()}
				<GridComponent
					emptyMessage="You should change your filter options"
					fetchingData={this.state.fetchingData}
					fetchingAdditionalData={this.state.fetchingAdditionalData}
					filteredRows={this.state.filteredRows}
					columns={this.state.columns}
					data={this.state.data}
					resolveColumnName={this.resolveColumnName}
					inlineFilters={this.state.inlineFilters}
					components={this.components}
					onChange={this.handleChange}
					enableExport={true}
					enablePrint={true}
					title="Gift Report"
					printTitle="Gift Report"
					type="giftReport"
					filters={this.state.filters}
					activeFilters={this.state.activeFilters}
					enableFilters={true}
					fetchData={this.refetchData}
					fetchAllData={this.fetchAllGiftActivityData}
					lastApiRefNum={this.state.lastApiRefNum}
					showResults={true}
					showPrintDropdown={false}
					ref={this.gridRef}
					useInlineFilters={true}
					fetchExportData={{
						current: exportService.mapGiftActivityData,
						all: exportService.getGiftActivityData,
					}}
					classes={this.classes}
					filterProps={{
						className: 'rc-menu-link filter__select--link',
						title: 'Add Filter',
						advancedFilterWrapperClassName: '',
						advancedFilterTitleClassName: '',
					}}
					hasPaging={true}
					hasMoreData={this.hasMoreData}
					loadMoreOptions={this.loadMoreOptionsWithAll}
					onLoadMoreLimitChange={this.onLoadMoreLimitChange}
					loadMoreLimit={this.state.loadMoreLimit}
					updateStandaloneFilter={this.updateStandaloneFilter}
					tooltipProps={this.state.tooltipProps}
					displayErrorRefNum={this.shouldDisplayErrorRefNum()}
					tabs={this.props.tabs}
				/>
			</Fragment>
		);
	};
}

GiftActivity.propTypes = {
	makePendingRequest: PropTypes.func,
	activeFilters: PropTypes.any,
	location: PropTypes.object,
	handleError: PropTypes.func,
	isLoading: PropTypes.bool,
	tabs: PropTypes.array.isRequired,
};

export default withError(withLoader(withCancelable(GiftActivity)));
