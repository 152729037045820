import React, { Component } from 'react';
import PropTypes from 'prop-types';

class SingleSelectionComponent extends Component {
	filterRemoved = () => {
		const {
			onFilterRemoved,
			filter: { key },
		} = this.props;
		onFilterRemoved({
			id: key,
			emptyValue: null,
		});
	};

	render() {
		const {
			filter: { key, values, selectionName, name },
			prefix,
		} = this.props;
		return (
			<div className="filter__toolbar__selected-filter">
				{selectionName || name}: {prefix}
				<span className="type--wgt--medium">{values[key]}</span>
				<button onClick={this.filterRemoved} data-tooltip="Remove" className="btn btn--link datatooltip--auto">
					<i className="icon icon--tny icon--close--text"></i>
				</button>
			</div>
		);
	}
}

SingleSelectionComponent.propTypes = {
	filter: PropTypes.object.isRequired,
	onFilterRemoved: PropTypes.func.isRequired,
	prefix: PropTypes.string,
};

export default SingleSelectionComponent;
