import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

const statuses = {
	Pending: { text: 'Pending', className: 'warning' },
	Guaranteed: { text: 'Guaranteed', className: 'success' },
	'Not Guaranteed - Probable Fraud': { text: 'Probable Fraud', className: 'warning' },
	'Not Guaranteed - High Risk': { text: 'High Risk', className: 'warning' },
	'Not Guaranteed - Low Risk': { text: 'Low Risk', className: 'warning' },
	Error: { text: 'Error', className: 'error' },
	Review: { text: 'Review', className: 'success' },
	Chargeback: { text: 'Chargeback', className: 'warning' },
	Settled: { text: 'Settled', className: 'success' },
	Safe: { text: 'Safe', className: 'success' },
	Unknown: { text: 'N/A', className: 'default' },
	Cancelled: { text: 'Cencelled', className: 'default' },
	'N/A': { text: 'N/A', className: 'default' },
	'Awaiting Response': { text: 'Awaiting Response', className: 'warning' },
	Funded: { text: 'Funded', className: 'success' },
	Returned: { text: 'Returned', className: 'error' },
	Hold: { text: 'Hold', className: 'default' },
	Rejected: { text: 'Rejected', className: 'error' },
};

class StatusFraudComponent extends Component {
	render() {
		const { value, showBadge } = this.props;

		const status = statuses[value] || statuses.Unknown;
		let { text, className } = status;

		if (!statuses[value]) {
			[, text] = value.split(' ');
		}

		return (
			<Fragment>
				{showBadge ? (
					<Fragment>
						<div className={`badge badge--${className}`}>{text}</div>
						{!statuses[value] && ` ${text || ''}`}
					</Fragment>
				) : (
					value
				)}
			</Fragment>
		);
	}
}
StatusFraudComponent.defaultProps = {
	showBadge: true,
};
StatusFraudComponent.propTypes = {
	value: PropTypes.any,
	showBadge: PropTypes.bool,
};

export default StatusFraudComponent;
