import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { countryList } from './constants';
import { fieldPropType, isValid } from '../../Common/fields';
import { withCancelable } from '../../Common/components/cancelable';

class CustomerInfoFields extends Component {
	constructor(props) {
		super(props);
	}

	get zipLabel() {
		return this.props.isCanadianCurrency ? 'Postal Code' : 'ZIP';
	}

	get stateLabel() {
		return this.props.isCanadianCurrency ? 'Province' : 'State';
	}

	onChange = e => {
		const { name, value } = e.target;
		if (name === 'phoneNumber') {
			if (!/[^\d()+\-\s]/g.test(value) || value === '') {
				this.props.onChange({
					key: name,
					value: value,
				});
			}
		} else {
			this.props.onChange({
				key: name,
				value: value,
			});
		}
	};

	renderField = (key, defaultLabel, ignoreDataType, isCountry, inputProps = {}, requiredOverride = false) => {
		const {
			data,
			dataType,
			disabled,
			invalidClassName,
			requiredFields,
			customDisplayLabels,
			saveAsCustomer,
			transactionHiddenFields,
		} = this.props;
		const required =
			dataType == 'billing' && !disabled ? (
				<span className="form__group__required" data-tooltip="Required">
					*
				</span>
			) : null;
		const hideField = transactionHiddenFields[key] && (ignoreDataType || (dataType !== 'billing' || !saveAsCustomer));
		return !hideField ? (
			<div className="form__group">
				<div className="form__group__header">
					<label className="form__group__label">
						{customDisplayLabels[key] || defaultLabel} {requiredFields[key] || requiredOverride ? required : null}
					</label>
				</div>
				{isCountry ? (
					<select
						name="country"
						className={`input input--med input--select ${(!isValid(data.country) && invalidClassName) || ''}`}
						value={data.country.value}
						onChange={this.onChange}
						disabled={disabled}
					>
						{countryList.map(country => (
							<option key={country.code} value={country.code} disabled={country.disabled}>
								{country.name}
							</option>
						))}
					</select>
				) : (
					<input
						type="text"
						name={key}
						className={`input input--med ${(!isValid(data[key]) && invalidClassName) || ''}`}
						placeholder={customDisplayLabels[key] || defaultLabel}
						value={data[key].value}
						onChange={this.onChange}
						disabled={disabled}
						{...inputProps}
					/>
				)}
			</div>
		) : null;
	};

	render() {
		return (
			<Fragment>
				{this.renderField('company', 'Company')}
				<div className="f-row spc--bottom--sml">
					<div className="f-col f-col-sml-12 f-col-med-6">{this.renderField('firstName', 'First Name')}</div>
					<div className="f-col f-col-sml-12 f-col-med-6">{this.renderField('lastName', 'Last Name')}</div>
				</div>

				<h5 className="form__heading">Address information</h5>
				{this.renderField('address', 'Address', true)}
				<div className="f-row">
					<div className="f-col f-col-sml-12 f-col-med-8">{this.renderField('city', 'City', true)}</div>
					<div className="f-col f-col-sml-12 f-col-med-4">
						{this.renderField('zip', this.zipLabel, true, false, { inputMode: 'numeric' })}
					</div>
				</div>
				{this.renderField('state', this.stateLabel, true)}
				{this.renderField('country', 'Country', true, true)}

				<h5 className="form__heading">Contact</h5>
				{this.renderField(
					'phoneNumber',
					'Phone Number',
					true,
					false,
					{ type: 'tel' },
					this.props.dataType === 'billing' && this.props.sendSmsReceipt
				)}
			</Fragment>
		);
	}
}

CustomerInfoFields.propTypes = {
	data: PropTypes.shape({
		firstName: fieldPropType(PropTypes.string).isRequired,
		lastName: fieldPropType(PropTypes.string).isRequired,
		company: fieldPropType(PropTypes.string).isRequired,
		address: fieldPropType(PropTypes.string).isRequired,
		city: fieldPropType(PropTypes.string).isRequired,
		state: fieldPropType(PropTypes.string).isRequired,
		zip: fieldPropType(PropTypes.string).isRequired,
		country: fieldPropType(PropTypes.string).isRequired,
		phoneNumber: fieldPropType(PropTypes.string).isRequired,
	}).isRequired,
	onChange: PropTypes.func,
	dataType: PropTypes.string.isRequired,
	disabled: PropTypes.bool,
	invalidClassName: PropTypes.string,
	requiredFields: PropTypes.object,
	customDisplayLabels: PropTypes.object,
	transactionHiddenFields: PropTypes.object,
	saveAsCustomer: PropTypes.bool,
	makePendingRequest: PropTypes.func,
	isCanadianCurrency: PropTypes.bool,
	sendSmsReceipt: PropTypes.bool,
};

export default withCancelable(CustomerInfoFields);
