import React from 'react';

const GooglePayTooltipComponent = props => {
	return (
		<i
			{...props}
			className="icon icon--sml icon--info newtransaction__tooltip__trigger spc--left--sml spc--bottom--nano"
		>
			<span className="newtransaction__tooltip">
				<p>
					Google Pay allows consumers to store their payment information with Google and use it to pay for goods and
					services wherever Google Pay is supported. If you enable this option for PaymentSITE, a Google Pay button
					displays on the form for users to select as a payment option.
				</p>
			</span>
		</i>
	);
};

export default GooglePayTooltipComponent;
