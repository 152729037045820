import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';

import { UserAccountPanel } from 'common/components/user-account-panel';
import { get } from 'lodash';

class TerminalOnlyUpgradePlan extends Component {
	openPlansPage = () => {
		this.props.history.push({ pathname: '/upgrade-plan' });
	};

	render() {
		const { isCustomerBilling, hideHeader, location } = this.props;

		return (
			<div>
				{!hideHeader && (
					<div className="header">
						<div className="header__menu">
							<UserAccountPanel />
						</div>
					</div>
				)}
				<div className="terminal-only">
					<h1 className="terminal-only__title">
						Your account doesn't support{' '}
						{(location && get(location, 'state.hasCustomerBilling') === true) || isCustomerBilling
							? 'Customers and recurring billing'
							: 'this feature'}
					</h1>
					<button
						className="btn btn--med btn--primary w--300"
						onMouseLeave={this.setGrumpyFace}
						onMouseEnter={this.setHappyFace}
						onClick={this.openPlansPage}
					>
						Upgrade plan
					</button>
				</div>
			</div>
		);
	}
}

TerminalOnlyUpgradePlan.propTypes = {
	isCustomerBilling: PropTypes.bool,
	hideHeader: PropTypes.bool,
	history: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
};

export default withRouter(TerminalOnlyUpgradePlan);
