import React from 'react';
import { map } from 'lodash';

import { MultiselectCardknoxFilter, StringFilter } from 'common/components/columns/filters';
import { UserStatus } from 'common/components/columns/formatters';
import PortalManagementActions from 'common/components/columns/formatters/PortalManagementActions';
import { ColumnHeader } from 'common/components/columns/headers';

export const columns = [
	{
		key: 'username',
		name: 'Use­rname', // alt+0173 added to disable chrome autofill, see: https://cs.chromium.org/chromium/src/components/autofill/core/common/autofill_regex_constants.cc?l=149
		sortable: true,
		hideable: false,
		visible: true,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 150,
		resizable: true,
		isDefaultSorter: true,
		defaultSortDirection: 'ASC',
	},
	{
		key: 'userCreateDate',
		name: 'User Created Date',
		sortable: true,
		hideable: true,
		visible: true,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 150,
		resizable: true,
	},
	{
		key: 'status',
		name: 'Status',
		sortable: true,
		hideable: true,
		visible: true,
		filterable: true,
		filterRenderer: MultiselectCardknoxFilter,
		formatter: UserStatus,
		initWidth: 150,
		resizable: true,
	},
	{
		key: 'actions',
		name: '',
		sortable: false,
		hideable: false,
		visible: true,
		filterable: false,
		formatter: PortalManagementActions,
		getRowMetaData: row => row,
		initWidth: 250,
		fixWidth: true,
		hideOnExport: true,
	},
];

export const portalManagementColumnsAll = map(columns, column => {
	column = {
		headerRenderer: <ColumnHeader />,
		...column,
	};
	return column;
});
