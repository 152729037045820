import React from 'react';
import { times, padStart, map } from 'lodash';

import { StringFilter } from '../../columns/filters';
import { default as RecurringScheduleComponent } from '../../columns/formatters/recurring-schedule-formatter';
import { InputFormatter, LinkComponent } from '../../columns/formatters';
import { ColumnHeader, SelectAllHeader } from 'common/components/columns/headers';
import { PaymentMethodExpiryComponent } from 'common/components/columns/formatters';
import createGridColumn from 'common/utilities/createGridColumn';

export const columns = [
	createGridColumn('isSelected', '', 50, {
		locked: true,
		sortable: false,
		hideable: false,
		visible: true,
		initWidth: 50,
		fixWidth: true,
		filterable: false,
		formatter: InputFormatter,
		headerRenderer: props => <SelectAllHeader {...props} />,
		getRowMetaData: ({ customerId: dependentValue, _meta: { isSelected: checked } }) => ({
			dependentValue,
			checked,
			value: checked,
			inputType: 'checkbox',
			displayColumnValue: true,
		}),
		order: 0,
		disabled: true,
		disableDrag: true,
	}),
	createGridColumn('customerId', 'C­ustomer I­D', 170, {
		locked: true,
		sortable: true,
		hideable: true,
		visible: true,
		filterable: true,
		filterRenderer: StringFilter,
		isDefaultSorter: true,
		defaultSortDirection: 'DESC',
		order: 1,
		disabled: true,
		disableDrag: true,
	}),
	createGridColumn(
		'customerNumber',
		'C­ustomer #', // alt+0173 added to disable chrome autofill, see: https://cs.chromium.org/chromium/src/components/autofill/core/common/autofill_regex_constants.cc?l=149
		150,
		{
			filterRenderer: StringFilter,
			customSettingsKey: 'customerNumber',
		}
	),
	createGridColumn(
		'avsStreet',
		'AVS Street', // alt+0173 added to disable chrome autofill, see: https://cs.chromium.org/chromium/src/components/autofill/core/common/autofill_regex_constants.cc?l=149
		220,
		{
			filterRenderer: StringFilter,
			customSettingsKey: 'avsStreet',
		}
	),
	createGridColumn(
		'avsZip',
		'AVS Zip', // alt+0173 added to disable chrome autofill, see: https://cs.chromium.org/chromium/src/components/autofill/core/common/autofill_regex_constants.cc?l=149
		80,
		{
			customSettingsKey: 'avsZip',
			filterRenderer: StringFilter,
		}
	),
	createGridColumn(
		'billCompany',
		'Bill C­ompany', // alt+0173 added to disable chrome autofill, see: https://cs.chromium.org/chromium/src/components/autofill/core/common/autofill_regex_constants.cc?l=149,
		150,
		{
			filterRenderer: StringFilter,
			customSettingsKey: 'company',
		}
	),
	createGridColumn(
		'billFirstName',
		'Bill F­irst­ N­ame', // alt+0173 added to disable chrome autofill, see: https://cs.chromium.org/chromium/src/components/autofill/core/common/autofill_regex_constants.cc?l=149
		150,
		{
			filterRenderer: StringFilter,
			customSettingsKey: 'firstName',
		}
	),
	createGridColumn(
		'billLastName',
		'Bill L­ast­ N­ame', // alt+0173 added to disable chrome autofill, see: https://cs.chromium.org/chromium/src/components/autofill/core/common/autofill_regex_constants.cc?l=149,
		150,
		{
			filterRenderer: StringFilter,
			customSettingsKey: 'lastName',
		}
	),
	createGridColumn('recurringSchedule', 'Recurring Schedule', 160, {
		filterRenderer: StringFilter,
		formatter: RecurringScheduleComponent,
		getRowMetaData: row => row,
		customSettingsKey: 'recurringSchedule',
	}),
	createGridColumn(
		'billStreet',
		'Bill S­treet', // alt+0173 added to disable chrome autofill, see: https://cs.chromium.org/chromium/src/components/autofill/core/common/autofill_regex_constants.cc?l=149
		220,
		{
			filterRenderer: StringFilter,
			customSettingsKey: 'street',
		}
	),
	createGridColumn(
		'billCity',
		'Bill C­ity', // alt+0173 added to disable chrome autofill, see: https://cs.chromium.org/chromium/src/components/autofill/core/common/autofill_regex_constants.cc?l=149
		150,
		{
			filterRenderer: StringFilter,
			customSettingsKey: 'city',
		}
	),
	createGridColumn(
		'billState',
		'Bill S­tate', // alt+0173 added to disable chrome autofill, see: https://cs.chromium.org/chromium/src/components/autofill/core/common/autofill_regex_constants.cc?l=149,
		150,
		{
			filterRenderer: StringFilter,
			customSettingsKey: 'state',
		}
	),
	createGridColumn(
		'billZip',
		'Bill Z­ip', // alt+0173 added to disable chrome autofill, see: https://cs.chromium.org/chromium/src/components/autofill/core/common/autofill_regex_constants.cc?l=149
		80,
		{
			filterRenderer: StringFilter,
			customSettingsKey: 'zip',
		}
	),
	createGridColumn(
		'billPhone',
		'Bill Pho­ne Number', // alt+0173 added to disable chrome autofill, see: https://cs.chromium.org/chromium/src/components/autofill/core/common/autofill_regex_constants.cc?l=149
		150,
		{
			filterRenderer: StringFilter,
			initWidth: 150,
			customSettingsKey: 'phoneNumber',
		}
	),
	createGridColumn(
		'billMobile',
		'Mo­bile Pho­ne Number', // alt+0173 added to disable chrome autofill, see: https://cs.chromium.org/chromium/src/components/autofill/core/common/autofill_regex_constants.cc?l=149
		150,
		{
			filterRenderer: StringFilter,
			customSettingsKey: 'mobilePhoneNumber',
		}
	),
	createGridColumn(
		'email',
		'Em­ail', // alt+0173 added to disable chrome autofill, see: https://cs.chromium.org/chromium/src/components/autofill/core/common/autofill_regex_constants.cc?l=149
		200,
		{
			filterRenderer: StringFilter,
			customSettingsKey: 'email',
		}
	),
	createGridColumn('paymentMethodExpiry', 'Expiry', 200, {
		filterRenderer: StringFilter,
		formatter: PaymentMethodExpiryComponent,
		customSettingsKey: 'paymentMethod',
		dependentExportKey: ['paymentMethod', 'maskedCardCardNumber', 'isPaymentMethodExpired', 'isDefaultPaymentMethod'],
	}),
	createGridColumn('paymentMethod', 'Payment Method', 200, {
		sortable: false,
		hideable: false,
		visible: false,
		filterable: false,
	}),
	createGridColumn('maskedCardCardNumber', 'Card Number', 200, {
		sortable: false,
		hideable: false,
		visible: false,
		filterable: false,
	}),
	createGridColumn('isPaymentMethodExpired', 'Is Expired', 200, {
		sortable: false,
		hideable: false,
		visible: false,
		filterable: false,
	}),
	createGridColumn('isDefaultPaymentMethod', 'Is Default Payment Method', 200, {
		sortable: false,
		hideable: false,
		visible: false,
		filterable: false,
	}),
	createGridColumn('customerNotes', 'Notes', 200, {
		filterRenderer: StringFilter,
		customSettingsKey: 'customerNotes',
	}),

	...times(20, i => {
		const oneBasedIndex = padStart(i + 1, 2, 0);

		return createGridColumn(`customerCustom${oneBasedIndex}`, `Custom${oneBasedIndex}`, 150, {
			filterRenderer: StringFilter,
			formatter: LinkComponent,
			initWidth: 150,
			customSettingsKey: `custom${i + 1}`,
		});
	}),
];
export const CustomerColumns = showToken => {
	if (showToken) {
		columns.push({
			key: 'token',
			name: 'Token',
			sortable: false,
			hideable: false,
			visible: true,
			filterable: false,
			initWidth: 200,
		});
	}
	return map(columns, column => {
		column = {
			headerRenderer: <ColumnHeader />,
			...column,
		};
		return column;
	});
};
