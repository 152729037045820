import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import ToggleCheckbox from 'components/ToggleCheckbox';
import { chain, isEqual, map, sortBy, upperCase, toLower, includes } from 'lodash';

const AVS_ADVANCED_SETTINGS = [
	{ avsCode: 'nyz', description: 'Address: No Match & 5 Digit Zip: Match' },
	{ avsCode: 'yna', description: 'Address: Match & 5 Digit Zip: No Match' },
	{ avsCode: 'nnn', description: 'Address: No Match & 5 Digit Zip: No Match' },
	{ avsCode: 'nyw', description: 'Address: No Match & 9 Digit Zip: Match' },
	{ avsCode: 'xxw', description: 'Card Number Not On File' },
	{ avsCode: 'xxu', description: 'Address Information not verified for domestic transaction' },
	{ avsCode: 'xxr', description: 'Retry / System Unavailable' },
	{ avsCode: 'xxs', description: 'Service Not Supported' },
	{ avsCode: 'xxe', description: 'Retry / System Unavailable' },
	{ avsCode: 'xxg', description: 'Global Non-AVS participant' },
	{ avsCode: 'yyg', description: 'International Address: Match & Zip: Not Compatible' },
	{ avsCode: 'ygg', description: 'International Address: No Compatible & Zip: Match' },
];
const declineStreetValues = ['nnn', 'nyw', 'nyz'];
const declineZipValues = ['nnn', 'yna'];
const declineStreetAndZipValues = ['nnn', 'nyw', 'nyz', 'yna'];
class AvsSettings extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	isBasicSettingChecked = key => {
		const { AVS } = this.props.cardknoxSettings;
		const AVSArray = chain(AVS)
			.toLower()
			.split(',')
			.sort()
			.value();

		let comparisonArray;
		switch (key) {
			case 'declineStreet':
				comparisonArray = declineStreetValues;
				break;
			case 'declineZip':
				comparisonArray = declineZipValues;
				break;
			case 'declineStreetAndZip':
				comparisonArray = declineStreetAndZipValues;
				break;
			default:
				return false;
		}

		return isEqual(AVSArray, sortBy(comparisonArray));
	};

	handleCheckboxChange = (id, checked) => {
		this.props.handleBlockChange(true);
		const cardknoxSettings = { ...this.props.cardknoxSettings };

		if (id.startsWith('decline')) {
			this.handleDeclineChange(id, checked, cardknoxSettings);
		} else {
			this.handleCategoryChange(id, checked, cardknoxSettings);
		}

		this.props.handleSettingsUpdate('cardknoxSettings', cardknoxSettings);
	};

	handleDeclineChange = (id, checked, cardknoxSettings) => {
		if (!checked) return;

		switch (id) {
			case 'declineStreet':
				cardknoxSettings.AVS = declineStreetValues.join(',');
				break;
			case 'declineZip':
				cardknoxSettings.AVS = declineZipValues.join(',');
				break;
			case 'declineStreetAndZip':
				cardknoxSettings.AVS = declineStreetAndZipValues.join(',');
				break;
			default:
				break;
		}
		// This is to remove the AVS value if it is the same as the previous state meaning same checkbox was clicked
		if (this.props.cardknoxSettings.AVS === cardknoxSettings.AVS) {
			cardknoxSettings.AVS = '';
		}
	};

	handleCategoryChange = (id, checked, cardknoxSettings) => {
		if (id === 'CVV') {
			this.handleCvvChange(id, checked, cardknoxSettings);
		} else {
			this.handleOtherChange(id, checked, cardknoxSettings);
		}
	};

	handleCvvChange = (id, checked, cardknoxSettings) => {
		cardknoxSettings[id] = checked ? 'N' : '';
	};

	handleOtherChange = (id, checked, cardknoxSettings) => {
		const [category, key] = id.split('.');

		if (!cardknoxSettings[category]) {
			cardknoxSettings[category] = '';
		}

		const values = cardknoxSettings[category].split(',').filter(Boolean);

		if (checked) {
			this.updateValues(values, key);
		}

		cardknoxSettings[category] = values.join(',');
	};

	updateValues = (values, key) => {
		if (!values.includes(key)) {
			values.push(key);
		} else {
			const index = values.indexOf(key);
			if (index > -1) {
				values.splice(index, 1);
			}
		}
	};
	isAvsCodeIncluded = avsCode => {
		const avsCodes = map(this.props.cardknoxSettings.AVS.split(','), toLower);
		return includes(avsCodes, toLower(avsCode));
	};

	renderBasicSettings = () => {
		return (
			<Fragment>
				<div className="spc--bottom--med">
					<input
						type="radio"
						id="declineStreet"
						name="basicAVS"
						className="input--radio"
						checked={this.isBasicSettingChecked('declineStreet')}
						onClick={e => this.handleCheckboxChange('declineStreet', e.target.checked)}
						disabled={this.props.isLoading}
					/>
					<label htmlFor="declineStreet">Decline transaction if Street does not match</label>
				</div>
				<div className="spc--bottom--med">
					<input
						type="radio"
						id="declineZip"
						name="basicAVS"
						className="input--radio"
						checked={this.isBasicSettingChecked('declineZip')}
						onClick={e => this.handleCheckboxChange('declineZip', e.target.checked)}
						disabled={this.props.isLoading}
					/>
					<label htmlFor="declineZip">Decline transaction if ZIP does not match</label>
				</div>
				<div>
					<input
						type="radio"
						id="declineStreetAndZip"
						name="basicAVS"
						className="input--radio"
						checked={this.isBasicSettingChecked('declineStreetAndZip')}
						onClick={e => this.handleCheckboxChange('declineStreetAndZip', e.target.checked)}
						disabled={this.props.isLoading}
					/>
					<label htmlFor="declineStreetAndZip">Decline transaction if either Street or ZIP do not match</label>
				</div>
			</Fragment>
		);
	};
	renderAdvancedSettings = () => {
		return (
			<table className="table table--primary spc--bottom--xlrg">
				<colgroup>
					<col width="40" />
					<col width="95" />
					<col />
				</colgroup>
				<thead>
					<tr>
						<th>Block</th>
						<th>AVS Code</th>
						<th>Description</th>
					</tr>
				</thead>
				<tbody>
					{map(AVS_ADVANCED_SETTINGS, ({ avsCode, description }) => {
						const settingKey = `AVS.${avsCode}`;
						return (
							<tr key={settingKey}>
								<td>
									<ToggleCheckbox
										key={settingKey}
										id={avsCode}
										checked={this.isAvsCodeIncluded(avsCode)}
										onChange={value => this.handleCheckboxChange(settingKey, value)}
										disabled={this.props.isLoading}
									/>
								</td>
								<td>{upperCase(avsCode)}</td>
								<td>{description}</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		);
	};

	handleExcludeAnyCardsChange = e => {
		this.props.handleSettingsUpdate('cardknoxSettings', {
			...this.props.cardknoxSettings,
			AVS_USOnly: e.target.checked,
		});
	};
	render() {
		const { cardknoxSettings, isExpanded, toggleExpand } = this.props;
		return (
			<div className="card spc--bottom--lrg">
				<button className="card__header card__header--expandable" onClick={toggleExpand}>
					<h5>AVS & CVV Settings</h5>
					<i className={`icon icon--sml icon--chevron--${isExpanded ? 'top' : 'down'}--primary`}></i>
				</button>

				{isExpanded && (
					<div className="card__body">
						<div className="spc--bottom--xlrg">
							<h6 className="spc--bottom--sml">CVV Settings</h6>
							<p className="type--p4 spc--bottom--sml--alt">
								Choose from the following options to easily select common options:
							</p>
							<div>
								<input
									type="checkbox"
									id="cvv"
									name="cvv"
									className="input--check"
									value={cardknoxSettings.CVV}
									checked={toLower(cardknoxSettings.CVV) === 'n'}
									onChange={e => this.handleCheckboxChange('CVV', e.target.checked)}
									disabled={this.isLoadind}
								/>
								<label htmlFor="cvv">Block mismatch</label>
							</div>
						</div>

						<div className="spc--bottom--xxlrg">
							<h6>AVS Settings</h6>
							<p className="type--p4 spc--bottom--sml--alt">
								Choose from the following options to easily select common options:
							</p>
							{this.renderBasicSettings()}
						</div>

						{this.renderAdvancedSettings()}

						<div className="spc--bottom--sml--alt">
							<input
								type="checkbox"
								id="excludeAnyCards"
								name="excludeAnyCards"
								checked={cardknoxSettings.AVS_USOnly}
								onChange={this.handleExcludeAnyCardsChange}
								className="input--check"
								disabled={this.isLoadind}
							/>
							<label htmlFor="excludeAnyCards">Exclude any cards issued outside of the US from AVS block.</label>
						</div>

						<p className="type--p4 type--color--text--light">
							Read more about AVS Settings{' '}
							<a
								className="btn btn--link btn--link--underline"
								target="_blank"
								rel="noopener noreferrer"
								href="https://docs.cardknox.com/cardknox-products/merchant-portal#fraud-settings"
							>
								here.
							</a>
						</p>
					</div>
				)}
			</div>
		);
	}
}
AvsSettings.propTypes = {
	isExpanded: PropTypes.bool.isRequired,
	toggleExpand: PropTypes.func.isRequired,
	cardknoxSettings: PropTypes.object.isRequired,
	handleSettingsUpdate: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
	handleBlockChange: PropTypes.func.isRequired,
};
export default AvsSettings;
