import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { map, cloneDeep, isEmpty } from 'lodash';
import { withLoader } from '../../../Common/components/loader';
import { withCancelable } from '../../../Common/components/cancelable';
import { withError } from '../../../Common/components/error';
import { withBlock } from '../../../Common/components/block';
import { kvaasService } from '../../../Common/services';
import { Notification } from '../../../Common/components/notifications';
import { kvaasResources } from '../../../Common/utilities';
import checkIfError from 'components/user-settings/utils/checkIfError';
import handleInvalidRevision from '../utils/invalidRevision';
import FooterComponent from 'components/settings/components/FooterComponent';

const requestKeys = {
	FETCH: 'fetch',
	SAVE: 'save',
};

class UserSettingsBatch extends Component {
	constructor(props) {
		super(props);
		this.topRefRef = createRef();
		this.notification = createRef();

		this.state = {
			isSaving: false,
			errMessages: [],
			oldData: {
				userSettings: null,
			},
			userSettings: {
				displayOpenBatchesByDefault: false,
			},
		};
	}

	mapStateToRequest = (data, oldData) => {
		return {
			newData: {
				revision: 0,
				data,
			},
			oldData,
		};
	};

	componentDidMount = async () => {
		this.props.showLoader(true);
		try {
			const [userSettings] = await this.props.makePendingRequest(
				kvaasService.get({ ...kvaasResources.userSettings, throwError: true }),
				requestKeys.FETCH
			);
			const newState = {
				...this.mapResToState(userSettings),
			};
			this.setState(newState);
			this.props.showLoader(false);
		} catch (e) {
			if (this.props.handleError(e)) {
				this.props.handleKvaasLoadError();
				this.props.showLoader(false);
			}
		}
	};

	setStateAsync = newState => new Promise(resolve => this.setState(newState, resolve));

	mapResToState = (oldUserSettings = this.state.oldData.userSettings) => {
		const { oldData, userSettings } = cloneDeep(this.state);
		const newState = {
			userSettings,
			oldData,
			errMessages: [],
		};
		this.mapDataToState(newState, oldUserSettings, 'userSettings');
		return newState;
	};

	mapDataToState = (newData, oldData, type) => {
		checkIfError(newData, oldData, type, (value, key) => {
			newData[type][key] = value;
		});
	};

	save = async () => {
		if (this.props.isLoading) {
			return;
		}
		this.props.showLoader(true);
		let settingChanged = false;
		let refNum;
		let error;

		try {
			const [userSettings] = await this.props.makePendingRequest(
				kvaasService.save({
					newData: { revision: 0, data: this.state.userSettings },
					oldData: this.state.oldData.userSettings,
					...kvaasResources.userSettings,
				}),
				requestKeys.SAVE
			);
			const newState = this.mapResToState(userSettings);
			refNum = userSettings.refNum;
			await this.setStateAsync(newState);
			settingChanged = true;
			this.props.handleBlockChange(false);
		} catch (e) {
			error = this.props.handleError(e, { delayMessage: true });
			if (error) {
				try {
					const [userSettings] = await this.props.makePendingRequest(
						kvaasService.get(kvaasResources.userSettings),
						requestKeys.SAVE
					);
					const newState = this.mapResToState(userSettings);
					await this.setStateAsync(newState);
					settingChanged = true;
				} catch (err) {
					error = this.props.handleError(err, { delayMessage: true });
					settingChanged = true;
					if (!error) {
						return;
					}
				}
			} else {
				return;
			}
		}
		if (settingChanged) {
			this.handlesettingChanged(refNum, error);
		}
	};

	handlesettingChanged = (refNum, err) => {
		const { showLoader } = this.props;
		showLoader(false);
		if (!isEmpty(this.state.errMessages)) {
			this.scrollTop();
		} else if (!err) {
			this.notification.current.addNotification({
				message: 'Batch settings updated',
				ref: refNum,
				success: true,
			});
		} else {
			err.show();
		}
	};

	scrollTop = () => {
		if (this.topRef.current) {
			this.topRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
		}
	};

	handleUserSettingsChange = ({ target: { checked } }) => {
		this.setState({
			userSettings: {
				displayOpenBatchesByDefault: checked,
			},
		});
		this.props.handleBlockChange(true);
	};

	render() {
		const {
			errMessages,
			userSettings: { displayOpenBatchesByDefault },
		} = this.state;

		return (
			<div className="settings--main">
				<div className="settings__header">
					<h3 className="settings__title">User Settings</h3>
					<h5>Batch Settings</h5>
				</div>

				<div ref={this.topRef}></div>
				{errMessages.length > 0
					? map(errMessages, (error, index) => (
							<div key={index} className="spc--top--sml spc--bottom--med type--color--error">
								{error}
							</div>
					  ))
					: null}
				<div className="form__group">
					<input
						type="checkbox"
						id="displayOpenBatchesByDefault"
						name="displayOpenBatchesByDefault"
						className="input input--check"
						checked={displayOpenBatchesByDefault}
						onChange={this.handleUserSettingsChange}
					/>
					<label htmlFor="displayOpenBatchesByDefault" className="type--color--text--medium type--wgt--medium">
						Display Open Batches by Default
					</label>
				</div>

				<FooterComponent
					save={this.save}
					disabled={this.props.isLoading}
					isLoading={this.props.isLoading}
					hideReset={true}
				/>

				<Notification ref={this.notification} />
			</div>
		);
	}
}

UserSettingsBatch.propTypes = {
	handleError: PropTypes.func,
	makePendingRequest: PropTypes.func,
	showLoader: PropTypes.func,
	isLoading: PropTypes.bool,
	handleBlockChange: PropTypes.func,
	handleKvaasLoadError: PropTypes.func,
};

export default withError(withLoader(withCancelable(withBlock(UserSettingsBatch))), handleInvalidRevision);
