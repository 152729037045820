import React, { Component, createRef } from 'react';
import { any, array, func, object } from 'prop-types';
import { isEmpty, isEqual, noop } from 'lodash';

import { ZebraRenderer } from 'common/components/row';
import PopupGrid from 'common/components/grid/PopupGrid';
import { GridTooltip } from 'common/components/tooltips';
import { FormErrors } from 'common/utilities';

class BulkChargeCustomersPopupGrid extends Component {
	constructor(props) {
		super(props);

		this.gridRef = createRef();

		this.state = {
			expanded: {},
			fetchingData: false,
			fetchingAdditionalData: false,
			filters: {},
			activeFilters: {},
			inlineFilters: {},
		};

		this.components = {
			rowRenderer: ZebraRenderer,
			tooltip: GridTooltip,
		};

		this.classes = {
			printWrapper: 'datatooltip--v--bottom',
			exportWrapper: 'datatooltip--v--bottom',
			filterContainer: '',
			filter: 'spc--bottom--sml spc--right--sml',
			gridHeader: 'spc--bottom--sml',
			gridHolder: 'react-grid',
			gridWrapper: 'grid__holder--override',
			headerGroup: 'flex--primary',
		};
	}

	componentDidMount() {
		if (this.gridRef.current && this.props.data && !isEmpty(this.props.data.xReportData)) {
			this.gridRef.current.scrollTo({ top: 0, left: 0 });
			this.gridRef.current.handleInitialSort();
		}
		const errors = this.props.errors;
		this.setState({
			errors,
		});
	}
	componentDidUpdate() {
		if (!isEqual(this.props.errors, this.state.errors)) {
			this.setState({
				errors: this.props.errors,
			});
		}
	}
	render() {
		const { fetchingData, fetchingAdditionalData, inlineFilters, expanded, filters, activeFilters } = this.state;
		const {
			popupRef,
			handleChange,
			filteredRows,
			data,
			columns,
			tooltipProps,
			mapExistingValuesToRows,
			errors,
			defaultColumns,
			removeRow,
		} = this.props;

		return (
			<React.Fragment>
				{!isEmpty(errors) && (
					<div className={'newtransaction__amount--bulk__error'}>
						<FormErrors errors={this.state.errors} />
						{this.gridRef.current.updateViewport()}
					</div>
				)}
				<PopupGrid
					showColumnFilter={true}
					showPopupGrid={true}
					emptyMessage="You should change your filter options"
					fetchingData={fetchingData}
					fetchingAdditionalData={fetchingAdditionalData}
					filteredRows={filteredRows}
					columns={columns}
					filterColumns={columns}
					defaultColumns={defaultColumns}
					data={data}
					inlineFilters={inlineFilters}
					components={this.components}
					classes={this.classes}
					onChange={handleChange}
					expanded={expanded}
					type="bulkCharge"
					filters={filters}
					activeFilters={activeFilters}
					fetchData={noop}
					lastApiRefNum={null}
					ref={this.gridRef}
					columnFilterType="bulkCharge"
					kvaasResourceType="bulkCharge"
					popupRef={popupRef}
					initialFetch={false}
					tooltipProps={tooltipProps}
					mapExistingValuesToRows={mapExistingValuesToRows}
					removeRow={removeRow}
				/>{' '}
			</React.Fragment>
		);
	}
}

BulkChargeCustomersPopupGrid.propTypes = {
	popupRef: any.isRequired,
	data: object.isRequired,
	filteredRows: array.isRequired,
	handleChange: func.isRequired,
	columns: array.isRequired,
	defaultColumns: array.isRequired,
	tooltipProps: object,
	errors: object,
	mapExistingValuesToRows: any,
	removeRow: func,
};

export default BulkChargeCustomersPopupGrid;
