import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { startsWith } from 'lodash';

import { CardTypeImagePath, formatCardExpiry } from '../../Common/utilities';
import { fieldPropType, isValid } from '../../Common/fields';
import { transactionType } from './constants';

const required = (
	<span className="form__group__required" data-tooltip="Required">
		*
	</span>
);
class CreditCardDisplayComponent extends Component {
	get cardType() {
		return this.props.dependentValues.xCardType;
	}

	onNumberChange = ({ value }, { target: { name } }) => {
		this.props.onChange({
			key: name,
			value,
		});
	};

	onChange = ({ target: { name, value } }) => {
		this.props.onChange({
			key: name,
			value: value,
		});
	};
	renderTransactionGiftTypes = (allowGiftIssue, allowGiftRedeem) => (
		<Fragment>
			{allowGiftIssue ? <option value={transactionType.GIFT_ISSUE}>Gift: Issue</option> : null}
			{allowGiftRedeem ? <option value={transactionType.GIFT_REDEEM}>Gift: Redeem</option> : null}
		</Fragment>
	);
	renderTransactionType = () => {
		const {
			ccData,
			showEbtfsVoucherOption,
			permissions: {
				allowCcSale,
				allowCcSave,
				allowCcAuthOnly,
				allowCcCredit,
				allowCcPostAuth,
				allowGiftIssue,
				allowGiftRedeem,
				allowEbtfsVoucher,
			},
			displayOnlyGiftTypes,
			isGift,
			isEbtfs,
		} = this.props;
		return (
			<div className="form__group">
				<div className="form__group__header">
					<label className="form__group__label">Transaction type {required}</label>
				</div>
				<select
					name="transactionType"
					className="input input--med input--select"
					value={ccData.transactionType}
					onChange={this.onChange}
					tabIndex="-1"
				>
					{displayOnlyGiftTypes ? (
						this.renderTransactionGiftTypes(allowGiftIssue, allowGiftRedeem)
					) : (
						<Fragment>
							{allowCcSale ? <option value={transactionType.SALE}>Charge</option> : null}
							{allowCcSave ? <option value={transactionType.SAVE}>Save</option> : null}
							{allowCcAuthOnly ? <option value={transactionType.AUTH_ONLY}>Auth Only</option> : null}
							{allowCcCredit ? <option value={transactionType.CREDIT}>Refund</option> : null}
							{allowCcPostAuth ? <option value={transactionType.POST_AUTH}>Post Auth</option> : null}
							{allowEbtfsVoucher && showEbtfsVoucherOption && isEbtfs ? (
								<option value={transactionType.EBTFS_VOUCHER}>EBTFS Voucher</option>
							) : null}
							{isGift && this.renderTransactionGiftTypes(allowGiftIssue, allowGiftRedeem)}
						</Fragment>
					)}
				</select>
			</div>
		);
	};
	renderPostAuth = (ccData, invalidClassName) => {
		if (ccData.transactionType !== transactionType.POST_AUTH) return null;
		return (
			<Fragment>
				<div className="form__group">
					<div className="form__group__header">
						<label className="form__group__label">Auth Code {required}</label>
					</div>
					<input
						type="text"
						name="authCode"
						className={`input input--med ${(!isValid(ccData.authCode) && invalidClassName) || ''}`}
						placeholder="Auth Code"
						value={ccData.authCode.value}
						onChange={this.onChange}
					></input>
				</div>
				<div className="notes notes--primary spc--bottom--lrg">
					<div className="icon"></div>
					Please only use the auth code that was provided by your processing bank.
				</div>
			</Fragment>
		);
	};

	renderAuthorizationCode = (ccData, invalidClassName) => {
		if (ccData.transactionType !== transactionType.EBTFS_VOUCHER) return null;
		return (
			<Fragment>
				<div className="newtransaction__item newtransaction__item--expandable spc--top--med no-bottom">
					<label className="newtransaction__item__label newtransaction__item__label--expandable">
						Authorization Code {required}
					</label>
					<div className="newtransaction__item__value newtransaction__item__value--expandable">
						<input
							type="text"
							name="authCode"
							className={`input input--med ${(!isValid(ccData.authCode) && invalidClassName) || ''}`}
							placeholder="Authorization Code"
							value={ccData.authCode.value}
							onChange={this.onChange}
						></input>
					</div>
				</div>
				<div className="newtransaction__item newtransaction__item--expandable no-bottom">
					<label className="newtransaction__item__label newtransaction__item__label--expandable">
						Voucher Serial Number {required}
					</label>
					<div className="newtransaction__item__value newtransaction__item__value--expandable">
						<NumberFormat
							name="voucherSerial"
							value={ccData.voucherSerial.value}
							placeholder="Voucher Serial Number"
							className={`input input--med ${(!isValid(ccData.voucherSerial) && invalidClassName) || ''}`}
							onValueChange={this.onNumberChange}
						/>
					</div>
				</div>
			</Fragment>
		);
	};

	render() {
		const {
			type,
			mask,
			exp,
			cvv,
			invalidClassName,
			requiredFields,
			customDisplayLabels,
			transactionHiddenFields,
			ccData,
		} = this.props;

		return (
			<div className="newtransaction--expanded--inner">
				{this.renderTransactionType()}
				{this.renderPostAuth(ccData, invalidClassName)}
				{this.renderAuthorizationCode(ccData, invalidClassName)}
				<div className="form__group">
					<div className="form__group__header">
						<label className="form__group__label">
							{customDisplayLabels.cardNumber || 'Credit card'} {required}
						</label>
					</div>
					<div className="input input--med input--disabled">
						<div className="flex--primary flex--gap--sml">
							<img src={CardTypeImagePath.getPath(type)} className="grid__creditcard" />
							<span>{mask}</span>
						</div>
					</div>
				</div>
				<div className="row">
					{!startsWith(ccData.transactionType, 'gift') && (
						<div className="col col-sml-7">
							<div className="form__group">
								<div className="form__group__header">
									<label className="form__group__label">
										{customDisplayLabels.validUntil || 'Expiration'} {required}
									</label>
								</div>
								<NumberFormat
									format={formatCardExpiry}
									name="expiry"
									value={exp.value}
									placeholder="MM/YY"
									className={`input input--med ${(!isValid(exp) && invalidClassName) || ''}`}
									onValueChange={this.onNumberChange}
								/>
							</div>
						</div>
					)}
					{transactionHiddenFields.cvv || startsWith(ccData.transactionType, 'gift') ? null : (
						<div className="col col-sml-5">
							<div className="form__group">
								<div className="form__group__header">
									<label className="form__group__label">
										{customDisplayLabels.cvv || 'CVV'} {requiredFields.cvvAllTransactionsRequired && required}
									</label>
								</div>
								<NumberFormat
									format="####"
									name="cvv"
									className={`input input--med ${(!isValid(cvv) && invalidClassName) || ''}`}
									value={cvv.value}
									placeholder="CVV"
									onValueChange={this.onNumberChange}
								/>
							</div>
						</div>
					)}
				</div>
			</div>
		);
	}
}

CreditCardDisplayComponent.propTypes = {
	mask: PropTypes.string,
	type: PropTypes.string,
	exp: fieldPropType(PropTypes.string),
	cvv: fieldPropType(PropTypes.string),
	onChange: PropTypes.func,
	invalidClassName: PropTypes.string,
	transactionHiddenFields: PropTypes.object,
	requiredFields: PropTypes.object,
	customDisplayLabels: PropTypes.object,
	permissions: PropTypes.object,
	ccData: PropTypes.object,
	dependentValues: PropTypes.object,
	displayOnlyGiftTypes: PropTypes.bool,
	showEbtfsVoucherOption: PropTypes.bool,
	isGift: PropTypes.bool,
	isEbtfs: PropTypes.bool,
};

export default CreditCardDisplayComponent;
