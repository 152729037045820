import React from 'react';
import { object } from 'prop-types';
import { withRouter } from 'react-router-dom';

const PaymentSiteActionsComponent = ({ history, match: { path }, dependentValues }) => {
	const onEdit = () => {
		const { id } = dependentValues;
		history.push(`${path}/${id}`);
	};
	return (
		<button type="button" className="btn btn--link spc--right--med" onClick={onEdit}>
			<i className="icon icon--sml icon--edit" />
		</button>
	);
};

PaymentSiteActionsComponent.propTypes = {
	dependentValues: object,
	history: object,
	match: object,
};

export default withRouter(PaymentSiteActionsComponent);
