import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { each, upperFirst } from 'lodash';

class CustomFieldsSelectionComponent extends Component {
	get customValues() {
		const { values } = this.props.filter;

		const textElements = [];
		each(values, (value, key) => {
			if (value != null) {
				textElements.push({ key: upperFirst(key), value });
			}
		});

		return textElements;
	}

	filterRemoved = () => {
		this.props.onFilterRemoved({
			id: 'custom',
			emptyValue: null,
		});
	};

	renderCustomField = e => {
		return (
			<div key={e.key} className="filter__tag--custom">
				<label className="filter__tag__label">{e.key}:</label>
				<span className="display--ib align--v--middle">{e.value}</span>
			</div>
		);
	};

	render() {
		return (
			<div className="filter__toolbar__selected-filter">
				{this.customValues.map(i => {
					return this.renderCustomField(i);
				})}
				<button onClick={this.filterRemoved} className="btn btn--link datatooltip--auto" data-tooltip="Remove">
					<i className="icon icon--tny icon--close--text"></i>
				</button>
			</div>
		);
	}
}

CustomFieldsSelectionComponent.propTypes = {
	filter: PropTypes.object.isRequired,
	onFilterRemoved: PropTypes.func.isRequired,
};

export default CustomFieldsSelectionComponent;
