import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { map, cloneDeep, find, isEmpty, memoize, every } from 'lodash';
import { withLoader } from '../../../Common/components/loader';
import { withCancelable } from '../../../Common/components/cancelable';
import { withError } from '../../../Common/components/error';
import { withBlock } from '../../../Common/components/block';
import { principalService, authenticationService, kvaasService } from '../../../Common/services';
import { Notification } from '../../../Common/components/notifications';
import { kvaasResources } from '../../../Common/utilities';
import { Select } from '../../../Common/components/select';
import predefinedDates from '../../dashboard/filter/predefined-dates';
import checkIfError from 'components/user-settings/utils/checkIfError';
import handleInvalidRevision from '../utils/invalidRevision';
import FooterComponent from 'components/settings/components/FooterComponent';

const requestKeys = {
	FETCH: 'fetch',
	SAVE: 'save',
};

const dateOptions = map(predefinedDates, ({ key: name, displayValue: label }) => ({ name, label }));

class UserSettingsDashboard extends Component {
	constructor(props) {
		super(props);
		this.top = createRef();
		this.notification = createRef();

		this.state = {
			isSaving: false,
			errorMessages: [],
			oldData: {
				dashboardSettings: null,
			},
			dashboardSettings: {
				date: '',
			},
		};
	}

	mapStateToRequest = (data, oldData) => {
		return {
			newData: {
				revision: 0,
				data,
			},
			oldData,
			...kvaasResources.collapsedSettingsMessages,
		};
	};

	componentDidMount = async () => {
		this.props.showLoader(true);
		try {
			const [
				{ id, list: users },
				{
					signInUserSession: {
						idToken: { jwtToken: token },
					},
				},
				[dashboardSettings],
			] = await this.props.makePendingRequest(
				Promise.all([
					principalService.get(),
					authenticationService.getUser(),
					kvaasService.get({ ...kvaasResources.dashboardSettings, throwError: true }),
				]),
				requestKeys.FETCH
			);
			const newState = {
				...this.mapResponseToState(dashboardSettings),
				token,
				id,
				users,
				sortKeysAlphabetically: every(users, user => user.sortorder === 0),
			};
			this.setState(newState);
			this.props.showLoader(false);
		} catch (e) {
			if (this.props.handleError(e)) {
				this.props.handleKvaasLoadError();
				this.props.showLoader(false);
			}
		}
	};

	setStateAsync = newState => new Promise(resolve => this.setState(newState, resolve));

	getValue = container => memoize(name => find(container, { name }));

	getDate = this.getValue(dateOptions);

	mapResponseToState = (oldDashboardSettings = this.state.oldData.dashboardSettings) => {
		const { oldData, dashboardSettings } = cloneDeep(this.state);
		const newState = {
			errorMessages: [],
			oldData,
			dashboardSettings,
		};
		this.mapFieldsToState(newState, oldDashboardSettings, 'dashboardSettings');
		return newState;
	};

	mapFieldsToState = (newState, oldData, type) => {
		checkIfError(newState, oldData, type, (value, key) => {
			newState[type][key] = value;
		});
	};

	save = async () => {
		if (this.props.isLoading) {
			return;
		}
		this.props.showLoader(true);
		let anyChanged = false;
		let refNum;
		let error;

		try {
			const [dashboardSettings] = await this.props.makePendingRequest(
				kvaasService.save({
					newData: { revision: 0, data: this.state.dashboardSettings },
					oldData: this.state.oldData.dashboardSettings,
					...kvaasResources.dashboardSettings,
				}),
				requestKeys.SAVE
			);
			const newState = this.mapResponseToState(dashboardSettings);
			refNum = dashboardSettings.refNum;
			await this.setStateAsync(newState);
			anyChanged = true;
			this.props.handleBlockChange(false);
		} catch (e) {
			error = this.props.handleError(e, { delayMessage: true });
			if (error) {
				try {
					const [dashboardSettings] = await this.props.makePendingRequest(
						kvaasService.get(kvaasResources.dashboardSettings),
						requestKeys.SAVE
					);
					const newState = this.mapResponseToState(dashboardSettings);
					await this.setStateAsync(newState);
					anyChanged = true;
				} catch (err) {
					error = this.props.handleError(err, { delayMessage: true });
					anyChanged = true;
					if (!error) {
						return;
					}
				}
			} else {
				return;
			}
		}
		if (anyChanged) {
			this.handleAnyChanged(refNum, error);
		}
	};

	handleAnyChanged = (refNum, error) => {
		this.props.showLoader(false);
		if (!isEmpty(this.state.errorMessages)) {
			this.scrollToTop();
		} else if (!error) {
			this.notification.current.addNotification({
				message: 'Dashboard settings updated',
				ref: refNum,
				success: true,
			});
		} else {
			error.show();
		}
	};

	scrollToTop = () => {
		if (this.top.current) {
			this.top.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
		}
	};

	handleDefaultDateRangeChange = ({ name: date }) => {
		this.setState({
			dashboardSettings: {
				date,
			},
		});
		this.props.handleBlockChange(true);
	};

	render() {
		const {
			errorMessages,
			dashboardSettings: { date },
		} = this.state;

		return (
			<div className="settings--main">
				<div className="settings__header">
					<h3 className="settings__title">User Settings</h3>
					<h5>Dashboard</h5>
				</div>
				<div ref={this.top}></div>
				{errorMessages.length > 0
					? map(errorMessages, (error, index) => (
							<div key={index} className="spc--top--sml spc--bottom--med type--color--error">
								{error}
							</div>
					  ))
					: null}
				<div className="form__group">
					<div className="form__group__header">
						<span className="form__group__label">Default Dashboard Date Range</span>
					</div>
					<Select
						name="date"
						id="date"
						value={this.getDate(date)}
						options={dateOptions}
						onChange={this.handleDefaultDateRangeChange}
						menuPlacement="auto"
						getOptionValue={option => option.label}
					/>
				</div>
				<Notification ref={this.notification} />
				<FooterComponent
					save={this.save}
					isLoading={this.props.isLoading}
					disabled={this.props.isLoading}
					hideReset={true}
				/>
			</div>
		);
	}
}

UserSettingsDashboard.propTypes = {
	handleError: PropTypes.func,
	makePendingRequest: PropTypes.func,
	showLoader: PropTypes.func,
	isLoading: PropTypes.bool,
	handleBlockChange: PropTypes.func,
	handleKvaasLoadError: PropTypes.func,
};

export default withError(withLoader(withCancelable(withBlock(UserSettingsDashboard))), handleInvalidRevision);
