import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, groupBy, sumBy, filter, map, includes, round } from 'lodash';
import moment from 'moment';
import 'moment-timezone';
import NumberFormat from 'react-number-format';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

import { CurrencyMap, localTimezone, apiTimezone, apiToLocalMoment } from '../../Common/utilities';
import { withCancelable } from '../../Common/components/cancelable';

const displayDateFormat = ApplicationSettings.checkDateFormat;

class DashboardGrossRevenue extends Component {
	constructor(props) {
		super(props);

		this.state = {
			timeline: [],
			averageValue: props.averageValue,
			hasRecords: props.hasRecords,
			currency: CurrencyMap.resolveCurrency(),
		};
	}

	componentDidMount() {
		this.generateTimeline();
	}

	generateTimeline = async () => {
		try {
			const timeline = await this.props.makePendingRequest(this.generateUtcTimeline());
			const data = this.generateDataTimeline();
			for (let item of data) {
				let update = filter(timeline, { key: item.key })[0];
				if (update) {
					update.sale = item.sale;
					update.credit = item.credit;
					update.total = item.total;
				}
			}

			let average = 0;
			let count = 0;
			let sum = 0;
			for (let item of timeline) {
				if (item.total) {
					sum += Math.abs(item.total);
					count++;
				}
			}
			if (count > 0) {
				average = sum / count;
			}

			this.setState({
				timeline: timeline,
				average: average,
			});
		} catch (e) {
			if (e && !e.isCanceled) {
				//eslint-disable-next-line
				console.error(e);
			}
		}
	};

	generateUtcTimeline = async () => {
		const { dateRange } = this.props;
		if (dateRange && dateRange.xBeginDate && dateRange.xEndDate) {
			const mBeginDate = moment(dateRange.xBeginDate);
			const mEndDate = moment(dateRange.xEndDate);

			const showTime = !mBeginDate.diff(mEndDate, 'days');

			let timezone;
			try {
				timezone = await localTimezone();
			} catch (e) {
				timezone = apiTimezone;
			}
			let start = await apiToLocalMoment(dateRange.xBeginDate);
			if (showTime <= -7) {
				start = moment(start).add(-1, 'day');
			}
			let end = moment(mEndDate)
				.tz(timezone)
				.add(1, 'hours')
				.startOf('hour')
				.clone();

			let timeline = [];
			while (start <= end) {
				let tmpTime = cloneDeep(start);
				const labelTime = showTime ? tmpTime.format('h:mm A') : tmpTime.format(displayDateFormat);
				timeline.push({
					key: tmpTime.format('YYYYMMDDHH'),
					labelDate: tmpTime.format(displayDateFormat),
					labelTime: labelTime,
					sale: null,
					credit: null,
					total: null,
				});
				start.add(1, 'hours');
			}

			return timeline;
		}
		return [];
	};

	generateDataTimeline = () => {
		if (this.props.data && this.props.data.xReportData && this.props.data.xReportData.length > 0) {
			const data = this.props.data.xReportData;
			let timeline = [];
			for (let item of data) {
				let date = cloneDeep(item.xEnteredDate).startOf('hour');

				let returnObj = {
					key: date.format('YYYYMMDDHH'),
					sale: 0,
					credit: 0,
				};

				const type = item.xCommand.toLowerCase().split(':')[1];
				if (includes(['sale', 'capture', 'postauth', 'authonly', 'redeem', 'recommendation'], type)) {
					returnObj.sale = Math.abs(item.xAmount);
				} else if (type === 'credit' || type === 'refund' || 'issue') {
					let creditTmp = Math.abs(item.xAmount);
					returnObj.credit = creditTmp;
				}

				timeline.push(returnObj);
			}

			const groupedData = map(groupBy(timeline, 'key'), (objs, key) => ({
				key: key,
				sale: round(sumBy(objs, o => round(o.sale, 2)), 2),
				credit: round(sumBy(objs, o => round(o.credit, 2)), 2),
				total: round(round(sumBy(objs, o => round(o.sale, 2)), 2) - round(sumBy(objs, o => round(o.credit, 2)), 2), 2),
			}));

			return groupedData;
		}
		return [];
	};

	renderCustomTooltip = data => {
		let payload = {};
		if (this.props.hasRecords && data && data.payload && data.payload[0] && data.payload[0].payload) {
			payload = data.payload[0].payload;

			return (
				<React.Fragment>
					<label className="type--color--negative type--wgt--medium">
						{payload.labelTime !== payload.labelDate ? `${payload.labelTime} ${payload.labelDate}` : payload.labelDate}
					</label>
					<div className="value">
						<span className="total">Total</span>
						<NumberFormat
							prefix={this.state.currency}
							value={payload.total}
							displayType="text"
							thousandSeparator={true}
							decimalScale={2}
							fixedDecimalScale={true}
						/>
					</div>
					<div className="value">
						<span className="sales">Sales</span>
						<NumberFormat
							prefix={this.state.currency}
							value={payload.sale}
							displayType="text"
							thousandSeparator={true}
							decimalScale={2}
							fixedDecimalScale={true}
						/>
					</div>
					<div className="value">
						<span className="credit">Credit</span>
						<NumberFormat
							prefix={this.state.currency}
							value={payload.credit}
							displayType="text"
							thousandSeparator={true}
							decimalScale={2}
							fixedDecimalScale={true}
						/>
					</div>
				</React.Fragment>
			);
		}
	};

	renderEmptyGraph = () => {
		const timeline = cloneDeep(this.state.timeline);
		if (timeline && timeline.length > 0) {
			timeline[0].credit = timeline[timeline.length - 1].credit = 0;
			timeline[0].top = timeline[timeline.length - 1].top = 50;
		}

		return (
			<div className="rechart--emptystate">
				<div className="rechart--emptystate__text">No data to display in the current view</div>
				<ResponsiveContainer width="100%" aspect={4.0 / 0.7} debounce={1}>
					<LineChart width={700} height={300} data={timeline} margin={{ top: 5, right: 0, bottom: 5, left: 0 }}>
						<XAxis
							dataKey="labelTime"
							stroke="#676A7B"
							axisLine={false}
							tick={{ fontSize: 11 }}
							tickSize={0}
							tickMargin={20}
						/>
						<YAxis hide={true} />
						<CartesianGrid stroke="#CCCDD3" vertical={false} horizontal={true} />
						<Line
							connectNulls={true}
							isAnimationActive={false}
							type="monotone"
							legendType="circle"
							dataKey="top"
							stroke="#ffffff"
						/>
						<Line
							connectNulls={true}
							isAnimationActive={false}
							type="monotone"
							legendType="circle"
							dataKey="credit"
							stroke="0"
						/>
					</LineChart>
				</ResponsiveContainer>
			</div>
		);
	};

	render() {
		const { hasRecords, timeline } = this.state;

		return (
			<div className="spc--bottom--xlrg">
				<div className="dashboard__gross__header">
					<h5>Gross Revenue</h5>
					<div className="dashboard__gross__legend">
						<span className="dashboard__gross__legend__item total">Total</span>
						<span className="dashboard__gross__legend__item sales">Sales</span>
						<span className="dashboard__gross__legend__item credit">Credit</span>
					</div>
				</div>
				{hasRecords ? (
					<ResponsiveContainer width="100%" maxHeight={300} aspect={4.0 / 2.0} debounce={1}>
						<LineChart width={700} height={300} data={timeline} margin={{ top: 5, right: 0, bottom: 5, left: 0 }}>
							<XAxis
								dataKey="labelTime"
								stroke="#676A7B"
								axisLine={false}
								tick={{ fontSize: 12 }}
								tickSize={0}
								tickMargin={20}
							/>
							<YAxis hide={false} stroke="#676A7B" tickMargin={24} tickSize={0} />
							<CartesianGrid stroke="#CCCDD3" vertical={false} horizontal={true} />
							<Tooltip content={this.renderCustomTooltip} />
							<Line
								connectNulls={true}
								isAnimationActive={false}
								type="monotone"
								legendType="circle"
								dataKey="total"
								stroke="#2D50D8"
								dot={false}
								strokeWidth={3}
							/>
							<Line
								connectNulls={true}
								isAnimationActive={false}
								type="monotone"
								legendType="circle"
								dataKey="credit"
								stroke="#FE702B"
								dot={false}
								strokeWidth={3}
							/>
							<Line
								connectNulls={true}
								isAnimationActive={false}
								type="monotone"
								legendType="circle"
								dataKey="sale"
								stroke="#69C5D2"
								dot={false}
								strokeWidth={3}
							/>
						</LineChart>
					</ResponsiveContainer>
				) : (
					this.renderEmptyGraph()
				)}
			</div>
		);
	}
}

DashboardGrossRevenue.propTypes = {
	data: PropTypes.object,
	hasRecords: PropTypes.bool.isRequired,
	filters: PropTypes.array.isRequired,
	dateRange: PropTypes.object,
	averageValue: PropTypes.number,
	makePendingRequest: PropTypes.func,
};

export default withCancelable(DashboardGrossRevenue);
