import React from 'react';
import PropTypes from 'prop-types';
import ModalComponent from 'common/components/modal/modal';
import { isEmpty } from 'lodash';

const BulkProgressModal = ({
	isOpen,
	onClose,
	progress,
	mappedErrors,
	errors,
	someErrorMessage,
	dataType,
	successMessage,
	failureMessage,
	allSuccessMessage,
	action,
	className,
}) => {
	const uploadHasErrors = !isEmpty(mappedErrors);
	const allCustomersFailed = progress.completed === 0 && progress.errored === progress.total;
	const someCustomersFailed = progress.errored > 0 && !allCustomersFailed;
	const processCompleted = progress.completed + progress.errored === progress.total;
	const renderProgressStatus = () => {
		if (uploadHasErrors) {
			return (
				<table className="table table--secondary table--secondary--upload-error">
					<tbody>
						<tr>
							<td className="counter" colSpan={3}>
								Errors ({errors.length})
							</td>
						</tr>
						{mappedErrors}
					</tbody>
				</table>
			);
		}

		if (someCustomersFailed) {
			return (
				<p className="type--xlrg">
					<span>{progress.errored}</span> {someErrorMessage}
				</p>
			);
		}

		return null;
	};
	const message =
		uploadHasErrors && !allCustomersFailed
			? successMessage
			: allCustomersFailed
			? failureMessage
			: processCompleted
			? allSuccessMessage
			: '';
	return (
		<ModalComponent isOpen={isOpen} onClose={onClose}>
			<div className="modal__body">
				<div
					className={`customer--process ${className} ${!processCompleted && !uploadHasErrors ? 'is-loading' : ''} ${
						processCompleted && !allCustomersFailed ? 'is-success' : ''
					} ${uploadHasErrors ? 'has-error' : ''}`}
				>
					<div className="icon"></div>
				</div>
				<h4 className="type--center type--wgt--regular spc--bottom--med">{message}</h4>

				<p className="type--center type--color--text--light type--p1">
					{action} {progress.completed + progress.errored} of {progress.total} {dataType}
				</p>

				{renderProgressStatus()}
			</div>
			<div className="modal__footer"></div>
		</ModalComponent>
	);
};
BulkProgressModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	progress: PropTypes.shape({
		completed: PropTypes.number.isRequired,
		total: PropTypes.number.isRequired,
		errored: PropTypes.number.isRequired,
	}).isRequired,
	mappedErrors: PropTypes.node,
	errors: PropTypes.array,
	someErrorMessage: PropTypes.string,
	dataType: PropTypes.string,
	successMessage: PropTypes.string,
	failureMessage: PropTypes.string,
	allSuccessMessage: PropTypes.string,
	action: PropTypes.string.isRequired,
	className: PropTypes.string,
};

export default BulkProgressModal;
