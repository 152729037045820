import React from 'react';

const ApplePayTooltip = props => {
	return (
		<i
			{...props}
			className="icon icon--sml icon--info newtransaction__tooltip__trigger spc--left--sml spc--bottom--nano"
		>
			<span className="newtransaction__tooltip">
				<p>
					Apple Pay allows consumers to store their payment information with Apple Wallet and use it to pay for goods
					and services wherever Apple Pay is supported. If you enable this option for PaymentSITE, an Apple Pay button
					displays on the form for users to select as a payment option.
				</p>
			</span>
		</i>
	);
};

export default ApplePayTooltip;
