import React from 'react';
import PropTypes from 'prop-types';
import { toLower } from 'lodash';
const UserButton = ({ isMobile, companyAcronym, selectedCompany, email, toggleUserDropDown, userButtonClass }) => (
	<button onClick={toggleUserDropDown} className={`header__account ${userButtonClass}`}>
		<div className={`header__account__profile ${isMobile ? '' : 'spc--right--tny'}`}>{companyAcronym}</div>
		<div className="header__account__name__wrapper">
			<div className="header__account__name">
				{toLower(`${selectedCompany.dba_alias || selectedCompany.dba} - ${selectedCompany.mid}`)}
			</div>
			<div className="header__account__email">{email}</div>
		</div>
		<i className="icon icon--med icon--expand flex--no-shrink"></i>
	</button>
);

UserButton.propTypes = {
	isMobile: PropTypes.bool,
	companyAcronym: PropTypes.string,
	selectedCompany: PropTypes.shape({
		dba_alias: PropTypes.string,
		dba: PropTypes.string,
		mid: PropTypes.string,
	}),
	email: PropTypes.string,
	toggleUserDropDown: PropTypes.func,
	userButtonClass: PropTypes.string,
};
export default UserButton;
