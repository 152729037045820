import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const IGNORE_CLASSNAMES = ['ReactModal__Overlay', 'grid__multiselect__item'];

const OutsideClick = ({ children, action, className }) => {
	const wrapperRef = useRef(null);

	const handleClickOutside = event => {
		if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
			let currentNode = event.target;
			while (currentNode) {
				if (IGNORE_CLASSNAMES.some(className => currentNode.classList.contains(className))) {
					return;
				}
				currentNode = currentNode.parentElement;
			}
			action(event);
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	return (
		<div className={className} ref={wrapperRef}>
			{children}
		</div>
	);
};

OutsideClick.propTypes = {
	children: PropTypes.any.isRequired,
	action: PropTypes.func,
	className: PropTypes.string,
};

export default OutsideClick;
