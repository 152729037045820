import React from 'react';
import PropTypes from 'prop-types';
import { withContainer } from '../drag-and-drop';

const DraggableFilterColumn = ({ id, name, checked, onChange, label, idPrefix, disabled }) => {
	return (
		<div id={id} className="rc-menu-list-item">
			<div className={`${name === 'xStreet' || name === 'xZip' ? ' avsField' : ''}`}>
				<input
					type="checkbox"
					name={name}
					className="input input--check"
					checked={checked}
					id={`column-${name}${idPrefix}`}
					onChange={onChange}
					disabled={disabled}
				/>
				<label htmlFor={`column-${name}${idPrefix}`}>{label}</label>
			</div>
		</div>
	);
};
const mapItemToId = ({ key }) => key;
DraggableFilterColumn.defaultProps = {
	disabled: false,
	checked: false,
};
DraggableFilterColumn.propTypes = {
	id: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	checked: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
	label: PropTypes.string.isRequired,
	idPrefix: PropTypes.string.isRequired,
	disabled: PropTypes.bool,
};
export default withContainer(DraggableFilterColumn, mapItemToId, {
	dragStyle: {
		height: '42px',
		border: '1px dashed #c7ced5',
		backgroundColor: '#f8fafd',
		borderRadius: '6px',
	},
});
