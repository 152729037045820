import React from 'react';

const NewVersion = () => {
	return (
		<div className="">
			<p className="spc--bottom--sml">
				A new version has been released.{' '}
				<a className="btn btn--link" href="https://www.cardknox.com/new-in-cardknox/">
					Click here
				</a>{' '}
				to learn about the newest feature updates. For the best experience, we recommend downloading the latest browser
				and clearing your cache.
			</p>
		</div>
	);
};

export default NewVersion;
