import { find, has, each } from 'lodash';

export const getFilter = (filter, key) => find(filter, { key });

export const getFilterByValue = (filter, key) => find(filter, ({ values }) => has(values, key));

export const getFilterValues = filter => {
	const filterValues = {};
	each(filter, ({ key, values }) => {
		filterValues[key] = values;
	});
	return filterValues;
};
