import React, { Component, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { each } from 'lodash';

import { makeCancelable } from './../../utilities';

function withCancelable(WrappedComponent) {
	class CancelableComponent extends Component {
		constructor(props) {
			super(props);
			this.pendingRequests = {};
		}

		componentWillUnmount = () => {
			each(this.pendingRequests, pendingRequest => {
				if (pendingRequest) {
					pendingRequest.cancel();
				}
			});
		};

		makePendingRequest = (request, key) => {
			if (this.pendingRequests[key]) {
				this.pendingRequests[key].cancel();
			}
			this.pendingRequests[key] = makeCancelable(request);
			return this.pendingRequests[key].promise;
		};

		render = () => {
			const { forwardedRef, ...rest } = this.props;
			return <WrappedComponent makePendingRequest={this.makePendingRequest} ref={forwardedRef} {...rest} />;
		};
	}

	CancelableComponent.propTypes = {
		forwardedRef: PropTypes.any,
	};

	return forwardRef((props, ref) => <CancelableComponent {...props} forwardedRef={ref} />);
}

export default withCancelable;
