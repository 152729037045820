// Generated by Copilot
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { includes, map } from 'lodash';
import { createPortal } from 'react-dom';
import { OutsideClick } from 'common/utilities';

class NotificationComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			visible: false,
			notification: {},
		};
	}

	showNotification = () => {
		this.setState({
			visible: true,
		});
	};

	pluralize = ref => {
		const isPlural = includes(ref, ',');

		return `Reference Number${(isPlural && 's') || ''}: ${ref}`;
	};

	closeNotification = () => {
		this.setState({
			visible: false,
		});
	};

	hideNotification = () => {
		let cb = () => {};
		const {
			notification: { success, forceCloseHandler, onClose },
		} = this.state;
		if ((success || forceCloseHandler) && typeof onClose === 'function') {
			cb = onClose;
		}

		this.setState(
			{
				visible: false,
			},
			() => {
				cb();
			}
		);
	};

	addNotification = notification => {
		this.setState({
			visible: true,
			notification: notification,
		});
	};

	getItemClassName = item => {
		if (item.success) return 'check';
		if (item.infoMessage) return 'info';
		return 'error';
	};

	renderNotification = () => {
		const { visible, notification } = this.state;
		const { additional, style } = this.props;

		let title = null;
		let message = null;
		let ref = null;

		const notifications = notification.children ? [notification, ...notification.children] : [notification];

		if (notification.title) {
			title = (
				<div className="modal__header">
					<div className="modal__header__title">{notification.title}</div>
					{map(additional, ({ Title }, index) => Title && <Title key={index} notification={notification} />)}
				</div>
			);
		}

		if (notification.message) {
			message = (
				<div className={notification.contentClassName || 'flex--primary flex--column flex--top flex--gap--lrg'}>
					{notifications.map((item, index) => {
						return (
							<div className="flex--primary flex--top flex--nowrap" key={item.ref || index}>
								<h4 className="spc--right--med">{item.message}</h4>
								<i
									className={
										`spc--top--tny flex--no-shrink icon icon--med icon--regular--` + this.getItemClassName(item)
									}
								></i>
							</div>
						);
					})}
					{map(additional, ({ Message }, index) => Message && <Message key={index} notification={notification} />)}
				</div>
			);
		}

		if (notification.ref) {
			ref = (
				<div className="flex--tertiary flex--gap--sml spc--top--sml--alt">
					<p className="type--p2">{this.pluralize(notification.ref)}</p>
					{map(additional, ({ Ref }, index) => Ref && <Ref key={index} notification={notification} />)}
				</div>
			);
		}

		return (
			<React.Fragment>
				{visible ? (
					<div className="modal__overlay__wrapper">
						<div className="modal__overlay"></div>
						<OutsideClick action={this.hideNotification}>
							<div className="modal__content" style={style}>
								<div className="modal__content__wrapper">
									<div className="modal__header">{title}</div>
									<div className="modal__body">
										{message}
										{ref}
									</div>
									<div className="modal__footer">
										<button className="btn btn--med btn--tertiary modal__close" onClick={this.hideNotification}>
											Close
										</button>
										{notification.customerId ? (
											<Link
												onClick={this.hideNotification}
												className="btn btn--med btn--secondary"
												href="javascript:void(0)"
												to={{
													pathname: '/customers/report',
													customerId: notification.customerId,
													showPaymentMethods: notification.showPaymentMethods,
												}}
											>
												View customer
											</Link>
										) : null}
									</div>
								</div>
							</div>
						</OutsideClick>
					</div>
				) : null}
				{map(additional, ({ Body }, index) => Body && <Body key={index} notification={notification} />)}
			</React.Fragment>
		);
	};

	render() {
		return createPortal(this.renderNotification(), document.body);
	}
}

NotificationComponent.propTypes = {
	additional: PropTypes.arrayOf(
		PropTypes.shape({
			Ref: PropTypes.func,
			Title: PropTypes.func,
			Message: PropTypes.func,
			Body: PropTypes.func,
		})
	),
	style: PropTypes.object,
};

export default NotificationComponent;
