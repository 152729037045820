import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isObject, map } from 'lodash';

class FormErrorsComponent extends Component {
	renderError = (error, key) => {
		if (isObject(error)) {
			return map(error, (subError, subKey) => this.renderError(subError, key ? `${key}.${subKey}` : subKey));
		}
		if (error) {
			return (
				<p className="type--validation" key={key}>
					{error}
				</p>
			);
		}
		return null;
	};

	render = () => {
		const { errors, validationList, className } = this.props;
		return (
			<div className={`${className}${validationList === true ? ' type--validation__wrapper' : ''}`}>
				{this.renderError(errors)}
			</div>
		);
	};
}

FormErrorsComponent.propTypes = {
	errors: PropTypes.object,
	className: PropTypes.string,
	validationList: PropTypes.bool,
};

export default FormErrorsComponent;
