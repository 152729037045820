/* eslint-disable */
const formatterCache = {};

/* eslint-disable import/prefer-default-export */

/**
 * Gets Intl-based date formatter from formatter cache. If it doesn't exist in cache
 * just yet, it will be created on the fly.
 */
export const getFormatter = (locale, options) => {
	const stringifiedOptions = JSON.stringify(options);

	if (!formatterCache[locale]) {
		formatterCache[locale] = {};
	}

	if (!formatterCache[locale][stringifiedOptions]) {
		formatterCache[locale][stringifiedOptions] = n => n.toLocaleString(locale, options);
	}

	return formatterCache[locale][stringifiedOptions];
};
