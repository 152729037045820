import emailService from 'common/services/emailService';
import { emailTemplates } from 'common/utilities/emailTemplates';
import principalService from 'common/services/principalService';
import { find, get } from 'lodash';
// Get device type using Lodash
function getDeviceType() {
	const isMobile = /Mobi|Android/i.test(navigator.userAgent);
	const isTablet = /Tablet|iPad/i.test(navigator.userAgent);

	if (isMobile) {
		return 'Mobile';
	} else if (isTablet) {
		return 'Tablet';
	} else {
		return 'Desktop';
	}
}
function getBrowserInfo() {
	const ua = navigator.userAgent;
	const browser = find(
		[
			{ name: 'Firefox', version: /Firefox\/(\d+\.\d+)/ },
			{ name: 'Opera', version: /(Opera|OPR)\/(\d+\.\d+)/ },
			{ name: 'Internet Explorer', version: /Trident\/(\d+\.\d+)/ },
			{ name: 'Microsoft Edge', version: /Edge\/(\d+\.\d+)/ },
			{ name: 'Google Chrome', version: /Chrome\/(\d+\.\d+)/ },
			{ name: 'Safari', version: /Version\/(\d+\.\d+)/ },
		],
		browser => ua.match(browser.version)
	);

	return browser
		? { name: browser.name, version: ua.match(browser.version)[1] }
		: { name: 'Unknown', version: 'Unknown' };
}
export default async function sendError(errorMessage, subject, stack, additionalInfo = {}) {
	const env = AppBuildEnvironment || 'tst';
	if (env !== 'prd' && env !== 'stg') {
		// eslint-disable-next-line no-console
		console.error(stack);
		return null;
	}
	const principal = principalService.get();
	const browserInfo = getBrowserInfo();
	let templateParams = {};
	templateParams.template = {
		errorMessage,
		stack: stack || 'Unavailable',
		method: get(additionalInfo, 'requestInfo.method', 'Unknown'),
		requestUrl: get(additionalInfo, 'requestInfo.url', 'Unknown'),
		response: get(additionalInfo, 'responseInfo', 'Unavailable'),
		pageUrl: window.location.href,
		deviceType: getDeviceType(),
		browserName: browserInfo.name,
		browserVersion: browserInfo.version,
	};

	const template = {
		TemplateName: emailTemplates.errorReport,
		...templateParams,
	};

	try {
		if (principal) {
			await emailService.sendEmail(template);
		} else await emailService.sendFraudEmail(template);
	} catch (err) {
		// eslint-disable-next-line no-console
		console.error('Failed to send the error report:', err);
		// Handle the error if the email fails to send
	}
}
