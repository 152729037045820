import * as React from 'react';
import * as _ from 'lodash';
export default function () {
    function repeatErrorObj1(errorObj, ix) {
        return React.createElement('section', {
            'className': 'error',
            'key': ix
        }, React.createElement('div', { 'className': 'error__img' }), React.createElement('h3', { 'className': 'error__title' }, '\n\t\t\t', errorObj.message, '\n\t\t'), errorObj.errorMessage ? React.createElement('div', { 'key': '187' }, '\n\t\t\t', errorObj.errorMessage, '\n\t\t') : null, errorObj.stackTrace ? React.createElement('div', {
            'className': 'error__code',
            'key': '261'
        }, React.createElement('code', {}, errorObj.stackTrace)) : null, errorObj.requestInfo ? React.createElement('div', { 'key': '364' }, React.createElement('h3', {}, 'Request'), errorObj.requestInfo.method ? React.createElement('div', { 'key': '422' }, React.createElement('code', {}, 'Method: ', errorObj.requestInfo.method)) : null, errorObj.requestInfo.url ? React.createElement('div', { 'key': '532' }, React.createElement('code', {}, 'URL: ', errorObj.requestInfo.url)) : null) : null, errorObj.responseInfo ? React.createElement('div', { 'key': '641' }, React.createElement('h3', {}, 'Response'), errorObj.responseInfo.status ? React.createElement('div', { 'key': '701' }, React.createElement('code', {}, 'Status: ', errorObj.responseInfo.status)) : null, errorObj.responseInfo.exception ? React.createElement('div', { 'key': '814' }, React.createElement('code', {}, errorObj.responseInfo.exception)) : null) : null);
    }
    return React.createElement.apply(this, [
        'div',
        {},
        _.map(this.state.errorObjects, repeatErrorObj1.bind(this))
    ]);
}