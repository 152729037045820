import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';

function WebhookCard({
	name,
	keys,
	webhookSettings,
	allToggled,
	isLoading,
	handleSelectOptionalField,
	toggleAllFields,
	toggleAllName,
}) {
	const toggleExpand = () => setIsExpanded(!isExpanded);
	const [isExpanded, setIsExpanded] = React.useState(false);

	return (
		<div className={`card${isExpanded ? ' is-expanded' : ''} spc--bottom--lrg`}>
			<button className="card__header card__header--expandable" onClick={toggleExpand}>
				<h5>{name}</h5>
				<i className={`icon icon--sml icon--chevron--${isExpanded ? 'top' : 'down'}--primary`}></i>
			</button>

			{isExpanded && (
				<div className="card__body">
					<div className="flex--primary flex--top flex--column flex--gap--med">
						<div>
							<input
								className="input--check"
								type="checkbox"
								name={toggleAllName}
								id={toggleAllName}
								checked={allToggled}
								onChange={toggleAllFields}
								disabled={isLoading}
							/>
							<label htmlFor={toggleAllName}>Select All</label>
						</div>
						{map(keys, field => (
							<div key={field}>
								<input
									className="input--check"
									type="checkbox"
									name={field}
									id={`webhookSettings.optionalFields.${field}`}
									checked={webhookSettings.optionalFields[field]}
									onChange={() => handleSelectOptionalField(field)}
									disabled={isLoading}
								/>
								<label htmlFor={`webhookSettings.optionalFields.${field}`}>{field}</label>
							</div>
						))}
					</div>
				</div>
			)}
		</div>
	);
}
WebhookCard.propTypes = {
	name: PropTypes.string.isRequired,
	toggleAllName: PropTypes.string.isRequired,
	keys: PropTypes.array.isRequired,
	webhookSettings: PropTypes.object.isRequired,
	allToggled: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired,
	handleSelectOptionalField: PropTypes.func.isRequired,
	toggleAllFields: PropTypes.func.isRequired,
};

export default WebhookCard;
