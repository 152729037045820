import React, { Component, Fragment, createRef } from 'react';
import { bool, func, object } from 'prop-types';
import { get, map, noop, replace, toLower, find, isEmpty, some, startsWith, trimStart } from 'lodash';
import { Auth } from 'aws-amplify';

import { withCancelable } from 'common/components/cancelable';
import { withError } from 'common/components/error';
import { withLoader } from 'common/components/loader';
import { withBlock } from 'common/components/block';
import { Notification } from 'common/components/notifications';
import { authenticationService } from 'common/services';
import cognitoErrorMap from 'common/utilities/cognitoErrorMapping';
import { dialCodesByCountryList } from './constants';
import principalService from '../../Common/services/principalService';
import ConfirmPasswordBody from './components/ConfirmPasswordBody';
import { QRCodeSVG } from 'qrcode.react';
import { withStepup } from 'common/components/stepup-mfa';
import ImageWithFallback from 'common/components/ImageFallback';

const requestKeys = {
	FETCH: 'fetch',
	SAVE: 'save',
	CONFIRM: 'confirm',
	SEND: 'send',
	SAVE_VERIFY: 'saveVerify',
};

const internationalPhoneNumberRegex = /(\+\d{1,3})(\d{4,14})$/;

const phoneNumberTooltip = `Message and data rates may apply. If you choose to receive MFA passwords via text messages, we'll send you one text message per login attempt. To stop receiving these messages, reply 'STOP'.`;

const dialCodesList = map(dialCodesByCountryList, ({ dialCode, name }) => ({
	label: `${name} ${dialCode}`,
	value: dialCode,
}));

class Security extends Component {
	principal = principalService.get();
	constructor(props) {
		super(props);
		this.notificationRef = createRef();

		this.state = {
			isMfaRequired: get(props, 'location.state.isMfaRequired', false),
			customClassName: get(props, 'location.state.customClassName', ''),
			username: this.principal.email,
			password: '',
			password2: '',
			isSamlLogin: this.principal.isSamlLogin,
			countryDialCode: '+1',
			accessToken: '',
			isSoftwareMfaEnabled: false,
			isSmsMfaEnabled: false,
			enableSmsMfa: false,
			enableSoftwareMfa: false,
			phoneNumberVerified: false,
			phoneNumber: '',
			errorMessages: [],
			user: null,
			code: '',
			mfaCode: '',
			challengeAnswer: '',
			confirmationCode: '',
			displayConfirmCode: false,
			errorMessage: '',
			copied: false,
		};
	}

	setPasswordErrorMessage = passwordErrorMessage => {
		this.setState({ passwordErrorMessage });
	};

	async componentDidMount() {
		return this.fetchData();
	}

	setComponentState = newState => {
		this.setState(newState);
	};

	fetchData = async () => {
		const { showLoader, makePendingRequest } = this.props;
		showLoader(true);
		try {
			await makePendingRequest(
				Promise.all([
					Auth.currentAuthenticatedUser().then(async user => {
						await this.getUserData(user);
					}),
				]),
				requestKeys.FETCH
			);
		} catch (e) {
			this.handleError(e, true);
		} finally {
			showLoader(false);
		}
	};

	getCognitoUserDataAsync = async user => {
		return new Promise((resolve, reject) => {
			user.getUserData(
				(err, data) => {
					if (err) {
						reject(err);
						return;
					}
					resolve(data);
				},
				{ bypassCache: true }
			);
		});
	};

	getCognitoUserAttributesAsync = user => {
		return new Promise((resolve, reject) => {
			user.getUserAttributes((err, result) => {
				if (err) {
					reject(err);
					return;
				}
				resolve(result);
			});
		});
	};

	getUserData = async user => {
		const accessToken = get(user, 'signInUserSession.accessToken.jwtToken', '');
		try {
			const data = await this.getCognitoUserDataAsync(user);
			const { PreferredMfaSetting, UserMFASettingList } = data;
			const isSoftwareMfaEnabled = UserMFASettingList && UserMFASettingList.includes('SOFTWARE_TOKEN_MFA');
			const isSmsMfaEnabled = UserMFASettingList && UserMFASettingList.includes('SMS_MFA');
			const enableSoftwareMfa = PreferredMfaSetting === 'SOFTWARE_TOKEN_MFA';
			const enableSmsMfa = PreferredMfaSetting === 'SMS_MFA';

			const newState = {
				user,
				isSoftwareMfaEnabled,
				enableSoftwareMfa,
				isSmsMfaEnabled,
				enableSmsMfa,
			};
			this.setState(newState);
		} catch (err) {
			this.handleError(err, true);
		}

		try {
			const result = await this.getCognitoUserAttributesAsync(user);
			this.mapUserResponseToState(result, accessToken, user);
		} catch (err) {
			this.handleError(err, true);
		}

		/*
		Future - add display of current device
		user.getCachedDeviceKeyAndPassword();
		user.getDevice({
			onSuccess: function(result) {
				console.log('getDevice call result: ');
				console.log(result);
			},
			onFailure: function(err) {
				console.log('GetDevice error: ');
				console.log(err.message || JSON.stringify(err));
			},
		});*/
	};

	initializeSoftwareToken = async (useLoader = true) => {
		const showLoader = useLoader ? this.props.showLoader : noop;

		showLoader(true);
		Auth.setupTOTP(this.state.user).then(mfaCode => {
			this.setState({ mfaCode }, showLoader);
		});
	};

	updatePhoneNumber = async phoneNumber => {
		const { user, countryDialCode } = this.state;
		const phone_number = phoneNumber ? countryDialCode + phoneNumber : phoneNumber;

		this.setState({ prevSavedPhoneNumber: phoneNumber, successMessage: '', errorMessage: '' });
		await Auth.updateUserAttributes(user, { phone_number });
	};

	save = async () => {
		const { showLoader } = this.props;
		const { user, enableSoftwareMfa } = this.state;
		const addNotification = get(this.notificationRef, 'current.addNotification', noop);
		let error = null;
		let ref = null;

		try {
			showLoader(true);
			if (enableSoftwareMfa) {
				await Auth.setPreferredMFA(user, 'TOTP');
			} else {
				await Auth.setPreferredMFA(user, 'SMS');
			}

			if (this.principal.redirectToSecurity) {
				principalService.set({ ...this.principal, redirectToSecurity: false });
				this.props.history.push({ pathname: '/' });
			}
		} catch (e) {
			error = this.handleError(e, true, { delayMessage: true });
			if (error) {
				showLoader(true);
			}
		}

		await this.refreshData(true, error);

		if (!error) {
			addNotification({
				message: `Multi Factor Authentication Settings Updated`,
				success: true,
				ref,
			});
			showLoader(false);
		}
	};

	refreshData = async (refreshData, error) => {
		const { showLoader } = this.props;
		const { errorMessages } = this.state;

		if (refreshData) {
			try {
				if (isEmpty(errorMessages) && error) {
					error.show();
				}
				this.fetchData();
			} catch (e) {
				showLoader(false);
				this.handleError(e);
			}
		}
	};

	mapUserResponseToState = (UserAttributes, accessToken, user) => {
		const phone_number = get(find(UserAttributes, ({ Name }) => toLower(Name) === 'phone_number'), 'Value', '');
		const countryDialCode = get(
			find(dialCodesList, ({ value }) => value && startsWith(phone_number, value)),
			'value',
			'+1'
		);
		const phoneNumber = replace(phone_number, countryDialCode, '');
		const phoneNumberVerified = get(
			find(UserAttributes, ({ Name }) => toLower(Name) === 'phone_number_verified'),
			'Value',
			'false'
		);

		const newState = {
			user,
			accessToken,
			phoneNumber,
			prevSavedPhoneNumber: phoneNumber,
			countryDialCode,
			phoneNumberVerified: phoneNumberVerified === 'true',
			prevSavedPhoneVerified: phoneNumberVerified === 'true',
		};

		this.setState(newState);
	};

	validateFields = () => {
		const { phoneNumber, enableSmsMfa, countryDialCode } = this.state;
		const newState = { errorMessages: [] };

		if (!internationalPhoneNumberRegex.test(countryDialCode + phoneNumber)) {
			if (enableSmsMfa) {
				newState.errorMessages.push({ key: 'phoneNumber', message: 'Valid Phone Number is required.' });
			} else {
				newState.phoneNumber = '';
				newState.countryDialCode = '+1';
			}
		}

		this.setState(newState);

		return !isEmpty(newState.errorMessages);
	};

	validateField = fieldKey => {
		const { errorMessages } = this.state;
		let className = '';

		if (some(errorMessages, ({ key }) => key === fieldKey)) {
			className = ' is-invalid';
		}

		return className;
	};
	handleCopyClick = () => {
		if (navigator.clipboard) {
			navigator.clipboard.writeText(this.state.mfaCode);
		} else {
			document.execCommand('copy');
		}
		this.setState({ copied: true });
	};

	handleSubmitConfirmationCode = ({ target: { value, name } }) => {
		const newState = { [name]: replace(value, /\s/g, '') };

		this.setState(newState, this.validateField);
	};

	handleSubmitTotp = async event => {
		event.preventDefault();
		if (!this.formValidation(this.state.challengeAnswer)) {
			return;
		}
		this.props.showLoader(true);
		let { user, challengeAnswer } = this.state;

		Auth.verifyTotpToken(user, challengeAnswer)
			.then(async () => {
				await Auth.setPreferredMFA(user, 'TOTP');

				this.setState({
					errorMessage: null,
					successMessage: 'MFA set up successfully',
					isSoftwareMfaEnabled: true,
				});

				if (this.principal.redirectToSecurity) {
					principalService.set({ ...this.principal, redirectToSecurity: false });
					this.props.history.push({ pathname: '/' });
				}
				this.props.showLoader();
			})
			.catch(e => this.handleError(e));
	};

	handlePhoneNumberChange = ({ target: { name, value } }) => {
		if (startsWith(value, '0')) {
			value = trimStart(value, '0');
		}
		const digitRegex = /^\d*$/gi;
		if (digitRegex.test(value)) {
			this.handleMfaChange({ target: { name, value } });
		}
	};

	handleMfaChange = ({ target: { name, value, type, checked } }) => {
		try {
			const { mfaCode, isSoftwareMfaEnabled, prevSavedPhoneNumber, prevSavedPhoneVerified } = this.state;
			const newState = { [name]: type === 'checkbox' ? checked : value };

			if (type === 'checkbox') {
				newState[name] = checked;

				newState.successMessage = '';
				newState.errorMessage = '';

				if (name === 'enableSmsMfa') {
					newState.enableSoftwareMfa = false;
				} else {
					newState.enableSmsMfa = false;
					newState.displayConfirmCode = false;
				}
			}

			if (newState.enableSoftwareMfa && !mfaCode && !isSoftwareMfaEnabled) {
				this.initializeSoftwareToken();
			}

			if (newState.phoneNumber) {
				newState.phoneNumberVerified = prevSavedPhoneNumber === newState.phoneNumber && prevSavedPhoneVerified;
			}

			this.setState(newState, this.validateFields);
		} catch (e) {
			this.handleError(e, true);
		}
	};

	resetSoftwareToken = () => {
		this.initializeSoftwareToken();
		this.setState({ isSoftwareMfaEnabled: false, enableSoftwareMfa: true, challengeAnswer: '', successMessage: '' });
	};

	notifyPasswordChange = () => {
		this.notificationRef.current.addNotification({
			success: true,
			message: 'Password changed successfully',
		});
		this.setState({ displayReset: false });
	};

	handlePasswordReset = async () => {
		try {
			this.props.showLoader(true);
			await Auth.forgotPassword(this.principal.email);
			this.setState({ displayReset: true });
		} catch (err) {
			let message;
			switch (err && err.code) {
				case 'LimitExceededException': {
					message = 'Login attempts exceeded the limit. Please wait and try again later.';
					break;
				}
				default: {
					message = 'Something went wrong. Please try again.';
					break;
				}
			}
			this.setState({
				passwordErrorMessage: message,
			});
		}
		this.props.showLoader(false);
	};

	notifyAuthError = () => {
		this.notificationRef.current.addNotification({
			message: 'To update MFA settings please login again.',
			success: false,
			forceCloseHandler: true,
			onClose: () => this.props.history.push({ pathname: '/login' }),
		});
	};

	handleError = (error, displayInPopup = false, errorOptions = {}) => {
		const { showLoader, handleError } = this.props;

		showLoader(false);
		if (error && error.isCanceled) return;
		if (error === 'not authenticated') {
			return this.notifyAuthError();
		}
		if ((error && !error.message && !error.code) || (error && error.message && error.code)) {
			return this.handleCognitoError(error, displayInPopup);
		} else {
			return handleError(error, errorOptions);
		}
	};

	yieldUint8Chunks = async data => {
		const reader = data.getReader();
		try {
			const { done, value } = await reader.read();

			if (done) {
				return;
			}

			return value;
		} finally {
			reader.releaseLock();
		}
	};

	handleCognitoError = async (error, displayInPopup = false) => {
		let err = error;
		let respBody = get(error, 'response.body');
		if (!err.message && !!respBody) {
			const unit8ChunksList = await this.yieldUint8Chunks(respBody);
			err = JSON.parse(new TextDecoder().decode(unit8ChunksList));
		}

		const defaultMessage = this.principal.redirectToSecurity ? (
			<span>
				Something went wrong. Please{' '}
				<a className="btn btn--link" href="/logout">
					try logging in again.
				</a>
			</span>
		) : (
			`${get(err, 'response.message', 'Something went wrong. Please try again')}`
		);

		let message = cognitoErrorMap[err.__type] || cognitoErrorMap[err.code] || err.message || defaultMessage;

		if (message.message) {
			message = message.message;
		}

		if (displayInPopup) {
			this.props.handleError({ ...error, message });
		} else {
			this.setState({
				errorMessage: message,
				successMessage: null,
			});
		}
	};

	getButtonClassName = displayConfirmCode => (displayConfirmCode ? 'ghost' : 'primary');

	getButtonLabel = displayConfirmCode => (displayConfirmCode ? 'Resend Verification Code' : 'Verify Phone Number');

	formValidation = challengeAnswer => {
		if (challengeAnswer.length <= 0) {
			this.setState({
				errorMessage: 'Please enter the code to verify',
				successMessage: null,
			});
			return false;
		}

		return true;
	};

	confirmVerificationCode = async () => {
		const { showLoader, makePendingRequest } = this.props;
		const { accessToken, confirmationCode, user } = this.state;
		const addNotification = get(this.notificationRef, 'current.addNotification', noop);

		if (!this.formValidation(confirmationCode)) {
			return;
		}

		try {
			showLoader(true);
			await makePendingRequest(
				authenticationService.verifyUserAttribute(accessToken, 'phone_number', confirmationCode).then(() =>
					this.setState({
						displayConfirmCode: false,
						confirmationCode: '',
						phoneNumberVerified: true,
						prevSavedPhoneVerified: true,
					})
				),
				requestKeys.CONFIRM
			);
			await Auth.setPreferredMFA(user, 'SMS');

			await this.fetchData();

			addNotification({
				message: `Multi Factor Authentication Settings updated`,
				success: true,
			});

			if (this.principal.redirectToSecurity) {
				principalService.set({ ...this.principal, redirectToSecurity: false });
				this.props.history.push({ pathname: '/' });
			}
		} catch (e) {
			this.handleError(e);
		}
	};

	sendVerificationCode = async () => {
		const { showLoader, makePendingRequest } = this.props;
		const { accessToken } = this.state;
		const addNotification = get(this.notificationRef, 'current.addNotification', noop);

		try {
			showLoader(true);
			const {
				CodeDeliveryDetails: { DeliveryMedium: deliveryMedium, Destination: destination },
			} = await makePendingRequest(
				authenticationService.getUserAttributeVerificationCode(accessToken, 'phone_number'),
				requestKeys.SEND
			);
			this.setState({ displayConfirmCode: true });
			showLoader(false);

			addNotification({
				message: `${deliveryMedium} sent to ${destination}.`,
				success: true,
			});
		} catch (e) {
			this.handleError(e);
		}
	};

	savePhoneNumberAndSendVerificationCode = async () => {
		const { showLoader, makePendingRequest } = this.props;
		const { phoneNumber } = this.state;

		try {
			showLoader(true);
			await makePendingRequest(this.updatePhoneNumber(phoneNumber), requestKeys.SAVE_VERIFY);
			this.sendVerificationCode();
		} catch (e) {
			this.handleError(e);
		}
	};

	resetCopyCliboardState = () => {
		this.setState({ copied: false });
	};
	renderSuccessMessages = () => {
		const { successMessage } = this.state;
		return (
			<div className="spc--bottom--sml fullwidth">
				{successMessage ? <div className="message message--sml message--success">{successMessage}</div> : null}
			</div>
		);
	};
	renderWarningMessages = () => {
		const { errorMessage } = this.state;
		return (
			<Fragment>{errorMessage ? <div className="type--validation fullwidth">{errorMessage}</div> : null}</Fragment>
		);
	};

	renderResetButton = () => (
		<button className="btn btn--med btn--primary" onClick={() => this.resetSoftwareToken()}>
			Reset
		</button>
	);
	renderMfaSection = () => {
		const {
			challengeAnswer,
			mfaCode,
			enableSoftwareMfa,
			isSoftwareMfaEnabled,
			enableSmsMfa,
			phoneNumber,
			displayConfirmCode,
			confirmationCode,
			phoneNumberVerified,
			user,
			username,
			copied,
		} = this.state;
		const str = 'otpauth://totp/Cardknox:' + username + '?secret=' + mfaCode;
		return (
			<div>
				<div className="spc--bottom--lrg">
					<div className="spc--bottom--med">
						<input
							type="checkbox"
							className="input--radio"
							id="enableSoftwareMfa"
							name="enableSoftwareMfa"
							onChange={this.handleMfaChange}
							value="enableSoftwareMfa"
							checked={enableSoftwareMfa}
							disabled={!user}
						/>
						<label htmlFor="enableSoftwareMfa">Enable Software MFA (Preferred)</label>
					</div>
					<div>
						<input
							type="checkbox"
							className="input--radio"
							id="enableSmsMfa"
							name="enableSmsMfa"
							onChange={this.handleMfaChange}
							value="enableSmsMfa"
							checked={enableSmsMfa}
							disabled={!user}
						/>
						<label htmlFor="enableSmsMfa">Enable SMS MFA</label>
					</div>
				</div>

				<div className="security__sms">
					{this.renderSaveButton()}
					{isSoftwareMfaEnabled && enableSoftwareMfa && !this.props.isLoading && this.renderResetButton()}
				</div>

				{!isSoftwareMfaEnabled && enableSoftwareMfa && !this.props.isLoading && (
					<form className="form" onSubmit={this.handleSubmitTotp}>
						<div>
							{this.renderSuccessMessages()}

							<div className="notes notes--primary spc--bottom--lrg">
								<i className="icon"></i>
								<div>
									<p>
										Install an MFA app on your smartphone or computer to use Multi Factor Authentication (MFA) for
										Cardknox logins.
									</p>
									<p>(Some examples are Google Authenticator and LastPass Authenticator.)</p>
									<br />
									<p>
										Scan or Copy the <span className="type--wgt--bold">code below</span> and paste it into your MFA app.
									</p>
									<br />
									<p>
										The MFA app will display a <span className="type--wgt--bold">confirmation code</span> to paste into
										the field below.
									</p>
									<br />
									<p>
										To help you set up Software MFA, we have created a{' '}
										<a
											className="btn btn--link"
											href="https://cdn.cardknox.com/share/cardknox_merchant_portal_mfa.mp4"
											rel="noopener noreferrer"
											target="_blank"
										>
											video
										</a>{' '}
										tutorial that walks you through the process step-by-step.
									</p>
								</div>
							</div>

							<div className="card card--qr-code spc--bottom--lrg">
								<QRCodeSVG height={100} value={str} />
								<div className="card--qr-code__separator"></div>
								<div className="flex--primary flex--nowrap fullwidth">
									<input name="mfaCode" type="text" className="input input--med" disabled={true} value={mfaCode} />
									<button
										type="button"
										data-tooltip={copied ? `Copied!` : 'Copy to clipboard'}
										className="btn btn--link spc--left--med datatooltip--auto"
										onClick={this.handleCopyClick}
										onMouseOut={this.resetCopyCliboardState}
									>
										<i className="icon icon--sml icon--copy--primary"></i>
									</button>
								</div>
							</div>

							<div className="form__group">
								<div className="form__group__header">
									<span className="form__group__label">Confirmation code</span>
								</div>
								<div className="flex--primary flex--gap--sml flex--nowrap">
									<input
										name="challengeAnswer"
										type="text"
										className="input input--med"
										placeholder="000000"
										value={challengeAnswer}
										onChange={this.handleMfaChange}
									/>
									<button
										type="submit"
										className="btn btn--primary btn--med no-gap"
										disabled={this.props.isLoading || !challengeAnswer}
									>
										<span className="spc--right--tny">Confirm</span>
										<span className="hide--to--sml--inline">Code and Save</span>
									</button>
								</div>
							</div>

							{this.renderWarningMessages()}
						</div>
					</form>
				)}

				{enableSmsMfa && (
					<div>
						{this.renderSuccessMessages()}
						<div>
							<div className="form__group">
								<div className="form__group__header">
									<label htmlFor="phoneNumber" className="form__group__label">
										Phone number
									</label>
								</div>
								<div className={phoneNumberVerified ? '' : 'flex--primary flex--gap--sml flex--nowrap'}>
									<input
										id="phoneNumber"
										placeholder="Phone number"
										name="phoneNumber"
										className={`input input--med ${this.validateField('phoneNumber')}`}
										value={phoneNumber || ''}
										onChange={this.handlePhoneNumberChange}
										disabled={this.props.isLoading}
									/>
									{phoneNumberVerified ? (
										<div className="flex--primary flex--gap--tny spc--top--sml">
											<i className="icon icon--sml icon--regular--check" />
											<p className="type--color--success">Verified</p>
										</div>
									) : (
										<button
											className={`spc--bottom--tny btn btn--${this.getButtonClassName(displayConfirmCode)} btn--med`}
											onClick={this.savePhoneNumberAndSendVerificationCode}
											disabled={this.props.isLoading || this.validateField('phoneNumber')}
										>
											{this.getButtonLabel(displayConfirmCode)}
										</button>
									)}
								</div>
								{this.renderWarningMessages()}
							</div>
							<div className="notes notes--primary">
								<i className="icon"></i>
								{phoneNumberTooltip}
							</div>
						</div>

						{displayConfirmCode && (
							<div className="spc--bottom--sml">
								<div className="form__group">
									<div className="form__group__header">
										<label htmlFor="confirmationCode" className="form__group__label">
											Confirmation Code
										</label>
									</div>
									<input
										id="confirmationCode"
										placeholder="000000"
										name="confirmationCode"
										className={`input input--med${this.validateField('confirmationCode')}`}
										value={confirmationCode || ''}
										onChange={this.handleSubmitConfirmationCode}
									/>
								</div>
								<button
									className="btn btn--primary btn--med"
									onClick={this.confirmVerificationCode}
									disabled={this.props.isLoading || !confirmationCode}
								>
									Confirm
								</button>
							</div>
						)}
					</div>
				)}
			</div>
		);
	};

	renderPasswordResetSection = () => {
		const { username, password, password2, code, errorMessage } = this.state;
		return (
			<Fragment>
				{!this.state.displayReset && (
					<button className="btn btn--med btn--primary" onClick={this.handlePasswordReset}>
						Reset Password
					</button>
				)}
				{this.state.displayReset && (
					<Fragment>
						<h5 className="spc--bottom--lrg">Set new password</h5>

						<ConfirmPasswordBody
							setComponentState={this.setComponentState}
							username={username}
							password={password}
							password2={password2}
							code={code}
							errorMessage={errorMessage}
							hideBackButton={true}
							skipRedirect={true}
							notifyPasswordChange={this.notifyPasswordChange}
							showLoader={this.props.showLoader}
							hideEmail={true}
							hideNewPasswordHeader={true}
						/>
					</Fragment>
				)}
			</Fragment>
		);
	};

	renderSaveButton = () => {
		const {
			enableSoftwareMfa,
			enableSmsMfa,
			isSoftwareMfaEnabled,
			isSmsMfaEnabled,
			phoneNumberVerified,
			mfaCode,
		} = this.state;

		const noMfa = !enableSoftwareMfa && !enableSmsMfa;
		const bothMfaMethodsAvailable = isSoftwareMfaEnabled && isSmsMfaEnabled;
		const bothMethodsVerified = phoneNumberVerified && !mfaCode;

		const canEnableSmsVerification = enableSmsMfa && phoneNumberVerified;

		return (
			<Fragment>
				{!noMfa && ((bothMfaMethodsAvailable && bothMethodsVerified) || canEnableSmsVerification) && (
					<button
						className="btn btn--primary btn--med settings__header__btn"
						disabled={this.props.isLoading || !isEmpty(this.state.errorMessages)}
						onClick={() => this.save()}
					>
						Save
					</button>
				)}
			</Fragment>
		);
	};

	renderErrors = () => {
		const { errorMessages } = this.state;
		return map(errorMessages, ({ message, key }, index) => (
			<div key={`${index}.${key}`} className="type--validation spc--top--med">
				<p>{message}</p>
			</div>
		));
	};

	renderPasswordError = () => {
		const { passwordErrorMessage } = this.state;
		if (!passwordErrorMessage) return null;
		return (
			<div className="type--validation spc--top--med">
				<p>{passwordErrorMessage}</p>
			</div>
		);
	};
	renderSecurityConcernsText = () => {
		return (
			<div className="notes notes--primary spc--bottom--lrg">
				<div className="icon"></div>
				<div>
					<p className="type--p2 spc--bottom--med">Mandatory Multi-Factor Authorization (MFA) for All Accounts</p>
					<div>
						<p className="">
							In an effort to offer maximum protection to our merchants and their customers, we have implemented a new
							security protocol that requires all accounts to have Multi-Factor Authorization (MFA) enabled. While in
							the past this was optional, due to a global uptick in phishing scams, it is now mandatory.
						</p>
						<p className="">
							To help you set up Software MFA, we have created a{' '}
							<a
								className="btn btn--link"
								href="https://cdn.cardknox.com/share/cardknox_merchant_portal_mfa.mp4"
								rel="noopener noreferrer"
								target="_blank"
							>
								video
							</a>{' '}
							tutorial that walks you through the process step-by-step.
						</p>
						<p className="">
							Contact our{' '}
							<a
								className="btn btn--link"
								href="mailto: gatewaysupport@solapayments.com"
								target="_blank"
								rel="noopener noreferrer"
							>
								Support Team
							</a>{' '}
							if you have any questions. Thank you for your cooperation in keeping our platform secure.
						</p>
					</div>
				</div>
			</div>
		);
	};

	render() {
		const { isMfaRequired, isSamlLogin, customClassName } = this.state;
		return (
			<div className={customClassName ? 'auth' : ''}>
				{customClassName && (
					<div className="auth__sidebar">
						<ImageWithFallback imgAttributes={{ alt: 'logo' }}>
							{props => (
								<img alt="logo" src={'static/media/sola-logo.svg'} className="auth__sidebar__logo" {...props} />
							)}
						</ImageWithFallback>
					</div>
				)}
				<div className={customClassName ? 'auth__main' : ''}>
					<div className={customClassName ? 'auth__form' : ''}>
						{!customClassName && (
							<div className="settings__header">
								<h3 className="settings__title">User Settings</h3>
								<h5>Security</h5>
							</div>
						)}
						{customClassName && <h3 className="auth__form__title">Security</h3>}
						<p className="auth__form__description">Setup Multi-Factor Authentication</p>
						{isSamlLogin && <div className="type--p2">No settings found to manage for current user.</div>}
						{!isSamlLogin && (
							<Fragment>
								{isMfaRequired && this.renderSecurityConcernsText()}
								{this.renderMfaSection()}
								{this.renderErrors()}
								{this.renderPasswordError()}
								{!isMfaRequired && (
									<div className="spc--top--xxlrg">
										{!this.state.displayReset && <h5 className="spc--bottom--med">Set new password</h5>}
										{this.renderPasswordResetSection()}
									</div>
								)}
							</Fragment>
						)}
					</div>
				</div>

				<Notification ref={this.notificationRef} />
			</div>
		);
	}
}

Security.propTypes = {
	location: object.isRequired,
	history: object.isRequired,
	showLoader: func.isRequired,
	handleError: func.isRequired,
	makePendingRequest: func.isRequired,
	isLoading: bool,
	handleBlockChange: func.isRequired,
};

export default withError(withLoader(withCancelable(withBlock(withStepup(Security, 'account-management', false)))));
