import React, { Component } from 'react';

import sectionKeys from '../../routing/sections';
import NestedRoutes from 'routing/NestedRoutes';
import { RecurringScheduleGrid, RecurringTemplatesGrid } from 'components/recurring-schedule-grid';

const routes = [
	{
		path: '/report',
		component: RecurringScheduleGrid,
		section: sectionKeys.dashboard,
		title: 'Recurring Schedules',
		mobileTitle: 'Recurring Schedules',
		linkTitle: 'Recurring Schedules',
	},
	{
		path: '/recurring-templates',
		component: RecurringTemplatesGrid,
		section: sectionKeys.dashboard,
		title: 'Templates',
		mobileTitle: 'Templates',
		linkTitle: 'Templates',
	},
];

class RecurringSchedules extends Component {
	render() {
		return <NestedRoutes tabs={routes} />;
	}
}

export default RecurringSchedules;
