import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { Modal } from '../../modal';
import { withError } from '../../error';
import { withCancelable } from '../../cancelable';

class DeleteRecurringTemplateAddEdit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
		};
	}

	handleDelete = async () => {
		const { template, saveTemplate, makePendingRequest, closeModal, handleError } = this.props;
		try {
			const { isLoading } = this.state;
			if (isLoading) {
				return;
			}
			this.setState({ isLoading: true });
			await makePendingRequest(saveTemplate(template, template.xKey, true));
			this.setState({ isLoading: false });
			closeModal();
		} catch (e) {
			if (handleError(e)) {
				closeModal();
			}
		}
	};

	render = () => {
		const {
			isModalOpen,
			template: { xScheduleName },
			closeModal,
		} = this.props;
		const { isLoading } = this.state;

		return (
			<Modal className="modal__content modal--sml" isOpen={isModalOpen} onClose={closeModal}>
				<Fragment>
					{isModalOpen && (
						<div className="modal">
							<div className="modal__header">
								<div className="modal__header__title">Delete template</div>
							</div>
							<div className="modal__body" tabIndex="-1">
								<p>
									Are you sure you want to delete
									<span className="type--wgt--medium"> {xScheduleName}</span>?
								</p>
							</div>
							<div className="modal__footer">
								<button
									disabled={isLoading}
									type="button"
									tabIndex="-1"
									className="btn btn--med btn--primary"
									onClick={this.handleDelete}
								>
									Delete
								</button>
							</div>
						</div>
					)}
				</Fragment>
			</Modal>
		);
	};
}

DeleteRecurringTemplateAddEdit.propTypes = {
	template: PropTypes.object.isRequired,
	saveTemplate: PropTypes.func.isRequired,
	isModalOpen: PropTypes.bool.isRequired,
	closeModal: PropTypes.func.isRequired,
};

export default withCancelable(withError(DeleteRecurringTemplateAddEdit));
