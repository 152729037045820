import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, map, isEmpty, each, orderBy } from 'lodash';
import { Data } from 'react-data-grid-addons';
import { unix } from 'moment';

import { withError } from 'common/components/error';
import { withCancelable } from '../cancelable';
import { ZebraRenderer } from 'common/components/row';
import { PopoverGridTooltip } from 'common/components/tooltips';
import { portalManagementService } from 'common/services';
import PopupGrid from 'common/components/grid/PopupGrid';
import { toMoment, localTimezone } from 'common/utilities';
import { logViewerColumns } from './column-filter/columns';
const { isoDateFormat, displayDateTimeFormat } = ApplicationSettings;

const requestKeys = {
	FETCH: 'fetch',
};

class LogViewer extends Component {
	constructor() {
		super();

		this.state = {
			filters: {},
			activeFilters: {},
			columns: cloneDeep(logViewerColumns),
			inlineFilters: {},
			data: null,
			originalData: null,
			filteredRows: [],
			fetchData: false,
			fetchingAdditionalData: false,
			lastApiRefNum: null,
			loadMoreLimit: 60,
		};

		this.components = {
			rowRenderer: ZebraRenderer,
			tooltip: PopoverGridTooltip,
		};

		this.classes = {
			filterContainer: '',
			filter: 'col col-sml-12 col-med-6 spc--bottom--sml',
			gridHeader: 'col col-sml-12 col-med-6 spc--bottom--sml',
			gridHolder: 'react-grid',
			gridWrapper: 'grid__holder--override',
		};

		this.gridRef = createRef();
	}

	fetchData = async () => {
		const today = `${parseInt(new Date().getTime() / 1000)}000`;
		let from = new Date();
		from.setDate(from.getDate() - 10);
		from = `${parseInt(from.getTime() / 1000)}000`;

		const { handleError, makePendingRequest, username } = this.props;
		this.setState({
			fetchingData: true,
			data: null,
			filteredRows: [],
			lastApiRefNum: null,
		});
		try {
			const data = await makePendingRequest(
				portalManagementService.listLogs({
					from,
					to: today,
					username,
					pageSize: 500,
				}),
				requestKeys.FETCH
			);

			await this.mapData(data);

			const filteredRows =
				data && data.xReportData
					? Data.Selectors.getRows({
							rows: data.xReportData,
							filters: this.state.inlineFilters,
					  })
					: [];

			this.setState(
				{
					data,
					originalData: cloneDeep(data),
					filteredRows,
					fetchingData: false,
					lastApiRefNum: data.refNum ? data.refNum : '',
				},
				() => {
					const grid = this.gridRef.current;
					if (grid) {
						grid.handleInitialSort();
						grid.calculateColumnWidths();
					}
				}
			);
		} catch (e) {
			if (handleError(e)) {
				this.setState({
					fetchingData: false,
				});
			}
		}
	};

	mapData = async data => {
		data.xReportData = [];
		if (!isEmpty(data.events)) {
			await Promise.all(
				map(data.events, async event => {
					const { message, timestamp, ...restAttributes } = event;
					const eventMomentDate = await toMoment(unix(timestamp / 1000).utc(), 'UTC', localTimezone, isoDateFormat);
					const eventDate = eventMomentDate.format(displayDateTimeFormat);
					const logEntries = JSON.parse(message);
					await Promise.all(
						map(logEntries, async entry => {
							const { timestamp, message, errorDetails, ...rest } = entry;
							const logMomementDate = await toMoment(unix(timestamp / 1000).utc(), 'UTC', localTimezone, isoDateFormat);
							const logDate = eventMomentDate.format(displayDateTimeFormat);
							data.xReportData.push({
								eventMomentDate,
								eventDate,
								logMomementDate,
								logDate,
								message: `${message} ${errorDetails ? errorDetails : ''}`,
								...restAttributes,
								...rest,
							});
						})
					);
				})
			);
		}
		data.xReportData = orderBy(data.xReportData, ['logMomementDate'], ['desc']);
		delete data.events;
	};

	handleChange = changes => {
		const newState = {};
		each(changes, change => {
			if (change.key === 'data' || change.key === 'inlineFilters') {
				let filters, data;
				if (change.key === 'data') {
					filters = this.state.inlineFilters;
					data = change.value;
				} else {
					filters = change.value;
					data = this.state.data;
				}
				newState.filteredRows =
					data && data.xReportData
						? Data.Selectors.getRows({
								rows: data.xReportData,
								filters,
						  })
						: [];
			}
			newState[change.key] = change.value;
		});
		return new Promise(resolve => {
			this.setState(newState, resolve);
		});
	};

	render() {
		const { fetchingData, filteredRows, columns, data, inlineFilters, lastApiRefNum } = this.state;
		const { popupRef } = this.props;

		return (
			<PopupGrid
				title="User Logs"
				emptyMessage="You should change your filter options"
				fetchingData={fetchingData}
				columns={columns}
				data={data}
				useInlineFilters={true}
				filteredRows={filteredRows}
				inlineFilters={inlineFilters}
				components={this.components}
				classes={this.classes}
				onChange={this.handleChange}
				hasPaging={true}
				fetchData={this.fetchData}
				lastApiRefNum={lastApiRefNum}
				ref={this.gridRef}
				showResults={true}
				showGridHeader={false}
				showPrintDropdown={false}
				showPanel={false}
				displayHeaderMenu={false}
				showGridFooter={false}
				popupRef={popupRef}
			/>
		);
	}
}

LogViewer.propTypes = {
	makePendingRequest: PropTypes.func,
	handleError: PropTypes.func,
	popupRef: PropTypes.any.isRequired,
	username: PropTypes.string.isRequired,
};

export default withError(withCancelable(LogViewer));
