import React, { Component, createRef, Fragment } from 'react';
import { split, toLower, get, head, map } from 'lodash';
import { object, func, any, bool } from 'prop-types';

import LinkExistingCustomerForm from '../customers/popup/LinkExistingCustomerForm';
import { AddEditCustomer } from '../customers/popup';
import { kvaasService, transactionService, customerService, principalService } from 'common/services';
import { kvaasResources, renderIf } from 'common/utilities';

const requestKeys = {
	KVAAS: 'kvaas',
};

class LinkTransactionComponent extends Component {
	constructor(props) {
		super(props);
		this.principal = principalService.get();
		this.state = {
			isOpenLinkExistingCustomer: props.linkPaymentMethod || false,
			selectedCustomer: null,
			newCustomer: props.newCustomer || false,
			isLoading: false,
		};

		this.popupRef = createRef();
	}

	get permissions() {
		return get(this.principal, 'idInfo.permissions', {});
	}

	get allowCcAdjust() {
		const {
			row: { xCustom01 },
		} = this.props;
		return !xCustom01 && get(this.permissions, 'allowCcAdjust', false);
	}
	linkPaymentMethodCustomer = async () => {
		this.setState({ isLoading: true });
		try {
			const {
				row: {
					xCommand,
					xRefNum,
					xToken,
					xExp,
					xRouting,
					xAmount,
					xTip,
					xDescription,
					xOrderID,
					xCustom02,
					xCustom03,
					xZip,
					xStreet,
					xMerchantId,
				},
				notificationRef,
			} = this.props;
			const { selectedCustomer } = this.state;

			this.props.showLoader(true);

			let [paymentType, transactionType] = split(toLower(xCommand), ':');
			if (toLower(xCommand) === 'gift:redeem' || toLower(xCommand) === 'gift:issue') {
				paymentType = 'CC';
			}
			const [recurringSchedules] = await this.props.makePendingRequest(
				kvaasService.get(kvaasResources.recurringSchedules),
				requestKeys.KVAAS
			);
			const setNewCardAsDefault = get(recurringSchedules, 'data.setNewCardAsDefault', false);
			const requestsArray = [
				customerService.addCustomerPaymentMethod({
					customerId: selectedCustomer.customerId,
					tokenType: paymentType,
					token: xToken,
					setAsDefault: setNewCardAsDefault || !selectedCustomer.defaultPaymentMethodId,
					exp: xExp,
					routingNumber: xRouting,
					zip: xZip,
					street: xStreet,
				}),
			];

			if (this.allowCcAdjust) {
				requestsArray.unshift(
					transactionService.transactionAdjust(
						xRefNum,
						null,
						paymentType,
						transactionType,
						xAmount,
						xTip,
						xDescription,
						xOrderID,
						selectedCustomer.customerId,
						xCustom02,
						xCustom03,
						false,
						xMerchantId
					)
				);
			}
			const response = await this.props.makePendingRequest(Promise.all(requestsArray), requestKeys.SAVE);
			const refs = map(response, r => {
				const refNum = get(r, 'data.RefNum', '') || get(r, 'xRefNum', '');
				if (refNum) return refNum;
			});
			await notificationRef.addNotification({
				success: true,
				message: `Payment method linked to customer`,
				ref: head(refs) || '',
				customerId: selectedCustomer.customerId,
				showPaymentMethods: true,
			});
		} catch (e) {
			this.props.handleError(e);
		} finally {
			this.setState({ isLoading: false });
		}
		this.closeLinkTransactionPopup();
		this.props.showLoader(false);
	};

	closeLinkTransactionPopup = () => {
		this.setState({ selectedCustomer: null }, this.props.closeModal);
	};

	selectCustomer = selectedCustomer => {
		this.setState({ selectedCustomer });
	};
	renderTriggerPlaceholder = () => {
		return <Fragment></Fragment>;
	};
	render() {
		const { selectedCustomer, isLoading } = this.state;
		const { row, notificationRef, linkPaymentMethod } = this.props;

		if (this.props.newCustomer) {
			return (
				<AddEditCustomer
					closeLinkTransactionPopup={this.closeLinkTransactionPopup}
					hideLinkTransactionPopup={this.props.hideModal}
					refreshGrid={this.props.refreshGridData}
					existingTransaction={row}
					advancedView={true}
					notificationRef={{ current: { ...notificationRef } }}
					shouldRefreshGrid={false}
					isModalOpen={true}
					trigger={this.renderTriggerPlaceholder}
				/>
			);
		}

		return (
			<div className="modal__content--link-payment" ref={this.popupRef}>
				{renderIf(isLoading)(<div className="loader loader--progress"></div>)}
				<div className="modal__header">
					<div>
						<h4 className="spc--bottom--med">Link payment method</h4>
						<div>
							<p className="type--p3 type--color--text--light">
								{`Please select the customer you'd like to link this ${
									linkPaymentMethod ? 'payment method to. ' : 'customer and its payment method to.'
								} `}
							</p>
							{!this.allowCcAdjust && (
								<p className="type--p3 type--color--text--light">The transaction will not be linked to the Customer</p>
							)}
						</div>
					</div>
				</div>
				<div className="modal__body">
					<LinkExistingCustomerForm
						popupRef={this.popupRef}
						selectCustomer={this.selectCustomer}
						existingTransaction={row}
					/>
				</div>
				<div className="modal__footer ">
					<div className="type--right">
						<button
							type="button"
							tabIndex="-1"
							className="btn btn--med btn--primary"
							onClick={this.linkPaymentMethodCustomer}
							disabled={!selectedCustomer || isLoading}
						>
							Save
						</button>
					</div>
				</div>
			</div>
		);
	}
}

LinkTransactionComponent.propTypes = {
	row: object.isRequired,
	openModal: func.isRequired,
	refreshGridData: func.isRequired,
	showLoader: func.isRequired,
	makePendingRequest: func.isRequired,
	handleError: func.isRequired,
	closeModal: func.isRequired,
	hideModal: func.isRequired,
	notificationRef: any.isRequired,
	linkPaymentMethod: bool,
	newCustomer: bool,
};

export default LinkTransactionComponent;
