import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { withLoader } from '../../Common/components/loader';
import { authenticationService } from '../../Common/services';
import updateApiEndpoint from 'common/utilities/UpdateApiEndpoint';

const {
	apiVersion,
	loginEndpoint,
	paymentSiteApiEndpoint,
	recurringApiEndpoint,
	kvaasEndpoint,
	kvaasMinorVersion,
	keyManagementEndpoint,
	portalManagementApiEndpoint,
} = ApplicationSettings;
const { developer } = TermsAndConditions;
const { name, version: softwareVersion } = SoftwareSettings;

class InfoComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			ssoVersion: 'checking...',
		};
	}

	async componentDidMount() {
		await this.fetchSsoVersion();
	}

	fetchSsoVersion = async () => {
		const user = await authenticationService.getUser(false);
		if (user && user.signInUserSession && user.signInUserSession.idToken && user.signInUserSession.idToken.jwtToken) {
			const token = user.signInUserSession.idToken.jwtToken;
			const res = await authenticationService.getSsoVersion(token);
			if (res && res.xAssemblyVersion) {
				this.setState({
					ssoVersion: res.xAssemblyVersion,
				});
				return;
			}
		}
		this.setState({
			ssoVersion: 'Requires logged in user to check version',
		});
	};

	redirectToHome = () => {
		const { history } = this.props;
		history.push('/');
	};

	render() {
		const { ssoVersion } = this.state;
		const version = `${softwareVersion}-${AppBuildVersion || ''}-${AppBuildEnvironment || ''}`;
		const labelClass = 'type--p2 type--color--light--secondary';
		const infoClass = 'type--p2 type--p2--medium type--color--light--secondary';

		return (
			<Fragment>
				<div className="flex--primary flex--gap--sml--alt">
					<a
						type="button"
						onClick={this.redirectToHome}
						className="btn btn--action btn--action--secondary auth--info__back"
					>
						<i className="icon icon--sml icon--chevron--left"></i>
					</a>
					<h2>Info</h2>
				</div>
				<ul className="list--bordered spc--bottom--huge">
					<li className="item">
						<p className={labelClass} data-tooltip="Version in site footer">
							App Version:
						</p>
						<p className={infoClass}>{version}</p>
					</li>
					<li className="item">
						<p className={labelClass} data-tooltip="Used in requests to the API">
							Software Name:
						</p>
						<p className={infoClass}>{name}</p>
					</li>
					<li className="item">
						<p className={labelClass} data-tooltip="Used in requests to the API">
							Software Version:
						</p>
						<p className={infoClass}>{softwareVersion}</p>
					</li>
					<li className="item">
						<p className={labelClass} data-tooltip="Environment for which App was built for">
							Build environment:
						</p>
						<p className={infoClass}>{AppBuildEnvironment || null}</p>
					</li>
					<li className="item">
						<p className={labelClass} data-tooltip="Code version from git">
							Git version:
						</p>
						<p className={infoClass}>{AppBuildVersion || null}</p>
					</li>
					<li className="item">
						<p className={labelClass} data-tooltip="Latest git commit hash">
							Git commit:
						</p>
						<p className={infoClass}>{AppBuildCommitHash || null}</p>
					</li>
					<li className="item">
						<p className={labelClass} data-tooltip="From which branch was code built">
							Git branch:
						</p>
						<p className={infoClass}>{AppBuildBranch || null}</p>
					</li>
					<li className="item">
						<p className={labelClass} data-tooltip="Terms and Conditions version">
							T&amp;C Version:
						</p>
						<p className={infoClass}>{developer}</p>
					</li>
				</ul>
				<ul className="list--bordered">
					<li className="item">
						<p className={labelClass} data-tooltip="API endpoint URL">
							API Endpoint:
						</p>
						<p className={infoClass}>{updateApiEndpoint(null)}</p>
					</li>
					<li className="item">
						<p className={labelClass} data-tooltip="API version">
							API Version:
						</p>
						<p className={infoClass}>{apiVersion}</p>
					</li>
					<li className="item">
						<p className={labelClass} data-tooltip="Recurring API endpoint URL">
							Recurring API Endpoint:
						</p>
						<p className={infoClass}>{recurringApiEndpoint}</p>
					</li>
					<li className="item">
						<p className={labelClass} data-tooltip="SSO endpoint URL">
							SSO Endpoint:
						</p>
						<p className={infoClass}>{loginEndpoint}</p>
					</li>
					<li className="item">
						<p className={labelClass} data-tooltip="SSO version - xAssemblyVersion">
							SSO Version:
						</p>
						<p className={infoClass}>{ssoVersion}</p>
					</li>
					<li className="item">
						<p className={labelClass} data-tooltip="KVAAS endpoint URL">
							KVAAS Endpoint:
						</p>
						<p className={infoClass}>{kvaasEndpoint}</p>
					</li>
					<li className="item">
						<p className={labelClass} data-tooltip="KVAAS minor version">
							KVAAS Version:
						</p>
						<p className={infoClass}>{kvaasMinorVersion}</p>
					</li>
					<li className="item">
						<p className={labelClass} data-tooltip="PaymentSITE API endpoint URL">
							PaymentSITE API Endpoint:
						</p>
						<p className={infoClass}>{paymentSiteApiEndpoint}</p>
					</li>
					<li className="item">
						<p className={labelClass} data-tooltip="Key management API endpoint URL">
							Key management API Endpoint:
						</p>
						<p className={infoClass}>{keyManagementEndpoint}</p>
					</li>
					<li className="item">
						<p className={labelClass} data-tooltip="Portal management API endpoint URL">
							Portal management API Endpoint:
						</p>
						<p className={infoClass}>{portalManagementApiEndpoint}</p>
					</li>
				</ul>
			</Fragment>
		);
	}
}

InfoComponent.propTypes = {
	history: PropTypes.object,
};

export default withLoader(InfoComponent);
