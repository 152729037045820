import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
const Company = ({ company, selectedCompany, switchCompany }) => {
	const selectedCompanyMid = get(selectedCompany, 'mid', '');
	const companyMid = get(company, 'mid', '');
	const companyDba = get(company, 'dba', '');
	const companyDbaAlias = get(company, 'dba_alias', '');
	const isActiveClass = selectedCompanyMid === companyMid ? ' is-active' : '';

	return (
		<li key={company.key} className={'account' + isActiveClass}>
			<button onClick={() => switchCompany(company)}>
				{companyDbaAlias || companyDba}
				{companyMid ? ` - ${companyMid}` : ''}
			</button>
		</li>
	);
};
Company.propTypes = {
	company: PropTypes.shape({
		mid: PropTypes.string,
		dba: PropTypes.string,
		dba_alias: PropTypes.string,
		key: PropTypes.string,
	}).isRequired,
	selectedCompany: PropTypes.shape({
		mid: PropTypes.string,
	}).isRequired,
	switchCompany: PropTypes.func.isRequired,
};
export default Company;
