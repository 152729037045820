import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import { ResizeSensor } from 'common/utilities';
class GridFooter extends Component {
	state = { expanded: false };

	componentDidMount = () => {
		const { gridFooterRef } = this.props;
		if (gridFooterRef.current) {
			ResizeSensor(gridFooterRef.current, this.hidePopup);
		}
	};

	hidePopup = () => {
		this.setState({
			expanded: false,
		});
	};

	render() {
		const {
			gridFooterRef,
			isLoadMoreEnabled,
			loadMoreOptions,
			fetchingAdditionalData,
			onLoadMoreLimitChange,
			loadMoreLimit,
		} = this.props;
		return (
			<div ref={gridFooterRef} className="react-grid__footer">
				{isLoadMoreEnabled && (
					<div className="pagination align--h--left">
						{map(loadMoreOptions, item => (
							<button
								key={item}
								onClick={() => onLoadMoreLimitChange(item)}
								className={`${loadMoreLimit === item ? 'is-active' : ''} pagination__item cursor--pointer`}
								disabled={fetchingAdditionalData}
							>
								{item || 'All'}
							</button>
						))}
					</div>
				)}
			</div>
		);
	}
}
GridFooter.propTypes = {
	gridFooterRef: PropTypes.any,
	isLoadMoreEnabled: PropTypes.bool,
	loadMoreOptions: PropTypes.any,
	fetchingAdditionalData: PropTypes.bool,
	onLoadMoreLimitChange: PropTypes.func,
	loadMoreLimit: PropTypes.any,
};
export default GridFooter;
