import React, { Component, Fragment } from 'react';
import PropTypes, { oneOf } from 'prop-types';

class ConfirmActionComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
		};
	}

	onConfirm = () => {
		const { isLoading } = this.state;
		if (isLoading) {
			return;
		}
		this.setState(
			{
				isLoading: true,
			},
			async () => {
				const { onConfirm, notificationHandler, notificationRef, closeModal } = this.props;
				let result;
				try {
					result = await onConfirm();
				} catch (e) {
					result = e;
				} finally {
					if (notificationHandler) {
						const notification = notificationHandler(result);
						notificationRef.addNotification(notification);
					}
					this.setState(
						{
							isLoading: false,
						},
						closeModal
					);
				}
			}
		);
	};

	render() {
		const { isLoading } = this.state;
		const { question, loadingMessage } = this.props;

		return (
			<div>
				{isLoading ? (
					<div className="modal__body type--med">
						<div className="loader__holder grid-sidebar__loader">
							<div className="loader__spinner"></div>
						</div>
						<div className="type--wgt--medium type--center">{loadingMessage}</div>
					</div>
				) : (
					<Fragment>
						<div className="modal__body">{question}</div>
						<div className="modal__footer">
							<button onClick={this.onConfirm} disabled={isLoading} className="btn btn--med btn--primary">
								Confirm
							</button>
						</div>
					</Fragment>
				)}
			</div>
		);
	}
}

ConfirmActionComponent.defaultProps = {
	loadingMessage: 'Please wait',
};

ConfirmActionComponent.propTypes = {
	question: oneOf([PropTypes.string, PropTypes.object]).isRequired,
	loadingMessage: PropTypes.string,
	onConfirm: PropTypes.func.isRequired,
	closeModal: PropTypes.func.isRequired,
	notificationRef: PropTypes.object.isRequired,
	notificationHandler: PropTypes.func,
};

export default ConfirmActionComponent;
