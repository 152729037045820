import React, { Fragment } from 'react';
import { isEmpty, startsWith, toLower, noop, get, join, concat } from 'lodash';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import KeyboardEventHandler from 'react-keyboard-event-handler';

import { FormErrors, hasFeaturePackage } from '../../Common/utilities';
import { transactionType, paymentMethod } from './constants';
import { default as TransactionDetailsFields } from './transaction-details-fields';
import { default as CustomFields } from './custom-fields';
import { NewTransaction } from './new-transaction';
import { isValid } from '../../Common/fields';
import { featurePackages, featurePackageTooltips } from 'common/utilities/has-feature-package';
import { withCancelable } from 'common/components/cancelable';
import { withLoader } from 'common/components/loader';
import { withError } from 'common/components/error';
import { UserAccountPanel } from 'common/components/user-account-panel';
import ReceiptNotificationComponent from 'common/components/notifications/receipt-notification';
import { principalService } from 'common/services';

const handleInvalidAccountSettings = error => {
	if (error && toLower(error.message) === 'invalid account settings (g3)') {
		return {
			...error,
			isApiError: false,
			displayMessage: "This account can't process gift cards",
		};
	}
	return error;
};

class NewTransactionVirtualTerminal extends NewTransaction {
	constructor(props) {
		super(props);
		const principal = principalService.get();
		const userRole = get(principal, 'idInfo.permissions.role');
		this.setState({
			isSaveOnly: userRole === 'saveonly',
		});
	}

	get isExpanded() {
		return true;
	}

	get notificationRef() {
		return this.notification;
	}
	getWrapperClassName = () => {
		let wrapperClassName = 'virtual-terminal__new-transaction__footer';
		const { isSaveOnly } = this.state;
		if (isSaveOnly) {
			wrapperClassName = join(concat(wrapperClassName, 'addSaveOnly'), ' ');
		}
		return wrapperClassName;
	};

	refreshGridData = () => {
		this.setState(
			this.initialState(this.props, this.state.permissions, this.state.achEnabled, this.state.has3DS),
			this.componentDidMount
		);
	};

	renderForm = () => {
		const {
			amount,
			transactionDetails,
			customFields,
			errors,
			customerId,
			requiredFields,
			isLoading,
			customDisplayLabels,
			transactionHiddenFields,
			saveAsCustomer,
			convenienceFees,
			convenienceFee,
			salesTax,
			includeConvenience,
			includeSalesTax,
		} = this.state;
		const { invalidClassName, creditCard, hideTransactionDetails, hideCustomFields } = this.formRenderingParams;

		return (
			<Fragment>
				{isLoading ? (
					this.renderLoader()
				) : (
					<Fragment>
						<span ref={this.pageTopRef}></span>
						<form id="transaction-form" onSubmit={this.onSubmit}>
							<KeyboardEventHandler handleKeys={['enter']} onKeyEvent={() => this.onSubmit()}>
								{!isEmpty(errors) && (
									<div className="spc--bottom--med">
										<FormErrors errors={errors} />
									</div>
								)}
								<div className="virtual-terminal is-expanded">
									<div className="virtual-terminal__new-transaction__aside">
										<div className="newtransaction__amount">
											<p className="spc--bottom--tny type--base type--color--text--regular datatooltip--h--right">
												{customDisplayLabels.amount || 'Amount'}{' '}
												<span className="form__group__required" data-tooltip="Required">
													*
												</span>
											</p>
											<NumberFormat
												getInputRef={el => {
													this.amountRef = el;
												}}
												placeholder={`${this.currencyCode}0.00`}
												thousandSeparator=","
												decimalSeparator="."
												allowNegative={false}
												decimalScale={2}
												prefix={this.currencyCode}
												onValueChange={this.handleAmountChange}
												value={amount.value}
												isAllowed={this.checkIfAmountAllowed}
												disabled={
													creditCard &&
													creditCard.transactionType === transactionType.SAVE &&
													this.state.paymentMethod === paymentMethod.CC
												}
												autoFocus={true}
												inputMode="numeric"
												className={`input newtransaction__amount__input ${(!isValid(amount) && invalidClassName) ||
													''} ${amount.value.length > 6 ? 'is-reduced' : ''}`}
											/>
											{this.renderTaxAndConvenience()}
										</div>
										{this.renderTransactionTabs()}
										{this.isAchDisabled && (
											<div className="spc--bottom--lrg type--center">
												<i className="icon icon--xlrg icon--ach-disabled" />
												<div className="type--wgt--light type--base">
													Allow your customers to pay directly to your bank account without a credit card.
												</div>
											</div>
										)}
										{this.renderSelectedCustomerPaymentMethod(
											'',
											'',
											'newtransaction__item newtransaction__item--expandable',
											'',
											'newtransaction__accordion spc--bottom--sml'
										)}
									</div>
									{this.isExpanded && (
										<div className="virtual-terminal__new-transaction__main">
											<ul className="newtransaction__group">
												{this.renderBillingAndShippingInfo('clearfix')}
												{!hideTransactionDetails && (
													<Fragment>
														<li>
															<div className="newtransaction__item__title">Transaction Details</div>
														</li>
														<li className="clearfix">
															<div className="newtransaction__group">
																<TransactionDetailsFields
																	data={transactionDetails}
																	onChange={this.onTransactionDetailsChange}
																	invalidClassName={invalidClassName}
																	requiredFields={requiredFields}
																	customDisplayLabels={customDisplayLabels}
																	transactionHiddenFields={transactionHiddenFields}
																	hideOrderId={!!customerId}
																/>
															</div>
														</li>
													</Fragment>
												)}
												{!hideCustomFields && (
													<Fragment>
														<li>
															<div className="newtransaction__item__title">Custom details</div>
														</li>
														<li>
															<CustomFields
																numberOfCustomFields={19}
																data={customFields}
																onChange={this.onCustomFieldsChange}
																customerId={customerId}
																invalidClassName={invalidClassName}
																requiredFields={requiredFields}
																customDisplayLabels={customDisplayLabels}
																transactionHiddenFields={transactionHiddenFields}
																saveAsCustomer={saveAsCustomer}
																convenienceFees={convenienceFees}
																originalAmount={amount.value}
																convenienceFee={convenienceFee}
																salesTax={salesTax}
																includeSalesTax={includeSalesTax}
																includeConvenience={includeConvenience}
															/>
														</li>
													</Fragment>
												)}
											</ul>
										</div>
									)}
								</div>
							</KeyboardEventHandler>
						</form>
					</Fragment>
				)}
			</Fragment>
		);
	};

	renderPopupHeader = () => (
		<div className="header">
			<div className="header__title type--wgt--med">Add new transaction</div>
			<div className="header__menu">
				<UserAccountPanel />
			</div>
		</div>
	);

	renderPopupFooter = () => {
		const {
			isProcessing,
			total,
			amount,
			saveAsCustomer,
			showPopupDetails,
			showAllowDuplicate,
			allowDuplicate,
			disableVerification,
			showDisableVerification,
			includeConvenience,
			includeSalesTax,
			paymentMethod,
			creditCard,
			permissions: { allowCcSave, allowCheckSave },
		} = this.state;
		const { customer } = this.props;

		const saveAsCustomerDisabled = !hasFeaturePackage(featurePackages.customerBilling);
		const saveAsCustomerTooltip = saveAsCustomerDisabled ? featurePackageTooltips.noCustomerBilling : null;

		return (
			<div className={this.getWrapperClassName()}>
				<div>
					<div className="hide--from--lrg spc--bottom--tny">
						<a className="btn btn--link" onClick={this.toggleShowDetails}>
							{showPopupDetails ? '- Hide' : '+ Show'} details
						</a>
					</div>
					{this.isAchDisabled ? null : (
						<div className={`modal__footer--convenience ${showPopupDetails ? 'is-visible' : ''}`}>
							{(!customer || showAllowDuplicate || showDisableVerification) && (
								<div>
									{!customer &&
										(creditCard &&
											!startsWith(toLower(creditCard.transactionType), transactionType.EBTFS_VOUCHER) &&
											((paymentMethod === 'cc' && allowCcSave) || (paymentMethod === 'check' && allowCheckSave))) && (
											<div
												className="display--ib spc--right--lrg--alt spc--bottom--sml"
												data-tooltip={saveAsCustomerTooltip}
											>
												<input
													type="checkbox"
													name="saveAsCustomer"
													id="saveAsCustomer"
													className="input input--check input--check--sml"
													value={saveAsCustomer}
													checked={saveAsCustomer}
													onChange={this.handleChange}
													disabled={saveAsCustomerDisabled}
												/>
												<label htmlFor="saveAsCustomer">Save as customer</label>
											</div>
										)}
									{showAllowDuplicate && (
										<div className="display--ib spc--right--lrg--alt spc--bottom--sml">
											<input
												type="checkbox"
												name="allowDuplicate"
												id="allowDuplicate"
												className="input input--check input--check--sml"
												value={allowDuplicate}
												checked={allowDuplicate}
												onChange={this.handleChange}
											/>
											<label htmlFor="allowDuplicate">Allow duplicates</label>
										</div>
									)}
									{showDisableVerification && (
										<div className="display--ib spc--bottom--sml">
											<input
												type="checkbox"
												name="disableVerification"
												id="disableVerification"
												className="input input--check input--check--sml"
												value={disableVerification}
												checked={disableVerification}
												onChange={this.handleChange}
											/>
											<label htmlFor="disableVerification">Disable 3DS verification</label>
										</div>
									)}
								</div>
							)}
						</div>
					)}
				</div>
				{this.isAchDisabled ? (
					<a
						className="btn btn--primary btn--med"
						rel="noopener noreferrer"
						target="_blank"
						href="https://www.cardknox.com/go"
					>
						Click here to apply for ACH
					</a>
				) : (
					<button
						type="button"
						className="btn btn--primary btn--med virtual-terminal__new-transaction__footer__process"
						disabled={isProcessing}
						onClick={this.onSubmit}
					>
						<span className="spc--right--tny">Process (Total</span>{' '}
						<NumberFormat
							thousandSeparator=","
							decimalSeparator="."
							disabled={true}
							decimalScale={2}
							prefix={this.currencyCode}
							value={
								((includeConvenience || includeSalesTax) &&
								creditCard &&
								creditCard.transactionType !== transactionType.SAVE
									? total
									: amount.value) || 0
							}
							displayType="text"
						/>
						)
					</button>
				)}
			</div>
		);
	};

	render() {
		const { showPopupDetails } = this.state;
		return (
			<Fragment>
				<ReceiptNotificationComponent ref={el => (this.notification = el)} />
				<Fragment>
					{this.renderPopupHeader()}
					<div
						className={`l--content virtual-terminal__new-transaction__layout ${
							this.isExpanded ? 'is-expanded--alt' : ''
						} ${showPopupDetails ? 'is-visible' : ''}`}
					>
						{this.renderForm()}
						{this.renderPopupFooter()}
					</div>
				</Fragment>
			</Fragment>
		);
	}
}

NewTransactionVirtualTerminal.defaultProps = {
	refreshGridData: noop,
};

NewTransactionVirtualTerminal.propTypes = {
	makePendingRequest: PropTypes.func,
	handleError: PropTypes.func,
};

export default withLoader(withError(withCancelable(NewTransactionVirtualTerminal), handleInvalidAccountSettings));
