import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

class SingleDateSelectionComponent extends Component {
	get startDayValue() {
		return moment(this.props.filter.values.startDay, ApplicationSettings.apiDateFormat).format(
			ApplicationSettings.displayDateFormat
		);
	}

	filterRemoved = () => {
		this.props.onFilterRemoved({
			id: 'startDay',
			emptyValue: null,
		});
	};

	render() {
		return (
			<div className="filter__toolbar__selected-filter">
				<span>Start Date:</span>
				<span className="type--wgt--medium">{this.startDayValue}</span>
				<button onClick={this.filterRemoved} data-tooltip="Remove" className="btn btn--link datatooltip--auto">
					<i className="icon icon--tny icon--close--text"></i>
				</button>
			</div>
		);
	}
}

SingleDateSelectionComponent.propTypes = {
	filter: PropTypes.object.isRequired,
	onFilterRemoved: PropTypes.func.isRequired,
};

export default SingleDateSelectionComponent;
