import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImageWithFallback from 'common/components/ImageFallback';

class PublicLayoutComponent extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className={`auth ${this.props.customLayoutClass ? this.props.customLayoutClass : ''}`}>
				<aside className="auth__sidebar">
					<ImageWithFallback imgAttributes={{ alt: 'logo' }}>
						{props => <img alt="logo" src={'static/media/sola-logo.svg'} className="auth__sidebar__logo" {...props} />}
					</ImageWithFallback>
				</aside>

				<div className="auth__main">{this.props.children}</div>
			</div>
		);
	}
}

PublicLayoutComponent.propTypes = {
	children: PropTypes.any,
	customLayoutClass: PropTypes.string,
};

export default PublicLayoutComponent;
