import { NumericFilter, MultiselectCardknoxFilter } from '../../columns/filters';
import {
	DisplayDateComponent,
	CurrencyComponent,
	CurrencyCodesComponent,
	StatusComponent,
} from '../../columns/formatters';
import createGridColumn from 'common/utilities/createGridColumn';

export const TransferColumns = [
	createGridColumn('xRefNum', 'Ref #', 150, {
		hideable: false,
		visible: true,
		showOnMobile: true,
		order: 1,
		fieldKey: 'xRefNum,xEnteredDate,xResponseResult,xAmount,xCurrency,xCommand,xTransferMerchantName',
	}),
	createGridColumn('xEnteredDate', 'Date', 200, {
		showOnMobile: true,
		formatter: DisplayDateComponent,
		isDefaultSorter: true,
		defaultSortDirection: 'DESC',
		customSettingsKey: 'date',
	}),
	createGridColumn('xCurrency', 'Currency', 100, {
		visible: true,
		showOnMobile: true,
		formatter: CurrencyCodesComponent,
		customSettingsKey: 'currency',
		exportKey: 'xCurrency',
		filterRenderer: MultiselectCardknoxFilter,
	}),
	createGridColumn('xAmount', 'Amount', 120, {
		hideable: false,
		visible: true,
		showOnMobile: true,
		formatter: CurrencyComponent,
		customSettingsKey: 'amount',
		exportKey: 'xAmount',
		filterRenderer: NumericFilter,
		getRowMetaData: row => {
			return { ...row, displayBadge: true };
		},
		alignHeaderRight: true,
	}),
	createGridColumn('xResponseResult', 'Result', 120, {
		visible: true,
		showOnMobile: true,
		formatter: StatusComponent,
		customSettingsKey: 'result',
		filterRenderer: MultiselectCardknoxFilter,
		getRowMetaData: row => row,
	}),
	createGridColumn('xCommand', 'Transfer Type', 140, {
		hideable: false,
		visible: true,
		showOnMobile: true,
		customSettingsKey: 'transferType',
		filterRenderer: MultiselectCardknoxFilter,
	}),
	createGridColumn('xTransferMerchantId', 'Merchant ID', 150, {
		hideable: false,
		visible: false,
		showOnMobile: true,
		customSettingsKey: 'transferMerchantId',
		getRowMetaData: row => row,
	}),
	createGridColumn('xTransferMerchantName', 'Merchant Name', 180, {
		visible: true,
		showOnMobile: true,
		customSettingsKey: 'transferMerchantName',
		getRowMetaData: row => row,
	}),
];
