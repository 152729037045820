import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

import { CurrencyMap } from '../../Common/utilities';
import { round } from 'lodash';

class DashboardTransactionStatus extends Component {
	constructor(props) {
		super(props);

		this.state = {
			hasRecords: props.hasRecords,
			sale: props.sale,
			credit: props.credit,
			total: props.total,
			avg: props.avg,
		};
	}

	currencyCode = CurrencyMap.resolveCurrency();

	getValue = (value, isTotal) => {
		const { hasRecords } = this.state;
		if (hasRecords && !isNaN(value)) {
			if (isTotal) {
				value = round(value, 2);
			} else {
				value = Math.abs(value);
			}
		} else {
			value = 0.0;
		}
		return (
			<NumberFormat
				prefix={this.currencyCode}
				value={value}
				displayType="text"
				thousandSeparator={true}
				decimalScale={2}
				fixedDecimalScale={true}
			/>
		);
	};

	render() {
		const { sale, credit, total, avg } = this.state;
		return (
			<div className="dashboard__status__wrapper">
				<h5 className="spc--bottom--lrg">Transaction stats</h5>
				<div className="row">
					<div className="col col-sml-6 col-lrg-3">
						<div className="dashboard__status">
							<div className="dashboard__status__icon total"></div>
							<div>
								<div className="dashboard__status__label">Total</div>
								<div className="dashboard__status__value">{this.getValue(total, true)}</div>
							</div>
						</div>
					</div>
					<div className="col col-sml-6 col-lrg-3">
						<div className="dashboard__status">
							<div className="dashboard__status__icon sales"></div>
							<div>
								<div className="dashboard__status__label">Sales</div>
								<div className="dashboard__status__value">{this.getValue(sale)}</div>
							</div>
						</div>
					</div>
					<div className="col col-sml-6 col-lrg-3">
						<div className="dashboard__status">
							<div className="dashboard__status__icon credit"></div>
							<div>
								<div className="dashboard__status__label">Credit</div>
								<div className="dashboard__status__value">{this.getValue(credit)}</div>
							</div>
						</div>
					</div>
					<div className="col col-sml-6 col-lrg-3">
						<div className="dashboard__status">
							<div className="dashboard__status__icon avg-transaction"></div>
							<div>
								<div className="dashboard__status__label">Avg. Transaction</div>
								<div className="dashboard__status__value">{this.getValue(avg)}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

DashboardTransactionStatus.propTypes = {
	hasRecords: PropTypes.bool.isRequired,
	sale: PropTypes.number,
	credit: PropTypes.number,
	total: PropTypes.number,
	avg: PropTypes.number,
};

export default DashboardTransactionStatus;
