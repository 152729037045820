import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { toLower } from 'lodash';

import { withError } from 'common/components/error';
import { withCancelable } from 'common/components/cancelable';
import { CurrencyMap } from 'common/utilities';
import { giftService } from 'common/services';
import { withLoader } from 'common/components/loader';

const invalidCardNumberMessage = 'Please enter a valid card number';

const requestKeys = {
	FETCH: 'fetch',
};

class GiftActivityBreakdownGridHeader extends Component {
	constructor(props) {
		super(props);
	}
	isValidGiftCardNumber = (maskedCardNumber = this.props.parentState.maskedCardNumber) => {
		if (maskedCardNumber.length < 12 || maskedCardNumber.length > 19) {
			return false;
		}
		return true;
	};

	async componentDidMount() {
		try {
			const { giftCardNumber } = this.props.parentState;
			if (giftCardNumber.length > 15) {
				await this.getRemainingBalance(giftCardNumber);
			}
		} catch (e) {
			this.props.handleError(e);
		}
	}

	handleGiftCardNumberChange = ({ value }) => {
		const newState = { maskedCardNumber: value };
		newState.error = this.isValidGiftCardNumber(value) ? '' : invalidCardNumberMessage;
		this.props.handleGridHeaderChange(newState);
	};

	handleApply = async e => {
		e.preventDefault();
		if (!this.props.isLoading) {
			const isValid = this.isValidGiftCardNumber();
			this.props.handleGridHeaderChange({
				error: isValid ? '' : invalidCardNumberMessage,
			});
			if (isValid) {
				await this.getRemainingBalance();
			}
			this.props.showLoader(false);
		}
	};

	getRemainingBalance = async (giftCardNumber = this.props.parentState.maskedCardNumber) => {
		try {
			this.props.showLoader(true);
			const { xStatus, xRemainingBalance } = await this.props.makePendingRequest(
				giftService.getRemainingBalance(giftCardNumber),
				requestKeys.FETCH
			);
			if (toLower(xStatus) === 'approved') {
				this.props.handleGridHeaderChange({
					remainingBalance: xRemainingBalance,
				});
			}
		} catch (e) {
			const { message } = e;
			if (message === 'Invalid GiftCard Number') {
				this.props.setParentState({
					error: message,
				});
			}
		}
		this.props.showLoader(false);
	};

	render() {
		const { maskedCardNumber, remainingBalance, error } = this.props.parentState;
		const { isLoading } = this.props;

		return (
			<Fragment>
				<div className="gift-report__inputgroup spc--bottom--sml--alt">
					<div>
						<div className="form__group__header">
							<span className="form__group__label">Enter Gift Card Number</span>
						</div>
						<form onSubmit={this.handleApply} className="gift-report__inputgroup__input start">
							<NumberFormat
								name="giftCardNumber"
								className="input"
								placeholder="0000 0000 0000"
								value={maskedCardNumber}
								inputMode="numeric"
								onValueChange={this.handleGiftCardNumberChange}
							/>
						</form>
					</div>
					<div className="gift-report__inputgroup__separator">=</div>
					{isLoading ? (
						<div className="loader__holder">
							<div className="loader__spinner"></div>
						</div>
					) : (
						<div>
							<div className="form__group__header">
								<span className="form__group__label">Remaining Balance</span>
							</div>
							<div className="gift-report__inputgroup__input end">
								{remainingBalance ? (
									<NumberFormat
										prefix={CurrencyMap.resolveCurrency()}
										value={remainingBalance}
										displayType="text"
										thousandSeparator={true}
										decimalScale={2}
										fixedDecimalScale={true}
										className="input"
									/>
								) : (
									<input disabled className="input" placeholder={`${CurrencyMap.resolveCurrency()}0.00`}></input>
								)}
							</div>
						</div>
					)}
				</div>
				{error && <p className="type--color--error spc--top--tny">{error}</p>}
			</Fragment>
		);
	}
}

GiftActivityBreakdownGridHeader.propTypes = {
	data: PropTypes.object.isRequired,
	handleChange: PropTypes.func.isRequired,
	handleError: PropTypes.func.isRequired,
	makePendingRequest: PropTypes.func.isRequired,
	showLoader: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
	parentState: PropTypes.object.isRequired,
	handleGridHeaderChange: PropTypes.func.isRequired,
	setParentState: PropTypes.func.isRequired,
};

export default withError(withLoader(withCancelable(GiftActivityBreakdownGridHeader)));
