import React, { Component } from 'react';
import PropTypes from 'prop-types';

class BatchGridHeader extends Component {
	render() {
		const { children, ...rest } = this.props;
		return (
			<thead {...rest}>
				<tr>{children}</tr>
			</thead>
		);
	}
}

BatchGridHeader.propTypes = {
	children: PropTypes.any,
};

export default BatchGridHeader;
