import React from 'react';

const ClickToPayTooltip = props => {
	return (
		<i
			{...props}
			className="icon icon--sml icon--info newtransaction__tooltip__trigger spc--left--sml spc--bottom--nano"
		>
			<span className="newtransaction__tooltip">
				<p>
					Click-To-Pay allows consumers to store their payment information and use it to pay for goods and services
					wherever Click-To-Pay is supported. If you enable this option for PaymentSITE, an Click-To-Pay button displays
					on the form for users to select as a payment option.
				</p>
			</span>
		</i>
	);
};

export default ClickToPayTooltip;
