import React from 'react';
import PropTypes from 'prop-types';
import { filter, toLower, isEmpty, map, includes } from 'lodash';

const CountryList = ({ countries, checked, search, handleCheck, disabled }) => {
	const filteredCountries = filter(
		countries,
		country => !isEmpty(country) && includes(toLower(country), toLower(search))
	);

	return (
		<ul className="transfer-list">
			{map(filteredCountries, country => (
				<div key={country}>
					<input
						type="checkbox"
						name={country}
						id={country}
						className="input input--check"
						checked={checked}
						value={country}
						onChange={() => handleCheck(country)}
						disabled={disabled}
					/>
					<label htmlFor={country}>{country}</label>
				</div>
			))}
		</ul>
	);
};

CountryList.propTypes = {
	countries: PropTypes.array.isRequired,
	search: PropTypes.string,
	handleCheck: PropTypes.func.isRequired,
	checked: PropTypes.bool.isRequired,
	disabled: PropTypes.bool,
};

export default CountryList;
