import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { parse } from 'query-string';
import { clone, noop } from 'lodash';

import principalService from '../../Common/services/principalService';
import authenticationService from '../../Common/services/authenticationService';
import { withError } from '../../Common/components/error';
import { withLoader } from 'common/components/loader';
import { quickAssistService } from 'common/services';

class ApiKeysComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentWillMount() {
		const {
			location: { search },
		} = this.props;
		const data = clone(principalService.get());
		let callback = noop;
		if (search) {
			const parsed = parse(search);
			if (parsed && parsed.hash) {
				data.hash = parsed.hash;
				data.navigateOnSave = true;
				callback = this.fetchKey;
			}
		}
		this.setState(data, callback);
	}

	fetchKey = async () => {
		this.props.showLoader(true);
		try {
			const { xKey: key } = await quickAssistService.getFullKey(this.state.hash);
			this.setState(
				{
					key,
				},
				this.saveKeys
			);
		} catch (e) {
			this.props.handleError(e);
		}
	};

	saveKeys = async () => {
		const { key, navigateOnSave } = this.state;
		if (!key) {
			this.setErrorMessage('Key is required');
			return;
		}

		this.props.showLoader(true);
		localStorage.removeItem('hasPaymentSites');
		localStorage.removeItem('isProPay');

		try {
			const data = await authenticationService.validateKey(key);
			const { list } = this.state;
			const newState = {
				id: data.xMerchantID,
				companyName: data.xMerchantDBA,
				idInfo: data,
				list: [
					...list,
					{
						dba: data.xMerchantDBA,
						mid: data.xMerchantID,
						revision: '1',
						role: 'Admin',
						sortorder: list.length.toString(),
					},
				],
			};

			principalService.set(newState);
			this.setState(newState, () => {
				if (navigateOnSave) {
					window.location = '/';
				} else {
					window.location.reload();
				}
			});
		} catch (e) {
			this.props.handleError(e, { additionalInfo: { key } });
		}
		this.props.showLoader(false);
	};

	handleKeyChange = e => {
		this.setState({ key: e.target.value });
	};

	handleIfieldsChange = e => {
		this.setState({ ifields: e.target.value });
	};

	setErrorMessage = errorMessage => {
		this.setState({ errorMessage });
	};

	render() {
		const { isLoading } = this.props;
		const { errorMessage } = this.state;
		return (
			<div>
				<header className="header">
					<div className="type--xlrg type--wgt--medium">Api Keys</div>
				</header>

				<div className="l--content">
					<div className="w--450">
						<div className="form__group">
							<div className="form__group__header">
								<label className="form__group__label" htmlFor="id">
									Key:{' '}
								</label>
							</div>
							<input
								id="id"
								type="text"
								className="input input--med"
								placeholder="Key"
								value={this.state.key}
								disabled={isLoading}
								onChange={this.handleKeyChange}
							/>
						</div>
						<div className="form__group">
							<div className="form__group__header">
								<label className="form__group__label" htmlFor="ifields">
									Ifields key:{' '}
								</label>
							</div>
							<input
								id="ifields"
								type="text"
								className="input input--med"
								placeholder="Ifields key"
								value={this.state.ifields}
								disabled={isLoading}
								onChange={this.handleIfieldsChange}
							/>
						</div>
						<div className="form__group">
							<div className="form__group__header">
								<label className="form__group__label" htmlFor="ifields">
									Company:{' '}
								</label>
							</div>
							{this.state.companyName}
						</div>
						<hr />
						<button className="btn btn--sml btn--primary spc--top--med" disabled={isLoading} onClick={this.saveKeys}>
							Save
						</button>
						{errorMessage ? <div className="validation spc--top--sml">{errorMessage}</div> : null}
					</div>
				</div>
			</div>
		);
	}
}

ApiKeysComponent.propTypes = {
	handleError: PropTypes.func,
	location: PropTypes.object,
	isLoading: PropTypes.bool,
	showLoader: PropTypes.func,
};

export default withLoader(withError(ApiKeysComponent));
