import React, { Component } from 'react';
import { find } from 'lodash';

import { principalService, authenticationService } from 'common/services';

class PhonePayFree extends Component {
	constructor(props) {
		super(props);

		this.state = {
			emailBody: '',
		};
	}

	async componentDidMount() {
		const principal = principalService.get();
		if (!principal) {
			return;
		}
		const activeKey = find(principal.list, ({ mid }) => mid === principal.id);
		if (!activeKey) {
			return;
		}
		let emailBody = activeKey.dba;
		const user = await authenticationService.getUser();
		const email = user && user.attributes && user.attributes.email;
		if (email) {
			emailBody += `, ${email}`;
		}
		this.setState({ emailBody });
	}

	render() {
		return (
			<p className="spc--bottom--sml">
				Still not using PhonePay Free? Accept phone payments from your customers quickly and securely, day or night.
				Contact{' '}
				<a
					href={`mailto:cs@solapayments.com?subject=I want to use PhonePay Free&body=${this.state.emailBody}`}
					className="btn btn--link"
				>
					Customer Service
				</a>{' '}
				to learn more.
			</p>
		);
	}
}

export default PhonePayFree;
