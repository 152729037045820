import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { differenceWith, filter, isEqual, map, startsWith, union, head, isEmpty, includes } from 'lodash';
import { countryList } from 'components/new-transaction/constants';
import CountryList from './components/CountryList';

class TransactionScreening extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedCountry: '',
			AFF_BLOCK_COUNTRIES: [],
			search: '',
			enableCountryWhitelist: null,
		};
	}
	componentDidUpdate(prevProps) {
		if (!isEqual(prevProps.affSettings.AFF_Allow_Country, this.props.affSettings.AFF_Allow_Country)) {
			const newState = {
				AFF_BLOCK_COUNTRIES: differenceWith(this.getAllCountries(), this.props.affSettings.AFF_Allow_Country, isEqual),
			};
			if (this.state.enableCountryWhitelist === null) {
				newState.enableCountryWhitelist = head(this.props.affSettings.AFF_Allow_Country);
			}
			this.setState(newState);
		}
	}
	handleSearch = event => {
		this.setState({ search: event.target.value });
	};

	getAllCountries = () => {
		return map(
			filter(countryList, country => !country.hideAff && country.name && !startsWith(country.name, '-')),
			country => country.name
		);
	};
	isChecked = (settings, key) => {
		return this.props[settings][key];
	};
	handleEnableCountryWhiteList = e => {
		if (!e.target.checked) {
			const updatedAffSettings = {
				...this.props.affSettings,
				AFF_Allow_Country: [''],
			};
			this.props.handleSettingsUpdate('affSettings', updatedAffSettings);
		}
		this.setState({ enableCountryWhitelist: e.target.checked, selectedCountry: '' });
	};

	handleCheckboxChange = (key, isChecked) => {
		const updatedAffSettings = {
			...this.props.affSettings,
		};
		updatedAffSettings[key] = isChecked;
		this.props.handleSettingsUpdate('affSettings', updatedAffSettings);
	};

	handleProcessingOptionChange = () => {
		const { AFF_Allow_Country } = this.props.affSettings;
		const { AFF_BLOCK_COUNTRIES, search } = this.state;
		const key = !isEmpty(AFF_BLOCK_COUNTRIES) ? 'blockProcessing' : 'allowProcessing';

		const combinedCountries = union(AFF_BLOCK_COUNTRIES, AFF_Allow_Country);
		const filteredCountries = filter(combinedCountries, country =>
			country.toLowerCase().includes(search.toLowerCase())
		);

		const filteredBlockCountries = filter(filteredCountries, country => includes(AFF_BLOCK_COUNTRIES, country));
		const filteredAllowCountries = filter(filteredCountries, country => includes(AFF_Allow_Country, country));

		if (key === 'blockProcessing') {
			const remainingBlockCountries = differenceWith(AFF_BLOCK_COUNTRIES, filteredBlockCountries, isEqual);
			const updatedAllowCountries = union(AFF_Allow_Country, filteredBlockCountries);
			this.props.handleSettingsUpdate('affSettings', {
				...this.props.affSettings,
				AFF_Allow_Country: updatedAllowCountries,
			});
			this.setState({ AFF_BLOCK_COUNTRIES: remainingBlockCountries, selectedCountry: '' });
		} else if (key === 'allowProcessing') {
			const remainingAllowCountries = differenceWith(AFF_Allow_Country, filteredAllowCountries, isEqual);
			const updatedBlockCountries = union(AFF_BLOCK_COUNTRIES, filteredAllowCountries);
			this.props.handleSettingsUpdate('affSettings', {
				...this.props.affSettings,
				AFF_Allow_Country: remainingAllowCountries,
			});
			this.setState({ AFF_BLOCK_COUNTRIES: updatedBlockCountries, selectedCountry: '' });
		}
	};
	handleDenyToAllow = selectedCountry => {
		const { AFF_BLOCK_COUNTRIES } = this.state;
		if (!selectedCountry) return;
		const { AFF_Allow_Country } = this.props.affSettings;
		const updatedAffSettings = {
			...this.props.affSettings,
			AFF_Allow_Country: [...AFF_Allow_Country, selectedCountry],
		};
		this.setState({
			selectedCountry: '',
			AFF_BLOCK_COUNTRIES: AFF_BLOCK_COUNTRIES.filter(country => country !== selectedCountry),
		});
		this.props.handleSettingsUpdate('affSettings', updatedAffSettings);
	};

	handleAllowToDeny = selectedCountry => {
		if (!selectedCountry) return;
		const { AFF_Allow_Country } = this.props.affSettings;
		const updatedAffSettings = {
			...this.props.affSettings,
			AFF_Allow_Country: AFF_Allow_Country.filter(country => country !== selectedCountry),
		};
		this.setState({
			selectedCountry: '',
			AFF_BLOCK_COUNTRIES: [...this.state.AFF_BLOCK_COUNTRIES, selectedCountry],
		});
		this.props.handleSettingsUpdate('affSettings', updatedAffSettings);
	};

	handleCountrySelect = (selectedCountry, selectedList) => {
		if (this.props.isLoading || !this.state.enableCountryWhitelist) return;
		this.props.handleBlockChange(true);
		this.setState({ selectedCountry, selectedList });
	};

	render() {
		const { affSettings, isLoading, isExpanded, toggleExpand } = this.props;
		const { selectedCountry, AFF_BLOCK_COUNTRIES, search, enableCountryWhitelist } = this.state;

		return (
			<div className="card spc--bottom--lrg">
				<button className="card__header card__header--expandable" onClick={toggleExpand}>
					<h5>Transaction Screening</h5>
					<i className={`icon icon--sml icon--chevron--${isExpanded ? 'top' : 'down'}--primary`}></i>
				</button>
				{isExpanded && (
					<div className="card__body">
						<div className="spc--bottom--lrg">
							<input
								type="checkbox"
								id="enableCountryWhitelist"
								checked={enableCountryWhitelist}
								onChange={this.handleEnableCountryWhiteList}
								className="input--check"
							></input>
							<label htmlFor="enableCountryWhitelist">Enable Country Whitelist</label>
						</div>
						<div className="transfer-list__wrapper spc--bottom--lrg">
							<div>
								<div className="form__group__header">
									<span className="form__group__label">List of Countries</span>
								</div>
								<input
									className="input input--med input--search"
									type="text"
									value={search}
									onChange={this.handleSearch}
									placeholder="Search country"
									disabled={!enableCountryWhitelist}
								/>
							</div>
							<div>
								<button
									className="btn btn--link type--xsml type--wgt--medium"
									onClick={this.handleProcessingOptionChange}
									disabled={isLoading || !enableCountryWhitelist}
								>
									{!AFF_BLOCK_COUNTRIES.length ? 'Block' : 'Allow'} all
								</button>
							</div>
							<div>
								<CountryList
									countries={affSettings.AFF_Allow_Country}
									selectedCountry={selectedCountry}
									search={search}
									handleCountrySelect={country => this.handleCountrySelect(country, 'allow')}
									checked={true}
									handleCheck={this.handleAllowToDeny}
								/>
								<hr className="separator--dark fullwidth" />
								<CountryList
									countries={AFF_BLOCK_COUNTRIES}
									selectedCountry={selectedCountry}
									search={search}
									handleCountrySelect={country => this.handleCountrySelect(country, 'deny')}
									handleCheck={this.handleDenyToAllow}
									disabled={!enableCountryWhitelist}
									checked={false}
								/>
							</div>
						</div>
						<div>
							<input
								type="checkbox"
								id="AFF_Allow_Country_BlockOnFailure"
								name="AFF_Allow_Country_BlockOnFailure"
								checked={!this.isChecked('affSettings', 'AFF_Allow_Country_BlockOnFailure')}
								onChange={e => this.handleCheckboxChange('AFF_Allow_Country_BlockOnFailure', !e.target.checked)}
								className="input--check"
								disabled={isLoading}
							/>
							<label htmlFor="AFF_Allow_Country_BlockOnFailure">
								Allow transactions when the IP lookup fails or the IP address is unknown.
							</label>
						</div>
					</div>
				)}
			</div>
		);
	}
}

TransactionScreening.propTypes = {
	isExpanded: PropTypes.bool.isRequired,
	toggleExpand: PropTypes.func.isRequired,
	affSettings: PropTypes.object.isRequired,
	isLoading: PropTypes.bool.isRequired,
	handleSettingsUpdate: PropTypes.func.isRequired,
	handleBlockChange: PropTypes.func.isRequired,
};

export default TransactionScreening;
