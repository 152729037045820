import React, { Component, Fragment, createRef } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, each, toLower } from 'lodash';

import { kvaasService, principalService } from '../../../Common/services';
import { Notification } from '../../../Common/components/notifications';
import { withLoader } from '../../../Common/components/loader';
import { withCancelable } from '../../../Common/components/cancelable';
import { withBlock } from '../../../Common/components/block';
import { withError } from '../../../Common/components/error';
import { invokeIfFunction, kvaasResources } from '../../../Common/utilities';
import sectionKeys from '../../../routing/sections';
import { SidebarContext } from 'common/contexts';
import handleInvalidRevision from '../utils/invalidRevision';
import FooterComponent from 'components/settings/components/FooterComponent';

const requestKeys = {
	KVAAS: 'kvaas',
	SAVE: 'save',
	FETCH: 'fetch',
};

class UserSettingsAdditionalFunctionality extends Component {
	constructor(props) {
		super(props);
		this.notification = createRef();
		const principal = principalService.get();
		this.hasAch = !!principal.idInfo.xACHEnabled;
		this.hasPermissions = !!principal.hasAccess[sectionKeys.newTransaction];
		this.hasGoPlus = !!principal.hasAccess[sectionKeys.goPlus];
		this.hasPermissionReportNet =
			principal && principal.idInfo && principal.idInfo.permissions && !!principal.idInfo.permissions.allowReportNet;

		this.state = this.initialState;
	}
	static contextType = SidebarContext;
	get initialState() {
		return {
			oldData: {
				flags: null,
			},
			portalFlags: this.initialFlags,
			showEbtfsVoucherOption: false,
		};
	}

	get initialFlags() {
		return {
			multipleCapture: false,
			showEbtfsVoucherOption: false,
			showDisputesTab: false,
			includeSplitPay: false,
			displaySplitPayColumns: false,
		};
	}

	mapStateToRequest = (data, oldData) => {
		return {
			newData: {
				revision: 0,
				data,
			},
			oldData,
			...kvaasResources.collapsedSettingsMessages,
		};
	};

	componentDidMount = async () => {
		this.props.showLoader(true);
		try {
			const getKvaas = kvaasService.get({ ...kvaasResources.portalFlags, throwError: true });
			const [flags] = await this.props.makePendingRequest(getKvaas, requestKeys.FETCH);
			this.props.showLoader(false);
			const newState = this.mapResponseToState(flags);
			newState.showEbtfsVoucherOption = !!flags.data.showEbtfsVoucherOption;

			this.setState(newState);
		} catch (e) {
			if (this.props.handleError(e)) {
				this.props.handleKvaasLoadError();
			}
		}
	};

	mapFieldsToState = (newState, oldData, type) => {
		const { data, result, error, refNum } = oldData;
		if (data && (toLower(result) === 's' || error === 'Item does not exist')) {
			if (!error) {
				newState.oldData[type] = {
					...oldData,
				};
			}

			each(data, (value, key) => {
				if (type === 'flags') {
					newState.portalFlags[key] = value;
				}
			});
		} else if (toLower(error) === 'invalid: revision' || toLower(error) === 'item exists. revision cannot be 0') {
			const errMessage = {
				isApiError: true,
				ref: refNum,
				message: error,
				success: false,
			};
			throw errMessage;
		}
	};

	mapResponseToState = (oldFlags = this.state.oldData.flags) => {
		const { portalFlags } = cloneDeep(this.state);
		const newState = {
			portalFlags,
			oldData: {},
		};
		this.mapFieldsToState(newState, oldFlags, 'flags');

		return newState;
	};

	mapStateToRequiredFields = async setToDefaults => [
		{
			newData: {
				revision: 0,
				data: setToDefaults ? this.initialFlags : this.state.portalFlags,
			},
			oldData: this.state.oldData.flags,
			...kvaasResources.portalFlags,
		},
	];

	handlePortalFlagsChange = ({ target: { name, checked } }) => {
		this.setState({
			portalFlags: {
				...this.state.portalFlags,
				[name]: checked,
			},
		});

		this.props.handleBlockChange(true);
	};

	handleRefreshData = async error => {
		let refreshErr = error;
		this.setState(this.initialState);
		try {
			const [flags] = await this.props.makePendingRequest(
				kvaasService.get(kvaasResources.portalFlags),
				requestKeys.KVAAS
			);

			const newState = this.mapResponseToState(flags);
			this.setState(newState);
			this.props.showLoader(false);
			this.props.handleBlockChange(false);
		} catch (e) {
			refreshErr = this.props.handleError(e, { delayMessage: true });
		}
		this.props.showLoader(false);
		return refreshErr;
	};

	save = async setToDefaults => {
		let refNum;
		let error;
		let refreshData = false;
		this.props.showLoader(true);
		const mappedState = await this.mapStateToRequiredFields(setToDefaults);

		try {
			const [flags] = await this.props.makePendingRequest(kvaasService.save(...mappedState), requestKeys.SAVE);
			refNum = flags.refNum;
			const newState = this.mapResponseToState(flags);

			if (setToDefaults && this.hasPermissions) {
				newState.portalFlags = this.initialFlags;
			}
			this.props.showLoader(false);
			this.setState(newState);
			this.props.handleBlockChange(false);
		} catch (e) {
			error = this.props.handleError(e, { delayMessage: true });
			if (error) {
				refreshData = true;
			} else {
				return;
			}
		}
		if (refreshData) error = await this.handleRefreshData(error);
		if (!error) {
			this.notification.current.addNotification({
				message: setToDefaults ? 'Additional settings reset to default' : 'Additional settings updated',
				ref: refNum,
				success: true,
			});
		} else {
			error.show();
		}
		invokeIfFunction(this.context.showDisputesTab, this.state.portalFlags.showDisputesTab);
	};

	render() {
		const {
			portalFlags: {
				multipleCapture,
				showEbtfsVoucherOption,
				showDisputesTab,
				includeSplitPay,
				displaySplitPayColumns,
			},
		} = this.state;

		const { handlePortalFlagsChange } = this;
		return (
			<div className="settings--main">
				<div className="settings__header">
					<h3 className="settings__title">Account Settings</h3>
					<h5>Additional Functionality</h5>
				</div>

				<div ref={this.top}></div>

				<div className="spc--bottom--lrg">
					<h4 className="type--p2 type--p2--medium spc--bottom--lrg">Cardknox Go</h4>

					<div className="spc--bottom--med">
						<input
							type="checkbox"
							id="showDisputesTab"
							name="showDisputesTab"
							checked={showDisputesTab}
							value={showDisputesTab}
							onChange={handlePortalFlagsChange}
							className="input--check"
						/>
						<label htmlFor="showDisputesTab">Show 'Disputes' tab</label>
					</div>
					{this.hasPermissionReportNet && (
						<Fragment>
							<div className="spc--bottom--med">
								<input
									type="checkbox"
									id="includeSplitPay"
									name="includeSplitPay"
									checked={includeSplitPay}
									value={includeSplitPay}
									onChange={handlePortalFlagsChange}
									className="input--check"
								/>
								<label htmlFor="includeSplitPay">Include Split Pay transactions</label>
							</div>
							<div>
								<input
									type="checkbox"
									id="displaySplitPayColumns"
									name="displaySplitPayColumns"
									checked={displaySplitPayColumns}
									value={displaySplitPayColumns}
									onChange={handlePortalFlagsChange}
									className="input--check"
								/>
								<label htmlFor="displaySplitPayColumns">Display Total Net Fee and Processing Fee in Transactions</label>
							</div>
						</Fragment>
					)}
				</div>

				<div>
					<h4 className="type--p2 type--p2--medium spc--bottom--lrg">Others</h4>

					<div className="spr__content">
						<div className="flex--primary flex--gap--tny spc--bottom--med">
							<div>
								<input
									type="checkbox"
									id="multipleCapture"
									name="multipleCapture"
									checked={multipleCapture}
									value={multipleCapture}
									onChange={this.handlePortalFlagsChange}
									className="input--check"
								/>
								<label htmlFor="multipleCapture">Enable Split Capture</label>
							</div>
							<div className="badge badge--success">Beta</div>
						</div>

						<div>
							<div className="flex--primary flex--gap--tny">
								<div>
									<input
										type="checkbox"
										id="showEbtfsVoucherOption"
										name="showEbtfsVoucherOption"
										checked={showEbtfsVoucherOption}
										value={showEbtfsVoucherOption}
										onChange={this.handlePortalFlagsChange}
										className="input--check"
									/>
									<label htmlFor="showEbtfsVoucherOption">Show EBTFS voucher</label>
								</div>
								<div className="badge badge--success">Beta</div>
							</div>
							<p className="type--p3 type--color--text--light spc--left--lrg spc--top--tny">
								(Show Ebtfs Voucher Transaction Type Option)
							</p>
						</div>
					</div>
				</div>

				<Notification ref={this.notification} />
				<FooterComponent disabled={this.props.isLoading} save={this.save} isLoading={this.props.isLoading} />
			</div>
		);
	}
}

UserSettingsAdditionalFunctionality.propTypes = {
	showLoader: PropTypes.func,
	isLoading: PropTypes.bool,
	handleBlockChange: PropTypes.func,
	handleError: PropTypes.func,
	makePendingRequest: PropTypes.func,
	handleKvaasLoadError: PropTypes.func,
};

export default withError(
	withLoader(withCancelable(withBlock(UserSettingsAdditionalFunctionality))),
	handleInvalidRevision
);
