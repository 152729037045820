import React, { Component, createRef, Fragment } from 'react';
import PropTypes from 'prop-types';

class PhoneFilterComponent extends Component {
	constructor(props) {
		super(props);
		this.inputRef = createRef();
	}

	componentDidUpdate() {
		const { noFocus } = this.props;
		if (this.inputRef.current && !noFocus) {
			this.inputRef.current.focus();
		}
	}

	handleChange = ({ target: { value } }) => {
		const {
			filter: { key },
			onFilterChanged,
		} = this.props;
		onFilterChanged({
			id: key,
			values: [{ key, value }],
			emptyValue: null,
		});
	};

	render() {
		const {
			filter: { key, values, name },
			noFocus,
			placeholder,
			goButtonHandler,
		} = this.props;

		return (
			<Fragment>
				<input
					type="tel"
					placeholder={placeholder || name}
					className="input input--med"
					value={values[key] === null ? '' : values[key]}
					id={key}
					onChange={this.handleChange}
					ref={this.inputRef}
					autoFocus={!noFocus}
				/>
				{goButtonHandler && (
					<button className="btn btn--med btn--primary" onClick={goButtonHandler}>
						Submit
					</button>
				)}
			</Fragment>
		);
	}
}

PhoneFilterComponent.propTypes = {
	filter: PropTypes.object.isRequired,
	onFilterChanged: PropTypes.func.isRequired,
	noFocus: PropTypes.bool,
	placeholder: PropTypes.string,
	goButtonHandler: PropTypes.func,
};

export default PhoneFilterComponent;
