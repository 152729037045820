import { map, noop } from 'lodash-es';
import moment from 'moment';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class YearMonth extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isMonthPopupOpen: false,
			isYearPopupOpen: false,
		};
	}

	openDateModal = () => {
		this.setState({
			isMonthPopupOpen: true,
		});
	};
	handleClosePopup = () => {
		this.setState({
			isMonthPopupOpen: false,
		});
	};
	handleMonthSelect = (month, yr) => {
		const { date } = this.props;
		let year = yr || date.getFullYear();
		if (month === 0) {
			year--;
			month = 12;
		}
		const formattedMonth = moment(`${year}-${month}-15`).toDate();
		this.props.dayPickerRef.showMonth(formattedMonth);
		this.handleClosePopup();
	};
	openYearModal = () => {
		return this.setState({ isYearPopupOpen: true });
	};

	handleYearSelect = year => {
		const { localeUtils } = this.props;

		const months = localeUtils.getMonths();
		const month =
			moment()
				.month(months[this.props.date.getMonth() - 1] || months[0])
				.month() + 1;

		const formattedYear = moment(`${year}-${month}-15`).toDate();
		this.props.dayPickerRef.setState({
			currentMonth: formattedYear,
		});
		this.setState({ isYearPopupOpen: false });
	};

	getYears = () => {
		const currentYear = new Date().getFullYear();
		const fromMonth = new Date(currentYear, 0);
		const toMonth = new Date(currentYear + 10, 11);
		const calendarYears = [];

		if (this.props.bottomBarrier) {
			this.mapCalendarYears(calendarYears, this.props.bottomBarrier, this.props.upperBarrier);
		} else {
			this.mapCalendarYears(calendarYears, fromMonth, toMonth);
		}
		return calendarYears;
	};

	mapCalendarYears = (calendarYears, bottomBarrier, upperBarrier) => {
		for (let i = bottomBarrier.getFullYear(); i <= upperBarrier.getFullYear(); i += 1) {
			calendarYears.push(i);
		}
	};

	getMonthIndex = month => {
		while (month.className !== 'DayPicker-Month') month = month.parentElement;
		const months = month.parentElement;
		return [...months.children].indexOf(month) || 0;
	};
	disablePrevMonth = (upperBarrier, bottomBarrier, date) => {
		const { singleDate } = this.props;
		if (singleDate) {
			return moment(date)
				.subtract(1, 'months')
				.isBefore(new Date(), 'months');
		}
		return (
			upperBarrier &&
			moment(date)
				.subtract(2, 'months')
				.isBefore(bottomBarrier, 'months')
		);
	};

	disableNextMonth = (upperBarrier, date) => {
		return (
			upperBarrier &&
			moment(date)
				.add(2, 'months')
				.isAfter(upperBarrier, 'months')
		);
	};

	render() {
		const { isMonthPopupOpen, isYearPopupOpen } = this.state;
		let { localeUtils, date, availableYears, bottomBarrier, upperBarrier } = this.props;
		const months = localeUtils.getMonths();

		return (
			<div className="DayPicker-Month-Header">
				<button
					className="btn btn--action btn--action--secondary"
					disabled={this.disablePrevMonth(upperBarrier, bottomBarrier, date)}
					onClick={() => this.props.dayPickerRef.showPreviousMonth(() => noop)}
					onMouseDown={e => e.preventDefault()}
				>
					<i className="icon icon--sml icon--chevron--left"></i>
				</button>

				<div className="flex--primary flex--gap--tny">
					<button
						className="btn btn--link btn--link--tertiary"
						onClick={this.openDateModal}
						onMouseDown={e => e.preventDefault()}
					>
						{localeUtils.formatMonthTitle(date).replace(/\d+/g, '')}
					</button>
					<button
						className="btn btn--link btn--link--tertiary"
						onClick={this.openYearModal}
						onMouseDown={e => e.preventDefault()}
					>
						{date.getFullYear()}
					</button>
				</div>

				<button
					className="btn btn--action btn--action--secondary"
					disabled={this.disableNextMonth(upperBarrier, bottomBarrier)}
					onClick={() => this.props.dayPickerRef.showNextMonth(() => noop)}
					onMouseDown={e => e.preventDefault()}
				>
					<i className="icon icon--sml icon--chevron--right"></i>
				</button>

				{isMonthPopupOpen && (
					<div className="DayPicker-month-list">
						{map(months, (m, i) => (
							<p
								key={m}
								onClick={e => this.handleMonthSelect(i + 1 - this.getMonthIndex(e.target))}
								onMouseDown={e => e.preventDefault()}
								className="item"
							>
								{m}
							</p>
						))}
					</div>
				)}
				{isYearPopupOpen && (
					<div className="DayPicker-month-list">
						{map(availableYears, year => (
							<p
								key={year}
								onClick={() => this.handleYearSelect(year)}
								className="item"
								onMouseDown={e => e.preventDefault()}
							>
								{year}
							</p>
						))}
					</div>
				)}
			</div>
		);
	}
}

YearMonth.propTypes = {
	dayPickerRef: PropTypes.any,
	localeUtils: PropTypes.any,
	bottomBarrier: PropTypes.any,
	upperBarrier: PropTypes.any,
	date: PropTypes.any,
	availableYears: PropTypes.array,
	singleDate: PropTypes.any,
};

export default YearMonth;
