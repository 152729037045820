import React, { Component, Fragment } from 'react';
import { object, func, bool, string, number, any, array, oneOfType } from 'prop-types';
import { find, isEmpty, map, replace, startCase, toLower, upperFirst } from 'lodash';

import { invokeIfFunction } from 'common/utilities';

const tabs = {
	manageLayout: 'manageLayout',
	generalSettings: 'generalSettings',
	internalSettings: 'internalSettings',
};

class PaymentSiteFormSetting extends Component {
	getWrapperClassName = (internalSetting, groupedSetting) => {
		return internalSetting || groupedSetting ? 'spc--bottom--lrg' : 'card spc--bottom--lrg';
	};
	renderSideMenuInput = ({ onChange, key, disableCheck, disabled }) => {
		const { entireTabA, entireTabB, entireTabC } = this.props.state;
		let value = this.props.state[`entire${replace(startCase(key), /\s/g, '')}`];
		if (toLower(key) === 'additionalaccounttabs') {
			value = entireTabA || entireTabB || entireTabC;
		}

		return (
			<div className="spc--right--tny">
				<input
					onChange={onChange}
					type="checkbox"
					className="input--check input--check--no-label"
					id={key}
					name={key}
					value={value}
					checked={value}
					disabled={disableCheck || invokeIfFunction(disabled)}
				/>
				<label htmlFor={key}></label>
			</div>
		);
	};
	getDisplayTab = formKey => {
		const { entireTabB, entireTabC, entireTabA } = this.props.state;
		if (formKey === 'tab_a') {
			return entireTabA;
		} else if (formKey === 'tab_b') {
			return entireTabB;
		} else if (formKey === 'tab_c') {
			return entireTabC;
		}
		return true;
	};

	displayExpanded = ({
		expanded,
		section,
		settingActive,
		internalSetting,
		groupedSetting,
		isTab,
		displayTab,
		formKey,
	}) => {
		return (
			(expanded[tabs[section]] &&
				(settingActive ||
					(isTab && displayTab) ||
					toLower(formKey) === 'paymentmethods' ||
					toLower(formKey) === 'allowedcommands')) ||
			internalSetting ||
			groupedSetting
		);
	};
	render() {
		const {
			renderComponent,
			toggleExpanded,
			formKey,
			tooltip,
			expanded,
			label,
			fields,
			sectionIndex,
			toggleRemoveSectionPopup,
			removeFormSetting,
			section,
			internalSetting,
			getFormSettingLabel,
			hideHeader,
			indent,
			groupedSetting,
			state,
			isTab,
		} = this.props;
		const { availableFormSettings } = state;
		let filterFormKey = formKey;
		const displayTab = this.getDisplayTab(formKey);
		const removeTooltip = internalSetting ? 'Clear' : 'Delete';
		const availableSetting = find(availableFormSettings, setting => toLower(setting.key) === toLower(filterFormKey));
		const isDisabled = availableSetting && invokeIfFunction(availableSetting.disabled);
		const availableTooltip = availableSetting && invokeIfFunction(availableSetting.tooltip);
		const wrapperClassName = this.getWrapperClassName(internalSetting, groupedSetting);
		const settingActive = this.props.state[`entire${upperFirst(formKey)}`];
		let displayTooltip = tooltip;
		if (availableTooltip && isDisabled) {
			displayTooltip = availableTooltip;
		}
		if (isTab && !displayTab) return null;

		return (
			<div className={wrapperClassName}>
				{!hideHeader && (
					<button
						className={`card__header card__header--expandable ${isDisabled ? 'is-disabled' : ''}`}
						onClick={() => toggleExpanded(formKey, section)}
					>
						<div className="flex--primary flex--gap--sml">
							{!isEmpty(availableSetting) && (
								<Fragment>{this.renderSideMenuInput(availableSetting, formKey, isDisabled)}</Fragment>
							)}
							<h5>{getFormSettingLabel(formKey, fields) || label}</h5>
							{displayTooltip && (
								<i className="icon icon--tny icon--regular--info datatooltip--w--150" data-tooltip={displayTooltip}></i>
							)}
						</div>
						<div className="card__header__actions">
							{isTab && (
								<Fragment>
									<button
										type="button"
										className="btn btn--icon btn--icon--lrg"
										onClick={() => toggleRemoveSectionPopup(() => removeFormSetting(formKey), label)}
										data-tooltip={removeTooltip}
									>
										<i className="icon icon--sml icon--delete--light"></i>
									</button>
									<div className="card__header__actions__separator"></div>
								</Fragment>
							)}
							{!internalSetting &&
								(settingActive ||
									(isTab && displayTab) ||
									toLower(formKey) === 'allowedcommands' ||
									toLower(formKey) === 'paymentmethods') && (
									<i
										data-tooltip={expanded[tabs[section]] ? 'Collapse' : 'Expand'}
										className={`icon icon--sml icon--chevron--${expanded[tabs[section]] ? 'top' : 'down'}--light`}
									></i>
								)}
						</div>
					</button>
				)}
				<div className="card__body card__body--general-settings">
					{this.displayExpanded({
						expanded,
						section,
						settingActive,
						internalSetting,
						groupedSetting,
						isTab,
						displayTab,
						formKey,
					}) && (
						<Fragment>
							{map(
								fields,
								(
									{ key, name, component, type, Tooltip, options, placeholder, isRequired, hideField, disabled, icon },
									fieldIndex
								) =>
									renderComponent({
										key,
										name,
										component,
										type,
										Tooltip,
										options,
										suffix: 1,
										placeholder,
										fieldIndex,
										sectionIndex,
										isRequired: invokeIfFunction(isRequired),
										hideField,
										disabled,
										icon,
										reduceSpacing: hideHeader,
										indent,
										isLastField: hideHeader && !fields[fieldIndex + 1],
										internalSetting: internalSetting,
									})
							)}
						</Fragment>
					)}
				</div>
			</div>
		);
	}
}

PaymentSiteFormSetting.propTypes = {
	renderComponent: func.isRequired,
	removeFormSetting: func.isRequired,
	toggleRemoveSectionPopup: func.isRequired,
	formComponent: func,
	toggleExpanded: func.isRequired,
	getFormSettingLabel: func,
	internalSetting: any,
	displayDelete: bool,
	hideHeader: bool,
	indent: bool,
	groupedSetting: bool,
	sectionIndex: number.isRequired,
	expanded: oneOfType([object, func]).isRequired,
	label: string.isRequired,
	formKey: string.isRequired,
	tooltip: string,
	section: string.isRequired,
	fields: array.isRequired,
	state: object.isRequired,
	isTab: bool,
};

export default PaymentSiteFormSetting;
