import React, { Component, Fragment, createRef } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { findIndex, each, toLower, get } from 'lodash';

import { OutsideClick } from '../../../utilities';
import { portalManagementService, principalService } from 'common/services';
import { withError } from 'common/components/error';
import { withCancelable } from 'common/components/cancelable';
import { Modal } from 'common/components/modal';
import PortalManagementUserEventsGrid from 'components/portal-management/PortalManagementUserEventsGrid';
import LogViewer from 'common/components/logmanagement/log-viewer';
import { modalNames } from 'common/components/transaction-actions';

class PortalManagementActionsComponent extends Component {
	constructor(props) {
		super(props);

		PortalManagementActionsComponent.instances.push(this);

		this.state = {
			isDisplayMenuOpen: false,
			isOpenUserEventsPopup: false,
			isOpenUserLogsPopup: false,
			isOpenResetMfaPopup: false,
			principal: principalService.get(),
		};

		this.popupRef = createRef();
		this.logsPopupRef = createRef();
	}

	static instances = [];

	componentDidUpdate = prevProps => {
		if (prevProps.isScrolling !== this.props.isScrolling) {
			this.closeDisplayMenu();
		}
	};

	componentWillUnmount() {
		const index = findIndex(PortalManagementActionsComponent.instances, this);
		if (index > -1) {
			PortalManagementActionsComponent.instances.splice(index, 1);
		}
	}

	confirmUser = async () => {
		const {
			dependentValues: { username, refreshGridData, showLoader, notificationRef },
			makePendingRequest,
			handleError,
		} = this.props;
		try {
			showLoader(true);
			this.closeDisplayMenu();
			const { result, refNum: ref } = await makePendingRequest(portalManagementService.confirmUser({ username }));
			notificationRef.addNotification({
				success: toLower(result) === 's',
				ref,
				message: `${username} successfully confirmed`,
			});
			refreshGridData();
			showLoader(false);
		} catch (e) {
			showLoader(false);
			handleError(e);
		}
	};

	resendConfirmationCode = async () => {
		const {
			dependentValues: { username, showLoader, notificationRef },
			makePendingRequest,
			handleError,
		} = this.props;
		try {
			showLoader(true);
			this.closeDisplayMenu();
			const { result, refNum: ref } = await makePendingRequest(
				portalManagementService.resendConfirmationCode({ username })
			);
			notificationRef.addNotification({
				success: toLower(result) === 's',
				ref,
				message: `Confirmation code successfully sent to ${username}`,
			});
			showLoader(false);
		} catch (e) {
			showLoader(false);
			handleError(e);
		}
	};

	resetMfa = async () => {
		const {
			dependentValues: { username, notificationRef, onDelete },
			makePendingRequest,
			handleError,
		} = this.props;

		try {
			const { result, refNum: ref, error } = await makePendingRequest(portalManagementService.resetMfa({ username }));

			let success = toLower(result) === 's';
			notificationRef.addNotification({
				success,
				ref,
				message: success
					? `Successfully reset MFA settings for ${username}`
					: `Failed to reset MFA settings for ${username} (${error})`,
			});

			onDelete(username);
		} catch (e) {
			handleError(e);
		}
	};

	displayMenu = () => {
		const { openActions, status } = this.props.dependentValues;
		const { isDisplayMenuOpen } = this.state;

		const actions = isDisplayMenuOpen ? (
			<OutsideClick action={this.closeDisplayMenu} className="popover">
				<ul className="popover__list">
					{toLower(status) === 'unconfirmed' && (
						<Fragment>
							<li className="item">
								<button className="btn btn--link btn--link--tertiary" onClick={this.resendConfirmationCode}>
									Resend Confirmation Code
								</button>
							</li>
							<li className="item">
								<button className="btn btn--link btn--link--tertiary" onClick={this.confirmUser}>
									Confirm User
								</button>
							</li>
						</Fragment>
					)}
				</ul>
			</OutsideClick>
		) : null;
		const rect = get(this, 'info', null) && this.info.getBoundingClientRect();
		if (!rect) return;
		openActions(
			{
				width: (rect.left || 0) + (rect.width * -1 || 0),
				height: (rect.top || 0) + 40,
			},
			actions
		);
		if (isDisplayMenuOpen) {
			each(PortalManagementActionsComponent.instances, instance => {
				if (instance.state.isDisplayMenuOpen && instance !== this) {
					instance.setState({ isDisplayMenuOpen: false });
				}
			});
		}
	};

	openDisplayMenu = () => {
		this.setState({ isDisplayMenuOpen: true }, this.displayMenu);
	};

	closeDisplayMenu = () => {
		setTimeout(() => this.setState({ isDisplayMenuOpen: false }, this.displayMenu), 50);
	};

	closeUserEventsPopup = () => {
		this.setState({ isOpenUserEventsPopup: !this.state.isOpenUserEventsPopup });
	};

	openUserEventsPopup = () => {
		this.setState({ isOpenUserEventsPopup: true, isDisplayMenuOpen: false }, this.displayMenu);
	};

	openUserLogsPopup = () => {
		this.setState({ isOpenUserLogsPopup: true, isDisplayMenuOpen: false }, this.displayMenu);
	};

	openResetMfaPopup = () => {
		const {
			dependentValues: { username, openCloseModal },
		} = this.props;

		if (!openCloseModal) return;
		openCloseModal({
			name: modalNames.confirmAction,
			data: {
				loadingMessage: 'Reset MFA',
				question: (
					<div>
						<h4 className="spc--bottom--med">Reset MFA</h4>
						<p>
							Are you sure you want to <span className="type--wgt--medium">reset MFA</span> settings for{' '}
							<span className="type--wgt--medium">{username}</span>?
						</p>
						<p>This action cannot be undone.</p>
					</div>
				),
				onConfirm: this.resetMfa,
			},
		});
	};

	closeUserLogsPopup = () => {
		this.setState({ isOpenUserLogsPopup: !this.state.isOpenUserLogsPopup });
	};

	renderUserEventsPopup = () => {
		const { isOpenUserEventsPopup } = this.state;
		const {
			dependentValues: { username },
		} = this.props;

		return (
			<Modal
				isOpen={isOpenUserEventsPopup}
				onClose={this.closeUserEventsPopup}
				shouldCloseOnOverlayClick={false}
				overlayClassName="modal__overlay"
				className="modal__content modal--big"
			>
				<div ref={this.popupRef}>
					<div className="modal__header">
						<h4>User Events</h4>
					</div>
					<div className="modal__body">
						<PortalManagementUserEventsGrid username={username} popupRef={this.popupRef} />
					</div>
					<div className="modal__footer"></div>
				</div>
			</Modal>
		);
	};

	renderUserLogsPopup = () => {
		const { isOpenUserLogsPopup } = this.state;
		const {
			dependentValues: { username },
		} = this.props;

		return (
			<Modal
				isOpen={isOpenUserLogsPopup}
				onClose={this.closeUserLogsPopup}
				shouldCloseOnOverlayClick={false}
				overlayClassName="modal__overlay"
				className="modal__content modal--big"
			>
				<div ref={this.logsPopupRef}>
					<div className="modal__header">
						<h4>User Logs</h4>
					</div>
					<div className="modal__body">
						<LogViewer username={username} popupRef={this.logsPopupRef} />
					</div>
					<div className="modal__footer"></div>
				</div>
			</Modal>
		);
	};

	render() {
		const { isDisplayMenuOpen } = this.state;
		const { status } = this.props.dependentValues;
		const isUnconfirmed = toLower(status) === 'unconfirmed';
		return (
			<div className="flex--primary flex--gap--med flex--nowrap flex--right">
				{this.renderUserEventsPopup()}
				{this.renderUserLogsPopup()}
				<button
					className="btn btn--link datatooltip--left datatooltip--auto datatooltip--no-wrap"
					onClick={this.openResetMfaPopup}
					data-tooltip="Reset MFA"
				>
					<i className="icon icon--sml icon--reload--light"></i>
				</button>
				<button
					className="btn btn--link datatooltip--left datatooltip--auto datatooltip--no-wrap"
					onClick={this.openUserLogsPopup}
					data-tooltip="User Logs"
				>
					<i className="icon icon--sml icon--code--light"></i>
				</button>
				<button
					className="btn btn--link datatooltip--left datatooltip--auto datatooltip--no-wrap"
					onClick={this.openUserEventsPopup}
					data-tooltip="User Events"
				>
					<i className="icon icon--sml icon--calendar--light"></i>
				</button>
				{isUnconfirmed && (
					<button ref={el => (this.info = el)} onClick={this.openDisplayMenu} className="btn btn--link">
						<i className={`icon icon--sml icon--more--${isDisplayMenuOpen ? 'primary' : 'light'}`}></i>
					</button>
				)}
			</div>
		);
	}
}

PortalManagementActionsComponent.propTypes = {
	dependentValues: PropTypes.object,
	isScrolling: PropTypes.bool,
	history: PropTypes.object.isRequired,
	handleError: PropTypes.func.isRequired,
};

export default withRouter(withError(withCancelable(PortalManagementActionsComponent)));
