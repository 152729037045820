import React, { Component } from 'react';
import { bool, oneOfType, string } from 'prop-types';
import { isBoolean, toLower } from 'lodash';

class ActiveComponent extends Component {
	constructor(props) {
		super(props);
	}

	render = () => {
		const { value } = this.props;
		const isActive = isBoolean(value) ? value : toLower(value) === 'true';

		const icon = isActive ? 'badge badge--start-icon badge--success' : 'badge badge--start-icon badge--error';
		const statusText = isActive ? 'Active' : 'Inactive';
		return <div className={icon}>{statusText}</div>;
	};
}

ActiveComponent.propTypes = {
	value: oneOfType([bool, string]),
};

export default ActiveComponent;
