import React from 'react';
import { object } from 'prop-types';
import { withRouter } from 'react-router-dom';

function DigitalWallet(props) {
	return (
		<div className="">
			<p className="spc--bottom--sml">
				Streamline Checkout with Digital Wallets. Enable Google Pay and Apple Pay on PaymentSITE hosted checkout pages
				to make checkout easier than ever.
			</p>
			<div className="spc--bottom--sml--alt">
				<button
					className="btn btn--sml btn--primary"
					onClick={() =>
						props.history.replace({
							pathname: '/features/digital-wallet',
							hasPaymentSites: true,
						})
					}
				>
					Activate
				</button>
				<button
					className="btn btn--sml btn--secondary spc--left--tny"
					onClick={() =>
						window.open(
							'https://www.cardknox.com/paymentsite/?utm_source=portal&utm_medium=banner&utm_campaign=cs#wallets',
							'_blank',
							'noopener'
						)
					}
				>
					Learn More
				</button>
			</div>
		</div>
	);
}
DigitalWallet.propTypes = {
	history: object.isRequired,
};
export default withRouter(DigitalWallet);
