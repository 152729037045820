import React from 'react';

const RoutingNumberTooltipComponent = props => {
	return (
		<i {...props} className="icon icon--tny icon--regular--info spc--left--tny newtransaction__tooltip__trigger">
			<span className="newtransaction__tooltip">
				<div className="newtransaction__tooltip__image routing"></div>
				<p>
					Your routing number or ABA number is the nine-digit code number of your bank, located at the bottom left on
					your bank checks. The ABA number is EXACTLY 9 digits long.
				</p>
			</span>
		</i>
	);
};

export default RoutingNumberTooltipComponent;
