import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map, findIndex } from 'lodash';

class SelectFilterComponent extends Component {
	handleChange = ({ target: { name, checked } }) => {
		const {
			filter: { key, values },
			isMulti,
			onFilterChanged,
		} = this.props;
		let newValues;

		if (isMulti) {
			newValues = map(values, (value, key) => ({
				key,
				value,
			}));

			const objIndex = findIndex(newValues, { key: name });

			newValues[objIndex] = {
				...newValues[objIndex],
				value: checked,
			};
		} else {
			newValues = [{ key: name, value: checked }];
		}

		onFilterChanged({
			id: key,
			values: newValues,
			emptyValue: false,
		});
	};

	render() {
		const {
			isMulti,
			filter: { key, values, checkbox, permissions: filterPermissions, options, disabled = {} },
			permissions,
			injectedFilters,
			goButtonHandler,
		} = this.props;
		return (
			<div className="spc--top--sml">
				{map(values, (checked, optionKey) => {
					const id = `${key}-${optionKey}`;
					return (
						<div key={id} className="spc--bottom--sml">
							<input
								disabled={
									(filterPermissions &&
										filterPermissions[optionKey] &&
										(!permissions || !permissions[filterPermissions[optionKey]])) ||
									(disabled && disabled[optionKey] && disabled[optionKey](injectedFilters))
								}
								type={isMulti || checkbox ? 'checkbox' : 'radio'}
								name={optionKey}
								className={`input input--${isMulti || checkbox ? 'check' : 'radio'}`}
								checked={checked}
								value={optionKey}
								id={id}
								onChange={this.handleChange}
							/>
							<label htmlFor={id}>{options[optionKey]}</label>
						</div>
					);
				})}
				{goButtonHandler && (
					<button className="btn btn--med btn--primary spc--bottom--xsml" onClick={goButtonHandler}>
						Submit
					</button>
				)}
			</div>
		);
	}
}

SelectFilterComponent.propTypes = {
	filter: PropTypes.object.isRequired,
	onFilterChanged: PropTypes.func.isRequired,
	isMulti: PropTypes.bool,
	permissions: PropTypes.object,
	injectedFilters: PropTypes.object,
	goButtonHandler: PropTypes.func,
};

export default SelectFilterComponent;
