import React from 'react';
import { compact } from 'lodash';
import { emailTemplates } from 'common/utilities';

const features = [
	{
		templateId: 'giftCard',
		path: 'gift-card',
		title: 'Cardknox Gift',
		details: 'Get started with our Cardknox Gift Card Program to issue, track, and redeem gift cards.',
		advancedDetails: {
			title: 'Cardknox Gift',
			subtitle: 'Get Started With Our Cardknox Gift Card Program',
			learnMoreLink: 'https://www.cardknox.com/gift?utm_source=portal&utm_medium=banner&utm_campaign=cs',
			details: () => (
				<p>
					Don't miss out! Use our gift card program to issue, track, and redeem gift cards.
					<br />
					<br />
					The Cardknox gift card program is available for just $0.25 per transaction and no monthly fee. After
					activation, you will receive an email which contains 250 gift card numbers.{' '}
					<span className="type--wgt--medium">
						<br />
						<br />
						Please{' '}
						<a
							className="btn btn--link btn--link--underline"
							rel="noopener noreferrer"
							target="_blank"
							href="https://www.cardknox.com/gift/instructions"
						>
							read these instructions
						</a>{' '}
						on how to get started using Cardknox gift cards and save the link for future reference.
					</span>
				</p>
			),
		},
		question:
			'Please confirm you would like to enable Cardknox Gift on your account.\nThe cost is $0.25 Per Transaction.',
		successMessage:
			'Your request has been submitted. You will receive an email confirmation with the requested Gift Card numbers once Cardknox Gift has been setup.',
	},
	{
		templateId: 'vp3300',
		path: 'vp3300',
		title: 'ID TECH VP3300 Card Reader',
		details:
			'Boost your revenue with mobile payments! Order our sleek and compact card reader — the ID TECH VP3300 — to conveniently accept payments on the go.',
		advancedDetails: {
			title: 'ID TECH VP3300 Card Reader',
			subtitle: 'Boost Your Revenue with Mobile Payments',
			learnMoreLink: 'https://www.cardknox.com/vp3300-integration/?utm_source=portal&utm_medium=banner&utm_campaign=cs',
			details:
				'Our sleek and compact card reader, the ID TECH VP3300, is compatible with the Cardknox Mobile App and offers a convenient and secure way to accept payments on the go. Quickly connect via Bluetooth to start processing faster. The VP3300 mobile card reader is available for $150 and will ship to the address on file.',
		},
		question:
			'Please confirm you would like to order the ID TECH VP3300 mobile card reader for $150.\n This device will be shipped to the address on file.',
		successMessage: 'Thanks for your order. Your device will be shipped shortly.',
		activateLabel: 'Order Now',
	},
	{
		templateId: 'accountupdater',
		path: 'account-updater',
		title: 'Account Updater',
		details: "Reduce declines -- get automatic updates on your customer's expired and inactive cards.",
		advancedDetails: {
			title: 'Account Updater',
			subtitle: 'Get automatic updates on expired and inactive cards.',
			learnMoreLink: 'https://www.cardknox.com/account-updater/',
			details:
				'When a card is declined, Account Updater automatically obtains the updated card information, stores it on file, and applies it to future recurring payments.\n\n Account Updater is $0.25 per card update. No setup fee or monthly fees.\n\n The failed transaction will not be reprocessed automatically unless it is associated with a recurring schedule that has retry attempts enabled.',
		},
		question: 'Are you sure you want to enable Account Updater? \nThe cost is $0.25 per card update.',
		successMessage:
			'Your request has been submitted. You will receive an email confirmation once Account Updater has been set up.',
	},
	{
		templateId: emailTemplates.paymentSite,
		title: 'PaymentSITE',
		path: 'payment-site-feature',
		details:
			'This customizable hosted checkout page can be shared with customers via email or SMS to collect payment, or it can be embedded into an existing webpage.',
		advancedDetails: {
			title: 'PaymentSITE',
			subtitle: 'Collect online payments with ease',
			learnMoreLink: 'https://www.cardknox.com/paymentsite/',
			details:
				'PaymentSITE is our secure, user-friendly solution for sending digital invoices and creating secure online checkout pages.\n Once your PaymentSITE is activated, you’ll be able to customize it in the Settings module. \n	You’ll also have access to the Send Payment Request module for sending out email and SMS invoices.\n\n There is a one-time setup fee of $99.99 plus $19.99 per month.',
		},
		question:
			'Are you sure you want to request a PaymentSITE? \nThere is a one-time setup fee of $99.99 plus $19.99 per month.',
		successMessage:
			'Your request has been submitted. You will receive an email confirmation once your PaymentSITE has been set up.',
	},
	{
		templateId: 'taptophoneandroid',
		title: 'Tap to Phone - Android',
		path: 'tap-to-phone-android-feature',
		details:
			'With Tap to Phone - Android, merchants can turn their Android Phones or Tablets into contactless payment terminals which will allow them to accept secure payments anywhere.',
		content: '',
		advancedDetails: {
			title: 'Tap to Phone - Android',
			subtitle: 'Turn your Android device into a payment terminal',
			learnMoreLink: 'https://www.cardknox.com/tap-to-phone/?utm_source=portal&utm_medium=banner&utm_campaign=cs',
			details:
				'Tap to Phone - Android is our innovative solution that transforms Android Phones or Tablets into contactless payment terminals. \nOnce activated, you can accept secure payments anywhere. Please note, this will add an additional cost of $0.15 per transaction on top of your regular transaction fee.',
		},
		question:
			'Are you sure you want to enable Tap to Phone - Android on your account?\n Please note, this will add an additional cost of $0.15 per transaction on top of your regular transaction fee.',
		successMessage:
			'Your request has been submitted. You will receive an email confirmation once your Tap to Phone - Android has been set up.',
	},
];

export default compact(features);
