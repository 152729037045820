import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { filter, keys, get, camelCase } from 'lodash';
import FormGroupPreview from 'common/components/form-group-preview/FormGroupPreview';

const CustomerPreview = ({ data, customDisplayLabels, customerHiddenFields, isCanadian }) => {
	const [sections, setSections] = useState({
		general: true,
		billing: true,
		shipping: true,
		custom: true,
	});

	const toggleSection = section => {
		setSections(prevSections => ({
			...prevSections,
			[section]: !prevSections[section],
		}));
	};

	const getLabel = (key, defaultLabel) => get(customDisplayLabels, key, defaultLabel);
	const getValue = key => get(data, key, '---');

	const zipLabel = isCanadian ? 'Postal Code' : 'ZIP';
	const stateLabel = isCanadian ? 'Province' : 'State';

	const getFields = (isBill = false, fieldsToInclude = [], order = [], previewConfig = {}, halfwidthConfig = {}) => {
		const baseFields = [
			{ key: 'FirstName', label: 'First Name' },
			{ key: 'LastName', label: 'Last Name' },
			{ key: 'Company', label: 'Company' },
			{ key: 'Phone', label: 'Phone Number' },
			{ key: 'Street', label: 'Address 1' },
			{ key: 'Street2', label: 'Address 2' },
			{ key: 'City', label: 'City' },
			{ key: 'State', label: stateLabel },
			{ key: 'Zip', label: zipLabel },
			{ key: 'Mobile', label: 'Mobile Phone Number' },
		];

		let filteredFields = baseFields
			.filter(field => fieldsToInclude.length === 0 || fieldsToInclude.includes(field.key))
			.map(field => {
				const baseConfig = {
					previewField: previewConfig[field.key] !== undefined ? previewConfig[field.key] : field.previewField,
					halfwidth: halfwidthConfig[field.key] !== undefined ? halfwidthConfig[field.key] : field.halfwidthField,
				};
				return {
					...field,
					key: isBill ? `bill${field.key}` : `ship${field.key}`,
					...baseConfig,
				};
			});

		if (order.length > 0) {
			const orderMap = new Map(order.map((key, index) => [key, index]));
			filteredFields.sort(
				(a, b) =>
					(orderMap.get(a.key.replace(/^(bill|ship)/, '')) || 0) -
					(orderMap.get(b.key.replace(/^(bill|ship)/, '')) || 0)
			);
		}

		return filteredFields;
	};

	const previewConfig = {
		Company: true,
		FirstName: true,
		LastName: true,
		Phone: true,
	};

	const halfwidthConfig = {
		FirstName: true,
		LastName: true,
	};

	const generalFieldsOrder = ['Company', 'FirstName', 'LastName', 'Phone'];
	const generalFields = getFields(
		true,
		['Company', 'FirstName', 'LastName', 'Phone'],
		generalFieldsOrder,
		previewConfig,
		halfwidthConfig
	);

	const fieldsToRender = fields => (
		<div className="f-row">
			{fields.map(({ key, label, previewField, halfwidth }) => {
				if (!customerHiddenFields[key]) {
					const value = getValue(camelCase(key));
					const customLabel = getLabel(key, label);
					return (
						<div key={key} className={`f-col f-col-sml-12 f-col-med-${halfwidth === true ? '6' : '12'}`}>
							<FormGroupPreview label={customLabel} value={value} previewField={previewField} />
						</div>
					);
				}
				return null;
			})}
		</div>
	);

	return (
		<Fragment>
			<button className="info-panel__heading__wrapper is-expandable" onClick={() => toggleSection('general')}>
				<h6 className="info-panel__heading">General Information</h6>
				<i className={`icon icon--sml icon--chevron--${sections.general ? 'top' : 'down'}--primary`}></i>
			</button>
			{sections.general && (
				<div className="info-panel__section">
					<FormGroupPreview previewField={true} label="Customer #" value={getValue('customerNumber')} />
					{fieldsToRender(generalFields)}
				</div>
			)}

			<button className="info-panel__heading__wrapper is-expandable" onClick={() => toggleSection('billing')}>
				<h6 className="info-panel__heading">Billing Information</h6>
				<i className={`icon icon--sml icon--chevron--${sections.billing ? 'top' : 'down'}--primary`}></i>
			</button>
			{sections.billing && <div className="info-panel__section">{fieldsToRender(getFields(true))}</div>}

			<button className="info-panel__heading__wrapper is-expandable" onClick={() => toggleSection('shipping')}>
				<h6 className="info-panel__heading">Shipping Information</h6>
				<i className={`icon icon--sml icon--chevron--${sections.shipping ? 'top' : 'down'}--primary`}></i>
			</button>
			{sections.shipping && <div className="info-panel__section">{fieldsToRender(getFields(true))}</div>}

			<button className="info-panel__heading__wrapper is-expandable" onClick={() => toggleSection('custom')}>
				<h6 className="info-panel__heading">Custom Fields</h6>
				<i className={`icon icon--sml icon--chevron--${sections.custom ? 'top' : 'down'}--primary`}></i>
			</button>
			{sections.custom && (
				<div className="info-panel__section">
					<div className="spc--top--sml--alt">
						{mapStateToCustomFields(data, customDisplayLabels, customerHiddenFields)}
					</div>
				</div>
			)}
		</Fragment>
	);
};

const mapStateToCustomFields = (data, customDisplayLabels, customerHiddenFields) => {
	const visibleCustomFields = get(data, '_meta.visibleCustomFields', {});
	return filter(keys(visibleCustomFields), key => visibleCustomFields[key])
		.map(key => {
			const number = key.slice(-2);
			const parsedNumber = parseFloat(number);
			if (customerHiddenFields[`custom${parsedNumber}`] || parsedNumber === 1) return null;
			const value = data[key] || '---';
			const label = get(customDisplayLabels, `custom${parsedNumber}`, `Custom${number}`);
			return <FormGroupPreview key={key} label={label} value={value} previewField={false} />;
		})
		.filter(field => field !== null);
};

CustomerPreview.propTypes = {
	data: PropTypes.object.isRequired,
	customDisplayLabels: PropTypes.object.isRequired,
	customerHiddenFields: PropTypes.object.isRequired,
	isCanadian: PropTypes.bool,
};

export default CustomerPreview;
