import React, { Component, forwardRef, Fragment } from 'react';
import PropTypes from 'prop-types';

import ConfirmMFAComponent from './../../../components/login/confirm-mfa';
import { principalService } from './../../../Common/services';
import { nth } from 'lodash';

function withStepup(WrappedComponent, componentKey, bypassStepupInitially) {
	class StepupComponent extends Component {
		principal = principalService.get();

		constructor(props) {
			super(props);
			const bypassStepup = !!props.bypassStepup;
			this.state = {
				verified: false,
				bypassStepup,
				isSamlLogin: this.principal.isSamlLogin,
			};
		}

		setBypass = shouldBypass => {
			this.setState({ bypassStepup: shouldBypass });
		};

		markAsVerified = () => {
			this.setState({ verified: true });
		};

		render() {
			const { verified, isSamlLogin, bypassStepup } = this.state;
			const { forwardedRef, ...rest } = this.props;

			return (
				<React.Fragment>
					{!verified &&
					!isSamlLogin &&
					!bypassStepup &&
					nth(window.location.pathname.split('/', 2), -1) !== 'security' ? (
						<Fragment>
							<ConfirmMFAComponent
								markAsVerified={this.markAsVerified}
								isStepup={true}
								componentKey={componentKey}
							></ConfirmMFAComponent>
							<div className="modal__footer"></div>
						</Fragment>
					) : (
						<WrappedComponent ref={forwardedRef} setBypass={this.setBypass} {...rest} />
					)}
				</React.Fragment>
			);
		}
	}
	StepupComponent.propTypes = {
		forwardedRef: PropTypes.any,
		bypassStepup: PropTypes.bool,
	};
	return forwardRef((props, ref) => (
		<StepupComponent {...props} forwardedRef={ref} bypassStepup={!!bypassStepupInitially} />
	));
}

export default withStepup;
