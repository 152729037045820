import React from 'react';
import { map } from 'lodash';

import { StringFilter, MultiselectCardknoxFilter, NumericFilter } from '../../columns/filters';
import { CurrencyComponent } from '../../columns/formatters';
import TemplateActionsComponent from '../../columns/formatters/template-actions';
import { ColumnHeader } from 'common/components/columns/headers';

const columns = [
	{
		key: 'xScheduleName',
		name: 'Schedule Name',
		sortable: true,
		hideable: false,
		visible: true,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 150,
		resizable: true,
		order: 1,
	},
	{
		key: 'xDescription',
		name: 'Description',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 300,
		customSettingsKey: 'description',
		resizable: true,
	},
	{
		key: 'amount',
		name: 'Amount',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: NumericFilter,
		formatter: CurrencyComponent,
		dependentExportKey: 'currency',
		getRowMetaData: row => {
			return { ...row, displayBadge: true };
		},
		initWidth: 126,
		customSettingsKey: 'amount',
		resizable: true,
		alignHeaderRight: true,
	},
	{
		key: 'frequency',
		name: 'Frequency',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: MultiselectCardknoxFilter,
		getRowMetaData: row => row,
		initWidth: 160,
		customSettingsKey: 'frequency',
		resizable: true,
	},
	{
		key: 'templateActions',
		name: '',
		sortable: false,
		hideable: false,
		visible: true,
		filterable: false,
		formatter: TemplateActionsComponent,
		getRowMetaData: row => row,
		fixWidth: true,
		initWidth: 110,
		hideOnExport: true,
	},
	{
		key: 'currency',
		name: 'Currency',
		sortable: true,
		hideable: false,
		visible: false,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 75,
		resizable: true,
	},
	{
		key: 'xKey',
		name: 'Key',
		sortable: true,
		hideable: false,
		visible: false,
		filterable: true,
		filterRenderer: StringFilter,
		isDefaultSorter: true,
		defaultSortDirection: 'DESC',
		initWidth: 170,
		customSettingsKey: 'key',
		resizable: true,
		hideOnExport: true,
	},
];

export const RecurringTemplatesColumns = map(columns, column => {
	column = {
		headerRenderer: <ColumnHeader />,
		...column,
	};
	return column;
});
