import moment from 'moment';
import {
	cloneDeep,
	each,
	isEmpty,
	upperFirst,
	camelCase,
	toNumber,
	toUpper,
	startCase,
	sortBy,
	keys,
	includes,
	toLower,
	find,
	head,
	filter,
	round,
	size,
} from 'lodash';

import { transactionService, customerService, giftService, transferService } from './../../services';
import { apiToDisplay, CurrencyMap } from './../../utilities';
import { compileFilter as compileTransactionFilter } from './../transactions/filter/transactionsFilter';
import { compileFilter as compileTransferFilter } from '../transfers/filter/transfersFilter';
import { compileFilter as compileDisputeFilter } from './../disputes/filter/disputesFilter';
import { compileFilter as compileCustomerFilter } from './../customers/filter/customersFilter';
import { compileFilter as compileScheduleFilter } from './../recurring-schedules/filter/recurringSchedulesFilter';
import { compileFilter as compileFraudFilter } from './../transactions/filter/fraudFilter';
import { compileFilter as compileGiftActivityFilter } from './../gift-report/filter/giftActivityFilter';
import { compileFilter as compileGiftCardLiabilityFilter } from './../gift-report/filter/giftLiabilityFilter';
import { compileFilter as compileGiftCardSummaryFilter } from './../gift-report/filter/giftSummaryFilter';

class ExportService {
	shouldSubtract = command => toLower(command) === 'gift:issue';
	isCommandGiftRedeem = command => toLower(command) === 'gift:redeem';
	getTransformedAmount = (xCommand, xAmount) => {
		return this.shouldSubtract(xCommand) ? -Math.abs(xAmount) : Math.abs(xAmount);
	};
	getTransactionData = async (
		activeFilters,
		type,
		totalLabel,
		{ exportProcessingFee, exportNetSale } = { exportProcessingFee: false, exportNetSale: false },
		fields = null
	) => {
		const filters = cloneDeep(activeFilters);
		const compileFilter = type === 'disputes' ? compileDisputeFilter : compileTransactionFilter;
		const newFilter = await compileFilter(filters, ApplicationSettings.apiDateTimeFormat);
		let data = await transactionService.filterTransactionsAll(newFilter, fields);
		if (data.xReportData) {
			data = data.xReportData;
			this.mapTransactionData(data, { exportProcessingFee, exportNetSale }, totalLabel);
			return data;
		} else {
			return [];
		}
	};
	getRecurringTransactionData = async (
		activeFilters,
		_,
		{ exportProcessingFee, exportNetSale },
		totalLabel,
		fields = null
	) => {
		const scheduleIdFilter = find(activeFilters, { key: 'scheduleId' });
		const scheduleId = scheduleIdFilter.values.scheduleId;
		let data = await customerService.getRecurringTransactionsAll(scheduleId, null, fields);
		if (data.xReportData) {
			data = data.xReportData;
			this.mapTransactionData(data, { exportProcessingFee, exportNetSale }, totalLabel);
			return data;
		} else {
			return [];
		}
	};
	getTransferData = async (activeFilters, fields = null) => {
		const filters = cloneDeep(activeFilters);
		const compileFilter = compileTransferFilter;
		const newFilter = await compileFilter(filters, ApplicationSettings.apiDateTimeFormat);
		let data = await transferService.filterTransactionsAll(newFilter, fields);
		if (data.xReportData) {
			data = data.xReportData;
			this.mapData(data);
			return data;
		} else {
			return [];
		}
	};

	mapTransactionData = (data, { exportProcessingFee, exportNetSale }, totalLabel, selectedCurrency) => {
		if (isEmpty(data)) return;
		const cardBreakdown = this.getAmountsByCardType(data);
		const grandTotal = this.getGrandTotalAmounts(
			data,
			{ exportProcessingFee, exportNetSale },
			totalLabel,
			selectedCurrency
		);
		data.push(...cardBreakdown, ...grandTotal);
		this.mapData(data);
	};

	mapTransferData = (data, totalLabel, selectedCurrency) => {
		if (isEmpty(data)) return;
		const grandTotal = this.getGrandTotalAmounts(data, {}, totalLabel, selectedCurrency);
		data.push(...grandTotal);
		this.mapData(data);
	};

	mapData = data => {
		for (let item of data) {
			if (item.xEnteredDate) {
				item.xEnteredDate = moment(item.xEnteredDate, ApplicationSettings.apiResponseDateTimeFormat).format(
					ApplicationSettings.apiResponseDateTimeFormat
				);
			}
			if (item.xMaskedCardNumber && item.xMaskedCardNumber.includes('xxx')) {
				item.xMaskedCardNumber = `**** ${item.xMaskedCardNumber.slice(-4)}`;
			}

			if (item.xStatus) {
				const isCheck = includes(toLower(item.xCommand), 'check');
				if (!isCheck) {
					item.xStatus = '';
				}
			}

			this.mapMatchedColumn(item);

			item.xVoid = item.xVoid === '1' ? 'Voided' : '';
		}
	};

	mapMatchedColumn = item => {
		each(['xAmount', 'netSale', 'xProcessingFee'], column => {
			let matchedColumn = item[column];
			if (matchedColumn !== undefined) {
				item[column] = this.roundNumber(matchedColumn);
			}
		});
	};

	formatGrandTotal = (data, grandTotal, exportNetSale, exportProcessingFee) => {
		each(data, ({ xAmount, netSale, xProcessingFee, currency, xCommand }) => {
			let amount = this.isCommandGiftRedeem(xCommand) ? Math.abs(xAmount) : xAmount;
			if (this.shouldSubtract(xCommand)) {
				amount = -Math.abs(xAmount);
			}
			const mappedCurrency = this.mappedCurrency(currency);

			if (!grandTotal.Amount[mappedCurrency]) {
				grandTotal.Amount[mappedCurrency] = 0;
			}
			if (exportNetSale && !grandTotal['Net sale'][mappedCurrency]) {
				grandTotal['Net sale'][mappedCurrency] = 0;
			}
			if (exportProcessingFee && !grandTotal['Processing fee'][mappedCurrency]) {
				grandTotal['Processing fee'][mappedCurrency] = 0;
			}

			grandTotal.Amount[mappedCurrency] += parseFloat(amount);
			if (exportProcessingFee) {
				grandTotal['Processing fee'][mappedCurrency] += parseFloat(xProcessingFee);
			}
			if (exportNetSale) {
				grandTotal['Net sale'][mappedCurrency] += parseFloat(netSale);
			}
		});
	};

	formatResultByGrandTotal = (grandTotal, result) => {
		each(grandTotal, (obj, objKey) => {
			const row = {};
			let refNum = objKey;
			each(obj, (value, currencyKey) => {
				refNum += ` ${CurrencyMap.resolveCurrency(currencyKey)}${this.roundNumber(value)} ${currencyKey}`;
			});
			row.xRefNum = refNum;
			result.push(row);
		});
	};

	getGrandTotalAmounts = (data, { exportProcessingFee, exportNetSale }, totalLabel, selectedCurrency) => {
		let result = [{ xRefNum: 'Grand total:' }];
		let grandTotal = { Amount: {} };
		if (!exportProcessingFee && !exportNetSale && totalLabel) {
			head(result).xRefNum = totalLabel;
			if (!isEmpty(selectedCurrency)) {
				const { amount, label, symbol } = selectedCurrency;
				result.push({ xRefNum: `Amount ${symbol}${this.roundNumber(amount)} ${label}` });
				return result;
			}
		}

		if (exportProcessingFee) {
			grandTotal['Processing fee'] = {};
		}
		if (exportNetSale) {
			grandTotal['Net sale'] = {};
		}
		if (totalLabel && totalLabel === 'Total Approved:') {
			data = filter(data, item => item.xResponseResult === 'Approved');
		}

		this.formatGrandTotal(data, grandTotal, exportNetSale, exportProcessingFee);

		this.formatResultByGrandTotal(grandTotal, result);

		return result;
	};

	setTotalsByCardType = (totals, amount, cardType, mappedCurrency) => {
		if (!totals[cardType]) {
			totals[cardType] = {};
		}
		if (!totals[cardType][upperFirst(mappedCurrency)]) {
			totals[cardType][upperFirst(mappedCurrency)] = {};
		}
		if (!isEmpty(totals[cardType][upperFirst(mappedCurrency)])) {
			totals[cardType][upperFirst(mappedCurrency)].count++;
			totals[cardType][upperFirst(mappedCurrency)].amount += round(toNumber(amount), 2);
		} else {
			totals[cardType][upperFirst(mappedCurrency)].count = 1;
			totals[cardType][upperFirst(mappedCurrency)].amount = toNumber(amount);
			totals[cardType][upperFirst(mappedCurrency)].currency = mappedCurrency;
		}
		totals[cardType][upperFirst(mappedCurrency)].amount = toNumber(
			round(totals[cardType][upperFirst(mappedCurrency)].amount, 2)
		);
	};
	getAmountsByCardType = data => {
		const totals = {};
		const result = [];
		const sorted = {};

		each(data, item => {
			const { xAmount, xCardType, currency, xCommand } = item;
			let amount = xAmount;
			if (this.shouldSubtract(xCommand)) {
				amount = -Math.abs(xAmount);
			}
			if (this.isCommandGiftRedeem(xCommand)) {
				amount = Math.abs(xAmount);
			}
			item.xAmount = amount;
			const cardType = camelCase(xCardType);
			const mappedCurrency = this.mappedCurrency(currency);
			this.setTotalsByCardType(totals, amount, cardType, mappedCurrency);
		});

		each(sortBy(keys(totals)), item => (sorted[item] = totals[item]));

		each(sorted, (cardType, cardTypeKey) => {
			const row = {};
			let refNum = `${startCase(cardTypeKey)}:`;
			each(cardType, ({ amount, count, currency }) => {
				refNum += ` ${CurrencyMap.resolveCurrency(currency) + amount} ${currency} (count: ${count})`;
			});
			row.xRefNum = refNum;
			result.push(row);
		});
		result.unshift({ xRefNum: 'Total by card:' });
		result.push({});

		return result;
	};

	mappedCurrency = currency => CurrencyMap.isoCodesMap[currency] || toUpper(currency);

	roundNumber = value => round(parseFloat(value), 2);

	getFraudData = async (activeFilters, fields = null) => {
		const filters = cloneDeep(activeFilters);
		const newFilter = await compileFraudFilter(filters, ApplicationSettings.apiDateTimeFormat);
		newFilter.xCommand = 'Report:All';
		const defaultFields = transactionService.getFraudFields();
		const fraudFields = `${defaultFields},${fields}`;
		let data = await transactionService.filterTransactionsAll(newFilter, fraudFields);
		if (data.xReportData) {
			data = data.xReportData;
			this.mapFraudData(data);
			return data;
		} else {
			return [];
		}
	};

	mapFraudData = data => {
		if (size(data)) {
			for (let item of data) {
				if (item.xAmount && !includes(toLower(item.xCommand), 'redeem')) {
					item.xAmount = this.getTransformedAmount(item.xCommand, item.xAmount);
				}
				if (item.xEnteredDate) {
					item.xEnteredDate = moment(item.xEnteredDate, ApplicationSettings.apiResponseDateTimeFormat).format(
						ApplicationSettings.apiResponseDateTimeFormat
					);
					if (item && item.xMaskedCardNumber && item.xMaskedCardNumber.includes('xxx')) {
						item.xMaskedCardNumber = item.xMaskedCardNumber.slice(-4);
					}
				}
			}
		}
	};

	getGiftActivityData = async (activeFilters, fields = null) => {
		const filters = cloneDeep(activeFilters);
		const newFilter = await compileGiftActivityFilter(filters, ApplicationSettings.apiDateTimeFormat);
		newFilter.xCommand = 'Report:GiftApproved';
		let data = await transactionService.filterTransactionsAll(newFilter, fields);
		if (data.xReportData) {
			data = data.xReportData;
			this.mapGiftActivityData(data);
			return data;
		} else {
			return [];
		}
	};

	mapGiftActivityData = data => {
		if (data && data.length > 0) {
			for (let item of data) {
				if (item.xAmount) {
					item.xAmount = this.getTransformedAmount(item.xCommand, item.xAmount);
				}
				if (item.xEnteredDate) {
					item.xEnteredDate = moment(item.xEnteredDate, ApplicationSettings.apiResponseDateTimeFormat).format(
						ApplicationSettings.apiResponseDateTimeFormat
					);
					if (item && item.xMaskedCardNumber && item.xMaskedCardNumber.includes('xxx')) {
						item.xMaskedCardNumber = item.xMaskedCardNumber.replace(/x/g, '');
					}
				}
			}
		}
	};

	getScheduleData = async activeFilters => {
		const filters = cloneDeep(activeFilters);
		const newFilter = await compileScheduleFilter(filters, ApplicationSettings.apiDateTimeFormat);
		newFilter.xCommand = 'Report:Schedules';
		let data = await customerService.filterRecurringSchedulesRequest(newFilter);
		if (data.xReportData) {
			data = data.xReportData;
			this.mapScheduleData(data);
			return data;
		} else {
			return [];
		}
	};

	mapScheduleData = data => {
		if (data && data.length > 0) {
			for (let item of data) {
				if (item.xStartDate) {
					item.xStartDate = apiToDisplay(item.xStartDate);
				}
				if (item.xIntervalType) {
					item.xIntervalType = `Every ${item.xIntervalCount} ${item.xIntervalType}${
						item.xIntervalCount > 1 ? 's' : ''
					}`;
				}
				if (item.xEnteredDate) {
					item.xEnteredDate = moment(item.xEnteredDate, ApplicationSettings.apiShortDateTimeFormat).format(
						ApplicationSettings.displayShortDateTimeFormat
					);
				}
			}
		}
	};

	getCustomerData = async activeFilters => {
		const filters = cloneDeep(activeFilters);
		const newFilter = await compileCustomerFilter(filters, ApplicationSettings.apiDateTimeFormat);
		newFilter.xCommand = 'Report:Customers';
		let data = await customerService.filterCustomersRequest(newFilter);
		if (data.xReportData) {
			data = data.xReportData;
			this.mapCustomerData(data);
			return data;
		} else {
			return [];
		}
	};

	mapCustomerData = data => {
		if (data && data.length > 0) {
			for (let item of data) {
				if (!item.recurringSchedule) {
					item.recurringSchedule = '';
				}

				const temp = item.paymentMethodExpiry;
				item.paymentMethodExpiry = item.paymentMethod;
				item.paymentMethod = temp;
			}
		}
	};

	getGiftCardSummaryData = async (activeFilters, fields = null) => {
		const filters = cloneDeep(activeFilters);
		const newFilter = await compileGiftCardSummaryFilter(filters, ApplicationSettings.apiDateTimeFormat);
		let data = await giftService.filterGiftRequest(
			newFilter,
			undefined,
			fields,
			giftService.parseGiftCardSummaryResult
		);
		if (data.xReportData) {
			data = data.xReportData;
			this.mapGiftCardSummaryData(data);
			return data;
		} else {
			return [];
		}
	};

	mapGiftCardSummaryData = () => {
		return;
	};

	getGiftCardLiabilityData = async (activeFilters, fields = null) => {
		const filters = cloneDeep(activeFilters);
		const newFilter = compileGiftCardLiabilityFilter(filters, ApplicationSettings.apiDateTimeFormat);
		let data = await giftService.filterGiftRequest(
			newFilter,
			undefined,
			fields,
			giftService.parseGiftCardLiabilityResult
		);
		if (data.xReportData) {
			data = data.xReportData;
			this.mapGiftCardLiabilityData(data);
			return data;
		} else {
			return [];
		}
	};

	mapGiftCardLiabilityData = () => {
		return;
	};

	mapUserData = () => {
		return;
	};

	mapPaymentSiteData = data => {
		each(data, ({ path }, index) => {
			data[index].path = `${ApplicationSettings.paymentSiteUrl}${path}`;
		});
	};

	mapPortalManagementUsersData = () => {
		return;
	};

	mapUploadCustomerData = () => {};
}

const exportService = new ExportService();

export { exportService };
