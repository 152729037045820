import React from 'react';
import {
	sortBy,
	toLower,
	isEmpty,
	head,
	find,
	eachRight,
	some,
	each,
	camelCase,
	transform,
	get,
	replace,
	includes,
	endsWith,
} from 'lodash';
import { Auth } from 'aws-amplify';
import moment from 'moment';
import { parse } from 'query-string';

import httpService from './httpService';
import principalService from './principalService';
import kvaasService from './kvaasService';
import keyManagementService from './keyManagementService';
import { history, kvaasResources, logSuccessfulLogin, parseError } from '../utilities';
import updateApiEndpoint from 'common/utilities/UpdateApiEndpoint';

const { loginEndpoint: endpoint, newPortalEndpoint, oldPortalEndpoint, cognitoEndpoint } = ApplicationSettings;

const automatedTestingAccounts = [
	'icosic012@gmail.com',
	'dsuhic+batchestest@mono.software',
	'dsuhic+batchestest2@mono.software',
	'dsuhic+batchestest3@mono.software',
	'dsuhic+customerstest@mono.software',
	'dsuhic+quickreportstest@mono.software',
	'dsuhic+quickreportstest2@mono.software',
	'dsuhic+quickreportstest3@mono.software',
	'dsuhic+settingstest@mono.software',
	'dsuhic+settingstest2@mono.software',
	'dsuhic+settingstest3@mono.software',
	'automationtests@cardknox.com',
	'aikidoscanner@gmail.com',
];

const newline = '%0D%0A%0A';
const contactUsHref = (userEmail, stack = '', toEmail = 'cs@solapayments.com') =>
	`mailto:${toEmail}?subject=portal.cardknox.com - Login Issues from ${userEmail}&body=Hi there,${newline}I'm unable to log into my portal.cardknox.com account.${newline}Please assist.${newline}${
		stack ? `Error: ${stack}${newline}` : ''
	}All the best,${newline}`;

class AuthenticationService {
	constructor(httpService, principalService, kvaasService) {
		this.httpService = httpService;
		this.principalService = principalService;
		this.kvaasService = kvaasService;
	}

	cognitoHeaders = xAmzTarget => {
		let headers = new Headers();

		headers.set('Authority', 'cognito-idp.us-west-2.amazonaws.com');
		headers.set('Content-Type', 'application/x-amz-json-1.1');
		headers.set('x-amz-target', `AWSCognitoIdentityProviderService.${xAmzTarget}`);
		headers.set('x-amz-user-agent', 'aws-amplify/0.1.x js');

		return headers;
	};

	cognitoOptions = xAmzTarget => {
		return {
			isJson: true,
			noInit: true,
			headers: this.cognitoHeaders(xAmzTarget),
		};
	};

	redirectToNewPortal = () => {
		window.location.href = replace(window.location.href, oldPortalEndpoint, newPortalEndpoint);
	};

	getUserDataAsync = user => {
		return new Promise((resolve, reject) => {
			user.getUserData(
				(err, data) => {
					if (err) {
						reject(err);
					}
					resolve(data);
				},
				{ bypassCache: true }
			);
		});
	};

	async getValidAndInvalidKeys(keys) {
		const invalidKeys = [];
		for (let index in keys) {
			const { key, mid } = keys[index];
			let idInfo;
			try {
				idInfo = await this.validateKey(key, mid);
				if (idInfo && toLower(idInfo.xStatus) === 'success') {
					return { validKey: idInfo, invalidKeys };
				}
			} catch (e) {
				idInfo = e;
			}
			invalidKeys.push({
				idInfo,
				index,
			});
		}
		return { validKey: undefined, invalidKeys };
	}

	async login(token, userEmail, savePrincipal = true) {
		// Authenticate
		let auth;
		let userSettings;
		let redirectToSecurity = false;
		let isSamlLogin = false;

		try {
			let user = await Auth.currentAuthenticatedUser();

			if (
				user &&
				user.attributes &&
				user.attributes.identities &&
				includes(toLower(user.attributes.identities), '"providertype":"saml"')
			) {
				isSamlLogin = true;
			}

			const { PreferredMfaSetting } = await this.getUserDataAsync(user);
			await this.principalService.emailPromise;
			[[userSettings], auth] = await Promise.all([
				this.kvaasService.get(kvaasResources.userSettings),
				this.authenticate(token),
			]);

			const redirectToNewPortal = get(userSettings, 'data.redirectToNewPortal');
			redirectToSecurity =
				!isSamlLogin &&
				(!PreferredMfaSetting || PreferredMfaSetting === 'NOMFA') &&
				!includes(automatedTestingAccounts, userEmail);

			if (
				!redirectToSecurity &&
				redirectToNewPortal &&
				(newPortalEndpoint && !endsWith(window.location.host, newPortalEndpoint))
			) {
				document.cookie = `${AppBuildEnvironment}RedirectToNewPortal=true;expires=${moment
					.utc()
					.add(1, 'minute')
					.format('ddd, DD MMM YYYY HH:mm:ss')};domain=${
					AppBuildEnvironment !== 'local' ? 'solapayments.com' : LocalHost
				}`;
				this.redirectToNewPortal();
				await new Promise(() => {});
			}
		} catch (e) {
			auth = e;
		}
		if (auth && toLower(auth.xStatus) === 'success') {
			if (!isEmpty(auth.xResultData)) {
				const { validKey, invalidKeys } = await this.getValidAndInvalidKeys(auth.xResultData);
				const validKeys = auth.xResultData.slice();
				eachRight(invalidKeys, ({ index }) => {
					validKeys.splice(index, 1);
				});
				if (!isEmpty(validKeys)) {
					const firstAccount = head(validKeys);
					if (savePrincipal) {
						principalService.set({
							redirectToSecurity,
							isSamlLogin,
							id: firstAccount.mid,
							companyName: firstAccount.dba_alias || firstAccount.dba,
							list: sortBy(validKeys, ({ sortorder, dba_alias, dba }) => sortorder || toLower(dba_alias || dba)),
							idInfo: validKey,
							validatedActiveKeys: false,
						});
						// Log successful login when mid is set
						await logSuccessfulLogin(userEmail);
						return {
							success: true,
							redirectToTerms: !validKey.acceptedTermsAndConditions,
							redirectToSecurity: redirectToSecurity,
							isSamlLogin: isSamlLogin,
							isDefaultKey: isEmpty(invalidKeys),
							allKeys: auth.xResultData,
						};
					} else {
						return {
							success: true,
							redirectToTerms: !validKey.acceptedTermsAndConditions,
							principal: {
								id: firstAccount.mid,
								companyName: firstAccount.dba_alias || firstAccount.dba,
								list: sortBy(validKeys, ({ sortorder, dba_alias, dba }) => sortorder || toLower(dba_alias || dba)),
								redirectToSecurity: redirectToSecurity,
								isSamlLogin: isSamlLogin,
								idInfo: validKey,
							},
						};
					}
				} else {
					const error = head(invalidKeys).idInfo;
					const { message, stack } = parseError(error);
					return this.createLoginResponse(
						false,
						error && error.ref,
						<div>
							{message ? `${message}. ` : null}Please{' '}
							<a className="btn btn--link" href={contactUsHref(userEmail, stack, 'gatewaysupport@solapayments.com')}>
								contact customer service.
							</a>
						</div>
					);
				}
			} else {
				return this.createLoginResponse(
					false,
					auth && auth.xRefNum,
					<div>
						Email address does not have access to a Sola account. Please reach out to your account owner to add you to
						the account.
					</div>
				);
			}
		} else {
			const { message, stack } = parseError(auth);
			return this.createLoginResponse(
				false,
				auth && auth.ref,
				<div>
					{message || 'There was a problem logging you in'}. Please{' '}
					<a className="btn btn--link" href={contactUsHref(userEmail, stack)}>
						contact customer service.
					</a>
				</div>
			);
		}
	}

	refreshKeys = async (token, email) => {
		const { id, idInfo } = this.principalService.get();
		const response = await this.login(token, email, false);
		if (response && response.success) {
			const newPrincipal = response.principal;
			const activeUser = find(newPrincipal.list, ({ mid }) => id === mid);
			if (!activeUser) {
				return;
			}
			const { dba, dba_alias } = activeUser;
			principalService.set({
				id,
				companyName: dba_alias || dba,
				list: newPrincipal.list,
				idInfo,
			});
			// Log successful login when mid is set
			await logSuccessfulLogin(email);
		}
	};

	createLoginResponse = (isSuccess, ref = null, message = null) => {
		if (isSuccess) {
			return { success: true };
		} else {
			return {
				message: message,
				ref: ref,
				success: isSuccess,
			};
		}
	};

	async authenticate(token) {
		let headers = new Headers();
		headers.set('Authorization', token);
		const options = {
			headers: headers,
			isJson: true,
			noInit: true,
		};
		const result = await this.httpService.post(endpoint + 'listaccounts', undefined, options);
		const dropinDBA = get(parse(window.location.search), 'dropinDBA');
		const lowerDropInDBA = dropinDBA === undefined ? undefined : toLower(dropinDBA);

		// Sort accounts
		if (result && result.xResultData && result.xResultData.length > 0) {
			// convert sort order to number
			for (let item of result.xResultData) {
				try {
					item.sortorder = parseInt(item.sortorder) || 0;
				} catch (e) {
					//intentionally empty catch block
				}
			}
			result.xResultData = sortBy(result.xResultData, [
				({ dba }) => lowerDropInDBA !== toLower(dba),
				({ sortorder }) => !!sortorder && sortorder,
				({ dba_alias, dba }) => toLower(dba_alias || dba),
			]);
		}
		return result;
	}

	async addToCardknoxGateway(token, userSub) {
		let headers = new Headers();
		headers.set('Authorization', token);
		const options = {
			headers: headers,
			isJson: true,
			noInit: true,
		};

		const body = {
			rangekey: userSub,
			revision: '0',
			data: {
				dba: 'CardKnox Development',
				key: 'ps_demo_cardknox',
				role: 'Admin',
				sortorder: '0',
			},
		};
		const result = await this.httpService.post(endpoint + 'link', body, options);

		if (result.xStatus === 'Success') {
			return result.xResultData;
		}
		return null;
	}

	save = async ({ dba, dba_alias, mid, revision, role, sortorder }, email) => {
		const options = {
			isJson: true,
			noInit: true,
		};
		const body = {
			email,
			data: {
				dba,
				dba_alias,
				mid,
				role,
				sortorder,
			},
			revision,
		};
		const result = await this.httpService.post(endpoint + 'update', body, options);

		if (result && result.xStatus === 'Success') {
			return result;
		}
		return null;
	};

	getSsoVersion = async token => {
		let headers = new Headers();
		headers.set('Authorization', token);
		const options = {
			headers: headers,
			isJson: true,
			noInit: true,
		};
		return await this.httpService.post(endpoint + 'listaccounts', undefined, options);
	};

	getUser = async (logoutOnUnauthenticated = true) => {
		return Auth.currentAuthenticatedUser()
			.then(user => {
				return user;
			})
			.catch(() => {
				if (logoutOnUnauthenticated) {
					let wasAuthenticated = false;
					if (this.principalService.get()) {
						this.principalService.clear();
						wasAuthenticated = true;
					}
					history.push({
						pathname: '/login',
						state: { inactivity: wasAuthenticated },
					});
				}
				return null;
			});
	};

	getUserData = async AccessToken =>
		this.httpService.post(`${cognitoEndpoint}GetUser`, { AccessToken }, this.cognitoOptions('GetUser'));

	verifyUserAttribute = async (accessToken, attributeName, code) => {
		const data = {
			AccessToken: accessToken,
			AttributeName: attributeName,
			Code: code,
		};

		return this.httpService.post(
			`${cognitoEndpoint}VerifyUserAttribute`,
			data,
			this.cognitoOptions('VerifyUserAttribute')
		);
	};

	getUserAttributeVerificationCode = async (accessToken, attributeName) => {
		const data = {
			AccessToken: accessToken,
			AttributeName: attributeName,
		};

		return this.httpService.post(
			`${cognitoEndpoint}GetUserAttributeVerificationCode`,
			data,
			this.cognitoOptions('GetUserAttributeVerificationCode')
		);
	};

	logout = async () => {
		localStorage.removeItem('hasPaymentSites');
		localStorage.removeItem('isProPay');

		Object.keys(localStorage).forEach(k => {
			if (k.startsWith('mfa-')) localStorage.removeItem(k);
		});

		return Auth.signOut()
			.then(() => {
				this.principalService.clear();
				return true;
			})
			.catch(() => {
				this.principalService.clear();
				return false;
			});
	};

	validateGatewayKey = async (mid, key) => {
		const data = {};
		data.xCommand = 'oos:validate';
		data.xOOS = 8;
		data.xSupports64bitrefnum = true;
		let options = {};
		if (mid) {
			options = { headers: new Headers() };
			options.headers.set('x-merchant-id', mid);
		} else {
			data.xKey = key; // for api-keys
			options.noAuthHeader = true;
		}
		const response = await this.httpService.post(updateApiEndpoint('gateway'), data, options);

		return response;
	};

	validateKey = async (id, mid) => {
		const [response, [userSettings], account] = await Promise.all([
			this.validateGatewayKey(mid, id),
			this.kvaasService.get(kvaasResources.userSettings),
			keyManagementService.load(mid, id),
		]);

		if (toLower(response && response.xStatus) === 'success') {
			// Convert string bools to bools
			for (const key of Object.keys(response)) {
				if (toLower(response[key]) === 'true') {
					response[key] = true;
				} else if (toLower(response[key]) === 'false') {
					response[key] = false;
				}
			}
			response.userType = 'merchant';
			const { userType } = response;
			response.acceptedTermsAndConditions =
				userSettings &&
				((userSettings.error && toLower(userSettings.error) !== 'item does not exist') ||
					some(userSettings.data, (_, verKey) => verKey === `${TermsAndConditions[userType]}.${userType}`));
		}

		if (account && toLower(account.result) === 'success') {
			const permissions = transform(account.data, (acc, value, key) => {
				acc[camelCase(key)] = value;
			});
			each(permissions, (value, key) => {
				const val = toLower(value);
				if (val === 'true') {
					permissions[key] = true;
				} else if (val === 'false') {
					permissions[key] = false;
				}
			});
			response.permissions = permissions;
		} else {
			response.permissions = {};
		}

		return response;
	};
}

const authenticationService = new AuthenticationService(httpService, principalService, kvaasService);

export default authenticationService;
