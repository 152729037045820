import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { has } from 'lodash';
import TooltipFormatter from './TooltipFormatter';

class StatusComponent extends TooltipFormatter {
	static instances = [];

	constructor(props) {
		super(props, StatusComponent.instances);
		this.tooltipClass = 'react-grid__tooltip';
		this.badgeType = props.badgeType || '';
	}

	get tooltip() {
		const {
			dependentValues: { xLastTransactionError, xResponseError, xAttempts, xRetryAmount, lastTransactionError },
		} = this.props;
		return (
			<Fragment>
				{xAttempts > 0
					? `${xAttempts}${this.getSuffix(xAttempts)} of ${xRetryAmount} retries`
					: xLastTransactionError || xResponseError || lastTransactionError}
			</Fragment>
		);
	}

	get statusClasses() {
		return {
			Approved: `badge ${this.badgeType} badge--success`,
			Declined: `badge ${this.badgeType} badge--error`,
			Pending: `badge ${this.badgeType} badge--default`,
			Error: `badge ${this.badgeType} badge--error`,
			Verify: `badge ${this.badgeType} badge--default`,
		};
	}

	getStyle = () => {
		const { dependentValues: { xLastTransactionStatus } = {}, value } = this.props;
		const status =
			xLastTransactionStatus === 'Declined' || xLastTransactionStatus === 'Declined/Retrying' ? 'Declined' : value;
		if (has(this.statusClasses, status)) {
			return this.statusClasses[status];
		} else {
			return 'type--status type--status--verify';
		}
	};

	getSuffix = n => {
		const parsed = parseInt(n);
		if (!parsed) {
			return '';
		}
		const ones = parsed % 10;
		const tens = Math.floor(parsed / 10) % 10;
		if (tens === 1) {
			return 'th';
		}
		switch (ones) {
			case 1:
				return 'st';
			case 2:
				return 'nd';
			case 3:
				return 'rd';
			default:
				return 'th';
		}
	};

	displayErrorPopup = () => this.displayWarningIcon() && this.displayPopup();

	closeErrorPopup = () => this.displayWarningIcon() && this.closePopup();

	displayWarningIcon = () => {
		const {
			dependentValues: {
				xLastTransactionError,
				xLastTransactionStatus,
				lastTransactionStatus,
				xResponseError,
				xResponseResult,
				xAttempts,
				xRetryAmount,
			} = {},
		} = this.props;

		return (
			((xLastTransactionError || xResponseError) && (xAttempts > 0 && xAttempts < xRetryAmount)) ||
			xLastTransactionStatus === 'Error' ||
			xResponseResult === 'Error' ||
			lastTransactionStatus === 'Error'
		);
	};

	render = () => {
		const { value, dependentValues: { xAttempts, xRetryAmount } = {}, error } = this.props;
		return value ? (
			<span
				ref={this.elementRef}
				className={this.getStyle()}
				onMouseEnter={this.displayErrorPopup}
				onMouseLeave={this.closeErrorPopup}
			>
				{xRetryAmount && xAttempts === xRetryAmount ? 'Declined' : xAttempts > 0 ? 'Declined/Retrying' : value}
				{error && error !== 'Declined' ? `: ${error}` : null}
			</span>
		) : null;
	};
}

export default StatusComponent;

StatusComponent.propTypes = {
	value: PropTypes.string,
	dependentValues: PropTypes.shape({
		xLastTransactionError: PropTypes.string,
		xResponseError: PropTypes.string,
	}),
	error: PropTypes.string,
};
