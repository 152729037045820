import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { filter, find, each, map, replace, startCase, isPlainObject, isObject, get } from 'lodash';

import { customerService, principalService } from 'common/services';
import { UNEXPECTED_ERROR_MSG } from 'components/error';
import ActionsComponent from './actions';
import { modalNames } from 'common/components/transaction-actions/modal-names';
import { hasFeaturePackage } from 'common/utilities';
import { featurePackages, featurePackageTooltips } from 'common/utilities/has-feature-package';
import sectionKeys from '../../../../routing/sections';

class RecurringActions extends Component {
	constructor(props) {
		super(props);
	}

	get actions() {
		const {
			row,
			row: { deleteAction },
		} = this.props;

		const principal = principalService.get();
		const isViewOnly = get(principal, 'isViewOnly', false);
		const noPermissions = !principal.hasAccess[sectionKeys.newTransaction];
		const hasTerminalOnly = hasFeaturePackage(featurePackages.terminalOnly);
		const disabled = hasTerminalOnly || noPermissions;
		const noPermissionsTooltip = "You don't have permissions";
		const tooltip = hasTerminalOnly
			? featurePackageTooltips.hasTerminalOnly
			: noPermissions
			? noPermissionsTooltip
			: null;

		return isViewOnly
			? []
			: [
					{
						className: 'btn btn--link btn--sml',
						action: () => this.openNewTransactionModal(row),
						tooltip: tooltip || 'New transaction',
						key: 'newTransaction',
						icon: 'newtransaction',
						componentProps: {
							disabled,
						},
						section: sectionKeys.newTransaction,
					},
					{
						className: 'btn btn--link btn--sml',
						action: () => this.sendPaymentRequest(row),
						tooltip: tooltip || 'Send payment request',
						key: 'sendPaymentRequest',
						icon: 'send-email--grey',
						componentProps: {
							disabled,
						},
						section: [sectionKeys.newTransaction],
					},
					{
						className: 'btn btn--link btn--sml',
						action: () =>
							deleteAction.key === 'deleteCustomer'
								? this.openDeleteCustomerModal(row)
								: this.openDeleteScheduleModal(row),
						tooltip: deleteAction.tooltip,
						key: deleteAction.key,
						icon: 'delete',
					},
			  ];
	}

	openNewTransactionModal = async row => {
		try {
			row.showLoader(true);
			if (!row.defaultPaymentMethodId) {
				const customer = await customerService.getCustomer(row.customerId);
				row.defaultPaymentMethodId = get(customer, 'xReportData[0].defaultPaymentMethodId');
			}
			const paymentMethods = await customerService.getCustomerPaymentMethods(
				row.customerId,
				row.defaultPaymentMethodId
			);

			const defaultPayment = find(paymentMethods.xReportData, { isDefaultPaymentMethod: true });
			const mappedRow = this.prefixKeysWithX(row);
			mappedRow.xZip = defaultPayment ? defaultPayment.zip : null;
			mappedRow.xStreet = defaultPayment ? defaultPayment.street : null;
			row.openCloseModal({
				name: modalNames.newTransaction,
				data: {
					customer: mappedRow,
					paymentMethods: filter(
						map(paymentMethods.xReportData, this.prefixKeysWithX),
						payment => !!payment.xPaymentMethodId
					),
					existingTransaction: null,
					refreshGridData: row.refreshGridData,
				},
			});
		} catch (e) {
			row.handleError(e);
		}
		row.showLoader(false);
	};

	deactivateActiveSchedules = async schedules =>
		Promise.all(
			map(schedules, ({ scheduleId, isActive }) => {
				if (isActive) {
					return customerService.activateCustomerRecurringSchedule(scheduleId, false);
				}
			})
		);

	deleteCustomer = async row => {
		try {
			row.showLoader(true);
			const schedules = await customerService.getCustomerRecurringSchedules(row.customerId);
			const existingSchedules = filter(schedules.xReportData, ({ scheduleId }) => scheduleId);
			await this.deactivateActiveSchedules(existingSchedules);
			const response = await customerService.deleteCustomer(row.customerId);
			row.showLoader(false);
			return response;
		} catch (e) {
			row.showLoader(false);
			throw e;
		}
	};

	deleteSchedule = async row => {
		try {
			row.showLoader(true);
			const response = await customerService.deleteCustomerRecurringSchedule(row.scheduleId);
			row.showLoader(false);
			return response;
		} catch (e) {
			row.showLoader(false);
			row.handleError(e);
		}
	};

	openDeleteCustomerModal = row => {
		row.openCloseModal({
			name: modalNames.confirmAction,
			data: {
				loadingMessage: 'Deleting Customer',
				modalClassName: 'modal__content modal--sml',
				question: (
					<div>
						<p className="spc--bottom--sml">
							When a Customer is deleted, all associated recurring schedules (including those that are active) are
							deleted as well.
						</p>
						<p className="type--wgt--bold spc--bottom--sml">This action cannot be undone.</p>
						<p>Are you sure you want to delete the selected Customer?</p>
					</div>
				),
				onConfirm: () => this.deleteCustomer(row),
				notificationHandler: rsp => ({
					ref: rsp && (rsp.refNum || rsp.ref),
					message:
						rsp && rsp.result === 'S'
							? 'Customer deleted'
							: (rsp && (rsp.message || rsp.error)) || UNEXPECTED_ERROR_MSG,
					success: rsp && rsp.result === 'S',
					onClose: row.refreshGridData,
				}),
			},
		});
	};

	openDeleteScheduleModal = row => {
		row.openCloseModal({
			name: modalNames.confirmAction,
			data: {
				loadingMessage: 'Deleting Recurring Schedule',
				question: `Are you sure you want to delete schedule ${row.scheduleName || row.scheduleId}?`,
				onConfirm: () => this.deleteSchedule(row),
				notificationHandler: rsp => ({
					ref: rsp && (rsp.refNum || rsp.ref),
					message:
						rsp && rsp.success
							? 'Recurring schedule deleted'
							: (rsp && (rsp.message || rsp.error)) || UNEXPECTED_ERROR_MSG,
					success: rsp && rsp.success,
					onClose: row.refreshGridData,
				}),
			},
		});
	};

	sendPaymentRequest = async row => {
		const customer = {};
		each(row, (value, key) => {
			if (!isObject(value)) {
				customer[key] = value;
			}
		});
		row.history.push({
			pathname: '/send-payment-request',
			state: { customer },
		});
	};

	prefixKeysWithX = obj => {
		let result = [];
		if (isPlainObject(obj)) {
			result = {};
			each(obj, (value, key) => {
				if (!isObject(value)) {
					result[`x${replace(startCase(key), /\s/g, '')}`] = value;
				}
			});
		} else {
			each(obj, (value, key) => {
				result.push({});
				each(value, (objValue, objKey) => {
					if (!isObject(value)) {
						result[key][`x${replace(startCase(objKey), /\s/g, '')}`] = objValue;
					}
				});
			});
		}
		return result;
	};

	render() {
		return (
			<tr>
				<ActionsComponent {...this.props} dependentValues={{ actions: this.actions }} />
			</tr>
		);
	}
}

RecurringActions.propTypes = {
	row: PropTypes.object.isRequired,
};

export default RecurringActions;
