import React, { Component, Fragment, createRef } from 'react';
import moment from 'moment';
import {
	cloneDeep,
	filter,
	find,
	each,
	map,
	unionBy,
	concat,
	isArray,
	includes,
	findIndex,
	toLower,
	orderBy,
	identity,
	sortBy,
	toNumber,
	size,
	isEmpty,
	has,
	findKey,
	some,
	head,
	startsWith,
	every,
	join,
	split,
	get,
	replace,
} from 'lodash';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { Data } from 'react-data-grid-addons';
import { components } from 'react-select';
import { parse, stringify } from 'query-string';
import { Link } from 'react-router-dom';

import { transferService, kvaasService, principalService } from 'common/services';
import { LoadMoreOptions, kvaasResources, invokeIfFunction } from 'common/utilities';
import { MainFilterComponent } from 'common/components/filter';
import { transfersFilter as Filter, compileFilter } from 'common/components/transfers/filter/transfersFilter';
import { TransferColumns as Columns } from 'common/components/transfers/column-filter/transferColumns';
import { GridComponent } from 'common/components/grid';
import { withError } from 'common/components/error';
import { predefinedDates } from 'common/components/date-picker';
import { Select } from 'common/components/select';
import { CurrencyComponent } from 'common/components/columns/formatters';
import { exportTypesByKey } from 'common/components/export/export-options';
import { exportService } from 'common/components/export/exportService';
import { ZebraRenderer } from 'common/components/row';
import { mapDefaultOrder } from 'common/utilities/map-default-order';
import { GridTooltip } from 'common/components/tooltips';
import { Tour } from 'common/components/tour';
import { withLoadMore } from 'common/components/loadmore';
import getTransactionAmounts from 'common/utilities/getTransactionAmounts';

const {
	apiDateTimeFormat,
	apiResponseDateTimeFormat,
	displayDateTimeFormat,
	parseDateTimeFormat,
} = ApplicationSettings;

const loadMoreOptionsWithAll = concat(LoadMoreOptions, [0]);

const requestKeys = {
	FETCH: 'fetch',
	KVAAS: 'kvaas',
	LOAD_MORE: 'loadMore',
	LOAD_ALL: 'loadAll',
	KEYS: 'keys',
	VALIDATE_KEYS: 'validateKeys',
};

const exportTypes = [exportTypesByKey.csv];
const errorConstants = ['failed to fetch', 'networkerror when attempting to fetch resource.'];
const tourConfig = {
	version: 2, // increase this every time you make changes to the tourConfig,
	key: 'filterAll',
	steps: [
		{
			selector: '.loadmore__item:last-child',
			content: () => {
				return (
					<span>
						Want to see the full list of transfers automatically when filtering data? Select "All" in the "Default Load
						Limit" drop-down in Transaction History
						<Link to="/settings/user-settings/transaction-history" className="btn btn--link spc--left--tny">
							{' '}
							here
						</Link>
						.
					</span>
				);
			},
		},
	],
};

class TransfersGrid extends Component {
	constructor(props) {
		super(props);
		const availableFilters = map(Columns, col => col.key);
		const filters = unionBy(props.location.filters, Filter, 'key') || Filter;
		const ignoreDefaultFilterValues = !!props.location.ignoreDefaultFilterValues;
		const activeFilters = props.activeFilters || cloneDeep(filters);
		const initialRecordsLimit = props.location.initialRecordsLimit || 20;
		const initialSortBy = props.location.initialSortBy || null;
		const allColumns = cloneDeep(Columns);
		if (window.innerWidth <= 990) {
			const nonMobileColumns = filter(allColumns, column => !column.showOnMobile);
			each(nonMobileColumns, column => {
				column.visible = false;
			});
		}
		const initiallySelectedColumns = initialSortBy ? this.removeColumnSorting(allColumns) : allColumns;
		if (initialSortBy) {
			const sortColumn = filter(initiallySelectedColumns, { key: initialSortBy.xColumn })[0];
			sortColumn.sortDirection = 'ASC';
			sortColumn.visible = true;
		}

		const principal = principalService.get();
		const permissions = (principal && principal.idInfo && principal.idInfo.permissions) || {};
		this.state = {
			availableFilters,
			isAdmin: principal.isAdmin,
			filters: filters,
			ignoreDefaultFilterValues,
			activeFilters: activeFilters,
			inlineFilters: {},
			expanded: {},
			data: null,
			filteredRows: [],
			originalData: null,
			fetchingData: true,
			fetchingAdditionalData: false,
			columns: initiallySelectedColumns.filter(c => (principal.isAdmin ? true : !c.isAdminColumn)),
			defaultColumns: cloneDeep(allColumns).filter(c => (principal.isAdmin ? true : !c.isAdminColumn)),
			loadMoreLimit: 50,
			initialRecordsLimit: initialRecordsLimit,
			lastApiRefNum: null,
			selectedCurrency: {},
			currencyOptions: [],
			permissions,
			expandedRow: '',
			isPrinting: false,
			showTour: false,
			includeVoidStatusInCsv: false,
			displayOlderTransfersFirst: false,
			printInPortrait: false,
			printOnOnePage: false,
		};

		this.gridRef = createRef();
		this.breakdownRef = createRef();

		this.setState = this.setState.bind(this);

		this.classes = {
			filter: '',
			gridHeader: 'flex--primary datatooltip--v--bottom',
		};

		this.components = {
			filter: MainFilterComponent,
			rowRenderer: ZebraRenderer,
			gridFooter: this.renderGridFooter,
			tooltip: GridTooltip,
		};
	}

	inlineFilterValues = (isExport, inlineFilters = this.state.inlineFilters) => {
		let result = [];

		if (!isEmpty(inlineFilters)) {
			if (inlineFilters.xResponseResult) {
				const {
					xResponseResult: { filterTerm },
				} = inlineFilters;
				each(filterTerm, ({ value }) => result.push(toLower(value)));
			} else {
				return result;
			}
		} else if (!isExport) {
			result.push('approved');
		}
		return result;
	};

	hasInlineFilter = inlineFilters => {
		let hasFilter = false;
		const { availableFilters } = this.state;
		each(availableFilters, key => {
			if (inlineFilters[key]) {
				hasFilter = true;
			}
		});

		return hasFilter;
	};

	isErrorAndDeclined = responseResults => {
		return (
			find(responseResults, status => status === 'declined') &&
			find(responseResults, status => status === 'error') &&
			!find(responseResults, status => status === 'approved')
		);
	};

	getLabelByRows = (filteredRows, inlineFilters) => {
		const responseResults = map(filteredRows, ({ xResponseResult }) => toLower(xResponseResult));
		if (this.isErrorAndDeclined(responseResults)) {
			return 'Total Declined:';
		}
		const isEveryRow = status => every(responseResults, item => item === status);
		if (isEveryRow('approved')) {
			return 'Total Approved:';
		} else if (isEveryRow('error')) {
			return 'Total Error:';
		} else if (isEveryRow('pending')) {
			return 'Total Pending:';
		} else if (isEveryRow('declined')) {
			return 'Total Declined:';
		} else if (isEveryRow('declined/retrying')) {
			return 'Total Declined/Retrying:';
		} else if (
			isEmpty(inlineFilters) ||
			(isEmpty(inlineFilters.xResponseResult) && !this.hasInlineFilter(inlineFilters))
		) {
			return 'Total Approved:';
		} else {
			return 'Total:';
		}
	};

	componentDidMount = async () => {
		try {
			const [columnsOrder, defaultValues, portalFlags] = await this.props.makePendingRequest(
				kvaasService.get(
					kvaasResources.transfersReportOrder,
					kvaasResources.transfersReportDefaultValues,
					kvaasResources.portalFlags
				),
				requestKeys.KVAAS
			);
			const displayOlderTransfersFirst = get(portalFlags, 'data.displayOlderTransfersFirst', false);
			const printInPortrait = get(portalFlags, 'data.printInPortrait', false);
			const printOnOnePage = get(portalFlags, 'data.printOnOnePage', false);

			const loadMoreLimit = get(defaultValues, 'data.limit');
			const order = mapDefaultOrder(kvaasResources.transactionReportOrder.defaultData, columnsOrder);
			let columns = this.mapColumns(
				this.addCustomData(this.state.columns, {
					order,
				})
			);

			const defaultColumns = this.mapColumns(
				this.addCustomData(this.state.defaultColumns, {
					order,
				})
			);
			if (displayOlderTransfersFirst) {
				this.updateDateSorter(columns);
				this.updateDateSorter(defaultColumns);
			}
			let filters = this.addCustomData(this.state.filters, {
				defaultValues,
				portalFlags,
			});
			let activeFilters = this.addCustomData(this.state.activeFilters, {
				defaultValues,
				portalFlags,
			});
			const queryParams = parse(this.props.location.search);
			filters = this.parseQueryFilters(filters, queryParams);
			activeFilters = this.parseQueryFilters(activeFilters, queryParams);
			this.queryFilterValues(filters, this.props.location.search, true);
			this.setState(
				{
					columns,
					defaultColumns,
					filters,
					activeFilters,
					loadMoreLimit: loadMoreLimit ? (loadMoreLimit === 'All' ? 0 : parseInt(loadMoreLimit)) : 20,
					initialRecordsLimit: loadMoreLimit ? (loadMoreLimit === 'All' ? 0 : parseInt(loadMoreLimit)) : 20,
					displayOlderTransfersFirst,
					printInPortrait,
					printOnOnePage,
				},
				() => {
					if (this.gridRef.current) {
						this.gridRef.current.reset();
					}
				}
			);
		} catch (e) {
			if (this.props.handleError(e)) {
				this.setState({
					fetchingData: false,
				});
			}
		}
	};

	resetLabelsToDefault = (columns, defaultColumns, filters, secondaryKeysFilterHasSelection) => {
		each(columns, ({ originalName, customName }, index) => {
			if (originalName) {
				columns[index].name = secondaryKeysFilterHasSelection ? originalName : customName;
			}
		});
		each(defaultColumns, ({ originalName, customName }, index) => {
			if (originalName) {
				defaultColumns[index].name = secondaryKeysFilterHasSelection ? originalName : customName;
			}
		});
		each(filters, ({ originalName, customName }, index) => {
			if (originalName) {
				filters[index].name = secondaryKeysFilterHasSelection ? originalName : customName;
			}
		});
	};

	updateDateSorter = columns => {
		const dateColumn = find(columns, { key: 'xEnteredDate' });
		if (!dateColumn) return;
		dateColumn.defaultSortDirection = 'ASC';
	};

	showHideColumns = (hideColumnsList, columns, toShow) => {
		each(columns, ({ key, hideable }, index) => {
			if (includes(hideColumnsList, key) && hideable) {
				columns[index].visible = toShow;
				columns[index].hideable = toShow;
			}
		});
	};

	mapColumns = columns => cloneDeep(columns);

	showHideAccountKeyColumn = ({
		columns = cloneDeep(this.state.columns),
		defaultColumns = cloneDeep(this.state.defaultColumns),
		filters = cloneDeep(this.state.filters),
	}) => {
		const secondaryKeysFilterHasSelection = get(find(filters, { key: 'secondaryKeys' }), 'hasSelection', false);
		const columnIndex = findIndex(columns, { key: 'xMerchantName' });
		const defaultColumnIndex = findIndex(defaultColumns, { key: 'xMerchantName' });

		if (columnIndex > -1) {
			columns[columnIndex].visible = secondaryKeysFilterHasSelection;
		}
		if (defaultColumnIndex > -1) {
			defaultColumns[defaultColumnIndex].visible = secondaryKeysFilterHasSelection;
		}

		this.resetLabelsToDefault(columns, defaultColumns, filters, secondaryKeysFilterHasSelection);
		this.setState({ columns, defaultColumns, filters });
	};

	parseHistoryQueriesToFilters = () => {
		const { filters, activeFilters } = this.state;
		const queryParams = parse(this.props.history.location.search);
		const newFilters = this.parseQueryFilters(filters, queryParams);
		const newActiveFilters = this.parseQueryFilters(activeFilters, queryParams);
		this.queryFilterValues(newFilters, this.props.history.location.search, false);
		this.setState(
			{
				filters: newFilters,
				activeFilters: newActiveFilters,
			},
			() => {
				if (this.gridRef.current) {
					this.gridRef.current.reset();
				}
			}
		);
	};

	handleFilters = ({
		filterKey,
		key,
		filterHasAnyValue,
		newFilters,
		dateRangeDisabled,
		defaultHasSelection,
		allowsDateDisable,
		index,
		defaultValues,
	}) => {
		if (filterKey !== 'scheduleId' && key !== 'loadAll' && !filterHasAnyValue) {
			newFilters[index].hasSelection =
				filterKey !== 'date' && dateRangeDisabled
					? !!allowsDateDisable && !!defaultHasSelection
					: !!defaultHasSelection;
		}
		let defaultValue = defaultValues[key];

		if (!newFilters[index].hasSelection) {
			if (typeof defaultValue === 'string') {
				defaultValue = '';
			} else if (typeof defaultValue === 'boolean') {
				defaultValue = false;
			} else if (typeof defaultValue === 'object') {
				defaultValue = null;
			}
		}

		newFilters[index].values[key] = defaultValue;
	};

	handleParseFilters = ({
		anyChanged,
		values,
		params,
		filterKey,
		dateRangeDisabled,
		allowsDateDisable,
		newFilters,
		index,
		filterHasAnyValue,
		defaultValues,
		defaultHasSelection,
	}) => {
		each(values, (_, key) => {
			const value = params[key];
			if (value) {
				const milliseconds = get(newFilters[index], `props.milliseconds.${key}`);
				const date = moment(`${value}.${milliseconds}`, apiDateTimeFormat, true);
				let hasSelection = true;

				if (filterKey !== 'date' && dateRangeDisabled) {
					hasSelection = !!allowsDateDisable;
				}

				newFilters[index].hasSelection = hasSelection;
				newFilters[index].values[key] = date.isValid() ? date : hasSelection ? value : defaultValues[key];
				anyChanged = true;
				filterHasAnyValue = true;
			} else {
				this.handleFilters({
					filterKey,
					key,
					filterHasAnyValue,
					newFilters,
					dateRangeDisabled,
					defaultHasSelection,
					allowsDateDisable,
					index,
					defaultValues,
				});
			}
		});
		return anyChanged;
	};

	parseQueryFilters = (filters, params) => {
		if (isEmpty(params)) {
			return filters;
		}
		const newFilters = cloneDeep(filters);
		const dateRangeDisabled = params.disabled === 'true';
		let anyChanged = false;
		each(newFilters, ({ values, key: filterKey, allowsDateDisable, defaultValues, defaultHasSelection }, index) => {
			let filterHasAnyValue = false;
			if (filterKey !== 'includeSplitPay') {
				anyChanged = this.handleParseFilters({
					anyChanged,
					values,
					params,
					filterKey,
					dateRangeDisabled,
					allowsDateDisable,
					newFilters,
					index,
					filterHasAnyValue,
					defaultValues,
					defaultHasSelection,
				});
			}
		});

		return anyChanged ? newFilters : filters;
	};

	queryFilterValues = (filters, query = '', initialExpandRow = false) => {
		const {
			history,
			location: { search: oldSearch },
		} = this.props;
		let result = {};
		if (query) {
			result = parse(query);
			if (!!result.expandedRow && initialExpandRow) {
				this.setState({ expandedRow: result.expandedRow });
			}
		}
		each(filters, ({ values, hasSelection }) => {
			if (hasSelection) {
				each(values, (value, key) => {
					if (value) {
						if (moment(value, apiDateTimeFormat, true).isValid()) {
							result[key] = value.format(parseDateTimeFormat);
						} else {
							result[key] = value;
						}
					}
				});
			}
		});
		const search = stringify(result);
		if (`?${search}` !== oldSearch) {
			history.replace({
				search,
			});
		}
	};

	addCustomDataType = (newData, customData, mapper, transformer = identity) => {
		let anyChanged = false;
		each(customData && customData.data, (value, customSettingsKey) => {
			const item = find(newData, item =>
				isArray(item.customSettingsKey)
					? includes(item.customSettingsKey, customSettingsKey)
					: includes([toLower(item.customSettingsKey), toLower(item.alternateCustomKey)], toLower(customSettingsKey)) ||
					  has(item, `values.${customSettingsKey}`)
			);
			if (item) {
				if (isArray(item.customSettingsKey)) {
					const labelIndex = findIndex(item.customSettingsKey, key => key === customSettingsKey);
					if (labelIndex > -1) {
						if (mapper(item, value, labelIndex)) {
							anyChanged = true;
						}
					}
				} else {
					if (mapper(item, value)) {
						anyChanged = true;
					}
				}
			}
		});
		if (anyChanged) {
			return transformer(newData);
		}
		return newData;
	};

	addCustomData = (
		data,
		{ customDefaultColumns, customDisplayLabels, hiddenColumns, order, defaultValues, parsedCustomKeys }
	) => {
		let newData = cloneDeep(data);
		let anyChanged = false;
		newData = this.addCustomDataType(newData, customDefaultColumns, (item, _, index) => {
			if (index === undefined) {
				item.visible = true;
				anyChanged = true;
				return anyChanged;
			}
		});
		newData = this.addCustomDataType(newData, customDisplayLabels, (item, value, index) => {
			if (index !== undefined) {
				item.props.labels[index] = value;
			} else {
				item.customName = value;
				item.originalName = item.name;
				item.name = value;
				const shippingItem = find(newData, ({ customSettingsKey }) =>
					includes(
						[toLower(`ship${item.customSettingsKey}`), toLower(`ship${item.alternateCustomKey}`)],
						toLower(customSettingsKey)
					)
				);
				if (shippingItem) {
					const shippingName = `Ship ${value}`;
					shippingItem.customName = shippingName;
					shippingItem.originalName = shippingItem.name;
					shippingItem.name = shippingName;
				}
			}
			anyChanged = true;
			return anyChanged;
		});
		newData = this.addCustomDataType(newData, hiddenColumns, (item, _, index) => {
			if (index !== undefined) {
				item.props.hiddenFields[index] = true;
			} else {
				item.hideable = false;
				item.visible = false;
			}
			anyChanged = true;
			return anyChanged;
		});
		if (!this.state.ignoreDefaultFilterValues) {
			newData = this.addCustomDataType(newData, defaultValues, (item, value) => {
				const predefinedDate = find(predefinedDates, ({ key }) => key === value);
				if (predefinedDate) {
					const { startValue, endValue } = predefinedDate;
					const startDate = moment()
						.startOf('day')
						.add(invokeIfFunction(startValue), 'days');
					const endDate = moment()
						.endOf('day')
						.add(invokeIfFunction(endValue), 'days');
					item.values = { ...item.values, key: value, startDate, endDate };
					item.defaultValues = { ...item.values };
					anyChanged = true;
					return anyChanged;
				} else {
					const values = { ...item.values };
					each(defaultValues.data, (value, key) => {
						if (has(item.values, key)) {
							values[key] = value;
							item.hasSelection = true;
							item.defaultHasSelection = true;
						}
					});
					item.values = values;
					item.defaultValues = { ...values };
					anyChanged = true;
					return anyChanged;
				}
			});
		}
		newData = this.addCustomDataType(
			newData,
			order,
			(item, value, index) => {
				if (index === undefined) {
					item.order = value;
					anyChanged = true;
					return anyChanged;
				}
			},
			updatedData => sortBy(updatedData, 'order')
		);
		newData = this.addCustomDataType(newData, parsedCustomKeys, item => {
			item.formatter = CurrencyComponent;
			item.getRowMetaData = ({ currency }) => ({ currency });
			anyChanged = true;
			return anyChanged;
		});
		return anyChanged ? newData : data;
	};

	handleChange = changes => {
		const { columns, filters, inlineFilters, data } = this.state;
		const newState = {};
		each(changes, ({ key, value }) => {
			if (key === 'expanded') {
				const expandedIndex = toNumber(findKey(value, item => !!item));
				const expandedRow = find(data.xReportData, ({ index }) => index === expandedIndex);
				const query = parse(this.props.location.search);
				if (expandedRow) {
					query.expandedRow = expandedRow.xRefNum;
				} else {
					delete query.expandedRow;
				}
				this.queryFilterValues(filters, stringify(query));
			}
			if (key === 'data' || key === 'inlineFilters') {
				let filters, data;
				if (key === 'data') {
					filters = inlineFilters;
					data = value;
				} else {
					filters = value;
					data = this.state.data;
				}
				newState.filteredRows =
					data && data.xReportData
						? Data.Selectors.getRows({
								rows: data.xReportData,
								filters,
						  })
						: [];
				const { currencyOptions, selectedCurrency } = getTransactionAmounts(
					newState.filteredRows,
					false,
					false,
					this.state.selectedCurrency,
					null,
					key === 'inlineFilters' && value
				);
				newState.currencyOptions = currencyOptions;
				newState.selectedCurrency = selectedCurrency;
			}
			if (includes(['activeFilters', 'filters'], key)) {
				const secondaryKeys = get(find(value, { key: 'secondaryKeys' }), 'values.secondaryKeys');
				const accountKeyColumn = find(columns, { key: 'xMerchantName' });
				if (secondaryKeys && accountKeyColumn) {
					accountKeyColumn.visible = true;
					newState.columns = cloneDeep(columns);
					const accountKeyColumnIndex = findIndex(newState.columns, { key: 'xMerchantName' });
					newState.columns[accountKeyColumnIndex].visible = true;
				}
			}

			newState[key] = value;
		});

		return new Promise(resolve => {
			this.setState(newState, resolve);
		});
	};

	removeColumnSorting = columns => {
		return map(columns, col => {
			col.sortDirection = null;
			return col;
		});
	};

	mapColumnsForExport = columns => {
		let filteredColumns = filter(columns, c => !c.hasNoField && !c.hideOnExport && (c.hideable || c.visible));

		each(filteredColumns, (column, index) => {
			if (column.dependentExportKey) {
				let dependentColumnKeys = [];
				if (!Array.isArray(column.dependentExportKey)) {
					dependentColumnKeys.push(column.dependentExportKey);
				} else {
					dependentColumnKeys = column.dependentExportKey;
				}
				each(dependentColumnKeys, dependentColumnKey => {
					if (!find(filteredColumns, { key: dependentColumnKey })) {
						let dependentColumn = find(this.state.columns, col => col.key === dependentColumnKey && !col.ignoreOnfetch);
						if (dependentColumn) filteredColumns.splice(index + 1, 0, dependentColumn);
					}
				});
			}
		});
		return map(filteredColumns, ({ key, exportKey, name, fieldKey }) => ({ key: exportKey || key, name, fieldKey }));
	};

	getVisibleColumns = columns => {
		//if developer, set column 'transfermerchantid' visible to true
		return filter(columns, { visible: true });
	};

	getFields = columns => {
		const mappedFields = map(
			this.mapColumnsForExport(this.getVisibleColumns(columns)),
			({ key, fieldKey }) => fieldKey || key
		);
		// xToken is needed to ensure transactionRow actions display on errored transactions
		if (!find(mappedFields, f => f === 'xToken')) {
			mappedFields.push('xToken');
		}
		let fields = join(mappedFields, ',');

		return fields;
	};

	handleSecondaryKeys = (filter, secondaryKeys, maxRecords, promises, fields, displayOlderTransfersFirst) => {
		if (secondaryKeys) {
			promises = [
				maxRecords
					? transferService.filterTransactionsRequest(filter, fields, maxRecords, displayOlderTransfersFirst)
					: transferService.filterTransactionsAll(filter, fields, displayOlderTransfersFirst),
			];
		}
		return promises;
	};

	getData = async ({ filter, maxRecords, fields, displayOlderTransfersFirst, secondaryKeys }) => {
		let tData = null;
		let promises = [
			maxRecords
				? transferService.filterTransactionsRequest(
						filter,
						fields,
						maxRecords > 1000 ? 1000 : maxRecords,
						displayOlderTransfersFirst
				  )
				: transferService.filterTransactionsAll(filter, fields, displayOlderTransfersFirst),
		];

		promises = this.handleSecondaryKeys(
			filter,
			secondaryKeys,
			maxRecords,
			promises,
			fields,
			displayOlderTransfersFirst
		);
		const [responseData] = await this.props.makePendingRequest(Promise.all(promises), requestKeys.FETCH);

		tData = responseData;
		return tData;
	};

	fetchData = async (filters, filterDateFormat, maxRecords = 1000) => {
		const { expandedRow, columns, displayOlderTransfersFirst, selectedCurrency } = this.state;
		const {
			history,
			history: {
				location: { search },
			},
		} = this.props;
		const parsedQuery = parse(search);
		const rowExpanded = expandedRow || (parsedQuery && parsedQuery.expandedRow);
		let showUserProcessingMessage = false;
		const dateFilter = find(filters, i => i.key === 'date');

		if (dateFilter) {
			const {
				values: { startDate, endDate },
				props: { maxApiDaysRange },
			} = dateFilter;
			showUserProcessingMessage = maxApiDaysRange && endDate.diff(startDate, 'days') > maxApiDaysRange;
			this.setState({ maxApiRangeBreach: showUserProcessingMessage });
		}

		this.setState({
			fetchingData: true,
			data: null,
			filteredRows: [],
			currencyOptions: [],
			expanded: {},
			lastApiRefNum: null,
		});

		if (showUserProcessingMessage && this.gridRef.current) this.gridRef.current.showExportLoader(false);

		try {
			const filter = await this.props.makePendingRequest(compileFilter(filters, filterDateFormat), requestKeys.FETCH);
			const formattedColumns = this.formatColumns(columns, cloneDeep(filter));
			const fields = this.getFields(columns);
			const secondaryKeys = get(find(filters, { key: 'secondaryKeys' }), 'values.secondaryKeys');

			let data = await this.getData({
				filter,
				maxRecords,
				fields,
				displayOlderTransfersFirst,
				secondaryKeys,
			});

			const lastApiRefNum = data.xRefNum;

			if (data && data.xReportData) {
				data.xReportData = map(data.xReportData, row => this.mapRow(row, secondaryKeys));
			}

			if (maxRecords === 0) {
				data.xRecordsReturned = data.xReportData.length;
				data.xReportingMaxTransactions = data.xReportData.length + 1; // +1 = quick fix
			}

			this.mapData(data);
			const filteredRows =
				data && data.xReportData
					? Data.Selectors.getRows({
							rows: data.xReportData,
							filters: this.state.inlineFilters,
					  })
					: [];
			if (this.gridRef.current) {
				this.gridRef.current.oldVisibleColumns = [];
				this.gridRef.current.scrollTo({ top: 0, left: 0 });
			}
			this.setState(
				{
					data,
					filteredRows,
					originalData: cloneDeep(data),
					fetchingData: false,
					columns: formattedColumns,
					lastApiRefNum: lastApiRefNum,
					...getTransactionAmounts(filteredRows, false, false, selectedCurrency, null, this.state.inlineFilters),
				},
				() => {
					if (this.gridRef.current) {
						this.gridRef.current.handleInitialSort();
						this.gridRef.current.calculateColumnWidths();
						if (rowExpanded) {
							const row = find(filteredRows, ({ xRefNum }) => xRefNum === rowExpanded);
							if (row) {
								this.gridRef.current.onRowClick(row.index, row);
								this.setState({ expandedRow: null });
							}
						}
					}
					each(parsedQuery, (_, key) => {
						if (startsWith(key, 'print')) {
							if (key === 'printAll' && this.gridRef.current && this.gridRef.current.printGridButtonRef.current) {
								this.gridRef.current.printGridButtonRef.current.triggerAll();
							} else if (
								key === 'printCurrent' &&
								this.gridRef.current &&
								this.gridRef.current.printGridButtonRef.current
							) {
								this.gridRef.current.printGridButtonRef.current.triggerCurrent();
							}
							delete parsedQuery[key];
							history.replace({ search: stringify(parsedQuery) });
						}
					});
				}
			);
			if (showUserProcessingMessage && this.gridRef.current) this.gridRef.current.showExportLoader(null);
		} catch (e) {
			const notification = this.props.handleError(e, { delayMessage: true });
			this.handleShowNotification(notification, e.ex);
			if (this.gridRef.current) this.gridRef.current.showExportLoader(null);
			this.setState({
				fetchingData: false,
			});
		}
	};

	displayRowCount = () => {
		return this.state.selectedCurrency.count || 0;
	};

	onInfoHover = (infoDimensions, tooltip) => {
		this.setState({ tooltipProps: { infoDimensions, tooltip } });
	};

	mapRow = row => {
		const mappedRow = {
			...row,
			isExpandable: toLower(row.xCommand) !== 'split pay',
			onInfoHover: this.onInfoHover,
		};

		return mappedRow;
	};

	resolveColumnName = column => {
		let key = column;
		switch (column) {
			case 'AmountData':
				key = 'xAmount';
				break;
			case 'RefNumData':
				key = 'xRefNum';
				break;
			case 'xEnteredDate':
				key = 'xEnteredDateMoment';
				break;
			default:
				break;
		}
		return key;
	};

	mapData = data => {
		let i = 0;
		if (data && data.xReportData && data.xReportData.length > 0) {
			each(data.xReportData, item => {
				if (moment.isMoment(item.xEnteredDate)) {
					item.xEnteredDateMoment = moment(item.xEnteredDate, apiResponseDateTimeFormat);
					item.xEnteredDate = item.xEnteredDateMoment.format(displayDateTimeFormat);
				}
				if (item.xMaskedCardNumber && item.xMaskedCardNumber.includes('xxx')) {
					if (this.state.isAdmin && /^\d{6}/g.test(item.xMaskedCardNumber))
						item.xMaskedCardNumber = `${item.xMaskedCardNumber.slice(0, 6)} **** ${item.xMaskedCardNumber.slice(-4)}`;
					else item.xMaskedCardNumber = `**** ${item.xMaskedCardNumber.slice(-4)}`;
				}

				// include gridRowNumber as a simple counter for zebra
				item.gridRowNumber = i;
				item.index = i + 1; // for initial sort;
				i++;
			});
		}
	};

	combineData = (baseData, additionalData, refNums) => {
		baseData.xReportData = concat(baseData.xReportData, additionalData.xReportData);
		baseData.xRecordsReturned += additionalData.xRecordsReturned - refNums.length;
		baseData.xReportingMaxTransactions += additionalData.xReportingMaxTransactions - refNums.length;
		if (additionalData.nextToken) {
			baseData.nextToken = additionalData.nextToken;
		}
	};

	getActiveInlineStatusFilters = inlineFilters => {
		if (!isEmpty(inlineFilters)) {
			return this.getLabelByInlineFilter(inlineFilters);
		}
	};

	getLabelByInlineFilter = inlineFilters => {
		const sortedInlineFilters = orderBy(map(inlineFilters, f => toLower(f.value)), ['asc']);
		const inlineFiltersSize = size(sortedInlineFilters);
		if (inlineFiltersSize === 2) {
			if (includes(sortedInlineFilters, 'approved') && includes(sortedInlineFilters, 'declined')) {
				return 'Total Approved/Declined:';
			}
			if (includes(sortedInlineFilters, 'approved') && includes(sortedInlineFilters, 'error')) {
				return 'Total Approved/Error:';
			}
			return 'Total Declined:';
		}
		if (inlineFiltersSize === 3) {
			return 'Total:';
		}
	};

	loadMore = async () => {
		const {
			loadMoreLimit,
			data,
			originalData,
			activeFilters,
			columns,
			displayOlderTransfersFirst,
			selectedCurrency,
		} = this.state;

		const hasData = originalData && originalData.xReportData && originalData.xReportData.length > 0;

		if (hasData) {
			const filters = cloneDeep(activeFilters);
			const dateFilter = find(filters, { key: 'date' });
			const { start, end, refNums } = transferService.getNewStartEndDates(
				dateFilter.values.startDate,
				dateFilter.values.endDate,
				originalData.xReportData,
				displayOlderTransfersFirst
			);
			dateFilter.values.startDate = start;
			dateFilter.values.endDate = end;
			let compiledFilter;
			try {
				compiledFilter = await this.props.makePendingRequest(
					compileFilter(filters, apiDateTimeFormat),
					requestKeys.LOAD_MORE
				);
			} catch (e) {
				this.props.handleError(e, { additionalInfo: { dateFilter: dateFilter.values, apiDateTimeFormat } });
				return;
			}
			if ((compiledFilter.xBeginDate && compiledFilter.xEndDate) || originalData.nextToken) {
				this.setState({
					fetchingAdditionalData: true,
					lastApiRefNum: null,
				});

				try {
					const fields = this.getFields(columns);

					let pendingData = await this.props.makePendingRequest(
						loadMoreLimit
							? transferService.filterTransactionsRequest(
									compiledFilter,
									fields,
									Math.min(loadMoreLimit + refNums.length, 1000),
									displayOlderTransfersFirst
							  )
							: transferService.filterTransactionsAll(compiledFilter, fields, displayOlderTransfersFirst),
						requestKeys.LOAD_MORE
					);
					const { additionalData, additionalRefNums } = await this.props.makePendingRequest(
						this.props.loadMore(
							pendingData,
							filters,
							dateFilter,
							loadMoreLimit,
							refNums,
							compiledFilter,
							compileFilter,
							fields,
							displayOlderTransfersFirst
						),
						requestKeys.LOAD_MORE
					);
					this.combineData(pendingData, additionalData, additionalRefNums);
					pendingData.xReportData = filter(pendingData.xReportData, ({ xRefNum }) => !includes(refNums, xRefNum));
					const updatedData = cloneDeep(data);
					if (!isEmpty(pendingData.xReportData)) {
						this.combineData(updatedData, pendingData, refNums);
						updatedData.xReportData = map(updatedData.xReportData, this.mapRow);
						if (!loadMoreLimit) {
							updatedData.xReportingMaxTransactions += 1;
						}
						this.mapData(updatedData);
						const filteredRows =
							updatedData && updatedData.xReportData
								? Data.Selectors.getRows({
										rows: updatedData.xReportData,
										filters: this.state.inlineFilters,
								  })
								: [];
						this.setState(
							{
								originalData: cloneDeep(updatedData),
								data: updatedData,
								filteredRows,
								fetchingAdditionalData: false,
								lastApiRefNum: pendingData.xRefNum,
								...getTransactionAmounts(filteredRows, false, false, selectedCurrency, null, this.state.inlineFilters),
							},
							() => {
								if (this.gridRef.current) {
									this.gridRef.current.handleInitialSort();
									this.gridRef.current.calculateColumnWidths();
								}
							}
						);
					} else {
						if (!loadMoreLimit) {
							updatedData.xReportingMaxTransactions += 1;
						}
						this.setState({
							data: updatedData,
							fetchingAdditionalData: false,
							lastApiRefNum: pendingData.xRefNum,
						});
					}
				} catch (e) {
					const notification = this.props.handleError(e, { delayMessage: true, additionalInfo: { compiledFilter } });
					this.handleShowNotification(notification, e.ex);
					this.setState({
						fetchingAdditionalData: false,
					});
				}
				if (this.gridRef.current) {
					this.gridRef.current.oldVisibleColumns = [];
				}
			}
		}
	};

	getAllTransactions = async () => {
		const { activeFilters, columns } = this.state;
		let fields = this.getFields(columns);
		try {
			let allData = null;
			const filters = await this.props.makePendingRequest(
				compileFilter(cloneDeep(activeFilters), apiDateTimeFormat),
				requestKeys.LOAD_ALL
			);

			allData = await this.props.makePendingRequest(
				transferService.filterTransactionsAll(filters, fields, this.state.displayOlderTransfersFirst),
				requestKeys.LOAD_ALL
			);

			this.mapData(allData);
			return allData.xReportData;
		} catch (e) {
			this.props.handleError(e);
		}
	};

	formatColumns = (columns, appliedFilter = null) => {
		each(appliedFilter, (_, prop) => {
			if (prop === 'xCommand') {
				return;
			}
			const column = find(columns, ({ key, exportKey, fieldKey }) => {
				const keys = [key, exportKey, ...split(fieldKey, ',')];
				if (prop && prop.includes('xcustom')) {
					prop = replace(prop, 'xcustom', 'xCustom');
				}
				return includes(keys, prop);
			});
			if (column) {
				column.visible = true;
			}
		});
		return columns;
	};

	hasMoreData = data => {
		const dateFilter = find(this.state.filters, i => i.key === 'date');
		return (
			data &&
			data.xReportData &&
			data.xReportData.length > 0 &&
			data.xRecordsReturned >= data.xReportingMaxTransactions &&
			(!!data.nextToken || !dateFilter.values.disabled)
		);
	};

	onLoadMoreLimitChange = value => {
		this.setState(
			{
				loadMoreLimit: value,
				showTour: true,
			},
			() => {
				this.loadMore();
			}
		);
	};

	handleCurrencyChange = selectedCurrency => {
		this.setState({ selectedCurrency }, () => {
			if (this.gridRef.current) {
				this.gridRef.current.forceUpdate();
			}
		});
	};

	renderSymbol = ({ data: { symbol }, ...props }) => (
		<components.SingleValue {...props}>{symbol}</components.SingleValue>
	);

	renderTransfersAmount = key => {
		const {
			selectedCurrency,
			selectedCurrency: { symbol },
			currencyOptions,
		} = this.state;
		const currencyArray = split(selectedCurrency[key], '-');
		let prefix = '';
		if (head(currencyArray) === '') {
			prefix = '-';
		}
		return (
			<Fragment>
				{currencyOptions.length > 1 ? (
					<Select
						name="currency"
						id="currency"
						className="display--ib align--v--middle reactselect__resize--sml w--min--55p spc--right--xsml"
						value={selectedCurrency}
						options={currencyOptions}
						onChange={this.handleCurrencyChange}
						components={{
							SingleValue: this.renderSymbol,
						}}
						menuPlacement="auto"
						getOptionValue={option => option.label}
					/>
				) : (
					<strong>
						{prefix}
						{symbol}
					</strong>
				)}
				<strong>
					<NumberFormat
						value={prefix ? currencyArray[1] : selectedCurrency[key]}
						displayType="text"
						thousandSeparator={true}
						decimalScale={2}
						fixedDecimalScale={true}
					/>
				</strong>
			</Fragment>
		);
	};

	refetchData = () => {
		this.setState({ loadMoreLimit: this.state.initialRecordsLimit }, () =>
			this.fetchData(this.state.activeFilters, apiDateTimeFormat, this.state.initialRecordsLimit)
		);
	};

	redirectToUpgradePlan = () => {
		this.props.history.push({ pathname: '/terminal-only' });
	};

	renderGridFooter = () => {
		const { totalLabel } = this.state;

		return (
			<Fragment>
				<div className="react-grid__footer__details">
					<div className="react-grid__footer__details__item">
						<span className="spc--right--tny">{totalLabel}</span>
						<span>{this.renderTransfersAmount('amount')}</span>
					</div>
				</div>
			</Fragment>
		);
	};

	handleShowNotification = (notification, error = {}) => {
		if (notification) {
			if (find(errorConstants, err => err === toLower(error.message))) {
				notification.message = 'Failed to load transfers, please refresh the page.';
			}
			notification.show();
		}
	};

	handlePrintError = (method, error) => {
		const { handleError } = this.props;
		if (handleError(error, { additionalInfo: { method } })) {
			this.setState({ isPrinting: false });
		}
	};

	handleAfterPrint = () => {
		this.setState({ isPrinting: false });
	};

	handleVisibleColumnChange = (oldVisibleColumns, newVisibleColumns) => {
		const isAnyColumnNew = some(newVisibleColumns, ({ key }) => !includes(oldVisibleColumns, key));
		if (isAnyColumnNew) this.refetchData();
	};

	render = () => {
		const {
			permissions: { allowReportAll },
			fetchingData,
			fetchingAdditionalData,
			columns,
			data,
			filteredRows,
			inlineFilters,
			expanded,
			defaultColumns,
			filters,
			activeFilters,
			lastApiRefNum,
			loadMoreLimit,
			tooltipProps,
			showTour,
			printInPortrait,
			printOnOnePage,
			selectedCurrency,
			totalLabel,
			customDisplayLabels,
			maxApiRangeBreach,
		} = this.state;

		return (
			<Fragment>
				{allowReportAll ? (
					<GridComponent
						emptyMessage="You should change your filter options"
						fetchingData={fetchingData}
						fetchingAdditionalData={fetchingAdditionalData}
						filteredRows={filteredRows}
						columns={columns}
						data={data}
						resolveColumnName={this.resolveColumnName}
						inlineFilters={inlineFilters}
						components={this.components}
						onChange={this.handleChange}
						isExpandable={false}
						expanded={expanded}
						hasPaging={true}
						loadMoreOptions={loadMoreOptionsWithAll}
						onLoadMoreLimitChange={this.onLoadMoreLimitChange}
						title="Transfers"
						filterColumns={true}
						defaultColumns={defaultColumns}
						enableExport={true}
						enablePrint={true}
						printTitle="Transfer report"
						printHideApprovedAmount={true}
						printHideTotalByCard={true}
						type="transfers"
						filters={filters}
						activeFilters={activeFilters}
						enableFilters={true}
						fetchData={this.refetchData}
						fetchAllData={this.getAllTransactions}
						lastApiRefNum={lastApiRefNum}
						hasMoreData={this.hasMoreData}
						showResults={true}
						ref={this.gridRef}
						initialFetch={false}
						columnFilterType="/settings/user-settings/transaction-history"
						kvaasResourceType="transfer"
						useInlineFilters={true}
						syncQueryFilters={true}
						queryFilterValues={this.queryFilterValues}
						allTitle="All transfers"
						exportTypes={exportTypes}
						maxApiRangeBreach={maxApiRangeBreach}
						showRefreshButton={true}
						fetchExportData={{
							current: exportData => exportService.mapTransferData(exportData, totalLabel, selectedCurrency),
							all: exportService.getTransferData(activeFilters, this.getFields(columns)),
						}}
						loadMoreLimit={loadMoreLimit}
						tooltipProps={tooltipProps}
						filterProps={{
							showHideAccountKeyColumn: this.showHideAccountKeyColumn,
						}}
						expandInSidebar={true}
						classes={this.classes}
						rowRendererDependentProps={{
							customDisplayLabels,
						}}
						onVisibleColumnsChange={this.handleVisibleColumnChange}
						printInPortrait={printInPortrait}
						printOnOnePage={printOnOnePage}
						showActionsOnError={true}
						hideColumnFilterSettings={true}
						hideColumnFilterSaveSelection={true}
					/>
				) : null}
				{showTour && <Tour tourConfig={tourConfig} />}
			</Fragment>
		);
	};
}

TransfersGrid.propTypes = {
	makePendingRequest: PropTypes.func,
	handleError: PropTypes.func,
	location: PropTypes.object,
	history: PropTypes.object,
	activeFilters: PropTypes.any,
	loadMore: PropTypes.func,
};

export default withError(withLoadMore(TransfersGrid));
