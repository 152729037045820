import React, { Component, createRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, get, each, some, toLower, find } from 'lodash';
import { Data } from 'react-data-grid-addons';

import { GridComponent } from 'common/components/grid';
import { withError } from 'common/components/error';
import { withCancelable } from 'common/components/cancelable';
import {
	portalManagementFilter,
	compileFilter,
	PortalManagementApiFilter,
} from 'common/components/portal-management/filter';
import { portalManagementColumns } from 'common/components/portal-management/column-filter/portalManagementColumns';
import { ZebraRenderer } from 'common/components/row';
import { portalManagementService } from 'common/services';
import { PopoverGridTooltip } from 'common/components/tooltips';
import { withLoader } from 'common/components/loader';
import { Notification } from 'common/components/notifications';
import { ActionsModal } from 'common/components/transaction-actions';

const requestKeys = {
	FETCH: 'fetch',
};

class PortalManagement extends Component {
	constructor(props) {
		super(props);

		this.state = {
			filters: cloneDeep(portalManagementFilter),
			activeFilters: cloneDeep(portalManagementFilter),
			columns: cloneDeep(portalManagementColumns),
			inlineFilters: {},
			data: null,
			filteredRows: [],
			fetchData: false,
			fetchingAdditionalData: false,
			lastApiRefNum: null,
		};

		this.gridRef = createRef();
		this.notificationRef = createRef();
	}

	components = {
		filter: PortalManagementApiFilter,
		rowRenderer: ZebraRenderer,
		tooltip: PopoverGridTooltip,
		modal: ActionsModal,
		gridHeader: () => this.renderGridHeader(),
	};

	classes = {
		gridHeader: '',
		filter: '',
	};

	getSnapshotBeforeUpdate = prevProps => prevProps.location.key !== this.props.location.key;

	componentDidUpdate = (_, __, snapshot) => {
		if (snapshot) {
			this.setState(this.initialState, () => {
				if (this.gridRef.current) {
					this.gridRef.current.clearFilters();
					this.gridRef.current.reset();
				}
			});
		}
	};

	fetchData = async filters => {
		const { handleError, makePendingRequest } = this.props;
		this.setState({
			fetchingData: true,
			data: null,
			filteredRows: [],
			lastApiRefNum: null,
		});

		try {
			const filter = await makePendingRequest(compileFilter(filters), requestKeys.FETCH);

			const user = await makePendingRequest(portalManagementService.get(filter), requestKeys.FETCH);
			let data = {};
			if (user.result.toLowerCase() === 's' && user.status.toLowerCase() !== 'nonexistent') {
				const [authEvents, userGroups] = await makePendingRequest(
					Promise.all([portalManagementService.listAuthEvents(filter), portalManagementService.listUserGroups(filter)]),
					requestKeys.FETCH
				);
				data = this.mapData({ user, authEvents, userGroups });
			}

			const lastApiRefNum = data.refNum;
			const filteredRows =
				data && data.xReportData
					? Data.Selectors.getRows({
							rows: data.xReportData,
							filters: this.state.inlineFilters,
					  })
					: [];
			this.setState(
				{
					data,
					filteredRows,
					fetchingData: false,
					lastApiRefNum: lastApiRefNum,
				},
				() => {
					const grid = this.gridRef.current;
					if (grid) {
						grid.handleInitialSort();
						grid.calculateColumnWidths();
					}
				}
			);
		} catch (e) {
			if (handleError(e)) {
				this.setState({
					fetchingData: false,
				});
			}
		}
	};

	mapData = ({ user: { status, username, mfa, ...rest }, userGroups, authEvents: { events } }) => {
		let i = 0;
		const data = { ...rest, xReportData: [{ status, username, mfa }] };

		each(data.xReportData, item => {
			item.isAdmin = some(get(userGroups, 'groups'), group => toLower(group) === 'admin');
			const lastLogin = find(events, ({ eventType: { value } }) => toLower(value) === 'signin');
			item.lastLoginDateMoment = get(lastLogin, 'creationDateMoment');
			item.lastLoginDate = get(lastLogin, 'creationDate');
			item.lastLoginIp = get(lastLogin, 'eventContextData.ipAddress', '');
			item.openActions = this.openActions;
			item.refreshGridData = this.gridRef && this.gridRef.current && this.gridRef.current.refreshGridData;
			item.showLoader = this.props.showLoader;
			item.notificationRef = this.notificationRef && this.notificationRef.current;
			item.openCloseModal = this.gridRef.current.openCloseModal;
			item.gridRowNumber = i;
			item.index = i + 1;
			item.onDelete = this.refetchData;
			i++;
		});

		return data;
	};

	openActions = (infoDimensions, tooltip) => {
		this.setState({ tooltipProps: { infoDimensions, tooltip } }, () => {
			if (this.gridRef.current) {
				this.gridRef.current.forceUpdate();
			}
		});
	};

	refetchData = () => {
		const { activeFilters } = this.state;
		this.fetchData(activeFilters);
	};

	resolveColumnName = column => {
		switch (column) {
			case 'lastLoginDate':
				return 'lastLoginDateMoment';
			default:
				return column;
		}
	};
	showAllAccounts = () => {
		this.props.history.push({ pathname: '/portal-management/all' });
	};
	renderGridHeader = () => {
		return (
			<button type="button" className="btn btn--primary btn--med spc--bottom--sml" onClick={this.showAllAccounts}>
				<span className="hide--to--sml--block">Show All Accounts</span>
				<span className="show--to--sml--block">Show All</span>
			</button>
		);
	};
	handleChange = changes => {
		const newState = {};
		each(changes, ({ key, value }) => {
			if (key === 'data' || key === 'inlineFilters') {
				let filters, data;
				if (key === 'data') {
					filters = this.state.inlineFilters;
					data = value;
				} else {
					filters = value;
					data = this.state.data;
				}
				newState.filteredRows =
					data && data.xReportData
						? Data.Selectors.getRows({
								rows: data.xReportData,
								filters,
						  })
						: [];
			}
			newState[key] = value;
		});
		return new Promise(resolve => {
			this.setState(newState, resolve);
		});
	};

	render() {
		const {
			fetchingData,
			fetchingAdditionalData,
			filteredRows,
			columns,
			data,
			inlineFilters,
			filters,
			activeFilters,
			lastApiRefNum,
			tooltipProps,
		} = this.state;
		return (
			<Fragment>
				<Notification ref={this.notificationRef} />
				<GridComponent
					emptyMessage="You should change your filter options"
					fetchingData={fetchingData}
					fetchingAdditionalData={fetchingAdditionalData}
					filteredRows={filteredRows}
					columns={columns}
					data={data}
					inlineFilters={inlineFilters}
					components={this.components}
					onChange={this.handleChange}
					title="Portal Management"
					filters={filters}
					activeFilters={activeFilters}
					enableFilters={true}
					fetchData={this.refetchData}
					lastApiRefNum={lastApiRefNum}
					ref={this.gridRef}
					initialFetch={false}
					useInlineFilters={true}
					resolveColumnName={this.resolveColumnName}
					fetchOnReset={false}
					classes={this.classes}
					tooltipProps={tooltipProps}
				/>
			</Fragment>
		);
	}
}

PortalManagement.propTypes = {
	makePendingRequest: PropTypes.func,
	handleError: PropTypes.func,
	location: PropTypes.object,
	showLoader: PropTypes.func.isRequired,
	history: PropTypes.object,
};

export default withError(withLoader(withCancelable(PortalManagement)));
