import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';

import isEmailFn from './isEmail';

class ReactMultiEmail extends Component {
	state = {
		focused: false,
		emails: [],
		inputValue: '',
	};

	emailInputRef;

	static getDerivedStateFromProps(nextProps, prevState) {
		if (prevState.propsEmails !== nextProps.emails) {
			return {
				propsEmails: nextProps.emails || [],
				emails: nextProps.emails || [],
				inputValue: '',
				focused: false,
			};
		}
		return null;
	}

	constructor(props) {
		super(props);

		this.emailInputRef = createRef();
	}

	findEmailAddress = (value, isEnter) => {
		const { validateEmail } = this.props;
		let validEmails = [];
		let inputValue = '';
		const re = /[ ,;]/g;
		const isEmail = validateEmail || isEmailFn;

		const addEmails = email => {
			const emails = this.state.emails;
			for (let i = 0, l = emails.length; i < l; i++) {
				if (emails[i] === email) {
					return false;
				}
			}
			validEmails.push(email);
			return true;
		};

		if (value !== '') {
			if (re.test(value)) {
				let splitData = value.split(re).filter(n => {
					return n !== '' && n !== undefined && n !== null;
				});

				const setArr = new Set(splitData);
				let arr = [...setArr];

				do {
					if (isEmail('' + arr[0])) {
						addEmails('' + arr.shift());
					} else {
						if (arr.length === 1) {
							/// 마지막 아이템이면 inputValue로 남겨두기
							inputValue = '' + arr.shift();
						} else {
							arr.shift();
						}
					}
				} while (arr.length);
			} else {
				if (isEmail(value) && isEnter) {
					addEmails(value);
				} else {
					inputValue = value;
				}
				this.props.onChange({ inputValue, isValidInput: isEmail(value) });
			}
		}

		this.setState({
			emails: [...this.state.emails, ...validEmails],
			inputValue,
		});

		if (validEmails.length && this.props.onChange) {
			this.props.onChange({ emails: [...this.state.emails, ...validEmails] });
		}
	};

	onChangeInputValue = value => {
		this.findEmailAddress(value);
	};

	removeEmail = index => {
		this.setState(
			prevState => {
				return {
					emails: [...prevState.emails.slice(0, index), ...prevState.emails.slice(index + 1)],
				};
			},
			() => {
				if (this.props.onChange) {
					this.props.onChange({ emails: this.state.emails, isValidInput: false });
				}
			}
		);
	};

	handleOnKeydown = e => {
		switch (e.which) {
			case 13:
			case 9:
				e.preventDefault();
				break;
			case 8:
				if (!e.currentTarget.value) {
					this.removeEmail(this.state.emails.length - 1);
				}
				break;
			default:
		}
	};

	handleOnKeyup = e => {
		switch (e.which) {
			case 13:
			case 9:
				this.findEmailAddress(e.currentTarget.value, true);
				break;
			default:
		}
	};

	handleOnChange = e => this.onChangeInputValue(e.currentTarget.value);

	handleOnBlur = e => {
		this.setState({ focused: false });
		this.findEmailAddress(e.currentTarget.value, true);
	};

	handleOnFocus = () =>
		this.setState({
			focused: true,
		});

	render() {
		const { focused, emails, inputValue } = this.state;
		const { style, getLabel, className = '', noClass, placeholder, disabled } = this.props;

		// removeEmail

		return (
			<div
				className={`${className} ${noClass ? '' : 'input input--multiselect'} ${focused ? 'focused' : ''} ${
					inputValue === '' && emails.length === 0 ? 'empty' : ''
				}`}
				style={style}
				onClick={() => {
					if (this.emailInputRef.current) {
						this.emailInputRef.current.focus();
					}
				}}
			>
				{placeholder ? <span data-placeholder>{placeholder}</span> : null}
				{emails.map((email, index) => getLabel(email, index, this.removeEmail))}
				<input
					ref={this.emailInputRef}
					type="text"
					value={inputValue}
					onFocus={this.handleOnFocus}
					onBlur={this.handleOnBlur}
					onChange={this.handleOnChange}
					onKeyDown={this.handleOnKeydown}
					onKeyUp={this.handleOnKeyup}
					disabled={disabled}
				/>
			</div>
		);
	}
}

ReactMultiEmail.propTypes = {
	style: PropTypes.object,
	getLabel: PropTypes.func,
	className: PropTypes.string,
	noClass: PropTypes.string,
	placeholder: PropTypes.string,
	onChange: PropTypes.func,
	validateEmail: PropTypes.func,
	disabled: PropTypes.bool,
};

export default ReactMultiEmail;
