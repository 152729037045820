import React from 'react';
import { get, map, noop, padStart, times } from 'lodash';

import { StringFilter, MultiselectCardknoxFilter, NumericFilter } from '../../columns/filters';
import {
	CurrencyComponent,
	StatusComponent,
	ActiveComponent,
	InputFormatter,
	InfoIconFormatter,
	LinkComponent,
} from '../../columns/formatters';
import { TooltipComponent, ColumnHeader, SelectAllHeader } from '../../columns/headers';
import createGridColumn from 'common/utilities/createGridColumn';

const columns = [
	{
		key: 'isSelected',
		name: '',
		initWidth: 60,
		locked: true,
		sortable: false,
		hideable: false,
		visible: true,
		filterable: false,
		resizable: false,
		fixWidth: true,
		formatter: InputFormatter,
		headerRenderer: props => <SelectAllHeader {...props} />,
		getRowMetaData: ({ scheduleId: dependentValue, _meta: { isSelected: checked } }) => ({
			dependentValue,
			checked,
			value: checked,
			inputType: 'checkbox',
			displayColumnValue: true,
		}),
		order: 0,
		disabled: true,
		disableDrag: true,
	},
	{
		key: 'scheduleId',
		name: 'Recurring I­D',
		sortable: true,
		hideable: true,
		visible: true,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 230,
		resizable: true,
		order: 1,
		locked: true,
		disabled: true,
		disableDrag: true,
	},
	{
		key: 'scheduleName',
		name: 'Schedule Name',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 230,
		customSettingsKey: 'scheduleName',
		resizable: true,
	},
	{
		key: 'billName',
		name: 'Cust­omer N­ame',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 150,
		customSettingsKey: 'customer',
		resizable: true,
	},
	{
		key: 'billCompany',
		name: 'Com­pany',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 150,
		customSettingsKey: 'company',
		resizable: true,
	},
	{
		key: 'customerNumber',
		name: 'Cust­omer #',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 150,
		customSettingsKey: 'customerNumber',
		resizable: true,
	},
	{
		key: 'email',
		name: 'Em­ail',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 150,
		customSettingsKey: 'email',
		resizable: true,
	},
	{
		key: 'dateCreated',
		name: 'Da­te created',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: StringFilter,
		isDefaultSorter: true,
		defaultSortDirection: 'DESC',
		initWidth: 200,
		customSettingsKey: 'dateCreated',
		resizable: true,
	},
	{
		key: 'displayStartDate',
		name: 'Start da­te',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: StringFilter,
		headerRenderer: (
			<TooltipComponent
				name="Start date"
				tooltip="Recurring transaction schedule start date. Schedules are processed in EST."
			/>
		),
		initWidth: 150,
		customSettingsKey: 'startDate',
		resizable: true,
	},
	{
		key: 'nextBillingDate',
		name: 'Next billing da­te',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: StringFilter,
		headerRenderer: <TooltipComponent name="Next billing date" tooltip="Schedules are processed in EST." />,
		initWidth: 170,
		customSettingsKey: 'nextBillingDate',
		resizable: true,
	},
	{
		key: 'displayEndDate',
		name: 'End da­te',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: StringFilter,
		headerRenderer: <TooltipComponent name="End date" tooltip="Schedules are processed in EST." />,
		initWidth: 150,
		customSettingsKey: 'endDate',
		resizable: true,
	},
	{
		key: 'frequency',
		name: 'Frequency',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: MultiselectCardknoxFilter,
		getRowMetaData: row => row,
		initWidth: 160,
		customSettingsKey: 'frequency',
		resizable: true,
	},
	{
		key: 'remainingCharges',
		name: 'Remaining charges',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: NumericFilter,
		headerRenderer: (
			<TooltipComponent
				name="Remaining charges"
				tooltip="Number of payments remaining for the recurring transaction."
			/>
		),
		initWidth: 160,
		customSettingsKey: 'remainingCharges',
		resizable: true,
	},
	{
		key: 'currency',
		name: 'Currency',
		sortable: true,
		hideable: false,
		visible: false,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 75,
		resizable: true,
	},
	{
		key: 'amount',
		name: 'Amo­unt',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: NumericFilter,
		formatter: CurrencyComponent,
		dependentExportKey: 'currency',
		getRowMetaData: row => {
			return { ...row, displayBadge: true };
		},
		initWidth: 126,
		customSettingsKey: 'amount',
		resizable: true,
		alignHeaderRight: true,
	},
	{
		key: 'remainingBalance',
		name: 'Remaining balance',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: NumericFilter,
		headerRenderer: (
			<TooltipComponent name="Remaining balance" tooltip="Remaining amount owed for the recurring transaction." />
		),
		formatter: CurrencyComponent,
		dependentExportKey: 'currency',
		getRowMetaData: row => {
			return { ...row, displayBadge: true };
		},
		initWidth: 160,
		customSettingsKey: 'remainingBalance',
		resizable: true,
		alignHeaderRight: true,
	},
	{
		key: 'totalCharges',
		name: 'Total charges',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: NumericFilter,
		headerRenderer: <TooltipComponent name="Total charges" tooltip="Total amount charged to date." />,
		formatter: props => (
			<InfoIconFormatter
				{...props}
				handleClick={get(props, 'row.openTotalChargesPopup', noop)}
				tooltip="Click to view total charges"
			/>
		),
		dependentExportKey: 'currency',
		getRowMetaData: row => {
			return { ...row, displayBadge: true };
		},
		initWidth: 130,
		customSettingsKey: 'totalCharges',
		resizable: true,
		alignHeaderRight: true,
	},
	{
		key: 'description',
		name: 'Des­cription',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 300,
		customSettingsKey: 'description',
		resizable: true,
	},
	{
		key: 'lastTransactionStatus',
		name: 'Status',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: MultiselectCardknoxFilter,
		headerRenderer: (
			<TooltipComponent name="Status" tooltip="Status of the last transaction processed for this recurring schedule." />
		),
		formatter: StatusComponent,
		getRowMetaData: row => row,
		initWidth: 180,
		customSettingsKey: 'status',
		resizable: true,
	},
	{
		key: 'isActive',
		name: 'Active',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: MultiselectCardknoxFilter,
		headerRenderer: (
			<TooltipComponent name="Active" tooltip="Indicates whether the recurring transaction schedule is active." />
		),
		formatter: ActiveComponent,
		initWidth: 120,
		customSettingsKey: 'active',
		resizable: true,
	},
	{
		key: 'invoice',
		name: 'Inv­oice',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: StringFilter,
		customSettingsKey: 'invoice',
		initWidth: 150,
		resizable: true,
		order: 16,
	},
	{
		key: 'failedTransactionRetryTimes',
		name: 'Retry count',
		sortable: true,
		hideable: true,
		visible: false,
		filterable: true,
		filterRenderer: StringFilter,
		customSettingsKey: 'failedTransactionRetryTimes',
		initWidth: 150,
		resizable: true,
		order: 17,
	},
	...times(19, i => {
		const oneBasedIndex = padStart(i + 1, 2, 0);

		return createGridColumn(`customerCustom${oneBasedIndex}`, `Custom${oneBasedIndex}`, 150, {
			filterRenderer: StringFilter,
			formatter: LinkComponent,
			initWidth: 150,
			customSettingsKey: `custom${i + 1}`,
		});
	}),
];

export const RecurringScheduleColumns = map(columns, column => {
	column = {
		headerRenderer: <ColumnHeader />,
		...column,
	};
	return column;
});
