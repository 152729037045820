import React from 'react';
import { withRouter } from 'react-router-dom';
import { object } from 'prop-types';

function VP3300(props) {
	return (
		<div className="">
			<p className="spc--bottom--sml">
				Boost your revenue by accepting payments on the go! Order our compact card reader, the ID TECH VP3300.
			</p>
			<div className="spc--bottom--sml--alt">
				<button className="btn btn--sml btn--primary" onClick={() => props.history.push('features/vp3300')}>
					Order Now
				</button>
				<button
					className="btn btn--sml btn--secondary spc--left--tny"
					onClick={() =>
						window.open(
							'https://www.cardknox.com/vp3300-integration/?utm_source=portal&utm_medium=banner&utm_campaign=cs',
							'_blank',
							'noopener'
						)
					}
				>
					Learn More
				</button>
			</div>
		</div>
	);
}
VP3300.propTypes = {
	history: object.isRequired,
};
export default withRouter(VP3300);
