import React from 'react';
import { string } from 'prop-types';

const LogoPreviewComponent = ({ logoUrl }) => (
	<div className="modal">
		<div className="modal__header">
			<div className="modal__header__title">Logo Preview</div>
		</div>
		<div className="modal__body">
			<img src={logoUrl} alt="Logo Image" width={'100%'} />
		</div>
		<div className="modal__footer"></div>
	</div>
);

LogoPreviewComponent.propTypes = {
	logoUrl: string.isRequried,
};

export default LogoPreviewComponent;
