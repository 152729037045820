import React, { Component, createRef } from 'react';
import { cloneDeep, each, get, includes, isEmpty, map, noop, split } from 'lodash';
import { bool, func } from 'prop-types';

import { withCancelable } from 'common/components/cancelable';
import { withError } from 'common/components/error';
import { withLoader } from 'common/components/loader';
import { withBlock } from 'common/components/block';
import { Notification } from 'common/components/notifications';
import { kvaasService, principalService } from 'common/services';
import { checkForUrls, kvaasResources } from 'common/utilities';
import { ActionsModal, modalNames } from 'common/components/transaction-actions';
import { Tour } from 'common/components/tour';
import FooterComponent from '../components/FooterComponent';

const requestKeys = {
	DATA: 'data',
	KVAAS: 'kvaas',
	SAVE: 'save',
	FETCH: 'fetch',
};

const fromCompanyTooltip =
	"To use a different from company, type in your changes. To use the text currently displayed, don't make any changes. Available placeholders like [invoice], [company], [link], and [name] are replaced with the appropriate text when Cardknox sends the email. When left blank the field will be prefilled with your company name";

const subjectTooltip =
	"To use a different subject line, type in your changes. To use the text currently displayed, don't make any changes. Available placeholders like [invoice], [company], [link], and [name] are replaced with the appropriate text when Cardknox sends the email.";
const contentTooltip =
	"To use different text for the email message, type in your changes. To use the text currently displayed, don't make any changes. Available placeholders like [invoice], [company], [link], and [name] are replaced with the appropriate text when Cardknox sends the email.";

const tourConfig = {
	version: 1, // increase this every time you make changes to the tourConfig,
	key: 'sendTestEmail',
	steps: [
		{
			selector: '#sendTestEmail',
			content: 'Send a test email to yourself or others.',
		},
	],
};

class SendPaymentRequestManagementComponent extends Component {
	constructor() {
		super();
		const { companyName: fromCompany = '', hasAccess } = principalService.get();

		this.top = createRef();
		this.notificationRef = createRef();

		this.state = {
			isAdmin: hasAccess.users,
			isContentValid: true,

			principalData: {
				fromCompany,
			},
			errorMessages: [],
			isSaving: false,
			modal: {
				name: modalNames.none,
				data: null,
			},
			oldData: {
				sendPaymentRequestManagement: null,
				portalFlags: null,
			},
			sendPaymentRequestManagement: {
				subject: '',
				content: '',
				fromCompany: '',
			},
			portalFlags: {
				allowAdjustAmount: false,
				enableSmsRequests: false,
			},
		};
	}

	get isLoadingOrInvalid() {
		return this.props.isLoading || !this.state.isContentValid;
	}

	componentDidMount() {
		this.fetchData();
	}

	fetchData = async () => {
		const {
			props: { showLoader, handleError },
			fetchKvaas,
		} = this;
		try {
			showLoader(true);
			const [sendPaymentRequestManagement, portalFlags] = await fetchKvaas();
			const newState = this.mapResponseToState(sendPaymentRequestManagement, portalFlags);
			newState.isContentValid = this.validateContent(get(sendPaymentRequestManagement, 'data.content', ''));
			this.setState(newState, () => {
				if (!isEmpty(newState.errorMessages)) {
					this.scrollToTop();
				}
			});
		} catch (e) {
			this.props.handleKvaasLoadError();
			handleError(e);
		}
		showLoader(false);
	};

	fetchKvaas = async () => {
		const [sendPaymentRequestManagement, portalFlags] = await this.props.makePendingRequest(
			kvaasService.getIgnoreCache(
				{ ...kvaasResources.sendPaymentRequestManagement, throwError: true },
				{ ...kvaasResources.portalFlags, throwError: true }
			),
			requestKeys.KVAAS
		);
		return [sendPaymentRequestManagement, portalFlags];
	};

	setStateAsync = newState => {
		return new Promise(resolve => {
			this.setState(newState, resolve);
		});
	};

	validateContent = (content = this.state.sendPaymentRequestManagement.content) => {
		return includes(content, '[link]') && !checkForUrls(content);
	};

	mapResponseToState = (
		oldSendPaymentRequestSettings = this.state.oldData.sendPaymentRequestManagement,
		oldPortalFlags = this.state.oldData.portalFlags
	) => {
		const { sendPaymentRequestManagement, portalFlags, oldData } = cloneDeep(this.state);
		const newState = {
			errorMessages: [],
			oldData,
			sendPaymentRequestManagement,
			portalFlags,
		};
		const callback = type => (value, key) => (newState[type][key] = value);
		kvaasService.mapFieldsToState(
			newState,
			oldSendPaymentRequestSettings,
			'sendPaymentRequestManagement',
			callback('sendPaymentRequestManagement')
		);
		kvaasService.mapFieldsToState(newState, oldPortalFlags, 'portalFlags', callback('portalFlags'));

		return newState;
	};

	mapDefaultPaymentRequestValues = (field, key, defaultData) => {
		const { principalData } = this.state;
		if (includes(['fromCompany'], key) && (!field || defaultData)) {
			return principalData[key];
		}
		if (defaultData) {
			return defaultData[key] || false;
		}
		return field;
	};
	mapStateToRequiredFields = async setToDefaults => [
		await this.mapStateToFields(
			'sendPaymentRequestManagement',
			kvaasResources.sendPaymentRequestManagement,
			setToDefaults
		),
		await this.mapStateToFields('portalFlags', kvaasResources.portalFlags),
	];

	mapStateToFields = async (key, { primaryKey, userSetting, defaultData }, resetToDefault) => {
		try {
			const newState = cloneDeep(this.state);
			const data = {};
			const sections = {
				[key]: this.state[key],
			};
			each(sections, section => {
				each(section, (field, fieldKey) => {
					if (resetToDefault) {
						data[fieldKey] = this.mapDefaultPaymentRequestValues(field, fieldKey, defaultData);
					} else {
						data[fieldKey] = this.mapDefaultPaymentRequestValues(field, fieldKey);
					}
				});
			});
			await this.setStateAsync(newState);
			return {
				newData: {
					revision: 0,
					data,
				},
				oldData: this.state.oldData[key],
				primaryKey,
				userSetting,
			};
		} catch (e) {
			this.props.handleError(e, { additionalInfo: { key, primaryKey, resetToDefault } });
		}
	};

	scrollToTop = () => {
		if (this.top.current) {
			this.top.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
		}
	};

	save = async setToDefaults => {
		const { showLoader, handleBlockChange, makePendingRequest, handleError } = this.props;
		let refreshData = false;
		let refNum;
		let error;
		const addNotification = get(this.notificationRef, 'current.addNotification', noop);
		showLoader(true);
		const mappedState = await this.mapStateToRequiredFields(setToDefaults);

		try {
			const [sendPaymentRequestManagement, portalFlags] = await makePendingRequest(
				kvaasService.save(...mappedState),
				requestKeys.SAVE
			);

			const newState = this.mapResponseToState(sendPaymentRequestManagement, portalFlags);

			refNum = sendPaymentRequestManagement.refNum;

			if (setToDefaults) {
				newState.isContentValid = true;
				newState.sendPaymentRequestManagement = { ...sendPaymentRequestManagement.data };
			}
			showLoader(false);
			this.setState(newState, () => {
				if (!isEmpty(newState.errorMessages)) {
					this.scrollToTop();
				}
			});
			handleBlockChange(false);
		} catch (e) {
			error = handleError(e, { delayMessage: true });
			if (error) {
				refreshData = true;
			} else {
				return;
			}
		}
		if (refreshData) {
			try {
				const response = await makePendingRequest(
					kvaasService.getIgnoreCache(kvaasResources.sendPaymentRequestManagement, kvaasResources.portalFlags),
					requestKeys.REFRESH
				);
				const newState = this.mapResponseToState(...response);
				this.setState(newState, () => {
					if (!isEmpty(newState.errorMessages)) {
						this.scrollToTop();
					}
				});
				showLoader(false);
				handleBlockChange(false);
			} catch (e) {
				error = handleError(e, { delayMessage: true });
				if (error) {
					refreshData = true;
				} else {
					return;
				}
			}
			showLoader(false);
			if (!isEmpty(this.state.errorMessages)) {
				this.scrollToTop();
			} else {
				error.show();
			}
		}
		if (!error) {
			addNotification({
				message: `Send Payment Request settings${setToDefaults ? ' reset to default' : ' updated'}`,
				ref: refNum,
				success: true,
			});
		}
	};

	openSendTestEmail = () =>
		this.setState({
			modal: {
				name: modalNames.sendEmail,
				data: {
					handleError: this.props.handleError,
					addNotification: get(this.notificationRef, 'current.addNotification', noop),
					sendPaymentRequestManagement: cloneDeep(this.state.sendPaymentRequestManagement),
				},
			},
		});

	handleChange = ({ target: { name, checked, value, type } }) => {
		const newState = cloneDeep(this.state);
		const newValue = type === 'checkbox' ? checked : value;
		if (includes(name, 'content')) {
			newState.isContentValid = this.validateContent(value);
		}
		if (includes(name, '.')) {
			const [section, key] = split(name, '.');
			newState[section][key] = newValue;
		} else {
			newState[name] = newValue;
		}
		this.setState(newState);
		this.props.handleBlockChange(true);
	};

	openCloseActionsModal = modal => this.setState({ modal });

	mapStateToRequest = (data, oldData) => {
		return {
			newData: {
				revision: 0,
				data,
			},
			oldData,
			...kvaasResources.collapsedSettingsMessages,
		};
	};

	render() {
		const {
			modal,
			errorMessages,
			portalFlags: { enableSmsRequests, allowAdjustAmount },
			sendPaymentRequestManagement: { subject, content, fromCompany },
			isContentValid,
			isAdmin,
		} = this.state;

		return (
			<div className="settings--main">
				<ActionsModal modal={modal} onModalClose={this.openCloseActionsModal} isLoading={this.props.isLoading} />
				<Notification ref={this.notificationRef} />
				{!this.props.isLoading && <Tour tourConfig={tourConfig} />}

				<div className="settings__header">
					<h3 className="settings__title">Account Settings</h3>
					<h5>Send Payment Request Management</h5>
				</div>

				<div ref={this.top}></div>
				{map(errorMessages, (error, index) => (
					<p key={index} className="type--validation spc--bottom--med">
						{error}
					</p>
				))}

				<div className="form__group">
					<div className="form__group__header">
						<label htmlFor="fromEmail" className="form__group__label">
							From Email
						</label>
					</div>
					<input
						type="text"
						id="sendPaymentRequestManagement.fromEmail"
						name="sendPaymentRequestManagement.fromEmail"
						className="input input--med"
						placeholder="From Email"
						disabled={true}
						value="noreply@solapayments.com"
					/>
				</div>
				<div className="form__group">
					<div className="form__group__header">
						<label htmlFor="fromCompany" className="form__group__label">
							From Company
						</label>
						<i
							className="icon icon--tny icon--regular--info datatooltip--w--250 datatooltip--top-right"
							data-tooltip={fromCompanyTooltip}
						></i>
					</div>
					<input
						type="text"
						id="sendPaymentRequestManagement.fromCompany"
						name="sendPaymentRequestManagement.fromCompany"
						className="input input--med"
						placeholder="From Company"
						value={fromCompany}
						onChange={this.handleChange}
					/>
				</div>
				<div className="form__group">
					<div className="form__group__header">
						<label htmlFor="sendPaymentRequestManagement.subject" className="form__group__label">
							Subject
						</label>
						<i
							className="icon icon--tny icon--regular--info datatooltip--w--250 datatooltip--top-right"
							data-tooltip={subjectTooltip}
						></i>
					</div>
					<input
						type="text"
						id="sendPaymentRequestManagement.subject"
						name="sendPaymentRequestManagement.subject"
						className="input input--med"
						placeholder="Subject"
						value={subject}
						onChange={this.handleChange}
					/>
				</div>
				<div className="form__group">
					<div className="form__group__header">
						<label htmlFor="sendPaymentRequestManagement.content" className="form__group__label">
							Content
							{!isContentValid && (
								<span data-tooltip="[link] required and no other links allowed" className="form__group__required">
									{' '}
									*
								</span>
							)}
						</label>
						<i
							className="icon icon--tny icon--regular--info datatooltip--w--250 datatooltip--top-right"
							data-tooltip={contentTooltip}
						></i>
					</div>
					<textarea
						rows="7"
						cols="10"
						name="sendPaymentRequestManagement.content"
						className={`input input--textarea input--textarea--vertical${isContentValid ? '' : ' is-invalid'}`}
						placeholder="Content"
						value={content}
						onChange={this.handleChange}
					/>
				</div>

				<div className="flex--primary flex--gap--sml--alt spc--bottom--lrg">
					<div data-tooltip="Restore email to the original template.">
						<button
							className="btn btn--med btn--secondary"
							disabled={this.props.isLoading}
							onClick={() => this.save(true)}
						>
							Reset
						</button>
					</div>

					<button
						id="sendTestEmail"
						className="btn btn--med btn--primary"
						disabled={this.isLoadingOrInvalid}
						onClick={this.openSendTestEmail}
					>
						Send Test Email
					</button>
				</div>

				<div className="spc--bottom--sml">
					<input
						type="checkbox"
						id="portalFlags.allowAdjustAmount"
						name="portalFlags.allowAdjustAmount"
						checked={allowAdjustAmount}
						value={allowAdjustAmount}
						onChange={this.handleChange}
						className="input--check"
					/>
					<label htmlFor="portalFlags.allowAdjustAmount">
						"Allow User To Adjust Amount" Checkbox Unchecked By Default
					</label>
				</div>

				{isAdmin && (
					<div className="flex--primary flex--gap--tny">
						<div>
							<input
								type="checkbox"
								id="portalFlags.enableSmsRequests"
								name="portalFlags.enableSmsRequests"
								checked={enableSmsRequests}
								value={enableSmsRequests}
								onChange={this.handleChange}
								className="input--check"
							/>
							<label htmlFor="portalFlags.enableSmsRequests">Enable Payment Requests via SMS</label>
						</div>
						<i
							className="icon icon--tny icon--regular--info datatooltip--top-left"
							data-tooltip="A $0.02 SMS fee will apply for every SMS payment request sent.  Changes made to this setting will be applied to the Mobile App as well."
						></i>
					</div>
				)}

				<FooterComponent
					save={this.save}
					isLoading={this.props.isLoading}
					disabled={this.isLoadingOrInvalid}
					hideReset={true}
				/>
			</div>
		);
	}
}

SendPaymentRequestManagementComponent.propTypes = {
	showLoader: func.isRequired,
	handleError: func.isRequired,
	makePendingRequest: func.isRequired,
	isLoading: bool.isRequired,
	handleBlockChange: func.isRequired,
	handleKvaasLoadError: func,
};

export default withError(withLoader(withCancelable(withBlock(SendPaymentRequestManagementComponent))));
