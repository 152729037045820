import { hasFeaturePackage } from 'common/utilities';
import { featurePackages } from 'common/utilities/has-feature-package';
import { get, toLower } from 'lodash';

const hasTerminalOnly = () => {
	return hasFeaturePackage(featurePackages.terminalOnly);
};
const isVoid = (xVoid, xResponseResult) => {
	return isApproved(xResponseResult) && xVoid === '1';
};
const isViewOnly = principal => {
	return get(principal, 'isViewOnly', false);
};

const isErroredToken = (xResponseResult, xToken) => {
	return xResponseResult === 'Error' && xToken;
};

const isDeclined = xResponseResult => {
	return toLower(xResponseResult) === 'declined';
};
const isErrored = xResponseResult => {
	return toLower(xResponseResult) === 'error';
};

const isApproved = xResponseResult => {
	return toLower(xResponseResult) === 'approved';
};
const isRefund = (xCommand = '') => {
	return xCommand.toLowerCase().split(':')[1] === 'refund';
};
const hasCustomerBilling = () => hasFeaturePackage(featurePackages.customerBilling);
const isNotAllowedPaymentMethod = paymentMethod => {
	return !['cc', 'check'].includes(paymentMethod) && !['gift:redeem', 'gift:issue'].includes(paymentMethod);
};
const shouldDisableAddCustomer = (
	xResponseResult,
	hasCustomerBilling,
	isNotAllowedPaymentMethod,
	xMerchantId,
	xVoid,
	principal
) =>
	toLower(xResponseResult) === 'declined' ||
	hasTerminalOnly() ||
	!hasCustomerBilling ||
	isNotAllowedPaymentMethod ||
	isVoid(xVoid, xResponseResult) ||
	isViewOnly(principal) ||
	!!xMerchantId;

export {
	hasCustomerBilling,
	isNotAllowedPaymentMethod,
	isErroredToken,
	hasTerminalOnly,
	isVoid,
	isViewOnly,
	isApproved,
	isRefund,
	shouldDisableAddCustomer,
	isDeclined,
	isErrored,
};
