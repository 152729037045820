import React, { Component, Fragment, createRef } from 'react';
import PropTypes from 'prop-types';
import { map, cloneDeep, head, find, isEmpty, get } from 'lodash';

import { kvaasService, principalService } from '../../../Common/services';
import { Notification } from '../../../Common/components/notifications';
import { withLoader } from '../../../Common/components/loader';
import { Select } from '../../../Common/components/select';
import { withCancelable } from '../../../Common/components/cancelable';
import { withError } from '../../../Common/components/error';
import { kvaasResources } from '../../../Common/utilities';
import { transactionType } from '../../../components/new-transaction/constants';
import { withBlock } from '../../../Common/components/block';
import sectionKeys from '../../../routing/sections';
import handleInvalidRevision from '../utils/invalidRevision';
import FooterComponent from 'components/settings/components/FooterComponent';

const requestKeys = {
	KVAAS: 'kvaas',
	SAVE: 'save',
	FETCH: 'fetch',
};

class UserSettingsNewTransaction extends Component {
	defaultPaymentTypeOptions = [{ label: 'Credit Card', value: 'cc' }, { label: 'Check', value: 'check' }];
	constructor(props) {
		super(props);
		this.top = createRef();
		this.notification = createRef();

		const principal = principalService.get();
		const permissions = (principal && principal.idInfo && principal.idInfo.permissions) || {};

		if (principal) {
			this.hasAch = !!principal.idInfo.xACHEnabled;
			this.hasPermissions = !!principal.hasAccess[sectionKeys.newTransaction];
		}

		this.state = {
			isSaving: false,
			errorMessages: [],
			oldData: {
				userSettings: { data: { ...kvaasResources.userSettings.defaultData } },
			},
			permissions,
			defaultTxnTypeOnNewTxn: '',
			defaultPaymentType: '',
			allowedTransactionTypes: [],
		};
	}

	getAllowedTransactionTypeOptions = allowedOptions =>
		map(allowedOptions, tType => (
			<option key={tType.key} value={tType.key}>
				{tType.label}
			</option>
		));

	getDefaultPaymentType = defPtype =>
		find(this.defaultPaymentTypeOptions, o => o.value === defPtype) || head(this.defaultPaymentTypeOptions);

	setStateAsync = newState => {
		return new Promise(resolve => {
			this.setState(newState, resolve);
		});
	};

	componentDidMount = async () => {
		this.props.showLoader(true);
		try {
			const getKvaas = kvaasService.get({ ...kvaasResources.userSettings, throwError: true });
			const [userSettings] = await this.props.makePendingRequest(getKvaas, requestKeys.FETCH);
			this.props.showLoader(false);
			const newState = {
				oldData: cloneDeep(this.state.oldData),
			};
			newState.defaultTxnTypeOnNewTxn = get(userSettings, 'data.defaultTxnTypeOnNewTxn', '');
			newState.defaultPaymentType = get(userSettings, 'data.defaultPaymentType', '');
			newState.oldData.userSettings = userSettings;

			newState.allowedTransactionTypes = this.allowedTransactionTypes(newState.showEbtfsVoucherOption);
			this.handleSetNewState(newState);
		} catch (e) {
			if (this.props.handleError(e)) {
				this.props.handleKvaasLoadError();
			}
		}
	};
	displayErrors = errors => {
		return map(errors, (error, index) => (
			<p key={index} className="type--validation spc--bottom--med">
				{error}
			</p>
		));
	};

	mapStateToRequiredFields = async setToDefaults => [
		{
			newData: {
				revision: 0,
				data:
					setToDefaults && this.hasPermissions
						? { ...this.state.oldData.userSettings.data, defaultTxnTypeOnNewTxn: '', defaultPaymentType: '' }
						: {
								...this.state.oldData.userSettings.data,
								defaultTxnTypeOnNewTxn: this.state.defaultTxnTypeOnNewTxn,
								defaultPaymentType: this.state.defaultPaymentType,
						  },
			},
			oldData: this.state.oldData.userSettings,
			...kvaasResources.userSettings,
		},
	];

	handleSetNewState = newState =>
		this.setState(newState, () => {
			if (!isEmpty(newState.errorMessages)) {
				this.scrollToTop();
			}
		});
	scrollToTop = () => {
		if (this.top.current) {
			this.top.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
		}
	};

	handleDefaultTransactionTypeChange = ({ value }, { name }) => this.handleChangeEvent({ target: { name, value } });
	handleChangeEvent = ({ target: { name, value } }) => {
		const newState = {
			[name]: value,
		};
		this.setState(newState);
	};

	save = async setToDefaults => {
		let refreshData = false;
		let refNum;
		let error;
		this.props.showLoader(true);
		const mappedState = await this.mapStateToRequiredFields(setToDefaults);

		try {
			const [userSettings] = await this.props.makePendingRequest(kvaasService.save(...mappedState), requestKeys.SAVE);
			const newState = {
				oldData: cloneDeep(this.state.oldData),
			};
			refNum = userSettings.refNum;
			newState.defaultTxnTypeOnNewTxn = get(userSettings, 'data.defaultTxnTypeOnNewTxn', '');
			newState.defaultPaymentType = get(userSettings, 'data.defaultPaymentType', '');

			newState.oldData.userSettings = userSettings;
			this.props.showLoader(false);
			this.handleSetNewState(newState);
			this.props.handleBlockChange(false);
		} catch (e) {
			error = this.props.handleError(e, { delayMessage: true });
			if (error) {
				refreshData = true;
			} else {
				return;
			}
		}
		if (refreshData) {
			try {
				const [userSettings] = await this.props.makePendingRequest(
					kvaasService.get(kvaasResources.userSettings),
					requestKeys.KVAAS
				);
				const newState = {
					oldData: cloneDeep(this.state.oldData),
				};
				refNum = userSettings.refNum;
				newState.defaultTxnTypeOnNewTxn = get(userSettings, 'data.defaultTxnTypeOnNewTxn', '');
				newState.defaultPaymentType = get(userSettings, 'data.defaultPaymentType', '');
				newState.oldData.userSettings = userSettings;
				this.setState(newState, () => {
					this.shouldScrollTop(newState.errorMessages);
				});
				this.props.showLoader(false);
				this.props.handleBlockChange(false);
			} catch (e) {
				const handledError = this.props.handleError(e, { delayMessage: true });
				error = handledError;
			}
			this.props.showLoader(false);
			this.shouldScrollTop(this.state.errorMessages, error);
		}
		if (!error) {
			this.notification.current.addNotification({
				message: setToDefaults ? 'New Transaction settings reset to default' : 'New Transaction settings updated',
				ref: refNum,
				success: true,
			});
		}
	};

	shouldScrollTop = (errorMessages, error) => {
		if (!isEmpty(errorMessages)) {
			return this.scrollToTop();
		} else if (error) {
			error.show();
		}
	};

	allowedTransactionTypes = showEbtfsVoucher => {
		const allowedPermissions = [];
		const { permissions, showEbtfsVoucherOption } = this.state;
		const {
			allowCcSale,
			allowCcSave,
			allowCcAuthOnly,
			allowCcCredit,
			allowCcPostAuth,
			allowGiftIssue,
			allowGiftRedeem,
			allowEbtfsVoucher,
		} = permissions;

		if (allowCcSale) allowedPermissions.push({ key: transactionType.SALE, label: 'Charge' });
		if (allowCcSave) allowedPermissions.push({ key: transactionType.SAVE, label: 'Save' });
		if (allowCcAuthOnly) allowedPermissions.push({ key: transactionType.AUTH_ONLY, label: 'Auth Only' });
		if (allowCcCredit) allowedPermissions.push({ key: transactionType.CREDIT, label: 'Refund' });
		if (allowCcPostAuth) allowedPermissions.push({ key: transactionType.POST_AUTH, label: 'Post Auth' });
		if (allowGiftIssue) allowedPermissions.push({ key: transactionType.GIFT_ISSUE, label: 'Gift: Issue' });
		if (allowGiftRedeem) allowedPermissions.push({ key: transactionType.GIFT_REDEEM, label: 'Gift: Redeem' });
		if (allowEbtfsVoucher && (showEbtfsVoucherOption || showEbtfsVoucher))
			allowedPermissions.push({ key: transactionType.EBTFS_VOUCHER, label: 'EBTFS Voucher' });
		return allowedPermissions;
	};
	shouldDisableSaveButton = () => {
		const { isLoading } = this.props;
		const { invalidFields, errorMessages } = this.state;
		return isLoading || !isEmpty(errorMessages) || !isEmpty(invalidFields);
	};

	render() {
		const { errorMessages, defaultTxnTypeOnNewTxn, defaultPaymentType, allowedTransactionTypes } = this.state;

		const { top, notification } = this;

		return (
			<div className="settings--main">
				<div className="settings__header">
					<h3 className="settings__title">User Settings</h3>
					<h5>New Transactions Settings</h5>
				</div>

				<div ref={top}></div>
				{this.displayErrors(errorMessages)}

				{this.hasPermissions && (
					<Fragment>
						<div className="form__group">
							<div className="form__group__header">
								<label htmlFor="convenienceCustomKey" className="form__group__label">
									Default Transaction Type
								</label>
							</div>
							<select
								name="defaultTxnTypeOnNewTxn"
								onChange={this.handleChangeEvent}
								value={defaultTxnTypeOnNewTxn}
								className="input input--med input--select"
							>
								{this.getAllowedTransactionTypeOptions(allowedTransactionTypes)}
							</select>
						</div>
						{this.hasAch && (
							<div className="form__group">
								<div className="form__group__header">
									<label htmlFor="convenienceCustomKey" className="form__group__label">
										Default Payment Type
									</label>
								</div>
								<Select
									name="defaultPaymentType"
									id="defaultPaymentType"
									value={this.getDefaultPaymentType(defaultPaymentType)}
									options={this.defaultPaymentTypeOptions}
									onChange={this.handleDefaultTransactionTypeChange}
									menuPlacement="auto"
								/>
							</div>
						)}
					</Fragment>
				)}

				<FooterComponent save={this.save} isLoading={this.props.isLoading} disabled={this.shouldDisableSaveButton()} />
				<Notification ref={notification} />
			</div>
		);
	}
}
UserSettingsNewTransaction.propTypes = {
	showLoader: PropTypes.func,
	isLoading: PropTypes.bool,
	handleBlockChange: PropTypes.func,
	handleError: PropTypes.func,
	makePendingRequest: PropTypes.func,
	handleKvaasLoadError: PropTypes.func,
};

export default withError(withLoader(withCancelable(withBlock(UserSettingsNewTransaction))), handleInvalidRevision);
