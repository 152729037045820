import React, { Component } from 'react';
import moment from 'moment';

import { Modal } from '../modal';
import PrivacyPolicy from '../../../assets/privacy_policy.html';

class PrivacyPolicyFooter extends Component {
	constructor() {
		super();

		this.state = {
			privacyPolicyVisible: false,
		};
	}

	togglePrivacyPolicy = () => {
		const { privacyPolicyVisible } = this.state;
		this.setState({
			privacyPolicyVisible: !privacyPolicyVisible,
		});
	};

	render = () => (
		<div className="auth__main__privacy">
			<Modal
				className="modal__content modal__content--privacy modal--med"
				isOpen={this.state.privacyPolicyVisible}
				onClose={this.togglePrivacyPolicy}
			>
				<PrivacyPolicy />
				<div className="modal__footer"></div>
			</Modal>
			&copy; Copyright {moment().format('YYYY')} Sola -{' '}
			<button
				href="#"
				className="auth__main__privacy__link"
				onClick={e => {
					e.preventDefault();
					this.togglePrivacyPolicy();
				}}
			>
				Privacy Policy
			</button>
		</div>
	);
}

export default PrivacyPolicyFooter;
