import React, { Component, Fragment } from 'react';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import PropTypes from 'prop-types';
import { cloneDeep, each, map, transform, find, some, findIndex } from 'lodash';

import { principalService } from 'common/services';
import { withRouter } from 'react-router-dom';
import { withForwardRef } from 'common/components/with-forward-ref';
import { parseFilters } from 'common/components/filter/filterUtils';

class PortalManagementApiFilterComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activeKeys: [],
			displayAdvancedFilter: false,
			filters: cloneDeep(props.filters),
			permissions: {},
		};
	}

	setStateAsync = async newState => {
		return new Promise(resolve => {
			this.setState(newState, resolve);
		});
	};

	componentDidMount() {
		try {
			const principal = principalService.get();
			const permissions = (principal && principal.idInfo && principal.idInfo.permissions) || {};
			this.setState({ permissions }, this.syncFilters);
		} catch (e) {
			//eslint-disable-next-line
			console.error(e);
		}
	}

	syncFilters = () => {
		this.setState({
			filters: cloneDeep(this.props.filters),
		});
	};

	injectFilters = filtersToInject => {
		const { filters } = this.state;
		return transform(
			filtersToInject,
			(acc, key) => {
				const filter = find(filters, { key });
				if (filter) {
					acc[key] = filter.values;
				}
			},
			{}
		);
	};

	onFilterChanged = item => {
		const filters = [...this.state.filters];
		const filterIndex = findIndex(filters, { key: item.id });
		const filter = cloneDeep(filters[filterIndex]);
		parseFilters(filter, item);
		filters[filterIndex] = filter;
		this.setState({
			filters,
		});
	};

	resetFilter = () => {
		const { filters, updateFilters } = this.props;
		each(filters, filter => {
			if (!filter.clearable) {
				return;
			}
			if (filter.resetToFalseOnRemove) {
				each(filter.values, (_, key) => {
					filter.values[key] = false;
				});
				filter.hasSelection = false;
			} else {
				filter.values = cloneDeep(filter.defaultValues);
				filter.hasSelection = filter.defaultHasSelection;
			}
		});
		updateFilters({
			filters,
			activeFilters: cloneDeep(filters),
			isReset: true,
		});
		this.setState({ activeKeys: [], filters: cloneDeep(filters) });
	};

	applyFilter = async (_, standaloneFilter) => {
		const { filters } = this.state;
		const { updateFilters } = this.props;
		if (!some(filters, ({ hasSelection }) => hasSelection)) {
			return;
		}
		updateFilters({
			filters: cloneDeep(filters),
			activeFilters: cloneDeep(filters),
			standaloneFilter,
		});
		await this.setStateAsync({ activeKeys: [] });
	};

	applyStandaloneFilter = async item => {
		await this.applyFilter(null, item);
		this.setState({
			filters: cloneDeep(this.props.filters),
		});
	};

	render() {
		const { filters, permissions } = this.state;
		return (
			<Fragment>
				{map(
					filters,
					item =>
						item.standalone && (
							<Fragment key={item.key}>
								<KeyboardEventHandler handleKeys={['enter']} onKeyEvent={() => this.applyStandaloneFilter(item)}>
									<item.component
										filter={item}
										onFilterChanged={this.onFilterChanged}
										permissions={permissions}
										noFocus={true}
										injectedFilters={this.injectFilters(item.injectFilters)}
										goButtonHandler={this.applyFilter}
										goButtonText="Search"
										{...item.props}
									/>
								</KeyboardEventHandler>
							</Fragment>
						)
				)}
			</Fragment>
		);
	}
}

PortalManagementApiFilterComponent.propTypes = {
	className: PropTypes.string,
	title: PropTypes.string,
	updateFilters: PropTypes.func,
	filters: PropTypes.array,
	activeFilters: PropTypes.array,
	clearFilters: PropTypes.func,
	filterSelectionRef: PropTypes.any,
	history: PropTypes.object.isRequired,
};

export default withForwardRef(PortalManagementApiFilterComponent, withRouter);
