import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FraudSettingsActionGrid from './components/FraudSettingsActionGrid';
import { pullAt } from 'lodash';

const ipPattern = /^(\d{1,3}\.){3}\d{1,3}$/;
class AdditionalFraudSettings extends Component {
	constructor(props) {
		super(props);
		this.state = { newValue: '' };
	}
	renderErrors = () => {
		const { isInvalid } = this.state;
		if (!isInvalid) return null;
		return (
			<div className="type--validation spc--bottom--med">
				<p>Invalid Whitelist IP</p>
			</div>
		);
	};
	onInputButton = () => {
		const { newValue } = this.state;
		if (!newValue) return;

		if (!ipPattern.test(newValue)) {
			return this.setState({ isInvalid: true });
		}

		const newIpList = [...this.props.affSettings.AFF_WhiteList_IPs, this.state.newValue];
		this.props.handleSettingsUpdate('affSettings', {
			...this.props.affSettings,
			AFF_WhiteList_IPs: newIpList,
		});
		this.setState({ newValue: '', isInvalid: false });
	};

	onAction = (_, index) => {
		let newSettings = { ...this.props.affSettings };
		pullAt(newSettings.AFF_WhiteList_IPs, index);
		this.props.handleSettingsUpdate('affSettings', newSettings);
	};
	onGridInputChange = value => {
		this.setState({ newValue: value });
	};
	setVelocitySetting = velocity => {
		this.props.handleSettingsUpdate('affSettings', {
			...this.props.affSettings,
			AFF_Velocity_NumberOfCards: velocity,
		});
	};
	render() {
		const { affSettings, isLoading, isExpanded, toggleExpand } = this.props;
		const { newValue } = this.state;
		return (
			<div className="card">
				<button className="card__header card__header--expandable" onClick={toggleExpand}>
					<h5>Additional Fraud Settings</h5>
					<i className={`icon icon--sml icon--chevron--${isExpanded ? 'top' : 'down'}--primary`}></i>
				</button>
				{isExpanded && (
					<div className="card__body">
						<div className="spc--bottom--sml--alt">
							<h5 className="spc--bottom--sml">Whitelist IP Addresses</h5>
							<p className="type--p4 type--color--text--light spc--bottom--sml--alt">
								When IP addresses are entered, transactions will only be permitted from those IP addresses.
							</p>
							{this.renderErrors()}
						</div>
						<FraudSettingsActionGrid
							data={affSettings.AFF_WhiteList_IPs}
							columns={[{ label: '', field: 'ipAddress' }]}
							onAction={this.onAction}
							onInputButton={this.onInputButton.bind(this)}
							gridTitle="List of whitelist IP addresses"
							inputPlaceholder={'000000 000000 000000 000000'}
							addButtonLabel={'Add to whitelist'}
							actionLabel={'Remove'}
							newGridItem={newValue}
							onGridInputChange={this.onGridInputChange}
							isLoading={isLoading}
						/>

						<h5 className="spc--bottom--sml">Velocity settings</h5>
						<div className="flex--primary spc--bottom--med">
							<p className="type--p3 type--color--text--light spc--bottom--med">
								Block an IP address that processed declined cards in the last 10 minutes
							</p>
							<input
								className="input input--med"
								type="number"
								value={affSettings.AFF_Velocity_NumberOfCards}
								onChange={e => this.setVelocitySetting(e.target.value)}
								disabled={isLoading}
							/>
						</div>
					</div>
				)}
			</div>
		);
	}
}
AdditionalFraudSettings.propTypes = {
	isExpanded: PropTypes.bool.isRequired,
	toggleExpand: PropTypes.func.isRequired,
	affSettings: PropTypes.object.isRequired,
	handleSettingsUpdate: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
};
export default AdditionalFraudSettings;
