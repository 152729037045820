import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const ColumnFilterHeader = props => {
	const { hideSettings } = props;
	return (
		<div style={{ display: 'none' }}>
			<p className="pull type--wgt--bold cursor--default">Available Columns</p>
			{!hideSettings && (
				<Link to={props.type} className="push btn btn--link">
					Settings
				</Link>
			)}
		</div>
	);
};

ColumnFilterHeader.propTypes = {
	type: PropTypes.string,
	hideSettings: PropTypes.any,
};

export default ColumnFilterHeader;
