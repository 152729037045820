import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { toLower, split } from 'lodash';
import NumberFormat from 'react-number-format';

import { CurrencyMap, PaymentTransactionTypes, mapAvsItem, CardTypeImagePath } from 'common/utilities';
import { StatusComponent } from 'common/components/columns/formatters';
import { withError } from 'common/components/error';
import { withCancelable } from 'common/components/cancelable';
import { withForwardRef } from 'common/components/with-forward-ref';

class TransactionHistoryItemComponent extends Component {
	get isAuthOnly() {
		const { item } = this.props;
		return toLower(item.xCommand).indexOf('authonly') !== -1;
	}

	get currency() {
		return this.props.item && this.props.item.currency;
	}

	get currencyCode() {
		return CurrencyMap.resolveCurrency(this.currency);
	}

	renderStatus = () => {
		const {
			item: {
				transactionStatus: {
					isVoid,
					isAuthOnly,
					isRefund,
					isRefunded,
					canRefundAmount,
					previouslyRefundedAmount,
					isSplitCaptured,
				},
			},
		} = this.props;
		let statusImage = null;
		let tooltip = null;

		if (isVoid) {
			statusImage = <img alt="Void" src="/static/media/tags/void.svg" className="h--18 spc--left--xsml " />;
		} else if (isRefund) {
			statusImage = <img alt="Refund" src="/static/media/tags/refund.svg" className="h--18 spc--left--xsml " />;
		} else if (isRefunded) {
			if (canRefundAmount > 0 && previouslyRefundedAmount > 0) {
				tooltip = `Partial refund of ${this.currencyCode}${parseFloat((previouslyRefundedAmount / 10).toFixed(2))}`;
				statusImage = (
					<img
						alt="Partially Refunded"
						src="/static/media/tags/partiallyrefunded.svg"
						className="h--18 spc--left--xsml "
					/>
				);
			} else {
				statusImage = <img alt="Refunded" src="/static/media/tags/refunded.svg" className="h--18 spc--left--xsml " />;
			}
		} else if (isAuthOnly) {
			if (isSplitCaptured) {
				statusImage = (
					<Fragment>
						<img alt="Auth" src="/static/media/tags/auth.svg" className="h--18 spc--left--tny " />
						<img alt="Split Captured" src="/static/media/tags/split-captured.svg" className="h--18 spc--left--tny " />
					</Fragment>
				);
			} else {
				statusImage = <img alt="Auth" src="/static/media/tags/auth.svg" className="h--18 spc--left--tny " />;
			}
		}

		if (statusImage !== null) {
			return (
				<span className="datatooltip--partialrefund">
					<span data-tooltip={tooltip}>{statusImage}</span>
				</span>
			);
		}
		return null;
	};

	isGiftTransaction = (payment, transaction, requestedTransaction) => {
		return payment === 'gift' && transaction === requestedTransaction;
	};

	renderTransactionDetails = () => {
		const {
			item,
			item: { xCommand, xResponseAVSCode, transactionStatus },
		} = this.props;
		let amount = transactionStatus.isVoid ? item.xRequestAmount : item.xAmount;
		let amountClass = transactionStatus.isVoid ? 'type--linethrough type--color--primary' : '';
		const accountNumber =
			item.xMaskedAccountNumber && item.xMaskedAccountNumber.includes('xxx')
				? `**** ${item.xMaskedAccountNumber.slice(-4)}`
				: item.xMaskedAccountNumber;
		let avs = mapAvsItem(xResponseAVSCode);
		if (avs) {
			avs = avs.text;
		}
		const [payment, transaction] = split(toLower(xCommand), ':');
		const issueGift = this.isGiftTransaction(payment, transaction, 'issue');
		const redeemGift = this.isGiftTransaction(payment, transaction, 'redeem');
		if (redeemGift) {
			amount = Math.abs(amount);
		}
		const paymentType = PaymentTransactionTypes.getPaymentType(payment);
		const transactionType = PaymentTransactionTypes.getTransactionType(transaction);

		return (
			<Fragment>
				<div className="info-panel__heading__wrapper">
					<h6 className="info-panel__heading">Reference Number {item.xRefNum}</h6>
				</div>
				<div className="info-panel__section">
					<div className="flex--primary flex--gap--sml--alt spc--bottom--lrg">
						<img src={CardTypeImagePath.getPath(payment.cardType, 'big/')} alt={`${payment.cardType} logo`} />
						<div className="flex--grow--1">
							<p className="type--p1 type--p1--medium spc--bottom--tny">{accountNumber}</p>
							<p className="type--p2 type--color--text--secondary">
								{item.xEnteredDate.format(ApplicationSettings.displayDateTimeFormat)}
							</p>
						</div>
						<div className="type--right">
							<p className="type--p1 type--p1--medium spc--bottom--tny">
								{issueGift ? '-' : ''}
								<NumberFormat
									value={amount}
									displayType="text"
									thousandSeparator={true}
									prefix={this.currencyCode}
									decimalScale={2}
									fixedDecimalScale={true}
								/>
							</p>
							<StatusComponent value={item.xResponseResult} />
						</div>
					</div>
					<div className="separator--primary"></div>
					<div className="grid-sidebar__field">
						<span className="type--p2 type--color--text--light">Transaction type:</span>
						<p className="type--p2 type--p2--medium">{transactionType}</p>
					</div>
					<div className="grid-sidebar__field">
						<span className="type--p2 type--color--text--light">Payment type:</span>
						<p className="type--p2 type--p2--medium">{paymentType}</p>
					</div>
					{item && item.xInvoice && item.xInvoice.trim() !== '' ? (
						<div className="grid-sidebar__field">
							<span className="type--p2 type--color--text--light">Invoice:</span>
							<p className="type--p2 type--p2--medium">{item.xInvoice}</p>
						</div>
					) : null}
					{item && item.xStreet && item.xStreet.trim() !== '' ? (
						<div className="grid-sidebar__field">
							<span className="type--p2 type--color--text--light">AVS Street:</span>
							<p className="type--p2 type--p2--medium">{item.xStreet}</p>
						</div>
					) : null}
					{item && item.xZip && item.xZip.trim() !== '' ? (
						<div className="grid-sidebar__field">
							<span className="type--p2 type--color--text--light">AVS Zip:</span>
							<p className="type--p2 type--p2--medium">{item.xZip}</p>
						</div>
					) : null}
					{item && item.xDescription && item.xDescription.trim() !== '' ? (
						<div className="grid-sidebar__field">
							<p className="type--p2 type--color--text--light">Transaction Description:</p>
							<div className="type--p2 type--p2--medium" title={item.xDescription}>
								{item.xDescription}
							</div>
						</div>
					) : null}
				</div>

				<div className="f-col f-col-sml-12 f-col-med-6 f-col-xlrg-4">
					<div
						className={`spc--bottom--tny type--wgt--medium type--color--text--medium type--sml--alt word-break--break-word ${amountClass}`}
					>
						{this.renderStatus()}
					</div>
				</div>
			</Fragment>
		);
	};

	render() {
		return <Fragment>{this.renderTransactionDetails()}</Fragment>;
	}
}

TransactionHistoryItemComponent.propTypes = {
	item: PropTypes.object,
	makePendingRequest: PropTypes.func,
	handleError: PropTypes.func,
	history: PropTypes.object,
};

export default withCancelable(withError(withForwardRef(TransactionHistoryItemComponent, withRouter)));
