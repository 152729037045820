import { map, max } from 'lodash-es';
import moment from 'moment';
import principalService from './principalService';

const lastAccessKey = new RegExp(`${AppBuildEnvironment}lastAccess=(\\d+)`, 'g');
const minute = 60 * 1000;
const accessTimeThreshold = 15 * minute;
const refreshTimeoutInterval = 5 * minute;

class LastAccessService {
	constructor() {
		window.addEventListener('unload', this.refresh);
		setInterval(this.refresh, refreshTimeoutInterval); // this is necessary when working in multiple tabs
	}

	get lastAccess() {
		try {
			const lastAccessKeys = [...document.cookie.matchAll(lastAccessKey)];
			if (!lastAccessKeys) return null;
			const keys = map(lastAccessKeys, key => key[1]);

			return parseInt(max(keys));
		} catch (e) {
			return null;
		}
	}

	set lastAccess(time) {
		document.cookie = `${AppBuildEnvironment}lastAccess=${time};path=/;expires=${moment
			.utc()
			.add(1, 'year')
			.format('ddd, DD MMM YYYY HH:mm:ss')};domain=${AppBuildEnvironment !== 'local' ? 'solapayments.com' : LocalHost}`;
	}

	get isValid() {
		if (!this.lastAccess || this.lastAccess < Date.now() - accessTimeThreshold) {
			return false;
		}
		return true;
	}

	clear = () => {
		document.cookie = `${AppBuildEnvironment}lastAccess=;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=${
			AppBuildEnvironment !== 'local' ? 'solapayments.com' : LocalHost
		}`;
		principalService.clear();
	};

	refresh = () => {
		this.lastAccess = Date.now();
	};
}

const lastAccessService = new LastAccessService();

export default lastAccessService;
