import httpService from './httpService';
import principalService from './principalService';

const { messagingApiEndpoint } = ApplicationSettings;

class EmailService {
	constructor(_httpService, _principalService) {
		this.httpService = _httpService;
		this.principalService = _principalService;
	}

	get headers() {
		let headers = new Headers();
		return headers;
	}

	get options() {
		return {
			isJson: true,
			headers: this.headers,
		};
	}

	sendFraudEmail = async template => {
		const body = template;
		return await this.httpService.post(`${messagingApiEndpoint}sendFraudEmail`, body, {
			allowPublic: true,
			isJson: true,
		});
	};
	sendEmail = async template => {
		const body = template;
		return await this.httpService.post(`${messagingApiEndpoint}sendEmail`, body, this.options);
	};
}

const emailService = new EmailService(httpService, principalService);

export default emailService;
