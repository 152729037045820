import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { transform } from 'lodash';

const arrowClassNames = {
	NONE: 'down--light',
	ASC: 'top--primary',
	DESC: 'down--primary',
};

class BatchGridHeaderItem extends Component {
	get getClassName() {
		const {
			column: { alignHeaderRight },
		} = this.props;
		if (alignHeaderRight) {
			return 'flex--right';
		}
		return '';
	}
	handleGridSort = () => {
		const { column } = this.props;
		const sortDirection =
			column.isDefaultSorter && !column.sortDirection ? column.defaultSortDirection : column.sortDirection || 'NONE';

		if (column.sortable) {
			let direction = 'ASC';

			if (sortDirection === 'ASC') {
				direction = 'DESC';
			} else if (sortDirection === 'DESC') {
				direction = 'NONE';
			}

			this.props.onGridSort(column.key, direction);
		}
	};
	handleClick = column => () => {
		if (column.toggleExpandAllColumn) {
			this.props.toggleExpandAllRows();
		}
	};
	render() {
		const {
			column,
			children,
			displaySorting,
			className,
			allExpanded,
			onGridSort,
			toggleExpandAllRows,
			...rest
		} = this.props;
		const sortDirection =
			column.isDefaultSorter && !column.sortDirection ? column.defaultSortDirection : column.sortDirection || 'NONE';
		const props = transform(rest, (acc, value, key) => {
			acc[key] = value;
		});

		return (
			<th onClick={this.handleGridSort} {...props} className={this.getClassName}>
				<button
					tabIndex="0"
					className={`btn btn--link btn--link--tertiary ${className}`}
					onClick={this.handleClick(column)}
					onKeyDown={event => {
						// Enter or Space key
						if (event.key === 'Enter' || event.key === ' ') {
							this.handleClick(column)();
						}
					}}
				>
					{column.toggleExpandAllColumn && (
						<i
							className={`icon icon--sml icon--chevron--${
								allExpanded ? 'down' : 'right'
							}--primary spc--right--sml--alt`}
						></i>
					)}
					<span className="type--p4 type--uppercase type--wgt--semibold">{column.name}</span>
					{displaySorting && column.sortable && (
						<i className={`icon icon--sml icon--chevron--${arrowClassNames[sortDirection]} spc--left--tny`}></i>
					)}
				</button>
			</th>
		);
	}
}

BatchGridHeaderItem.propTypes = {
	column: PropTypes.object.isRequired,
	onGridSort: PropTypes.func.isRequired,
	displaySorting: PropTypes.bool.isRequired,
	className: PropTypes.string.isRequired,
	children: PropTypes.any,
	toggleExpandAllRows: PropTypes.func,
	allExpanded: PropTypes.bool,
};

export default BatchGridHeaderItem;
