import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import {
	map,
	find,
	filter,
	each,
	toLower,
	times,
	padStart,
	memoize,
	sortBy,
	startsWith,
	cloneDeep,
	findIndex,
	split,
	clone,
	findKey,
	every,
	includes,
	get,
	keys,
	head,
} from 'lodash';

import { kvaasResources, LoadMoreOptions, checkIfCanadian } from '../../../Common/utilities';
import { predefinedDates } from 'common/components/date-picker';
import { setToCanadianVerbiage } from 'common/utilities/setToCanadianVerbiage';
import createColumn from 'components/user-settings/utils/createColumn';
import FooterComponent from '../components/FooterComponent';

function createAvsColumns() {
	return [createColumn('xStreet', 'AVS Street'), createColumn('xZip', setToCanadianVerbiage('AVS Zip'))];
}

const columns = {
	paymentMethod: createColumn('paymentMethod', 'Payment Method'),
	accountNumber: createColumn('accountNumber', 'Account #'),
	cardType: createColumn('cardType', 'Card Type'),
	invoice: createColumn('invoice', 'Invoice'),
	email: createColumn('email', 'Email'),
	description: createColumn('description', 'Description'),
	city: createColumn('city', 'Bill City'),
	state: createColumn('state', setToCanadianVerbiage('Bill State')),
	zip: createColumn('zip', setToCanadianVerbiage('Bill Zip')),
	customerNumber: createColumn('customerNumber', 'Customer #'),
	company: createColumn('company', 'Bill Company'),
	firstName: createColumn('firstName', 'Bill First Name'),
	lastName: createColumn('lastName', 'Bill Last Name'),
	currency: createColumn('currency', 'Currency'),
	cardholderName: createColumn('cardholderName', 'Cardholder Name'),
	amount: createColumn('amount', 'Amount'),
	frequency: createColumn('frequency', 'Frequency'),
};

const predefinedDateOptions = map(predefinedDates, ({ key: name, displayValue: label }) => ({ name, label }));
const loadMoreOptionsWithAll = map([20, ...LoadMoreOptions, 0], option => {
	return { name: option === 0 ? 'All' : option.toString(), label: option === 0 ? 'All' : option.toString() };
});

const defaultItemValues = { isDefault: false, hide: false };

class BaseSettings extends Component {
	constructor(props) {
		super(props);
		this.notification = createRef();
		this.state = {
			isSaving: false,
			expanded: false,
			collapsedSettingsMessages: { data: {} },
		};
	}

	get transactionInitialFields() {
		return this.initializeFields({ ...defaultItemValues, customLabel: '' }, [
			createColumn('date', 'Date'),
			columns.amount,
			createColumn('clearedAmount', 'Cleared Amount'),
			createColumn('clearedCount', 'Cleared Count'),
			columns.currency,
			createColumn('tax', 'Tax'),
			createColumn('serviceFee', 'Service Fee'),
			createColumn('computername', 'Computer Name', true),
			createColumn('token', 'Token'),
			createColumn('isemv', 'IsEmv', true),
			createColumn('isswiped', 'IsSwiped', true),
			createColumn('processor', 'Processor', true),
			createColumn('routing', 'Routing', true),
			createColumn('engine', 'Engine', true),
			createColumn('processingFee', 'Processing Fee'),
			createColumn('netSale', 'Net Sale'),
			createColumn('digitalWalletType', 'Digital Wallet'),
			columns.firstName,
			columns.lastName,
			columns.cardholderName,
			createColumn('softwareName', 'Software Name'),
			columns.cardType,
			columns.accountNumber,
			createColumn('result', 'Result'),
			createColumn('command', 'Command'),
			createColumn('achStatus', 'ACH Status'),
			createColumn('achReturnFee', 'ACH Return Fee'),
			columns.company,
			createColumn('address', 'Bill Address'),
			createColumn('billAddress2', 'Bill Address 2'),

			columns.city,
			columns.state,
			columns.zip,
			createColumn('country', 'Bill Country'),
			createColumn('billPhone', 'Bill Phone Number', false, 'phoneNumber'),
			createColumn('billMobile', 'Bill Mobile'),
			createColumn('shipFirstName', 'Ship First Name'),
			createColumn('shipLastName', 'Ship Last Name'),
			createColumn('shipCompany', 'Ship Company'),
			createColumn('shipAddress', 'Ship Street'),
			createColumn('shipAddress2', 'Ship Street 2'),
			createColumn('shipCity', 'Ship City'),
			createColumn('shipState', setToCanadianVerbiage('Ship State')),
			createColumn('shipZip', setToCanadianVerbiage('Ship Zip')),
			createColumn('shipCountry', 'Ship Country'),
			createColumn('shipPhoneNumber', 'Ship Phone'),
			createColumn('shipMobile', 'Ship Mobile'),

			columns.description,
			columns.email,
			columns.paymentMethod,
			createColumn('batch', 'Batch'),
			columns.invoice,
			createColumn('cvvResult', 'CVV Result'),
			createColumn('authCode', 'Auth Code'),
			createColumn('orderId', 'Order ID'),
			createColumn('poNumber', 'PO Number'),
			createColumn('avs', 'AVS'),
			createColumn('avsStreet', 'AVS Street'),
			createColumn('avsZip', checkIfCanadian() ? 'AVS Postal Code' : 'AVS Zip'),
			createColumn('nameVerification', 'AMEX Name Verification'),
			createColumn('emailVerification', 'AMEX Email Verification'),
			createColumn('phoneVerification', 'AMEX Phone Verification'),
			createColumn('sourceKey', 'Source Key'),
			createColumn('terminalNumber', 'Terminal Number'),
			createColumn('userName', 'User Name'),
			createColumn('ipAddress', 'IP Address'),
			createColumn('entryMethod', 'Entry Method'),
			createColumn('cardSource', 'Card Source'),
			createColumn('serialNumber', 'Serial Number'),
			createColumn('reviewed', '3D Secure Result'),
			createColumn('3DsResponseCode', '3DS Response Code'),
			createColumn('x3DsConsumerInteraction', '3DS Consumer Interaction'),
			createColumn('additionalRefnum', 'Additional Refnum'),
			createColumn('additionalAuthAmount', 'Additional Auth Amount'),
			createColumn('international', 'International'),
			createColumn('responseError', 'Error Details'),

			...this.createCustomFields(4, 19),
		]);
	}
	get disputeInitialFields() {
		return this.initializeFields(defaultItemValues, [
			createColumn('transactionDate', 'Transaction date'),
			createColumn('transactionAmount', 'Transaction amount'),
			columns.currency,
			columns.cardholderName,
			columns.cardType,
			columns.accountNumber,
		]);
	}

	get customerInitialFields() {
		return this.initializeFields(defaultItemValues, [
			columns.customerNumber,
			columns.company,
			columns.firstName,
			columns.lastName,
			createColumn('recurringSchedule', 'Recurring Schedule'),
			createColumn('customerNotes', 'Notes'),
			createColumn('street', 'Bill Street'),
			columns.city,
			columns.state,
			columns.zip,
			createColumn('phoneNumber', 'Bill Phone Number'),
			createColumn('mobilePhoneNumber', 'Mobile Phone Number'),
			columns.email,
			columns.paymentMethod,
			...createAvsColumns(),
			...this.createCustomFields(3, 20),
		]);
	}

	get recurringInitialFields() {
		return this.initializeFields(defaultItemValues, [
			createColumn('scheduleName', 'Schedule Name'),
			createColumn('customer', 'Customer Name'),
			columns.company,
			columns.customerNumber,
			columns.email,
			createColumn('dateCreated', 'Date Created'),
			createColumn('startDate', 'Start Date'),
			createColumn('nextBillingDate', 'Next Billing Date'),
			createColumn('endDate', 'End Date'),
			columns.frequency,
			createColumn('remainingCharges', 'Remaining Charges'),
			columns.amount,
			createColumn('remainingBalance', 'Remaining Balance'),
			createColumn('totalCharges', 'Total Charges'),
			columns.description,
			createColumn('status', 'Status'),
			createColumn('active', 'Active'),
			columns.invoice,
			createColumn('failedTransactionRetryTimes', 'Retry count'),
			...this.createCustomFields(0, 19),
		]);
	}
	get fraudWatchReportInitialFields() {
		return this.initializeFields(defaultItemValues, [
			createColumn('xRefNum', 'Reference Number'),
			createColumn('xEnteredDate', 'Date'),
			createColumn('xCurrency', 'Currency'),
			createColumn('xAmount', 'Amount'),
			createColumn('xCardType', 'Card type'),
			createColumn('xMaskedCardNumber', 'Account #'),
			createColumn('xName', 'C­ar­dholder N­ame'),
			createColumn('xInvoice', 'Invoice'),
			createColumn('xStatus', 'Status'),
			createColumn('xStatusReason', 'Status Details'),
			createColumn('xEntryMethod', 'Entry Method'),
			createColumn('xDescription', 'Description'),
			createColumn('xCommand', 'Command'),
			createColumn('xResponseCVVCode', 'CVV Result'),
			createColumn('xOrderID', 'Order Id'),
			createColumn('xPONum', 'PO Number'),
			createColumn('xSourceKey', 'Source Key'),
			createColumn('xUserName', 'User Name'),
			createColumn('xClientIP', 'Client IP'),
			createColumn('xEmail', 'Email'),
			createColumn('xSoftwareName', 'Software Name'),
			createColumn('xCardSource', 'Card Source'),
			createColumn('xBillFirstName', 'Bill First Name'),
			createColumn('xBillLastName', 'Bill Last Name'),
			createColumn('xBillCompany', 'Bill Company'),
			createColumn('xBillStreet', 'Bill Street'),
			createColumn('xBillCity', 'Bill City'),
			createColumn('xBillZip', 'Bill Zip'),
			createColumn('xBillState', 'Bill State'),
			createColumn('xBillCountry', 'Bill Country'),
			createColumn('xBillPhone', 'Bill Phone Number'),
			createColumn('xShipFirstName', 'Ship First Name'),
			createColumn('xShipLastName', 'Ship Last Name'),
			createColumn('xShipCompany', 'Ship Company'),
			createColumn('xShipStreet', 'Ship Street'),
			createColumn('xShipCity', 'Ship City'),
			createColumn('xShipZip', 'Ship Zip'),
			createColumn('xShipState', 'Ship State'),
			createColumn('xShipCountry', 'Ship Country'),
			createColumn('xShipPhone', 'Ship Phone'),
			createColumn('xCustom01', 'Custom01'),
			createColumn('xCustom02', 'Custom02'),
			createColumn('xCustom03', 'Custom03'),
			createColumn('xResponseAVSCode', 'AVS'),
			...createAvsColumns(),
		]);
	}

	get recurringTemplatesInitialFields() {
		return this.initializeFields(defaultItemValues, [columns.description, columns.amount, columns.frequency]);
	}

	mapStateToRequest = (data, oldData) => {
		return {
			newData: {
				revision: 0,
				data,
			},
			oldData,
			...kvaasResources.collapsedSettingsMessages,
		};
	};
	initializeFields = (defaultInitialValues, fields) => {
		return map(fields, (field, index) => {
			return {
				...defaultInitialValues,
				...field,
				order: index + 1,
			};
		});
	};
	loadMoreOptionsWithAll = map([20, ...LoadMoreOptions, 0], option => {
		return { name: option === 0 ? 'All' : option.toString(), label: option === 0 ? 'All' : option.toString() };
	});

	hasDataPortalFlags = (key, portalFlagsData) => {
		return find(portalFlagsData, flag => toLower(head(keys(flag))) === toLower(key))[key];
	};
	getPortalFlagsData = portalFlags => {
		const flags = [
			'displayOlderTransactionsFirst',
			'printInPortrait',
			'printOnOnePage',
			'showIssuingBankInfo',
			'displaySplitPayColumns',
			'displayAmexVerificationColumns',
			'printerReceiptOption',
			'multipleCapture',
			'showDisputesTab',
		];
		return map(flags, flag => ({ [flag]: get(portalFlags, `data.${flag}`, false) }));
	};

	mapPortalFlagsToState = (newState, portalFlagsData) => {
		each(portalFlagsData, flag => {
			const key = head(keys(flag));
			newState.transactionReport[key] = flag[key];
		});
	};

	getValue = container => memoize(name => find(container, { name }));

	getDate = this.getValue(predefinedDateOptions);

	getLimit = this.getValue(loadMoreOptionsWithAll);
	moveColumn = (sourceId, targetId) => {
		const reportType = this.state.reportType;
		this.pendingUpdateFunction = ({ fields = this.state[reportType].fields }) => {
			const sourceItem = find(fields, { key: sourceId });
			const sourceIndex = findIndex(fields, { key: sourceId });
			const targetIndex = findIndex(fields, { key: targetId });
			const newFields = fields.slice();
			newFields.splice(sourceIndex, 1);
			newFields.splice(targetIndex, 0, sourceItem);
			each(newFields, (field, index) => {
				field.order = index + 1;
			});
			return {
				[reportType]: {
					...this.state[reportType],
					fields: newFields,
				},
			};
		};
		if (!this.requestedFrame) {
			this.requestedFrame = requestAnimationFrame(this.drawFrame);
		}
	};

	drawFrame = () => {
		this.setState(this.pendingUpdateFunction);
		this.pendingUpdateFunction = null;
		this.requestedFrame = null;
	};

	mapResponseToState = (
		oldDefault,
		oldOrder,
		oldHide,
		oldCustomLabel,
		oldPortalFlags,
		oldUserSettings,
		oldDefaultValues
	) => {
		const { reportType } = this.state;
		const { fields, oldData } = cloneDeep(this.state[reportType]);
		const reportTypes = ['transactionreport', 'disputereport', 'recurringreport'];
		const newState = {
			oldData,
			fields,
		};
		const mapCustomLabelToState = reportType === 'transactionReport' || reportType === 'customerReport';
		const containsDefaults = includes(reportTypes, toLower(reportType));
		if (containsDefaults) {
			newState.defaultValues = this.state[reportType].defaultValues;
		}

		this.mapFieldsToState(newState, oldDefault, 'isDefault');
		this.mapFieldsToState(newState, oldOrder, 'order');
		if (oldHide && every(['recurring', 'recurringTemplates'], item => item !== reportType)) {
			this.mapFieldsToState(newState, oldHide, 'hide');
		}
		if (oldDefaultValues && containsDefaults) {
			this.mapDefaultValueToState(newState, oldDefaultValues, 'defaultValues');
		}
		if (oldCustomLabel && mapCustomLabelToState) {
			this.mapFieldsToState(newState, oldCustomLabel, 'customLabel');
		}
		if (oldUserSettings) {
			this.mapFieldsToState(newState, oldUserSettings, 'userSettings');
		}
		if (oldPortalFlags) {
			this.mapFieldsToState(newState, oldPortalFlags, 'portalFlags');
		}
		newState.fields = sortBy(newState.fields, ({ order }) => order);
		return newState;
	};

	checkIfError = (newState, oldData, type, callback) => {
		const { data, result, error, refNum } = oldData;
		if (data && (toLower(result) === 's' || error === 'Item does not exist')) {
			if (!error) {
				newState.oldData[type] = {
					...oldData,
				};
			}
			if (type === 'defaultValues') {
				each(data, callback);
			} else {
				each(newState.fields, callback);
			}
		} else if (toLower(error) === 'invalid: revision' || toLower(error) === 'item exists. revision cannot be 0') {
			this.props.handleError({
				isApiError: true,
				ref: refNum,
				message: error,
				success: false,
			});
		}
	};

	mapDefaultValueToState = (newState, oldData, type) => {
		this.checkIfError(newState, oldData, type, (value, key) => {
			newState.defaultValues[key] = value;
		});
	};

	mapFieldsToState = (newState, oldData, type) => {
		this.checkIfError(newState, oldData, type, ({ key: fieldKey, alternateKey }) => {
			const oldDataKey = findKey(oldData.data, (_, key) => key === fieldKey || key === alternateKey);
			const fieldIndex = findIndex(newState.fields, ({ key }) => key === fieldKey);
			if (oldDataKey) {
				newState.fields[fieldIndex][type] = oldData.data[oldDataKey];
			}
			if (type === 'isDefault' && !oldData.data[fieldKey]) {
				newState.fields[fieldIndex][type] = false;
			}
			if (type !== 'customLabel' || !oldDataKey) return;
			const shipFieldIndex = findIndex(newState.fields, ({ key }) =>
				includes([toLower(`ship${fieldKey}`), toLower(`ship${alternateKey}`)], toLower(key))
			);
			if (shipFieldIndex === -1) return;
			newState.fields[shipFieldIndex][type] = `Ship ${oldData.data[oldDataKey]}`;
		});
	};

	notifySuccess = (error, setToDefaults, message, refNum) => {
		const text = setToDefaults ? 'reset to default' : 'updated';
		const messageToShow = `${message} ${text} `;
		if (error) return;
		this.notification.current.addNotification({
			message: messageToShow,
			ref: refNum,
			success: true,
		});
	};

	handleChange = event => {
		const { reportType } = this.state;
		const { name, checked, value, type } = event.target;
		const [fieldKey, propKey] = split(name, '.');
		const newState = cloneDeep(this.state[reportType]);
		newState.fields = this.state[reportType].fields.slice();

		if (name === 'selectAll') {
			newState.fields = map(newState.fields, field => ({
				...field,
				isDefault: !field.hide ? checked : field.isDefault,
			}));
		} else {
			const changedIndex = findIndex(newState.fields, { key: fieldKey });
			const changedItem = clone(newState.fields[changedIndex]);
			changedItem[propKey] = type === 'checkbox' ? checked : value;
			newState.fields[changedIndex] = changedItem;
		}

		this.setState({ [reportType]: newState });
		this.props.handleBlockChange(true);
	};

	handleDefaultDateRangeChange = ({ name: date }, reportType) => {
		const defaultValues = clone(this.state[reportType].defaultValues);
		defaultValues.date = date;
		this.setState({
			[reportType]: {
				...this.state[reportType],
				defaultValues: defaultValues,
			},
		});
		this.props.handleBlockChange(true);
	};

	handleCheckboxChange = ({ target: { name, checked } }) => {
		const newState = {};

		if (name.indexOf('.') > -1) {
			const [section, key] = split(name, '.');
			newState[section] = {
				...this.state[section],
				[key]: checked,
			};
		} else {
			newState[name] = checked;
		}

		this.setState(newState);
	};
	handlePortalFlagsChange = ({ target: { name, checked } }) => {
		this.setState({
			portalFlags: {
				...this.state.portalFlags,
				[name]: checked,
			},
		});
		this.props.handleBlockChange(true);
	};
	handleUserSettingsChange = ({ target: { name, checked } }) => {
		this.setState({
			userSettings: {
				...this.state.userSettings,
				[name]: checked,
			},
		});
		this.props.handleBlockChange(true);
	};

	handleActiveDefaultChange = e => {
		const { reportType } = this.state;
		const { checked, name } = e.target;
		const defaultValues = clone(this.state[reportType].defaultValues);
		defaultValues[name] = checked;
		this.setState({
			[reportType]: {
				...this.state[reportType],
				defaultValues: defaultValues,
			},
		});
		this.props.handleBlockChange(true);
	};

	removeCustomField = key => {
		const reportType = this.state.reportType;
		const newFields = this.state[reportType].fields.slice();
		const customIndex = findIndex(newFields, { key });
		const custom = clone(newFields[customIndex]);
		custom.hide = true;
		custom.isDefault = false;
		newFields[customIndex] = custom;
		this.setState({ [reportType]: { ...this.state[reportType], fields: newFields } });
		this.props.handleBlockChange(true);
	};

	addCustomField = () => {
		const reportType = this.state.reportType;
		const fields = this.state[reportType].fields.slice();
		const fieldIndex = findIndex(fields, ({ key, hide }) =>
			startsWith(key, 'custom') && parseInt(key.substr('custom'.length)) > 3 ? hide : false
		);
		if (fieldIndex > -1) {
			const field = clone(fields[fieldIndex]);
			field.hide = false;
			fields[fieldIndex] = field;
			this.setState({ [reportType]: { ...this.state[reportType], fields: fields } });
			this.props.handleBlockChange(true);
		}
	};
	getCustomFieldLimit = type => {
		const types = {
			transactionReport: 19,
			recurringReport: 19,
		};

		return types[type] || 21;
	};

	get canAddCustomFields() {
		const reportType = this.state.reportType;
		const fields = get(this.state[reportType], 'fields', []);
		const limit = this.getCustomFieldLimit(reportType);

		return filter(fields, ({ key, hide }) => startsWith(toLower(key), 'custom') && !hide).length < limit;
	}

	createCustomFields = (numberOfFields, maxCustomerFields) =>
		times(maxCustomerFields, i => ({
			key: `custom${i + 1}`,
			label: `Custom${padStart(i + 1, 2, 0)}`,
			customLabel: '',
			isDefault: false,
			hide: false,
			canDelete: i >= numberOfFields,
		}));

	renderSaveButton = () => {
		return <FooterComponent disabled={this.props.isLoading} isLoading={this.props.isLoading} save={this.save} />;
	};

	mapStateToFields = (reportType, resourceKey, { primaryKey, userSetting, defaultData }, resetToDefault) => {
		const { fields, oldData } = this.state[reportType];
		const newState = cloneDeep(this.state[reportType]);
		const data = {};
		each(fields, ({ [resourceKey]: resource, key }, idx) => {
			if (resetToDefault) {
				data[key] = defaultData[key] ? defaultData[key] : false;
			} else {
				data[key] = resource;
			}
			if (resourceKey === 'isDefault' && !oldData[key]) {
				newState.fields[idx].isDefault = false;
			}
		});
		return {
			newData: {
				revision: 0,
				data,
			},
			oldData: oldData[resourceKey],
			primaryKey,
			userSetting,
		};
	};

	scrollToTop = () => {
		if (this.top.current) {
			this.top.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
		}
	};
}

BaseSettings.propTypes = {
	makePendingRequest: PropTypes.func,
	handleError: PropTypes.func,
	showLoader: PropTypes.func,
	history: PropTypes.object,
	match: PropTypes.object,
	location: PropTypes.object,
	isLoading: PropTypes.bool,
	handleBlockChange: PropTypes.func,
};
export default BaseSettings;
