const screenSize = {
	xsml: 375,
	sml: 544,
	med: 768,
	lrg: 992,
	xlrg: 1200,
	xxlrg: 1440,
	xxxlrg: 1520,
	huge: 1600,
};

export default screenSize;
