import emailService from 'common/services/emailService';
import { includes, join, filter } from 'lodash';

const loginAttemptsThreshold = 3;
const loginAttemptsPersistenceKey = 'fqa_4pkh';

let loginAttempts = JSON.parse(localStorage.getItem(loginAttemptsPersistenceKey) || '[]');
let knownLoginAttempts = [];
let successfulLoginAttempts = [];

function exceedesTreshold() {
	return loginAttempts.length % loginAttemptsThreshold === 0 && loginAttempts.length !== 0;
}
export function logLoginAttempt(username) {
	if (!includes(knownLoginAttempts, username)) knownLoginAttempts.push(username);
	const hashedUsername = createNonSecureHash(username);
	if (includes(loginAttempts, hashedUsername)) return;
	loginAttempts.push(hashedUsername);
	localStorage.setItem(loginAttemptsPersistenceKey, JSON.stringify(loginAttempts));

	// Check for the number of failed attempts and send email if it exceeds the threshold
	if (exceedesTreshold()) {
		sendFailedAttemptEmail(username, hashedUsername);
	}
}

export async function logSuccessfulLogin(username) {
	const hashedUsername = createNonSecureHash(username);
	successfulLoginAttempts.push(hashedUsername);
	loginAttempts = filter(loginAttempts, attempt => hashedUsername !== attempt);
	if (exceedesTreshold()) {
		sendFailedAttemptEmail(username, hashedUsername);
	}
	knownLoginAttempts = [];
	successfulLoginAttempts = [];
	localStorage.removeItem(loginAttemptsPersistenceKey);
}

async function sendFailedAttemptEmail(username, hashedUsername) {
	const isSuccessfulLogin = successfulLoginAttempts.includes(hashedUsername);

	const sanitizedUsername = isSuccessfulLogin ? username : '';

	const failedAttempts = loginAttempts.length;

	const template = {
		failedattempts: failedAttempts,
		knownAttempts: join(knownLoginAttempts, ', '),
	};

	// Choose the appropriate email template based on the username and known attempts
	let emailTemplate = {
		template,
		templateName: '',
	};
	emailTemplate.templateName = sanitizedUsername ? 'LoginAttemptsSuccessWithKnown' : 'LoginAttemptsFailedWithKnown';

	// Choose the appropriate email service method based on the username
	if (sanitizedUsername) {
		await emailService.sendEmail({ ...emailTemplate });
	} else {
		await emailService.sendFraudEmail({ ...emailTemplate });
	}
}
// This does not need to be cryptographically secure.
// Just a short and simple implementation of a hash function.
function createNonSecureHash(str) {
	let hash = 0;
	for (let i = 0, len = str.length; i < len; i++) {
		let chr = str.charCodeAt(i);
		hash = (hash << 5) - hash + chr;
		hash |= 0;
	}
	return hash.toString(16);
}
