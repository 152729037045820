import React from 'react';
import PropTypes from 'prop-types';
import { DragLayer } from 'react-dnd';

const layerStyles = {
	position: 'fixed',
	pointerEvents: 'none',
	zIndex: 100,
	left: 0,
	top: 0,
	width: '100%',
	height: '100%',
};

let lastHeight = null;
let lastWidth = null;

const getItemStyle = (initialOffset, currentOffset, ref) => {
	if (ref) {
		lastHeight = ref.clientHeight;
		lastWidth = ref.clientWidth;
	}
	if (!initialOffset || !currentOffset) {
		return {
			display: 'none',
		};
	}
	let { x, y } = currentOffset;
	const transform = `translate(${x}px, ${y}px)`;
	const style = {
		WebkitTransform: transform,
		transform,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		maxWidth: 530,
		borderRadius: '8px',
		backgroundColor: 'white',
		border: '1px solid #e6e6e9',
		zIndex: 9999,
	};
	if (lastHeight || lastWidth) {
		style.height = `${lastHeight}px`;
		style.width = `${lastWidth}px`;
	}
	return style;
};

const CustomDragLayer = ({ isDragging, draggedItem, initialOffset, currentOffset }) => {
	if (!isDragging) {
		return null;
	}

	const { WrappedComponent, item: { currentRef, ...item } = {}, ...rest } = draggedItem;
	if (!WrappedComponent) {
		return null;
	}
	return (
		<div style={layerStyles}>
			<div style={getItemStyle(initialOffset, currentOffset, currentRef)}>
				<WrappedComponent item={item} {...rest} />
			</div>
		</div>
	);
};

CustomDragLayer.propTypes = {
	isDragging: PropTypes.bool,
	draggedItem: PropTypes.any,
	initialOffset: PropTypes.any,
	currentOffset: PropTypes.any,
};

export default DragLayer(monitor => ({
	draggedItem: monitor.getItem(),
	initialOffset: monitor.getInitialSourceClientOffset(),
	currentOffset: monitor.getSourceClientOffset(),
	isDragging: monitor.isDragging(),
}))(CustomDragLayer);
