import { map } from 'lodash';

const commonFilterFields = {
	hasSelection: false,
	defaultHasSelection: false,
	clearable: true,
};

const createFilters = filters =>
	map(filters, filter => ({
		...commonFilterFields,
		defaultValues: { ...filter.values },
		...filter,
	}));

export default createFilters;
