const createOption = (name, dialCode, code) => ({ name, dialCode, code });

export const dialCodesByCountryList = [
	createOption('Please Select...', '', ''),
	createOption('Afghanistan', '+93', 'AF'),
	createOption('Aland Islands', '+358', 'AX'),
	createOption('Albania', '+355', 'AL'),
	createOption('Algeria', '+213', 'DZ'),
	createOption('AmericanSamoa', '+1684', 'AS'),
	createOption('Andorra', '+376', 'AD'),
	createOption('Angola', '+244', 'AO'),
	createOption('Anguilla', '+1264', 'AI'),
	createOption('Antarctica', '+672', 'AQ'),
	createOption('Antigua and Barbuda', '+1268', 'AG'),
	createOption('Argentina', '+54', 'AR'),
	createOption('Armenia', '+374', 'AM'),
	createOption('Aruba', '+297', 'AW'),
	createOption('Australia', '+61', 'AU'),
	createOption('Austria', '+43', 'AT'),
	createOption('Azerbaijan', '+994', 'AZ'),
	createOption('Bahamas', '+1242', 'BS'),
	createOption('Bahrain', '+973', 'BH'),
	createOption('Bangladesh', '+880', 'BD'),
	createOption('Barbados', '+1246', 'BB'),
	createOption('Belarus', '+375', 'BY'),
	createOption('Belgium', '+32', 'BE'),
	createOption('Belize', '+501', 'BZ'),
	createOption('Benin', '+229', 'BJ'),
	createOption('Bermuda', '+1441', 'BM'),
	createOption('Bhutan', '+975', 'BT'),
	createOption('Bolivia, Plurinational State of', '+591', 'BO'),
	createOption('Bosnia and Herzegovina', '+387', 'BA'),
	createOption('Botswana', '+267', 'BW'),
	createOption('Brazil', '+55', 'BR'),
	createOption('British Indian Ocean Territory', '+246', 'IO'),
	createOption('Brunei Darussalam', '+673', 'BN'),
	createOption('Bulgaria', '+359', 'BG'),
	createOption('Burkina Faso', '+226', 'BF'),
	createOption('Burundi', '+257', 'BI'),
	createOption('Cambodia', '+855', 'KH'),
	createOption('Cameroon', '+237', 'CM'),
	createOption('Cape Verde', '+238', 'CV'),
	createOption('Cayman Islands', '+ 345', 'KY'),
	createOption('Central African Republic', '+236', 'CF'),
	createOption('Chad', '+235', 'TD'),
	createOption('Chile', '+56', 'CL'),
	createOption('China', '+86', 'CN'),
	createOption('Christmas Island', '+61', 'CX'),
	createOption('Cocos (Keeling) Islands', '+61', 'CC'),
	createOption('Colombia', '+57', 'CO'),
	createOption('Comoros', '+269', 'KM'),
	createOption('Congo', '+242', 'CG'),
	createOption('Congo, The Democratic Republic of the Congo', '+243', 'CD'),
	createOption('Cook Islands', '+682', 'CK'),
	createOption('Costa Rica', '+506', 'CR'),
	createOption("Cote d'Ivoire", '+225', 'CI'),
	createOption('Croatia', '+385', 'HR'),
	createOption('Cuba', '+53', 'CU'),
	createOption('Cyprus', '+357', 'CY'),
	createOption('Czech Republic', '+420', 'CZ'),
	createOption('Denmark', '+45', 'DK'),
	createOption('Djibouti', '+253', 'DJ'),
	createOption('Dominica', '+1767', 'DM'),
	createOption('Dominican Republic', '+1849', 'DO'),
	createOption('Ecuador', '+593', 'EC'),
	createOption('Egypt', '+20', 'EG'),
	createOption('El Salvador', '+503', 'SV'),
	createOption('Equatorial Guinea', '+240', 'GQ'),
	createOption('Eritrea', '+291', 'ER'),
	createOption('Estonia', '+372', 'EE'),
	createOption('Ethiopia', '+251', 'ET'),
	createOption('Falkland Islands (Malvinas)', '+500', 'FK'),
	createOption('Faroe Islands', '+298', 'FO'),
	createOption('Fiji', '+679', 'FJ'),
	createOption('Finland', '+358', 'FI'),
	createOption('France', '+33', 'FR'),
	createOption('French Guiana', '+594', 'GF'),
	createOption('French Polynesia', '+689', 'PF'),
	createOption('Gabon', '+241', 'GA'),
	createOption('Gambia', '+220', 'GM'),
	createOption('Georgia', '+995', 'GE'),
	createOption('Germany', '+49', 'DE'),
	createOption('Ghana', '+233', 'GH'),
	createOption('Gibraltar', '+350', 'GI'),
	createOption('Greece', '+30', 'GR'),
	createOption('Greenland', '+299', 'GL'),
	createOption('Grenada', '+1473', 'GD'),
	createOption('Guadeloupe', '+590', 'GP'),
	createOption('Guam', '+1671', 'GU'),
	createOption('Guatemala', '+502', 'GT'),
	createOption('Guernsey', '+44', 'GG'),
	createOption('Guinea', '+224', 'GN'),
	createOption('Guinea-Bissau', '+245', 'GW'),
	createOption('Guyana', '+595', 'GY'),
	createOption('Haiti', '+509', 'HT'),
	createOption('Holy See (Vatican City State)', '+379', 'VA'),
	createOption('Honduras', '+504', 'HN'),
	createOption('Hong Kong', '+852', 'HK'),
	createOption('Hungary', '+36', 'HU'),
	createOption('Iceland', '+354', 'IS'),
	createOption('India', '+91', 'IN'),
	createOption('Indonesia', '+62', 'ID'),
	createOption('Iran, Islamic Republic of Persian Gulf', '+98', 'IR'),
	createOption('Iraq', '+964', 'IQ'),
	createOption('Ireland', '+353', 'IE'),
	createOption('Isle of Man', '+44', 'IM'),
	createOption('Israel', '+972', 'IL'),
	createOption('Italy', '+39', 'IT'),
	createOption('Jamaica', '+1876', 'JM'),
	createOption('Japan', '+81', 'JP'),
	createOption('Jersey', '+44', 'JE'),
	createOption('Jordan', '+962', 'JO'),
	createOption('Kazakhstan', '+77', 'KZ'),
	createOption('Kenya', '+254', 'KE'),
	createOption('Kiribati', '+686', 'KI'),
	createOption("Korea, Democratic People's Republic of Korea", '+850', 'KP'),
	createOption('Korea, Republic of South Korea', '+82', 'KR'),
	createOption('Kuwait', '+965', 'KW'),
	createOption('Kyrgyzstan', '+996', 'KG'),
	createOption('Laos', '+856', 'LA'),
	createOption('Latvia', '+371', 'LV'),
	createOption('Lebanon', '+961', 'LB'),
	createOption('Lesotho', '+266', 'LS'),
	createOption('Liberia', '+231', 'LR'),
	createOption('Libyan Arab Jamahiriya', '+218', 'LY'),
	createOption('Liechtenstein', '+423', 'LI'),
	createOption('Lithuania', '+370', 'LT'),
	createOption('Luxembourg', '+352', 'LU'),
	createOption('Macao', '+853', 'MO'),
	createOption('Macedonia', '+389', 'MK'),
	createOption('Madagascar', '+261', 'MG'),
	createOption('Malawi', '+265', 'MW'),
	createOption('Malaysia', '+60', 'MY'),
	createOption('Maldives', '+960', 'MV'),
	createOption('Mali', '+223', 'ML'),
	createOption('Malta', '+356', 'MT'),
	createOption('Marshall Islands', '+692', 'MH'),
	createOption('Martinique', '+596', 'MQ'),
	createOption('Mauritania', '+222', 'MR'),
	createOption('Mauritius', '+230', 'MU'),
	createOption('Mayotte', '+262', 'YT'),
	createOption('Mexico', '+52', 'MX'),
	createOption('Micronesia, Federated States of Micronesia', '+691', 'FM'),
	createOption('Moldova', '+373', 'MD'),
	createOption('Monaco', '+377', 'MC'),
	createOption('Mongolia', '+976', 'MN'),
	createOption('Montenegro', '+382', 'ME'),
	createOption('Montserrat', '+1664', 'MS'),
	createOption('Morocco', '+212', 'MA'),
	createOption('Mozambique', '+258', 'MZ'),
	createOption('Myanmar', '+95', 'MM'),
	createOption('Namibia', '+264', 'NA'),
	createOption('Nauru', '+674', 'NR'),
	createOption('Nepal', '+977', 'NP'),
	createOption('Netherlands', '+31', 'NL'),
	createOption('Netherlands Antilles', '+599', 'AN'),
	createOption('New Caledonia', '+687', 'NC'),
	createOption('New Zealand', '+64', 'NZ'),
	createOption('Nicaragua', '+505', 'NI'),
	createOption('Niger', '+227', 'NE'),
	createOption('Nigeria', '+234', 'NG'),
	createOption('Niue', '+683', 'NU'),
	createOption('Norfolk Island', '+672', 'NF'),
	createOption('Northern Mariana Islands', '+1670', 'MP'),
	createOption('Norway', '+47', 'NO'),
	createOption('Oman', '+968', 'OM'),
	createOption('Pakistan', '+92', 'PK'),
	createOption('Palau', '+680', 'PW'),
	createOption('Palestinian Territory, Occupied', '+970', 'PS'),
	createOption('Panama', '+507', 'PA'),
	createOption('Papua New Guinea', '+675', 'PG'),
	createOption('Paraguay', '+595', 'PY'),
	createOption('Peru', '+51', 'PE'),
	createOption('Philippines', '+63', 'PH'),
	createOption('Pitcairn', '+872', 'PN'),
	createOption('Poland', '+48', 'PL'),
	createOption('Portugal', '+351', 'PT'),
	createOption('Puerto Rico', '+1939', 'PR'),
	createOption('Qatar', '+974', 'QA'),
	createOption('Romania', '+40', 'RO'),
	createOption('Russia', '+7', 'RU'),
	createOption('Rwanda', '+250', 'RW'),
	createOption('Reunion', '+262', 'RE'),
	createOption('Saint Barthelemy', '+590', 'BL'),
	createOption('Saint Helena, Ascension and Tristan Da Cunha', '+290', 'SH'),
	createOption('Saint Kitts and Nevis', '+1869', 'KN'),
	createOption('Saint Lucia', '+1758', 'LC'),
	createOption('Saint Martin', '+590', 'MF'),
	createOption('Saint Pierre and Miquelon', '+508', 'PM'),
	createOption('Saint Vincent and the Grenadines', '+1784', 'VC'),
	createOption('Samoa', '+685', 'WS'),
	createOption('San Marino', '+378', 'SM'),
	createOption('Sao Tome and Principe', '+239', 'ST'),
	createOption('Saudi Arabia', '+966', 'SA'),
	createOption('Senegal', '+221', 'SN'),
	createOption('Serbia', '+381', 'RS'),
	createOption('Seychelles', '+248', 'SC'),
	createOption('Sierra Leone', '+232', 'SL'),
	createOption('Singapore', '+65', 'SG'),
	createOption('Slovakia', '+421', 'SK'),
	createOption('Slovenia', '+386', 'SI'),
	createOption('Solomon Islands', '+677', 'SB'),
	createOption('Somalia', '+252', 'SO'),
	createOption('South Africa', '+27', 'ZA'),
	createOption('South Sudan', '+211', 'SS'),
	createOption('South Georgia and the South Sandwich Islands', '+500', 'GS'),
	createOption('Spain', '+34', 'ES'),
	createOption('Sri Lanka', '+94', 'LK'),
	createOption('Sudan', '+249', 'SD'),
	createOption('Suriname', '+597', 'SR'),
	createOption('Svalbard and Jan Mayen', '+47', 'SJ'),
	createOption('Swaziland', '+268', 'SZ'),
	createOption('Sweden', '+46', 'SE'),
	createOption('Switzerland', '+41', 'CH'),
	createOption('Syrian Arab Republic', '+963', 'SY'),
	createOption('Taiwan', '+886', 'TW'),
	createOption('Tajikistan', '+992', 'TJ'),
	createOption('Tanzania, United Republic of Tanzania', '+255', 'TZ'),
	createOption('Thailand', '+66', 'TH'),
	createOption('Timor-Leste', '+670', 'TL'),
	createOption('Togo', '+228', 'TG'),
	createOption('Tokelau', '+690', 'TK'),
	createOption('Tonga', '+676', 'TO'),
	createOption('Trinidad and Tobago', '+1868', 'TT'),
	createOption('Tunisia', '+216', 'TN'),
	createOption('Turkey', '+90', 'TR'),
	createOption('Turkmenistan', '+993', 'TM'),
	createOption('Turks and Caicos Islands', '+1649', 'TC'),
	createOption('Tuvalu', '+688', 'TV'),
	createOption('Uganda', '+256', 'UG'),
	createOption('Ukraine', '+380', 'UA'),
	createOption('United Arab Emirates', '+971', 'AE'),
	createOption('United Kingdom', '+44', 'GB'),
	createOption('US/Canada', '+1', 'US/CA'),
	createOption('Uruguay', '+598', 'UY'),
	createOption('Uzbekistan', '+998', 'UZ'),
	createOption('Vanuatu', '+678', 'VU'),
	createOption('Venezuela, Bolivarian Republic of Venezuela', '+58', 'VE'),
	createOption('Vietnam', '+84', 'VN'),
	createOption('Virgin Islands, British', '+1284', 'VG'),
	createOption('Virgin Islands, U.S.', '+1340', 'VI'),
	createOption('Wallis and Futuna', '+681', 'WF'),
	createOption('Yemen', '+967', 'YE'),
	createOption('Zambia', '+260', 'ZM'),
	createOption('Zimbabwe', '+263', 'ZW'),
];
