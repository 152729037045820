import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Switch, NavLink, Redirect } from 'react-router-dom';
import { map, find, noop, startsWith, filter, replace } from 'lodash';
import { Authorize, hasFeaturePackage, invokeIfFunction, OutsideClick, screenSize } from 'common/utilities';
import { featurePackages } from 'common/utilities/has-feature-package';
import { UserAccountPanel } from 'common/components/user-account-panel';
import Route from 'routing/Route';
import { withError } from 'common/components/error';
import { withCancelable } from 'common/components/cancelable';

const paymentSiteEditRegex = /\/sola-account-settings\/payment-site\/.+/;

class SettingsTemplate extends Component {
	constructor(props) {
		super(props);
		props.routes.push({
			path: '',
			exact: true,
			component: ({ match }) => {
				if (props.isFraud) {
					const url = match.url === '/user-settings' ? `${match.url}/fraudwatch` : `${match.url}/fraud`;
					return <Redirect to={url} />;
				} else if (props.securityOnly) {
					return <Redirect to={`${match.url}/${'security'}`} />;
				} else {
					return <Redirect to={`${match.url}/${props.defaultRouteUrl}`} />;
				}
			},
		});
		this.state = {
			isSaving: false,
			expanded: false,
			isNavOpen: false,
			showKvaasError: false,
			showSettings: window.innerWidth >= screenSize.lrg,
		};
	}
	componentProps = {};

	componentDidUpdate(prevProps) {
		if (prevProps.defaultRouteUrl !== this.props.defaultRouteUrl) {
			if (this.props.isFraud) {
				const url =
					this.props.match.url === '/user-settings'
						? `${this.props.match.url}/fraudwatch`
						: `${this.props.match.url}/fraud`;
				this.props.history.push(url);
			} else if (this.props.securityOnly) {
				this.props.history.push(`${this.props.match.url}/security`);
			} else {
				this.props.history.push(`${this.props.match.url}/${this.props.defaultRouteUrl}`);
			}
		}
		if (
			prevProps.location.pathname !== this.props.location.pathname &&
			this.state.showSettings &&
			window.innerWidth < screenSize.lrg
		) {
			this.setState({
				showSettings: false,
			});
		}
	}
	componentDidMount() {
		this.unlistenHistory = this.props.history.listen(() => this.handleKvaasLoadError(false));
	}

	componentWillUnmount() {
		this.unlistenHistory();
	}

	get hasTerminalOnly() {
		return hasFeaturePackage(featurePackages.terminalOnly);
	}

	getClassName = (disabled, isMain) => {
		const navigationClass = `settings__nav__${isMain ? 'title' : 'link'} ${disabled ? 'is-disabled' : ''}`;
		return navigationClass;
	};

	openCloseNav = () => {
		this.setState({
			isNavOpen: !this.state.isNavOpen,
		});
	};

	findDefaultRoute = () => {
		const { routes } = this.props;
		const defRoute = find(routes, ({ isDefault }) => invokeIfFunction(isDefault));
		if (defRoute) {
			return `${this.props.match.url}${defRoute.path}`;
		}
		return null;
	};
	renderHeaderPanel = () => (
		<header className="header">
			<div className="header__breadcrumbs">{this.props.title}</div>
			<div className="header__menu">
				<UserAccountPanel />
			</div>
		</header>
	);

	renderRoutes = routes => {
		if (this.state.showKvaasError) {
			return (
				<div className="settings--main settings--main--alt">
					<div className="message message--warning">Settings failed to load, please try reloading.</div>
				</div>
			);
		}
		return (
			<Switch>
				{map(routes, (routeData, i) => {
					return invokeIfFunction(routeData.hidden) || routeData.disabled ? null : (
						<Route
							key={i}
							{...routeData}
							findDefaultRoute={this.findDefaultRoute}
							path={`${this.props.match.path}${routeData.path}`}
							componentProps={this.componentProps}
						/>
					);
				})}
				<Redirect to={this.getRedirectRoute()} />
			</Switch>
		);
	};
	getRedirectRoute = () => {
		const { match, redirectToRoute, isFraud } = this.props;
		if (isFraud) {
			return `${match.url}/fraudwatch`;
		}
		if (replace(match.path, /\//g, '') === replace(redirectToRoute, /\//g, '')) return '';
		return redirectToRoute;
	};
	toggleMobileScreenSettings = () => {
		this.setState({
			showSettings: !this.state.showSettings,
		});
	};

	renderShowSidebar = (onNavClick, navigationList, navClasses, routes, pathname, url) => {
		const hideSidebar = paymentSiteEditRegex.test(pathname);
		if (hideSidebar) return null;
		return (
			<Fragment>
				<button
					className="btn btn--action btn--action--secondary settings__aside__toggle"
					onClick={this.toggleMobileScreenSettings}
				>
					<i className="icon icon--sml icon--menu"></i>
				</button>
				{this.state.showSettings && (
					<div className="settings__aside__wrapper">
						<div className="settings__aside">
							<div className="display--n">
								<OutsideClick action={onNavClick}>
									<Fragment>
										<div className="pos--rel">
											<div className="input input--med input--select" onClick={this.openCloseNav}>
												{
													(
														find(routes, ({ exact, path }) =>
															exact ? pathname === `${url}${path}` : startsWith(pathname, `${url}${path}`)
														) || this.props.defaultRoute
													).label
												}
											</div>
											<ul className={navClasses}>{navigationList}</ul>
										</div>
									</Fragment>
								</OutsideClick>
							</div>
							<ul className="settings__nav">{navigationList}</ul>
						</div>
					</div>
				)}
			</Fragment>
		);
	};
	handleKvaasLoadError = (showKvaasError = true) => {
		this.setState({
			showKvaasError,
		});
	};
	render = () => {
		if (!this.componentProps.handleKvaasLoadError) {
			this.componentProps.handleKvaasLoadError = this.handleKvaasLoadError;
		}
		const {
			match: { url },
			location: { pathname },
			routes,
		} = this.props;
		const { isNavOpen } = this.state;
		const navClasses = isNavOpen ? 'settings__nav is-active' : 'settings__nav';
		const onNavClick = isNavOpen ? this.openCloseNav : noop;

		const navigationList = map(
			filter(routes, ({ path }) => {
				if (this.hasTerminalOnly) {
					return path === '/reports';
				} else {
					return true;
				}
			}),
			({ path, label, disabled, hidden, section, isMain }) =>
				path && !invokeIfFunction(hidden) ? (
					<Authorize key={path} section={section}>
						<li>
							<NavLink
								onClick={onNavClick}
								className={this.getClassName(disabled, isMain)}
								activeClassName="is-active"
								to={`${url}${path}`}
							>
								{label}
							</NavLink>
						</li>
					</Authorize>
				) : null
		);

		return (
			<Fragment>
				{this.renderHeaderPanel(pathname)}
				<div className="settings">
					{this.renderShowSidebar(onNavClick, navigationList, navClasses, routes, pathname, url)}
					<div className="settings__main">{this.renderRoutes(routes)}</div>
				</div>
			</Fragment>
		);
	};
}

SettingsTemplate.propTypes = {
	match: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	handleError: PropTypes.func.isRequired,
	makePendingRequest: PropTypes.func.isRequired,
	defaultRoute: PropTypes.object.isRequired,
	routes: PropTypes.array.isRequired,
	redirectToRoute: PropTypes.string.isRequired,
	defaultRouteUrl: PropTypes.string.isRequired,
	isFraud: PropTypes.any,
	userRole: PropTypes.any,
	title: PropTypes.string.isRequired,
	securityOnly: PropTypes.bool,
};

export default withError(withCancelable(SettingsTemplate));
