import { get, startsWith, toLower, transform, trim } from 'lodash';

import httpService from './httpService';
import principalService from './principalService';

const { keyManagementEndpoint } = ApplicationSettings;

class KeyManagementService {
	constructor(httpService) {
		this.httpService = httpService;

		this.loadEndpoint = `${keyManagementEndpoint}load`;
		this.saveEndpoint = `${keyManagementEndpoint}save`;
		this.createEndpoint = `${keyManagementEndpoint}createkey`;
		this.listEndpoint = `${keyManagementEndpoint}listkeys`;
	}

	getHeaders = mid => {
		let headers = new Headers();
		if (mid) {
			headers.set('x-merchant-id', mid);
		}
		return headers;
	};

	getOptions = mid => {
		return {
			isJson: true,
			headers: this.getHeaders(mid),
		};
	};

	load = async (mid, key) => {
		const result = await this.httpService.post(
			this.loadEndpoint,
			{ key },
			{
				...this.getOptions(mid),
				allowPublic: true,
			}
		);

		return this.parseResult(result);
	};

	save = async ({ key2, role, ddbrevision, index1, keytype, permissions, keySettings, whiteListedIPs, ...rest }) => {
		try {
			const result = await this.httpService.post(
				this.saveEndpoint,
				{
					data: {
						// key settings
						key2,
						role,
						DDB_Revision: ddbrevision || rest.ddbRevision,
						index1,
						keytype,
						WhiteList_ServerIPs: whiteListedIPs,
						...permissions,
						...keySettings,
						...rest,
					},
				},
				this.getOptions(null)
			);

			return this.parseResult(result);
		} catch (e) {
			return e;
		}
	};

	create = async ({ role, index1, keytype, email, ssoprovider, permissions, whiteListedIPs, ...rest }) => {
		const principal = principalService.get();
		const businessname = principal && principal.companyName;
		const newUser = {
			businessname,
			ssoprovider,
			data: {
				// key settings
				role,
				index1,
				keytype,
				WhiteList_ServerIPs: whiteListedIPs,
				...permissions,
				...rest,
			},
		};
		if (startsWith(toLower(keytype), 'sso')) {
			newUser.email = toLower(trim(email));
		}
		try {
			const result = await this.httpService.post(this.createEndpoint, newUser, this.getOptions(null));
			return this.parseResult(result);
		} catch (e) {
			return e;
		}
	};

	remove = async data => {
		const keyId = get(data, 'data.KeyId', false);
		if (keyId) {
			data.data.Key2 = data.data.KeyId;
			delete data.data.KeyId;
		}
		const result = await this.httpService.post(this.saveEndpoint, data, this.getOptions(null));
		return this.parseResult(result);
	};

	list = async (accountNumber, filter = {}) => {
		const result = await this.httpService.post(
			this.listEndpoint,
			{
				index1: accountNumber,
				...filter,
			},
			this.getOptions(null)
		);

		return this.parseResult(this.removeMasterKeys(result));
	};

	removeMasterKeys = data => {
		return {
			...data,
			data: transform(data.data, (acc, item, key) => {
				if (item.IsMasterKey !== 'True') {
					acc[key] = item;
				}
			}),
		};
	};

	parseResult = result => {
		return result;
	};
}

const keyManagementService = new KeyManagementService(httpService);

export default keyManagementService;
