import React from 'react';
import PropTypes from 'prop-types';

export default function FileNameRowRenderer({ row }) {
	return <div className="type--color--primary type--wgt--medium">{row.fileName}</div>;
}
FileNameRowRenderer.propTypes = {
	row: PropTypes.shape({
		fileName: PropTypes.string.isRequired,
	}).isRequired,
};
