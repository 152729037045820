import React from 'react';
import PropTypes from 'prop-types';

const NewPortal = ({ optInForNewPortal }) => {
	return (
		<div className="">
			Early Access To New Features!
			<p className="spc--bottom--sml">
				We've updated the Portal and we think you'll love it. You can try it out now, or you can stick with your current
				user experience. You can always switch back to the previous version at any time.{' '}
				<a className="btn btn--link" href="#" onClick={optInForNewPortal}>
					Click here
				</a>{' '}
				to try it out now!
			</p>
		</div>
	);
};

NewPortal.propTypes = {
	optInForNewPortal: PropTypes.func,
};

export default NewPortal;
