import React, { Component, createRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, filter, find, clone, get, toLower, includes, round } from 'lodash';
import moment from 'moment';

import { Filters, compileFilters } from './filter';
import { MainFilterComponent } from '../../Common/components/filter';
import { transactionService, kvaasService, principalService, emailService } from '../../Common/services';
import DashboardGrossRevenue from './dashboard-gross-revenue';
import DashboardTransactionStatus from './dashboard-transaction-status';
import DashboardPieChart from './dashboard-pie-chart';
import { UserAccountPanel } from './../../Common/components/user-account-panel';
import { withLoader } from '../../Common/components/loader';
import { withCancelable } from '../../Common/components/cancelable';
import { withError } from '../../Common/components/error';
import { kvaasResources } from '../../Common/utilities';
import predefinedDates from './filter/predefined-dates';
import sectionKeys from 'routing/sections';
import { ActionsModal, modalNames } from 'common/components/transaction-actions';
import { Notification } from 'common/components/notifications';
import { hasPaymentSites, isProPay } from 'common/services/helper-service';
import { emailTemplates } from '../../Common/utilities/emailTemplates';

const requestKeys = {
	FETCH: 'fetch',
	KVAAS: 'kvaas',
};

const dtFormat = ApplicationSettings.apiDateTimeFormat;

class Dashboard extends Component {
	constructor(props) {
		super(props);

		const filters = Filters;
		const activeFilters = props.activeFilters || cloneDeep(filters);

		const principal = principalService.get();
		this.notificationRef = createRef();
		this.state = {
			showTerms: true,
			data: null,
			hasRecords: false,
			isLoading: false,
			filters: filters,
			dateRange: null,
			activeFilter: activeFilters,
			// Transaction status
			sale: null,
			credit: null,
			total: null,
			avg: null,
			principal,
			modal: {
				name: modalNames.confirmAction,
				data: {
					question: 'Please confirm you would like to receive the link to our mobile app.',
					onConfirm: () => this.handleSendLink(),
				},
			},
		};
	}

	async componentDidMount() {
		try {
			const dateFilter = await this.props.makePendingRequest(this.fetchDashboardSettings(), requestKeys.FETCH);
			const [, proPay, hasPS] = await Promise.all([
				this.fetchData(dateFilter),
				isProPay(),
				hasPaymentSites(this.state.principal),
			]);
			this.setState({
				hasPaymentSite: hasPS,
				proPay,
			});
		} catch (e) {
			if (this.props.handleError(e)) {
				this.showLoader(false);
			}
		}
	}

	static getDerivedStateFromProps(props, state) {
		if (get(props.history, 'location.state.displaySendLink', false)) {
			props.history.replace({ state: null });
			state.popupActive = true;
			return state;
		}
		return null;
	}
	getSnapshotBeforeUpdate(prevProps) {
		// Register new click by detecting a different key
		if (prevProps.location.key !== this.props.location.key) {
			return true;
		}
		return false;
	}

	async componentDidUpdate(snapshot) {
		if (snapshot === true) {
			await this.fetchData();
		}
	}

	showLoader = (value = true) => {
		this.setState(
			{
				isLoading: value,
			},
			() => this.props.showLoader(value)
		);
	};

	generateTransactionStatus = () => {
		let sale = 0;
		let credit = 0;
		let count = 0;
		let saleCount = 0;
		let creditCount = 0;

		if (this.state.data && this.state.data.xReportData && this.state.data.xReportData.length > 0) {
			const data = this.state.data.xReportData;
			for (let item of data) {
				const type = item.xCommand.toLowerCase().split(':')[1];
				const isGiftIssue = toLower(type) === 'issue';
				if (includes(['sale', 'capture', 'postauth', 'authonly', 'redeem', 'recommendation'], type)) {
					sale += Math.abs(item.xAmount);
					saleCount++;
					count++;
				} else if (type === 'credit' || type === 'refund' || isGiftIssue) {
					credit += Math.abs(item.xAmount);
					creditCount++;
					count++;
				}
			}
		}

		let avg = (sale + Math.abs(credit)) / count;
		this.setState({
			sale,
			credit,
			total: round(sale - credit, 2),
			avg,
			saleCount,
			creditCount,
		});
	};

	getDate = date => find(predefinedDates, ({ key }) => key === date);

	fetchDashboardSettings = async () => {
		try {
			this.showLoader(true);
			const { activeFilter } = this.state;
			const dateFilter = clone(activeFilter);
			const [dashboardSettings, portalFlags] = await this.props.makePendingRequest(
				kvaasService.get(kvaasResources.dashboardSettings, kvaasResources.portalFlags),
				requestKeys.KVAAS
			);
			this.setState({ portalFlags });
			const date = get(dashboardSettings, 'data.date', false);
			if (date) {
				const predefinedDate = this.getDate(date);
				if (!predefinedDate) {
					return;
				}
				const { key, startValue, endValue } = predefinedDate;
				const values = {
					key,
					startDate: moment()
						.startOf('day')
						.add(startValue, 'days'),
					endDate: moment()
						.endOf('day')
						.add(endValue, 'days'),
				};

				dateFilter[0].defaultValues = values;
				dateFilter[0].values = values;
			}
			return dateFilter;
		} catch (e) {
			if (this.props.handleError(e)) {
				this.showLoader(false);
			}
		}
	};

	fetchData = async (activeFilter = this.state.activeFilter) => {
		try {
			this.showLoader(true);
			const dateRange = await this.props.makePendingRequest(compileFilters(activeFilter, dtFormat), requestKeys.FETCH);

			const displayOlderTransactionsFirst = get(this.state.portalFlags, 'data.displayOlderTransactionsFirst', false);

			const dataFilter = cloneDeep(dateRange);
			dataFilter.xCommand = 'Report:All';

			const fields = transactionService.getDashboardFields();
			let promise = [transactionService.filterTransactionsAll(dataFilter, fields, displayOlderTransactionsFirst)];

			const [data] = await this.props.makePendingRequest(Promise.all(promise), requestKeys.FETCH);

			// Filter out voided transactions with amount=0 (CP-358)
			if (data && data.xReportData && data.xReportData.length > 0) {
				const filteredData = filter(data.xReportData, row => {
					if (!(row.xAmount == 0 && row.xVoid == '1') && toLower(row.xResponseResult) === 'approved') {
						return row;
					}
				});
				data.xReportData = filteredData;
			}

			this.setState(
				{
					data: data,
					hasRecords: (data && data.xReportData && data.xReportData.length > 0) || false,
					dateRange: dateRange,
				},
				() => {
					this.generateTransactionStatus();
					this.showLoader(false);
				}
			);
		} catch (e) {
			if (this.props.handleError(e)) {
				this.showLoader(false);
			}
		}
	};

	onFilterUpdate = ({ filters, activeFilters }) => {
		let shouldFetch = false;
		const state = {};

		if (filters) {
			state.filters = filters;
		}

		if (activeFilters) {
			state.activeFilters = activeFilters;
			shouldFetch = true;
		}

		this.setState(state, () => {
			if (shouldFetch) {
				this.fetchData();
			}
		});
	};

	displayTermsMessage = () => {
		const { showTerms, principal } = this.state;
		if (!showTerms || !get(principal, `hasAccess.${sectionKeys.featureAddons}`)) return null;
		return (
			<div className="dashboard__message">
				<p className="type--p1 type--p1--medium">
					Get started with our Cardknox Gift Card Program to issue, track, and redeem gift cards.
				</p>
				<div className="dashboard__message__actions">
					<button
						className="btn btn--sml btn--secondary"
						onClick={() =>
							window.open(
								'https://www.cardknox.com/gift?utm_source=portal&utm_medium=banner&utm_campaign=cs',
								'_blank',
								'noopener'
							)
						}
					>
						Learn More
					</button>
					<button
						className="btn btn--sml btn--primary"
						onClick={() =>
							this.props.history.push({
								pathname: 'features/gift-card',
							})
						}
					>
						Activate
					</button>
				</div>
			</div>
		);
	};

	hideTermsMessage = () => {
		this.setState({
			showTerms: false,
		});
	};

	newTransactionLink = () => {
		const { history } = this.props;
		history.push({
			pathname: '/transactions',
			openNewTransaction: true,
		});
	};

	handleSendLink = async () => {
		this.props.history.replace({ state: null });
		this.props.showLoader(true);
		if (this.state.activating) return;
		if (!this.state.activating) {
			this.setState({ activating: true });
		}

		const templateParams = {
			To: this.state.principal.email,
		};

		const template = {
			TemplateName: emailTemplates.mobileApp,
			...templateParams,
		};

		try {
			await this.props.makePendingRequest(emailService.sendEmail(template), 'send');
			if (this.notificationRef.current) {
				this.notificationRef.current.addNotification({
					message: (
						<div>
							<div className="spc--bottom--sml">
								Your download link is on the way! Please check your email on your mobile device.
							</div>
						</div>
					),
					success: true,
				});
			}
		} catch (e) {
			this.props.handleError(e);
		} finally {
			this.props.showLoader(false);
			this.setState({ activating: false });
		}
	};

	onBeforeSendLink = async () => {
		this.setState({ popupActive: true });
	};
	closeActionsPopup = () => {
		this.props.history.replace({ state: null });
		this.setState({ popupActive: false });
	};

	render() {
		const { filters, activeFilter, data, hasRecords, isLoading, dateRange, principal, modal, popupActive } = this.state;

		const canProcessNewTransaction =
			principal && principal.hasAccess && principal.hasAccess[sectionKeys.newTransaction];
		const tooltip = canProcessNewTransaction ? null : 'Permission required';

		return (
			<Fragment>
				<Notification ref={this.notificationRef} />
				{popupActive && (
					<ActionsModal
						modal={modal}
						onModalClose={this.closeActionsPopup}
						notificationRef={this.notificationRef}
						isLoading={isLoading}
					/>
				)}
				<header className="header">
					<div className="header__breadcrumbs">Dashboard</div>
					<div className="header__menu">
						<UserAccountPanel displayEnterBetaPortal={true} />
					</div>
				</header>
				<div className="l--content">
					<div className="flex--tertiary flex--top flex--gap--med spc--bottom--med">
						<h3>Welcome to Sola</h3>
						<button
							data-tooltip={tooltip}
							disabled={!canProcessNewTransaction}
							type="button"
							className="btn btn--med btn--primary"
							onClick={this.newTransactionLink}
						>
							<i className="icon icon--sml icon--add--white"></i>
							<span>New Transaction</span>
						</button>
					</div>
					{this.displayTermsMessage()}
					<div className="spc--bottom--lrg">
						<MainFilterComponent
							filters={filters}
							activeFilters={activeFilter}
							updateFilters={this.onFilterUpdate}
							predefinedDates={predefinedDates}
							displayStandaloneDatePicker={true}
						/>
					</div>

					{isLoading ? null : (
						<Fragment>
							<DashboardTransactionStatus
								hasRecords={hasRecords}
								sale={this.state.sale}
								credit={this.state.credit}
								total={this.state.total}
								avg={this.state.avg}
							/>
							<DashboardGrossRevenue
								data={data}
								hasRecords={hasRecords}
								filters={filters}
								dateRange={dateRange}
								averageValue={this.state.avg}
							/>
							<div className="dashboard__summary">
								<div className="dashboard__summary__item">
									<DashboardPieChart
										data={data}
										total={this.state.sale}
										count={this.state.saleCount}
										shouldDisplayChart={hasRecords}
										type="sale"
									/>
								</div>
								<div className="dashboard__summary__item">
									<DashboardPieChart
										data={data}
										total={this.state.credit}
										count={this.state.creditCount}
										shouldDisplayChart={hasRecords}
										type="credit"
									/>
								</div>
							</div>
						</Fragment>
					)}
				</div>
			</Fragment>
		);
	}
}

Dashboard.propTypes = {
	activeFilters: PropTypes.any,
	filters: PropTypes.any,
	handleError: PropTypes.func,
	makePendingRequest: PropTypes.func,
	showLoader: PropTypes.func,
	history: PropTypes.object,
	location: PropTypes.object,
};

export default withError(withLoader(withCancelable(Dashboard)));
