import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import AddEditTemplateForm from './add-edit-template-form';
import { Modal } from '../../modal';

class AddEditRecurringTemplateAddEdit extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isModalOpen: false,
		};
	}

	handleOpenModal = () => {
		const { type, openEditModal } = this.props;
		if (type === 'edit') {
			openEditModal();
		}
		this.setState({ isModalOpen: true });
	};

	handleCloseModal = () => {
		const { type, closeEditModal } = this.props;
		if (type === 'edit') {
			closeEditModal();
		}
		this.setState({ isModalOpen: false });
	};

	render = () => {
		const { isModalOpen } = this.state;
		const { trigger, template, saveTemplate, type, isEditOpen } = this.props;

		return (
			<Fragment>
				{type === 'edit' ? (
					<Modal isOpen={isEditOpen} onClose={this.handleCloseModal} className="modal__content modal--med">
						<AddEditTemplateForm
							type={type}
							template={template}
							saveTemplate={saveTemplate}
							handleCloseModal={this.handleCloseModal}
						/>
					</Modal>
				) : (
					<Fragment>
						{trigger({ onClick: this.handleOpenModal })}
						<Modal isOpen={isModalOpen} onClose={this.handleCloseModal} className="modal__content modal--med">
							<Fragment>
								{isModalOpen && (
									<AddEditTemplateForm
										type={type}
										template={template}
										saveTemplate={saveTemplate}
										handleCloseModal={this.handleCloseModal}
									/>
								)}
							</Fragment>
						</Modal>
					</Fragment>
				)}
			</Fragment>
		);
	};
}

AddEditRecurringTemplateAddEdit.propTypes = {
	template: PropTypes.object.isRequired,
	saveTemplate: PropTypes.func.isRequired,
	type: PropTypes.string.isRequired,
	isEditOpen: PropTypes.bool,
	openEditModal: PropTypes.func,
	closeEditModal: PropTypes.func,
	trigger: PropTypes.any,
};

export default AddEditRecurringTemplateAddEdit;
