import React, { Component } from 'react';

import GiftActivity from './GiftActivity';
import GiftSummary from './GiftSummary';
import GiftLiability from './GiftLiability';
import sectionKeys from '../../routing/sections';
import NestedRoutes from 'routing/NestedRoutes';

const routes = [
	{
		path: '/activity',
		exact: true,
		component: GiftActivity,
		section: sectionKeys.giftActivity,
		title: 'Gift Card Activity',
		mobileTitle: 'Gift Card Activity',
		linkTitle: 'Activity',
	},
	{
		path: '/summary',
		component: GiftSummary,
		section: sectionKeys.giftSummary,
		title: 'Gift Card Summary',
		mobileTitle: 'Gift Card Summary',
		linkTitle: 'Summary',
	},
	{
		path: '/liability',
		component: GiftLiability,
		section: sectionKeys.giftLiability,
		title: 'Gift Card Liability Report',
		mobileTitle: 'Gift Card Liability',
		linkTitle: 'Liability',
	},
];

class GiftReport extends Component {
	render() {
		return <NestedRoutes tabs={routes} />;
	}
}

export default GiftReport;
