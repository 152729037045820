import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';

const cardTrendArrows = {
	positive: {
		arrow: 'top--success',
		cssClass: 'type--color--success',
		badge: 'success',
	},
	negative: {
		arrow: 'down--error',
		cssClass: 'type--color--error',
		badge: 'error',
	},
	neutral: {
		arrow: null,
		cssClass: null,
		badge: 'default',
	},
};

const BatchCard = ({ title, amount, count, currency }) => {
	// Determine trend based on the title
	let trend;
	if (title === 'Sale' || title === 'Void') {
		trend = cardTrendArrows.neutral;
	} else if (title === 'Credit' || title === 'Total Processing Fee') {
		trend = cardTrendArrows.negative;
	} else {
		trend = cardTrendArrows.positive;
	}

	return (
		<div key={title} className="display--ib flex--grow--1">
			<label className="type--p2 spc--bottom--sml">{title}</label>
			<div className={title === 'Total' ? 'flex--primary flex--gap--tny' : undefined}>
				<NumberFormat
					prefix={currency}
					value={amount}
					displayType="text"
					thousandSeparator={true}
					decimalScale={2}
					fixedDecimalScale={true}
					className={classNames('display--b', title === 'Total' ? 'type--h3' : 'type--h4', trend.cssClass)}
				/>
				<div className={`badge badge--${trend.badge}`}>
					<NumberFormat value={count} displayType="text" thousandSeparator={true} />
					{title !== 'Total' && trend !== cardTrendArrows.neutral && (
						<i className={`icon icon--tny icon--arrow--${trend.arrow}`}></i>
					)}
				</div>
			</div>
		</div>
	);
};

BatchCard.propTypes = {
	title: PropTypes.string.isRequired,
	amount: PropTypes.number.isRequired,
	count: PropTypes.number.isRequired,
	currency: PropTypes.string.isRequired,
};

export default BatchCard;
