import { each, toLower, some, get, includes, filter, isFunction } from 'lodash';
import { Auth, Hub } from 'aws-amplify';

import sectionKeys from './../../routing/sections';
import { defaultIfields } from 'common/fields';

const principalKey = 'PRINCIPAL';
const guidRegex = new RegExp('^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$');

class Principal {
	constructor({
		id,
		ifields,
		companyName,
		list = [],
		idInfo = {},
		email = '',
		username = '',
		isAdmin = false,
		isPortalDropIn = false,
		validatedActiveKeys = false,
		pendingActiveKeysValidation = null,
		redirectToSecurity = false,
		isSamlLogin = false,
	}) {
		this.id = id;
		this.ifields = ifields;
		this.companyName = companyName;
		this.list = list;
		this.idInfo = idInfo;
		this.email = email;
		this.username = username;
		this.isAdmin = isAdmin;
		this.isPortalDropIn = isPortalDropIn;
		this.validatedActiveKeys = validatedActiveKeys;
		this.pendingActiveKeysValidation = pendingActiveKeysValidation;
		this.redirectToSecurity = redirectToSecurity;
		this.isSamlLogin = isSamlLogin;
		// Permissions based on account key flags
		this.hasAccess = {};
		if (this.idInfo) {
			if (!this.idInfo.permissions) {
				this.idInfo.permissions = {};
			}
			const {
				xFraudAccount,
				xBatchReportingEnabled,
				acceptedTermsAndConditions,
				permissions,
				xIsTestAccount,
				xIsGoPlusAccount,
				xIsExchangeAccount,
				xIsInterchangePlus,
			} = this.idInfo;
			const {
				allowReportAll,
				allowReportGiftApproved,
				allowReportGiftSummary,
				allowReportLiability,
				allowCcAuthOnly,
				allowCcCredit,
				allowCcPostAuth,
				allowCcSale,
				allowCcSave,
				allowCheckSale,
				allowCheckSave,
				allowReportBatch,
				allowEbtfsAuthOnly,
				allowEbtcbAuthOnly,
				allowEbtwAuthOnly,
				allowGiftIssue,
				allowGiftRedeem,
				allowEbtfsVoucher,
				role,
			} = permissions;
			const isAdminRole = toLower(role) === 'admin';
			this.isViewOnly = toLower(role) === 'viewonly';
			this.hasAccess[sectionKeys.statements] = isAdminRole && !!this.idInfo.xIsExchangeAccount;
			this.hasAccess[sectionKeys.fraud] = !!xFraudAccount;
			this.hasAccess[sectionKeys.batches] = !!xBatchReportingEnabled && allowReportBatch;
			this.acceptedTermsAndConditions = !!acceptedTermsAndConditions;
			this.hasAccess[sectionKeys.users] = isAdminRole;
			this.hasAccess[sectionKeys.notSaveOnly] = toLower(role) !== 'saveonly';
			this.hasAccess[sectionKeys.customers] = !this.hasAccess[sectionKeys.fraud];
			this.hasAccess[sectionKeys.settings] =
				toLower(role) !== 'authonly' &&
				toLower(role) !== 'saleonly' &&
				toLower(role) !== 'saveonly' &&
				!this.hasAccess[sectionKeys.fraud];
			this.hasAccess[sectionKeys.solaAccountSettings] = toLower(role) === 'admin' || toLower(role) === 'advanced';
			this.hasAccess[sectionKeys.transactions] = !!allowReportAll;
			this.hasAccess[sectionKeys.disputes] = !!allowReportAll;
			this.hasAccess[sectionKeys.goPlus] = !!xIsGoPlusAccount;
			this.hasAccess[sectionKeys.interchangePlus] = !!xIsInterchangePlus;
			this.hasAccess[sectionKeys.newTransaction] = some(
				[
					allowCcAuthOnly,
					allowEbtfsAuthOnly,
					allowEbtcbAuthOnly,
					allowEbtwAuthOnly,
					allowCcSale,
					allowCcSave,
					allowCcCredit,
					allowCcPostAuth,
					allowCheckSale,
					allowCheckSave,
					allowGiftIssue,
					allowGiftRedeem,
					allowEbtfsVoucher,
				],
				item => item
			);
			this.hasAccess[sectionKeys.gift] = some(
				[!!allowReportGiftApproved, !!allowReportGiftSummary, !!allowReportLiability],
				item => !!item
			);
			this.hasAccess[sectionKeys.giftActivity] = !!allowReportGiftApproved;
			this.hasAccess[sectionKeys.giftSummary] = !!allowReportGiftSummary;
			this.hasAccess[sectionKeys.giftLiability] = !!allowReportLiability;
			this.hasAccess[sectionKeys.portalManagement] = !!this.isAdmin;
			this.hasAccess[sectionKeys.paymentEngine] = isAdminRole;
			this.hasAccess[sectionKeys.dropIn] = !!this.isPortalDropIn;
			this.hasAccess[sectionKeys.featureAddons] = isAdminRole && !xIsTestAccount;
			this.hasAccess[sectionKeys.virtualTerminal] =
				!this.hasAccess[sectionKeys.transactions] &&
				!this.hasAccess[sectionKeys.dashboard] &&
				this.hasAccess[sectionKeys.newTransaction];
			this.hasAccess[sectionKeys.transfers] = !!xIsExchangeAccount;
		} else {
			each(sectionKeys, key => {
				this.hasAccess[key] = false;
			});
		}
		this.hasAccess[sectionKeys.dashboard] =
			!this.hasAccess[sectionKeys.fraud] && this.idInfo.permissions.allowReportApproved;
	}
}

class PrincipalService {
	principal = null;
	subscriptions = [];

	constructor() {
		this.email = '';
		this.username = '';
		this.isAdmin = false;
		this.isPortalDropIn = false;
		this.emailPromise = new Promise(() => {}); // Initialize with a promise that will never resolve.

		const updateEmail = ({ payload: { event, data } }, resolve = null, reject = null) => {
			this.emailPromise = new Promise((innerResolve, innerReject) => {
				const isFSLoaded = isFunction(get(window, 'FS.identify'));
				if (event === 'signOut' || event === 'oAuthSignOut') {
					this.clear();
					if (!isFSLoaded) {
						if (resolve) {
							resolve();
						}
						innerResolve();
						return;
					}
					window.FS.identify(false);
					if (resolve) {
						resolve();
					}
					innerResolve();
					return;
				}
				if (event !== 'signIn' && event !== 'cognitoHostedUI') {
					if (resolve) {
						resolve();
					}
					innerResolve();
					return;
				}
				const username = get(data, 'username', '');
				const isOauth = !guidRegex.test(username);
				this.email = get(data, 'attributes.email');
				if (!this.email) {
					// The initial payload for OAuth authentication does not contain the email
					// but it shows up when fetching the current authenticated user
					Auth.currentAuthenticatedUser().then(data => {
						if (data) {
							if (resolve) {
								resolve();
							}
							updateEmail({ payload: { event: 'signIn', data } }, innerResolve, innerReject);
						} else {
							if (reject) {
								reject(new Error('Failed to fetch current authenticated user'));
							}
						}
					});
					return;
				}
				this.username = isOauth ? username : this.email;
				const cognitoGroups = get(data, 'signInUserSession.idToken.payload.cognito:groups', []);
				this.isAdmin = includes(cognitoGroups, 'admin');
				this.isPortalDropIn = includes(cognitoGroups, 'portal_dropin');

				if (isFSLoaded && username) {
					window.FS.identify(username, { email: this.email });
				}
				const currentPrincipal = this.get();
				if (!currentPrincipal) {
					if (resolve) {
						resolve();
					}
					innerResolve();
					return;
				}
				if (currentPrincipal.email && currentPrincipal.email !== this.email) {
					this.clear();
					if (resolve) {
						resolve();
					}
					innerResolve();
					return;
				}
				this.set(currentPrincipal);
				if (resolve) {
					resolve();
				}
				innerResolve();
			});
		};

		Hub.listen('auth', updateEmail);

		Auth.currentAuthenticatedUser()
			.then(data => {
				if (data) {
					updateEmail({ payload: { event: 'signIn', data } });
				}
			})
			.catch(() => {
				this.clear();
			});
	}
	set(principalData, suppress) {
		if (principalData) {
			// Set default ifields
			if (!principalData.ifields) {
				principalData.ifields = defaultIfields;
			}
			principalData.email = this.email;
			principalData.username = this.username;
			principalData.isAdmin = this.isAdmin;
			principalData.isPortalDropIn = this.isPortalDropIn;

			this.principal = new Principal(principalData);
			const { pendingActiveKeysValidation, ...rest } = principalData;
			localStorage.setItem(principalKey, JSON.stringify(rest));
			this.notify(suppress);
		}
	}

	get() {
		if (this.principal) {
			return this.principal;
		}
		const storedPrincipalData = JSON.parse(localStorage.getItem(principalKey));
		if (storedPrincipalData && storedPrincipalData.id) {
			this.principal = new Principal(storedPrincipalData);
			return this.principal;
		} else {
			return false;
		}
	}

	getDemoUser() {
		return { id: 'Mono_Test', ifields: 'ifields_GlobalIfieldDev_Test2_8178e18e9b4d4c4', companyName: 'Mono', list: [] };
	}

	clear() {
		this.principal = null;
		localStorage.removeItem(principalKey);
		this.notify();
	}

	notify(suppress = false) {
		const subscriptions = suppress ? filter(this.subscriptions, { ignoreSuppress: true }) : this.subscriptions;
		each(subscriptions, ({ handler }) => handler(this.principal));
	}

	subscribe(handler, ignoreSuppress = false) {
		const subscription = {
			handler,
			ignoreSuppress,
			unsubscribe: () => {
				const index = this.subscriptions.indexOf(subscription);
				this.subscriptions.splice(index, 1);
			},
		};
		this.subscriptions.push(subscription);
		return subscription;
	}
}

const principalService = new PrincipalService();

export default principalService;
