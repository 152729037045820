import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { findIndex, each, startsWith } from 'lodash';

import { AddEditTemplate, DeleteTemplate } from '../../recurring-templates/popup';
import AddEditCustomer from '../../customers/popup/add-edit-customer';
import { OutsideClick } from '../../../utilities';
import LinkExistingCustomerForm from 'common/components/customers/popup/LinkExistingCustomerForm';
import { Modal } from 'common/components/modal';

class TemplateActionsComponent extends Component {
	constructor(props) {
		super(props);

		TemplateActionsComponent.instances.push(this);
		this.popupRef = React.createRef();
		this.state = this.initialState;
	}
	get initialState() {
		return {
			template: {},
			isDisplayMenuOpen: false,
			isEditOpen: false,
			isDeleteOpen: false,
			isNewCustomerOpen: false,
			isOpenLinkSchedule: false,
			isOpenLinkExistingCustomer: false,
			data: null,
			selectedCustomer: null,
			isLoading: false,
		};
	}

	static instances = [];

	componentDidMount = () => {
		this.mapTemplateToState();
	};

	componentDidUpdate = prevProps => {
		if (prevProps.dependentValues !== this.props.dependentValues) {
			this.mapTemplateToState();
		}
		if (prevProps.isScrolling !== this.props.isScrolling) {
			this.closeDisplayMenu();
		}
	};

	componentWillUnmount() {
		const index = findIndex(TemplateActionsComponent.instances, this);
		if (index > -1) {
			TemplateActionsComponent.instances.splice(index, 1);
		}
	}
	closeLinkSchedulePopup = () => {
		this.setState({
			isOpenLinkExistingCustomer: false,
			isNewCustomerOpen: false,
			selectedCustomer: null,
		});
	};

	openLinkExistingCustomer = () => {
		this.setState({ isOpenLinkExistingCustomer: true });
	};

	selectCustomer = customer => {
		this.setState({ selectedCustomer: customer });
	};

	closeRenderLinkSchedulePopup = () => {
		this.setState({ ...this.initialState, template: this.state.template });
	};
	mapTemplateToState = () => {
		const template = {};
		each(this.props.dependentValues, (value, key) => {
			if (startsWith(key, 'x') || key === 'amount') {
				template[key] = value;
			}
		});
		this.setState({ template });
	};

	displayMenu = () => {
		const { openActions } = this.props.dependentValues;
		const { isDisplayMenuOpen } = this.state;

		const actions = isDisplayMenuOpen ? (
			<OutsideClick action={this.closeDisplayMenu} className="popover">
				<ul className="popover__list">
					<li className="item">
						<button className="btn btn--link btn--link--tertiary" onClick={this.openNewCustomer}>
							<i className="icon icon--lrg icon--add-user--light spc--right--sml--alt"></i>
							<span>New Customer</span>
						</button>
					</li>
					<li className="item">
						<button className="btn btn--link btn--link--tertiary" onClick={this.openLinkExistingCustomer}>
							<i className="icon icon--lrg icon--customers--light spc--right--sml--alt"></i>
							<span>Existing Customer</span>
						</button>
					</li>
				</ul>
			</OutsideClick>
		) : null;
		const rect = this.info.getBoundingClientRect();
		openActions(
			{
				width: (rect.left || 0) - 10,
				height: rect.top || 0,
			},
			actions
		);
		if (isDisplayMenuOpen) {
			each(TemplateActionsComponent.instances, instance => {
				if (instance.state.isDisplayMenuOpen && instance !== this) {
					instance.setState({ isDisplayMenuOpen: false });
				}
			});
		}
	};

	openDisplayMenu = () => {
		this.setState({ isDisplayMenuOpen: true }, this.displayMenu);
	};

	closeDisplayMenu = () => {
		this.setState({ isDisplayMenuOpen: false }, this.displayMenu);
	};

	openNewCustomer = () => {
		this.setState({ isNewCustomerOpen: true, isDisplayMenuOpen: true }, this.displayMenu);
	};

	openEditModal = () => {
		this.setState({ isEditOpen: true, isDisplayMenuOpen: false }, this.displayMenu);
	};

	openDeleteModal = () => {
		this.setState({ isDeleteOpen: true, isDisplayMenuOpen: false }, this.displayMenu);
	};

	closeNewCustomerModal = () => {
		this.setState({ isNewCustomerOpen: false });
	};

	closeEditModal = () => {
		this.setState({ isEditOpen: false });
	};

	closeDeleteModal = () => {
		this.setState({ isDeleteOpen: false });
	};

	handleLinkExistingCustomer = async (selectedCustomer, template) => {
		this.setState({ isLoading: true });
		const { linkScheduleToExistingCustomer } = this.props.dependentValues;
		await linkScheduleToExistingCustomer(selectedCustomer, template);
		this.closeLinkSchedulePopup();
		this.closeRenderLinkSchedulePopup();
		this.setState({ isLoading: false });
	};
	renderLinkSchedulePopup = () => {
		const { isOpenLinkExistingCustomer, selectedCustomer, isNewCustomerOpen, template, isLoading } = this.state;
		const { refreshGridData, notificationRef } = this.props.dependentValues;

		return (
			<div ref={this.popupRef}>
				{!isOpenLinkExistingCustomer ? (
					<AddEditCustomer
						advancedView={true}
						advancedViewByDefault={true}
						closeModal={this.closeNewCustomerModal}
						template={template}
						isOpen={isNewCustomerOpen}
						refreshGrid={refreshGridData}
						type="schedules"
						notificationRef={notificationRef()}
						closeLinkTransactionPopup={this.closeLinkSchedulePopup}
					/>
				) : (
					<Modal
						isOpen={true}
						onClose={this.closeLinkSchedulePopup}
						isLoading={isLoading}
						displayProgress={true}
						className="modal__content modal--lrg"
					>
						<div className="modal__body">
							<p className="notes notes--primary spc--bottom--lrg">
								<i className="icon"></i>
								<p className="type--p2">Please select the customer you'd like to link this Schedule Template to.</p>
							</p>
							{isOpenLinkExistingCustomer && (
								<div className="grid__holder--link-trans">
									<LinkExistingCustomerForm popupRef={this.popupRef} selectCustomer={this.selectCustomer} />
								</div>
							)}
						</div>
						<div className="modal__footer">
							<button
								type="button"
								tabIndex="-1"
								className="btn btn--med btn--primary"
								onClick={() => this.handleLinkExistingCustomer(selectedCustomer, template)}
								disabled={!selectedCustomer || isLoading}
							>
								Save
							</button>
						</div>
					</Modal>
				)}
			</div>
		);
	};

	render() {
		const { isDisplayMenuOpen, template, isEditOpen, isDeleteOpen } = this.state;
		const { saveTemplate } = this.props.dependentValues;
		return (
			<Fragment>
				<div className="flex--primary flex--gap--med flex--nowrap">
					<button
						className="btn btn--link datatooltip--auto datatooltip--left datatooltip--no-wrap"
						onClick={this.openDeleteModal}
						data-tooltip="Delete template"
					>
						<i className="icon icon--sml icon--delete"></i>
					</button>
					<button
						className="btn btn--link datatooltip--auto datatooltip--left datatooltip--no-wrap"
						onClick={this.openEditModal}
						data-tooltip="Edit"
					>
						<i className="icon icon--sml icon--edit"></i>
					</button>
					<button
						className="btn btn--link datatooltip--auto datatooltip--left datatooltip--no-wrap"
						onClick={this.openDisplayMenu}
						ref={el => (this.info = el)}
						data-tooltip="Apply schedule template"
					>
						<i className={`icon icon--sml icon--more${isDisplayMenuOpen ? '--primary' : ''}`}></i>
					</button>
				</div>
				<DeleteTemplate
					closeModal={this.closeDeleteModal}
					saveTemplate={saveTemplate}
					template={template}
					isModalOpen={isDeleteOpen}
				/>
				<AddEditTemplate
					openEditModal={this.openEditModal}
					closeEditModal={this.closeEditModal}
					saveTemplate={saveTemplate}
					template={template}
					type="edit"
					isEditOpen={isEditOpen}
					className="modal__content modal__med"
				/>
				{this.renderLinkSchedulePopup()}
			</Fragment>
		);
	}
}

TemplateActionsComponent.propTypes = {
	dependentValues: PropTypes.object,
	isScrolling: PropTypes.bool,
};

export default TemplateActionsComponent;
