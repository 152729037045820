import React, { Component, Fragment, createRef } from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
	cloneDeep,
	map,
	isEmpty,
	chain,
	each,
	sumBy,
	filter,
	find,
	toLower,
	split,
	compact,
	join,
	some,
	orderBy,
	reverse,
	camelCase,
	get,
	every,
	groupBy,
	assign,
	startsWith,
	round,
	take,
	takeRight,
} from 'lodash';
import { ExportToCsv } from 'export-to-csv';

import BatchCards from './batch-cards';
import { Modal } from '../../Common/components/modal';
import { Notification } from '../../Common/components/notifications';
import { batchService, transactionService, principalService, kvaasService } from '../../Common/services';
import { Filters, compileClosedBatchFilters, compileOpenBatchFilters } from './filter';
import { OutsideClick, getFilter, CurrencyMap, apiToLocalMoment, kvaasResources } from './../../Common/utilities';
import { BatchExportColumns, batchDebitExportColumns } from './export-columns';
import { exportOptions } from './../../Common/components/export/export-options';
import { UserAccountPanel } from './../../Common/components/user-account-panel';
import { BreakdownGrid } from '../../Common/components/breakdown-grid';
import { withCancelable } from './../../Common/components/cancelable';
import { withError } from './../../Common/components/error';
import { PrintGridData } from '../../Common/components/print-grid-data';
import { ReactToPrint } from '../../Common/components/react-to-print';
import tabs from './batchTabs';
import { batchPredefinedDates } from 'common/components/date-picker';

const { apiDateFormat } = ApplicationSettings;

const withVoidColumns = ['Total', 'Sale', 'Void', 'Credit'];

const noVoidColumns = ['Total', 'Sale', 'Credit'];

const cardNames = {
	Amex: 'Amex',
	Discover: 'Discover',
	EBT: 'EBT',
	EBTW: 'EBTW',
	MasterCard: 'MC',
	Visa: 'Visa',
};

class BatchIndex extends Component {
	showLoader = activeTab => {
		const isLoading = activeTab === tabs.closed ? 'closedIsLoading' : 'openIsLoading';
		this.setState({
			[isLoading]: true,
		});
	};

	hideLoader = activeTab => {
		const isLoading = activeTab === tabs.closed ? 'closedIsLoading' : 'openIsLoading';
		this.setState({
			[isLoading]: false,
		});
	};

	constructor(props) {
		super(props);

		const closedFilters = cloneDeep(Filters);
		const openFilters = cloneDeep(Filters);
		const openDateFilter = getFilter(openFilters, 'date');
		openDateFilter.values.startDate = moment()
			.add(-7, 'days')
			.startOf('day');
		openDateFilter.values.endDate = moment().endOf('day');
		openDateFilter.values.key = '7';
		openDateFilter.defaultValues = cloneDeep(openDateFilter.values);
		openDateFilter.values.disabled = true;
		const closedActiveFilters = cloneDeep(closedFilters);
		const openActiveFilters = cloneDeep(openFilters);
		const principal = principalService.get();

		this.state = {
			currency: CurrencyMap.resolveCurrency(),
			showProcessingFee: principal && principal.idInfo.xIsGoPlusAccount && !principal.idInfo.xIsInterchangePlus,
			closedBatches: {},
			firstOpenBatches: {},
			openBatches: {},
			openDateText: '',
			openSelectedStartDate: '',
			openSelectedEndDate: '',
			closedDateText: '',
			closedSelectedStartDate: '',
			closedSelectedEndDate: '',
			closedIsLoading: true,
			openIsLoading: true,
			openFilters,
			closedFilters,
			openActiveFilters,
			closedActiveFilters,
			activeTab: tabs.closed,
			isClosingBatch: false,
			isPrinting: false,
			displayOpenBatchesByDefault: false,
			expandedRows: {},
		};

		this.breakdown = createRef();
		this.setState = this.setState.bind(this);
	}

	updateExpandedRows = expandedRows => {
		this.setState({
			expandedRows,
		});
	};

	isFundedReport = (closedBatches = this.state.closedBatches) => {
		const { activeTab } = this.state;
		if (activeTab === tabs.open) {
			return false;
		}
		return !some(closedBatches && closedBatches.xReportData, ({ xBatch }) => xBatch);
	};

	isFundedReportOpenBatches = (closedBatches = this.state.closedBatches) => {
		const { activeTab } = this.state;
		return (
			closedBatches &&
			!isEmpty(closedBatches.xReportData) &&
			!some(closedBatches.xReportData, ({ xBatch }) => xBatch) &&
			activeTab === tabs.open
		);
	};

	get fontSizeClassName() {
		const { length } = this.batchesDetailsPrintColumns;
		if (length < 6) {
			return 'type--lrg';
		} else if (length < 10) {
			return 'type--base';
		} else {
			return '';
		}
	}

	get data() {
		const { activeTab, openBatches, closedBatches } = this.state;
		return activeTab === tabs.closed ? closedBatches : openBatches;
	}

	get selectedDateText() {
		const { activeTab, openDateText, closedDateText } = this.state;
		return activeTab === tabs.closed ? closedDateText : openDateText;
	}

	get selectedStartDate() {
		const { activeTab, openSelectedStartDate, closedSelectedStartDate } = this.state;
		return activeTab === tabs.closed ? closedSelectedStartDate : openSelectedStartDate;
	}

	get selectedEndDate() {
		const { activeTab, openSelectedEndDate, closedSelectedEndDate } = this.state;
		return activeTab === tabs.closed ? closedSelectedEndDate : openSelectedEndDate;
	}

	get filters() {
		const { activeTab, openFilters, closedFilters } = this.state;
		return activeTab === tabs.closed ? closedFilters : openFilters;
	}

	get activeFilters() {
		const { activeTab, openActiveFilters, closedActiveFilters } = this.state;
		return activeTab === tabs.closed ? closedActiveFilters : openActiveFilters;
	}

	get isLoading() {
		const { activeTab, openIsLoading, closedIsLoading } = this.state;
		return activeTab === tabs.closed ? closedIsLoading : openIsLoading;
	}

	get breakdownColumns() {
		const { activeTab, showProcessingFee } = this.state;
		let columns;
		if (activeTab === tabs.open) {
			columns = [...withVoidColumns];
		} else {
			columns = [...noVoidColumns];
		}
		const salesIndex = columns.indexOf('Sale');

		if (showProcessingFee && activeTab !== tabs.open) {
			//Only show Processing Fee for Closed Batches
			columns = [
				...take(columns, salesIndex),
				'Total Processing Fee',
				'Net Total',
				...takeRight(columns, columns.length - salesIndex),
			];
		}
		return columns;
	}

	get batchesDetailsPrintColumns() {
		const mappedColumns = [{ key: 'xBatch', name: 'ID #' }, { key: 'xBatchDate', name: 'Date and Time' }];
		each(this.breakdownColumns, column =>
			mappedColumns.push({ key: `x${column.replace(/ /g, '')}Amount`, name: column, alignHeaderRight: true })
		);
		if (this.isFundedReport() || this.isFundedReportOpenBatches()) {
			mappedColumns.shift();
		}
		return mappedColumns;
	}

	get cardTypes() {
		return ['Visa', 'MC', 'Discover', 'Amex', 'EBT', 'EWic', 'Debit', 'Ach'];
	}

	disableExport = () => {
		this.isExportDisabled = isEmpty(get(this.breakdown, 'current.state.data'));
		this.forceUpdate();
	};

	handleGridSort = (data, sortKey) => {
		data.xReportData = data.xReportData.sort(
			({ xBatchTime: aTime, [sortKey]: a }, { xBatchTime: bTime, [sortKey]: b }) => {
				let aNumber = Number(a);
				let bNumber = Number(b);

				if (sortKey === 'xBatchDate') {
					aNumber = Number(moment(`${a} ${aTime}`, 'M/D/YYYY h:mm A'));
					bNumber = Number(moment(`${b} ${bTime}`, 'M/D/YYYY h:mm A'));
				}

				if (!isNaN(aNumber) && !isNaN(bNumber)) {
					return aNumber - bNumber;
				}
				if (typeof a === 'string' && typeof b === 'string') {
					const aLower = toLower(a);
					const bLower = toLower(b);
					if (aLower === bLower) {
						return 0;
					}
					return aLower < bLower ? -1 : 1;
				}
				if (typeof a === 'string' || typeof a === 'string') {
					return typeof a === 'string' ? -1 : 1;
				}
				if (a == b) {
					return 0;
				}
				return a < b ? -1 : 1;
			}
		);
	};

	onGridSort = (sortKey, sortDirection) => {
		const { activeTab } = this.state;
		let data = { ...this.data };
		if (data && data.xReportData && data.xReportData.length > 0) {
			if (sortDirection === 'NONE') {
				data.xReportData = orderBy(data.xReportData, [item => item.index], ['ASC']);
			} else {
				this.handleGridSort(data, sortKey);
				if (sortDirection === 'DESC') {
					reverse(data.xReportData);
				}
			}
			each(data.xReportData, (item, index) => {
				item.index = index;
			});
		}
		this.setState({ [camelCase(activeTab)]: data });
	};

	componentDidMount = async () => {
		try {
			const [userSettings] = await this.props.makePendingRequest(kvaasService.get(kvaasResources.userSettings));

			const displayOpenBatchesByDefault = get(userSettings, 'data.displayOpenBatchesByDefault', false);

			this.setState({ displayOpenBatchesByDefault }, async () => {
				try {
					await this.fetchData(displayOpenBatchesByDefault ? tabs.open : this.state.activeTab);
				} catch (e) {
					this.hideLoader(this.state.activeTab);
					this.props.handleError(e);
				}
			});
		} catch (e) {
			this.hideLoader(this.state.activeTab);
			this.props.handleError(e);
		}
	};

	getSnapshotBeforeUpdate = prevProps => {
		// Register new click by detecting a different key
		if (prevProps.location.key !== this.props.location.key) {
			return true;
		}
		return false;
	};

	componentDidUpdate = async (_, __, snapshot) => {
		if (snapshot === true) {
			await this.fetchData();
		}
	};

	fetchData = async (activeTab = this.state.activeTab) => {
		const { closedActiveFilters, openActiveFilters } = this.state;
		const isOpenBatches = activeTab === tabs.open;
		this.showLoader(activeTab);
		const emptyState = {};
		const isFirstOpenBatchesFetch = isOpenBatches && isEmpty(this.state.openBatches);
		if (isOpenBatches) {
			emptyState.openBatches = {};
			emptyState.activeTab = activeTab;
		} else {
			emptyState.closedBatches = {};
		}
		this.setState(emptyState);

		const newState = {};

		try {
			let filter;
			if (isOpenBatches) {
				filter = await this.props.makePendingRequest(compileOpenBatchFilters(openActiveFilters, apiDateFormat));
				const closedFilter = await this.props.makePendingRequest(
					compileClosedBatchFilters(openActiveFilters, apiDateFormat)
				);
				const [openBatches, closedBatches] = await this.props.makePendingRequest(
					Promise.all([transactionService.filterTransactionsAll(filter), batchService.getBatchesSummary(closedFilter)])
				);
				newState.openBatches = await this.mapOpenBatches(openBatches, closedBatches);
				const [startDate, endDate] = await this.props.makePendingRequest(
					Promise.all([apiToLocalMoment(filter.xBeginDate), apiToLocalMoment(filter.xEndDate)])
				);
				const openDate = find(openActiveFilters, { key: 'date' });
				newState.openDateText = openDate.getSelectionText(openDate.values, batchPredefinedDates);
				newState.openSelectedStartDate = startDate.format('L');
				newState.openSelectedEndDate = endDate.format('L');
				if (isFirstOpenBatchesFetch) {
					newState.firstOpenBatches = newState.openBatches;
				}
			} else {
				filter = await this.props.makePendingRequest(compileClosedBatchFilters(closedActiveFilters, apiDateFormat));
				const data = await this.props.makePendingRequest(batchService.getBatchesSummary(filter));
				const closedDate = find(closedActiveFilters, { key: 'date' });
				newState.closedDateText = closedDate.getSelectionText(closedDate.values, batchPredefinedDates);
				newState.closedBatches = this.mapClosedBatches(data);
				newState.closedSelectedStartDate = moment(filter.xBeginDate).format('L');
				newState.closedSelectedEndDate = moment(filter.xEndDate).format('L');
			}
		} catch (e) {
			if (!this.props.handleError(e)) {
				return;
			}
		}
		this.setState(newState, () => this.hideLoader(activeTab));
	};

	mapClosedBatches = data => data;

	filterByCommand = (data, command) =>
		filter(
			compact(data),
			({ xCommand, xVoid }) => toLower(split(xCommand, ':')[1]) === toLower(command) && xVoid !== '1'
		);

	sumAmounts = data => round(sumBy(data, ({ amountToUse }) => round(amountToUse, 2)) || 0, 2);

	mapAmounts = (batch, data, type) => {
		const sale = [
			...this.filterByCommand(data, 'sale'),
			...this.filterByCommand(data, 'capture'),
			...this.filterByCommand(data, 'postauth'),
		];
		const credit = [...this.filterByCommand(data, 'credit'), ...this.filterByCommand(data, 'refund')];
		const voided = filter(compact(data), ({ xVoid }) => xVoid === '1');

		batch[`x${type}SaleAmount`] = Math.abs(this.sumAmounts(sale));
		batch[`x${type}SaleCount`] = sale.length;
		batch[`x${type}CreditAmount`] = Math.abs(this.sumAmounts(credit));
		batch[`x${type}CreditCount`] = credit.length;
		batch[`x${type}VoidAmount`] = Math.abs(this.sumAmounts(voided));
		batch[`x${type}VoidCount`] = voided.length;
	};

	isBatchOpen = (batchNumber, { xAdditionalData = {}, xReportData = [] }) => {
		if (batchNumber === 'N/A') {
			return false;
		}
		if (find(xReportData, ({ xBatch }) => parseInt(xBatch) === batchNumber)) {
			return false;
		}
		if (find(xAdditionalData, batches => find(batches, xBatch => xBatch === batchNumber))) {
			return false;
		}
		return true;
	};

	mapOpenBatches = async (openBatches, closedBatches) => {
		if (!openBatches) {
			return openBatches;
		}
		const newData = {
			...openBatches,
			xReportData: [],
			xRecordsReturned: 0,
		};
		const openBatchTransactions = assign(
			{},
			...compact(
				map(
					groupBy(
						filter(openBatches.xReportData, item => item && this.isBatchOpen(item.xResponseBatch, closedBatches)),
						'xResponseBatch'
					),
					(val, key) => {
						if (!every(val, { xVoid: '1' })) {
							return { [key]: val };
						}
					}
				)
			)
		);
		newData.xReportData = map(openBatchTransactions, (transactions, batchNumber) => {
			const cardTypes = chain(transactions)
				.filter(item => item && item.xCardType)
				.each(item => {
					if (startsWith(toLower(item.xCommand), 'ebtw')) {
						item.xCardType = 'EBTW';
					}
				})
				.groupBy('xCardType')
				.value();
			const batch = {
				xBatch: batchNumber,
			};
			each(cardNames, (name, key) => {
				const cardTransactions = cardTypes[key];
				if (isEmpty(cardTransactions)) {
					return;
				}
				this.mapAmounts(batch, cardTransactions, name);
			});
			this.mapAmounts(batch, transactions, '');
			batch.xTotalAmount = round(batch.xSaleAmount - batch.xCreditAmount, 2);
			batch.xTotalCount = batch.xSaleCount + batch.xCreditCount + batch.xVoidCount;
			return batch;
		});
		return await batchService.parseBatchResult(newData, this.isFundedReportOpenBatches());
	};

	onFilterUpdate = (filters, activeFilters) => {
		const isOpenBatches = this.state.activeTab === tabs.open;
		let shouldFetch = false;
		const state = {};

		if (filters) {
			const selectedFilter = isOpenBatches ? 'openFilters' : 'closedFilters';
			state[selectedFilter] = filters;
			const activeBatchFilter = this.activeFilters;
			const filterValues = find(filters, f => f.key === 'batch').values;
			find(activeBatchFilter, filter => filter.key === 'batch').values = filterValues;
			state.activeFilters = {
				...this.activeBatchFilter,
			};
		}

		if (activeFilters) {
			state[isOpenBatches ? 'openActiveFilters' : 'closedActiveFilters'] = activeFilters;
			shouldFetch = true;
		}

		this.setState(state, () => {
			if (shouldFetch) {
				this.fetchData();
			}
		});
	};

	closeBatch = async () => {
		this.setState({
			isClosingBatch: true,
		});
		try {
			const result = await batchService.closeBatch();
			this.handleModalOpenClose(null, false);
			this.setState({
				isClosingBatch: false,
			});
			this.notification.addNotification({
				message: result.success ? 'Batch closed' : 'Unable to close batch! ' + result.xError,
				ref: result.xRefNum,
				success: result.success,
				onClose: async () => {
					await this.fetchData();
				},
			});
		} catch (e) {
			if (this.props.handleError(e)) {
				this.handleModalOpenClose(null, false);
				this.setState({
					isClosingBatch: false,
				});
			}
		}
	};
	collapsePageOptions = e => {
		const isHandleOptions = get(e, 'target.id', '') === 'pageOptions';
		if (!this.state.isPageOptionsExpanded || isHandleOptions) {
			return;
		}
		this.setState({
			isPageOptionsExpanded: false,
		});
	};

	handlePageOptions = () => {
		this.setState({
			isPageOptionsExpanded: !this.state.isPageOptionsExpanded,
		});
	};

	handleModalOpenClose = (_, isModalOpen = !this.state.isModalOpen) => {
		this.setState(
			{
				isModalOpen,
				isPageOptionsExpanded: false,
			},
			() => {
				if (this.state.isModalOpen && isEmpty(this.state.firstOpenBatches)) {
					this.fetchData(tabs.open);
				}
			}
		);
	};

	handleBreakdownOpenClose = () => {
		this.setState({
			isBreakdownOpen: !this.state.isBreakdownOpen,
		});
	};

	handlePrintError = (method, error) => {
		const { handleError } = this.props;
		if (handleError(error, { additionalInfo: { method } })) {
			this.setState({ isPrinting: false });
		}
	};

	handleAfterPrint = () => {
		this.setState({ isPrinting: false });
	};

	handleOnBeforeGetContent = () => {
		return new Promise(resolve => {
			this.setState({ isPrinting: true, isPageOptionsExpanded: false }, resolve);
		});
	};

	getColumns = data => {
		let columns = [...BatchExportColumns];
		columns.push(...batchDebitExportColumns);
		return compact(
			map(columns, column => {
				if (find(data, item => item[column.key])) {
					return column;
				}
			})
		);
	};

	prepareExport = () => {
		const data = this.data.xReportData;
		let columns = this.getColumns(data);
		let exportData = [];

		if (data) {
			for (let row of data) {
				let exportRow = {};
				for (let column of columns) {
					exportRow[column.name] = row[column.key] === undefined ? '' : row[column.key];
				}
				exportData.push(exportRow);
			}
		}
		if (exportData.length === 0) {
			exportData.push({});
		}
		return exportData;
	};

	exportToCsv = async () => {
		let options = exportOptions;
		const { selectedEndDate, selectedStartDate } = this;
		if (selectedStartDate === selectedEndDate) {
			options.filename = `batch_${this.state.activeTab === tabs.open ? 'open' : 'closed'}_export_${selectedStartDate}`;
		} else {
			options.filename = `batch_${
				this.state.activeTab === tabs.open ? 'is-active' : 'is-closed'
			}_export_${selectedStartDate}_${selectedEndDate}`;
		}

		const exporter = new ExportToCsv(options);
		exporter.generateCsv(this.prepareExport());
	};

	switchTab = activeTab => {
		this.setState(
			{
				activeTab: tabs[activeTab],
			},
			() => {
				if (isEmpty(this.data)) {
					this.fetchData();
				}
			}
		);
	};

	downloadBreakdown = () => {
		const { selectedStartDate, selectedEndDate } = this;
		const filename =
			selectedStartDate === selectedEndDate
				? `card_breakdown_${selectedStartDate}`
				: `card_breakdown_${selectedStartDate}_${selectedEndDate}`;
		if (this.breakdown.current) {
			this.breakdown.current.download(filename);
		}
	};

	renderExportButton = () => {
		return (
			<div data-tooltip="Download" className="datatooltip--down datatooltip--auto">
				<button
					className="btn btn--action btn--action--secondary"
					onClick={() => this.downloadBreakdown()}
					disabled={this.isExportDisabled}
				>
					<i className="icon icon--sml icon--download" />
				</button>
			</div>
		);
	};

	renderPrintButton = () => {
		let columns = [];
		let data = [];
		if (this.breakdown.current) {
			columns = this.breakdown.current.createColumns(this.breakdownColumns);
			data = this.breakdown.current.state.data;
		}

		return (
			<Fragment>
				<ReactToPrint
					splitColumns={true}
					trigger={() => (
						<div data-tooltip="Print" className="datatooltip--down datatooltip--auto">
							<button
								disabled={this.state.isPrinting || this.isExportDisabled}
								className="btn btn--action btn--action--secondary"
							>
								<i className="icon icon--sml icon--print" />
							</button>
						</div>
					)}
					content={() => this.breakdownPrint}
					onPrintError={this.handlePrintError}
					onBeforeGetContent={this.handleOnBeforeGetContent}
					onAfterPrint={this.handleAfterPrint}
				/>
				<div className="display--n">
					<PrintGridData
						ref={el => (this.breakdownPrint = el)}
						data={data}
						columns={columns}
						title={`Batch breakdown by card (${this.selectedStartDate} - ${this.selectedEndDate})`}
						type="batchBreakdown"
					/>
				</div>
			</Fragment>
		);
	};

	getCount = (row, cardType, transactionType) => row[`x${cardType}${transactionType}Count`];

	getCounts = (row, cardType, ...transactionTypes) =>
		some(transactionTypes, transactionType => transactionType && this.getCount(row, cardType, transactionType));
	cardsToRenderAsExpandedCells = row => {
		return filter(this.cardTypes, cardType => this.getCounts(row, cardType, 'Sale', 'Credit', 'Void'));
	};

	renderExpandedCells = (row, rowIndex, column) => {
		const { expandedRows } = this.state;
		const isExpanded = expandedRows[row.xBatch || rowIndex];
		const cardsToRender = isExpanded && this.cardsToRenderAsExpandedCells(row);
		const currency = row.currency;
		switch (column.key) {
			case 'xBatchDate':
				return map(cardsToRender, card => (
					<div key={card} className="type--base">
						{card}
					</div>
				));
			case 'xTotalAmount':
				return map(cardsToRender, card => (
					<div key={card} className="type--sml--alt type--right type--base">
						{this.renderAmountAndCount(row[`x${card}TotalAmount`], row[`x${card}TotalCount`], currency)}
					</div>
				));
			case 'xSaleAmount':
				return map(cardsToRender, card => (
					<div key={card} className="type--sml--alt type--right type--base">
						{this.renderAmountAndCount(row[`x${card}SaleAmount`], row[`x${card}SaleCount`], currency)}
					</div>
				));

			case 'xCreditAmount':
				return map(cardsToRender, card => (
					<div key={card} className="type--sml--alt type--right type--base">
						{this.renderAmountAndCount(row[`x${card}CreditAmount`], row[`x${card}CreditCount`], currency)}
					</div>
				));
			case 'xVoidAmount':
				return map(cardsToRender, card => (
					<div key={card} className="type--sml--alt type--right type--base">
						{this.renderAmountAndCount(row[`x${card}VoidAmount`], row[`x${card}VoidCount`], currency)}
					</div>
				));
		}
	};

	renderAmountAndCount = (amount, count, currency) => {
		return (
			<Fragment>
				<NumberFormat
					prefix={CurrencyMap.resolveCurrency(currency)}
					value={amount || 0}
					displayType="text"
					thousandSeparator={true}
					decimalScale={2}
					fixedDecimalScale={true}
				/>
				<span className="u-display--ib spc--left--tny">{`(${count || 0})`}</span>
			</Fragment>
		);
	};

	render() {
		const {
			isBreakdownOpen,
			isPageOptionsExpanded,
			isModalOpen,
			activeTab,
			firstOpenBatches,
			openIsLoading,
			isClosingBatch,
			isPrinting,
			showProcessingFee,
		} = this.state;
		const { data, filters, activeFilters, isLoading, selectedDateText } = this;
		const showVoid = activeTab === tabs.open;

		return (
			<Fragment>
				<header className="header">
					<div className="header__menu">
						<UserAccountPanel />
						<div className="header__menu__action header__btn__holder align--h--right">
							<button
								onClick={this.handlePageOptions}
								className="btn btn--action btn--action--secondary"
								id="pageOptions"
							>
								<i className="icon icon--sml icon--more" />
							</button>
							{isPageOptionsExpanded ? (
								<OutsideClick action={this.collapsePageOptions}>
									<div className="buttondropdown">
										<ul className="buttondropdown__list">
											<li className="buttondropdown__item buttondropdown__item--withlink">
												<a
													href="javascript:void(0)"
													onClick={this.handleModalOpenClose}
													className="buttondropdown__link"
												>
													Close current batch
												</a>
											</li>
										</ul>
									</div>
								</OutsideClick>
							) : null}
							{!isEmpty(data.xReportData) && (
								<div className="display--n">
									<PrintGridData
										ref={el => (this.print = el)}
										data={data.xReportData}
										columns={this.batchesDetailsPrintColumns}
										title="Batches Details"
										type="batchDetails"
										printProcessingFee={showProcessingFee && !showVoid}
										printNetSale={showProcessingFee && !showVoid}
										renderAdditionalDataPerCell={this.renderExpandedCells}
									/>
								</div>
							)}
						</div>
					</div>
				</header>
				<div className="l--content">
					{isLoading ? <div className="loader--progress"></div> : null}
					<BatchCards
						filters={filters}
						activeFilters={activeFilters}
						onFilterUpdate={this.onFilterUpdate}
						data={data}
						onGridSort={this.onGridSort}
						selectedDateText={selectedDateText}
						handleBreakdownOpen={this.handleBreakdownOpenClose}
						transactionTypes={this.breakdownColumns}
						showVoid={showVoid}
						showProcessingFee={showProcessingFee && !showVoid}
						activeTab={activeTab}
						isFundedReport={this.isFundedReport()}
						isFundedReportOpenBatches={this.isFundedReportOpenBatches()}
						updateExpandedRows={this.updateExpandedRows}
						switchTab={this.switchTab}
						isLoading={isLoading}
						exportButtons={
							<Fragment>
								<ReactToPrint
									splitColumns={true}
									trigger={() => (
										<button
											type="button"
											disabled={isEmpty(data.xReportData) || isPrinting}
											className="btn btn--med btn--link btn--link--tertiary"
										>
											<i className="icon icon--sml icon--print--light"></i>
											<span>Print</span>
										</button>
									)}
									content={() => this.print}
									onPrintError={this.handlePrintError}
									onBeforeGetContent={this.handleOnBeforeGetContent}
									onAfterPrint={this.handleAfterPrint}
								/>
								<button
									type="button"
									disabled={isEmpty(data.xReportData)}
									className="btn btn--med btn--link btn--link--tertiary"
									onClick={this.exportToCsv}
								>
									<i className="icon icon--sml icon--download--light"></i>
									<span>Export</span>
								</button>
							</Fragment>
						}
					/>
					<Modal isOpen={isModalOpen} onClose={this.handleModalOpenClose} className="modal__content modal--lrg">
						<div className="modal__header">
							<div className="modal__header__title">Important</div>
						</div>
						<div className="modal__body">
							<div className="notes notes--warning spc--bottom--lrg">
								<i className="icon"></i>
								<p className="type--p2">Manual closing should be used in rare instances only</p>
							</div>
							<p className="spc--bottom--lrg">
								Your gateway is set to auto close. This means it will batch out by itself and there is no need to close
								the batch manually. Batching manually will not expedite funding and may cause issues with your internal
								settings. The manual close batch feature is designed to be used in rare instances when instructed to do
								so by our tech support team.
							</p>
							{openIsLoading || isClosingBatch ? (
								<div className="loader__holder">
									<div className="loader__spinner"></div>
								</div>
							) : isEmpty(firstOpenBatches.xReportData) ? (
								<div className="emptystate">
									<div className="emptystate__title">There are currently no open batches</div>
								</div>
							) : (
								<BreakdownGrid
									totals={firstOpenBatches.totals}
									displayTotal={true}
									showVoid={true}
									columns={withVoidColumns}
									dateRange={`Date range: (${this.selectedStartDate} - ${this.selectedEndDate})`}
									disableExport={this.disableExport}
									emptyMessage={'No Batches'}
								/>
							)}
						</div>
						<div className="modal__footer">
							{!openIsLoading && !isClosingBatch && !isEmpty(firstOpenBatches.xReportData) && (
								<div className="flex--primary flex--nowrap flex--gap--tny">
									<span className="type--color--text--light">
										Closing batch{firstOpenBatches.xReportData.length > 1 && 'es'}:
									</span>
									<span className="type--wgt--bold">
										{join(map(firstOpenBatches.xReportData, ({ xBatch }) => `#${xBatch}`), ', ')}
									</span>
								</div>
							)}
							<button
								type="button"
								disabled={openIsLoading || isClosingBatch || isEmpty(firstOpenBatches.xReportData)}
								className="btn btn--med btn--primary"
								onClick={this.closeBatch}
							>
								Confirm
							</button>
						</div>
					</Modal>
					<Modal isOpen={isBreakdownOpen} onClose={this.handleBreakdownOpenClose} className="modal__content modal--lrg">
						{isBreakdownOpen ? (
							<Fragment>
								<div className="modal__header">
									<div>
										<h4 className="modal__header__title spc--bottom--tny">Breakdown by Card</h4>
										<p className="type--p3 type--color--text--light">{selectedDateText}</p>
									</div>
									<div className="modal__header__actions">
										{this.renderExportButton()}
										{this.renderPrintButton()}
									</div>
								</div>
								<div className="modal__body">
									<BreakdownGrid
										ref={this.breakdown}
										columns={this.breakdownColumns}
										totals={data.totals}
										showVoid={activeTab === tabs.open}
										dateRange={`Date range: (${this.selectedStartDate} - ${this.selectedEndDate})`}
										disableExport={this.disableExport}
										emptyMessage={'No Batches'}
									/>
								</div>
								<div className="modal__footer"></div>
							</Fragment>
						) : (
							<div></div>
						)}
					</Modal>
					<Notification ref={el => (this.notification = el)} />
				</div>
			</Fragment>
		);
	}
}

BatchIndex.propTypes = {
	makePendingRequest: PropTypes.func,
	handleError: PropTypes.func,
	location: PropTypes.object,
};

export default withError(withCancelable(BatchIndex));
