import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

const AccountMenu = ({
	search,
	showCompanyDropDown,
	availableCompanies,
	toggleCompanyDropDown,
	searchCompany,
	renderCompanySubmenu,
}) => {
	const companyButtonClass = showCompanyDropDown ? 'open' : 'closed';
	const companyDropDownActiveClass = showCompanyDropDown ? 'open' : 'closed';
	const numberOfCompanies = get(availableCompanies, 'length') || 0;

	return (
		<Fragment>
			<button onClick={toggleCompanyDropDown} className={`btn account-list__nav-button ${companyButtonClass}`}>
				{showCompanyDropDown ? <i className="icon icon--tny icon--chevron--left--primary"></i> : null}
				{showCompanyDropDown ? 'Back' : 'Explore All'}
			</button>
			{numberOfCompanies > 1 && showCompanyDropDown ? (
				<div className={` ${companyDropDownActiveClass}`}>
					<div className="account-list__search">
						<input onChange={searchCompany} value={search} type="text" placeholder="Search..." />
					</div>
					<ul className="account-list">{renderCompanySubmenu()}</ul>
				</div>
			) : null}
		</Fragment>
	);
};
AccountMenu.propTypes = {
	search: PropTypes.string,
	showCompanyDropDown: PropTypes.bool.isRequired,
	availableCompanies: PropTypes.array.isRequired,
	toggleCompanyDropDown: PropTypes.func.isRequired,
	searchCompany: PropTypes.func.isRequired,
	renderCompanySubmenu: PropTypes.func.isRequired,
};
export default AccountMenu;
