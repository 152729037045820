import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

const ModalComponent = ({ children, onClose, hideCloseButton, isLoading, displayProgress, ...props }) => (
	<ReactModal onRequestClose={onClose} {...props}>
		<div className="modal__content__wrapper">{children}</div>
		{isLoading && displayProgress && <div className="loader loader--progress"></div>}
		{!(hideCloseButton || isLoading) && (
			<button tabIndex="-1" className="btn btn--med btn--tertiary modal__close" onClick={onClose}>
				Close
			</button>
		)}
	</ReactModal>
);

ModalComponent.defaultProps = {
	displayProgress: false,
	shouldCloseOnOverlayClick: true,
	overlayClassName: 'modal__overlay',
	className: 'modal__content',
};

ModalComponent.propTypes = {
	children: PropTypes.element.isRequired,
	onClose: PropTypes.func.isRequired,
	isOpen: PropTypes.bool,
	isLoading: PropTypes.bool,
	isProcessing: PropTypes.bool,
	overlayClassName: PropTypes.string,
	className: PropTypes.string,
	shouldCloseOnOverlayClick: PropTypes.bool,
	hideCloseButton: PropTypes.bool,
	displayProgress: PropTypes.bool,
};

export default ModalComponent;
