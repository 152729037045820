import React, { Component, Fragment } from 'react';
import { object, func, bool } from 'prop-types';
import { filter, find, findIndex, includes, map, toLower } from 'lodash-es';

import PaymentSiteFormSetting from '../PaymentSiteFormSetting';
import PaymentSiteGroupedSettings from '../components/PaymentSiteGroupedSettings';

class PaymentSiteGeneralSettingsTab extends Component {
	get expanded() {
		return {
			theme: true,
		};
	}

	renderTabs = () => {
		const {
			state: { formSettings, entireTabA, entireTabB, entireTabC, availableFormSettings, isKioskTheme },
		} = this.props;
		if (isKioskTheme) return null;
		let allDisplayed = entireTabA && entireTabB && entireTabC;
		const anyDisplayed = entireTabA || entireTabB || entireTabC;
		const accountTabs = find(availableFormSettings, setting => toLower(setting.key) === 'additionalaccounttabs');
		const tabs = filter(formSettings, ({ key }) => includes(toLower(key), 'tab'));
		return (
			<div className="card spc--bottom--lrg">
				<div className="card__header">
					<div className="flex--primary flex--gap--sml">
						<div className="spc--right--tny">
							<input
								onChange={accountTabs.onChange}
								type="checkbox"
								className="input--check"
								id={accountTabs.key}
								name={accountTabs.key}
								value={anyDisplayed}
								checked={anyDisplayed}
							/>
							<label htmlFor={accountTabs.key}></label>
						</div>
						<h5>Account Tabs</h5>
					</div>
				</div>
				<div className="card__body">
					{anyDisplayed &&
						map(
							tabs,
							({ key, label, tooltip, fields, expanded, component, noCheckbox, cardTypesAccepted, acceptAch }) => {
								return (
									<PaymentSiteFormSetting
										key={key}
										label={label}
										tooltip={tooltip}
										fields={fields}
										expanded={expanded}
										formComponent={component}
										sectionIndex={findIndex(formSettings, setting => setting.key === key)}
										displayDelete={true}
										allDisplayed={allDisplayed}
										section={'generalSettings'}
										formKey={key}
										noCheckbox={noCheckbox}
										cardTypesAccepted={cardTypesAccepted}
										acceptAch={acceptAch}
										formSettings={formSettings}
										isTab={true}
										{...this.props}
									/>
								);
							}
						)}
					{!allDisplayed && (
						<div>
							<button type="button" className="btn btn--sml btn--link" onClick={this.props.addTab}>
								Add Tab
							</button>
						</div>
					)}
				</div>
			</div>
		);
	};

	render() {
		const {
			state: { formSettings, entireAcceptACH, entireCardTypesAccepted, isKioskTheme },
		} = this.props;
		return (
			<Fragment>
				<PaymentSiteGroupedSettings {...this.props} />
				{map(
					formSettings,
					(
						{
							key,
							label,
							tooltip,
							fields,
							expanded,
							component,
							internalSetting,
							groupedSetting,
							noCheckbox,
							cardTypesAccepted,
							acceptAch,
						},
						sectionIndex
					) => {
						if ((key === 'redirectOptions' || key === 'enableDigitalWallet') && isKioskTheme) return null;
						if (internalSetting || groupedSetting || key === 'paymentSiteLogo') return;
						if (includes(toLower(key), 'tab')) return null;
						let displayDelete = key === 'cardTypesAccepted' ? entireAcceptACH : true;
						if (key === 'acceptACH' && !entireCardTypesAccepted) {
							displayDelete = false;
						}
						return (
							<PaymentSiteFormSetting
								key={key}
								label={label}
								tooltip={tooltip}
								fields={fields}
								expanded={expanded}
								formComponent={component}
								sectionIndex={sectionIndex}
								displayDelete={displayDelete}
								section={'generalSettings'}
								formKey={key}
								noCheckbox={noCheckbox}
								cardTypesAccepted={cardTypesAccepted}
								acceptAch={acceptAch}
								formSettings={formSettings}
								{...this.props}
							/>
						);
					}
				)}
				{this.renderTabs()}
			</Fragment>
		);
	}
}

PaymentSiteGeneralSettingsTab.propTypes = {
	handleAdditionalSettingsDataChange: func.isRequired,
	handleAdditionalSettingsClear: func.isRequired,
	toggleRemoveSectionPopup: func.isRequired,
	removeFormSetting: func.isRequired,
	renderComponent: func.isRequired,
	toggleExpanded: func.isRequired,
	hasThemeAccess: bool.isRequired,
	addTab: func.isRequired,
	state: object.isRequired,
};

export default PaymentSiteGeneralSettingsTab;
