import React, { Component } from 'react';
import NumberFormat from 'react-number-format';

import { CurrencyMap } from 'common/utilities';

class TestDeepLink extends Component {
	constructor() {
		super();

		this.state = {
			amount: '',
			dba: '',
			xCommand: 'cc:sale',
			xRedirectURL: 'https://www.cardknoxdeeplinktest.com/results',
			xVP3300Serial: '',
			xUserName: '',

			xInvoice: '',
			enableInvoice: false,

			xDescription: '',
			enableDescription: false,

			xPoNum: '',
			enablePoNum: false,

			enableSwipeTap: false,
			swipeTapActive: false,
			enableUserName: false,

			xBillFirstName: '',
			enableBillFirstName: false,

			xBillLastName: '',
			enableBillLastName: false,

			enableVP3300: false,
			enableKeyedEntry: false,
			swipeRadio: 'swipeRadio1',
			keyRadio: 'keyRadio1',
		};
	}

	onxCommandChange = e => {
		this.setState({ ['xCommand']: e.target.value });
	};

	handleChange = ({ target: { name, value, type, checked } }) => {
		if (type === 'checkbox') {
			value = checked;
		}
		this.setState({ [name]: value });
	};

	render() {
		const {
			amount,
			dba,
			xCommand,
			xRedirectURL,
			xVP3300Serial,
			xUserName,
			xInvoice,
			xDescription,
			xPoNum,
			keyRadio,
			swipeRadio,
			enableVP3300,
			enableUserName,
			enableInvoice,
			enableDescription,
			enablePoNum,
			enableKeyedEntry,
			swipeTapActive,
		} = this.state;

		const { xBillFirstName, xBillLastName, enableBillFirstName, enableBillLastName } = this.state;

		const appendSwipe = swipeTapActive
			? swipeRadio === 'swipeRadio1'
				? '&EnableDeviceInsertSwipeTap=true'
				: '&EnableDeviceInsertSwipeTap=false'
			: null;

		const appendKeyEntry = enableKeyedEntry
			? keyRadio === 'keyRadio1'
				? '&EnableKeyedEntry=true'
				: '&EnableKeyedEntry=false'
			: null;

		const appendVP3300 = enableVP3300 ? true : null;
		const appendUserName = enableUserName ? true : null;
		const appendInvoice = enableInvoice ? true : null;
		const appendDescription = enableDescription ? true : null;
		const appendPoNum = enablePoNum ? true : null;

		const appendBillFirstName = enableBillFirstName ? true : null;
		const appendBillLastName = enableBillLastName ? true : null;

		let url = `dck://app.cardknox.com/transaction?xAmount=${amount}&xCommand=${xCommand}&xRedirectURL=${xRedirectURL}&xKey=${dba}`;

		if (appendVP3300) {
			url = url.concat(`&xVP3300=${xVP3300Serial}`);
		}

		if (appendUserName) {
			url = url.concat(`&xUserName=${xUserName}`);
		}

		if (appendInvoice) {
			url = url.concat(`&xInvoice=${encodeURIComponent(xInvoice)}`);
		}

		if (appendPoNum) {
			url = url.concat(`&xPoNum=${encodeURIComponent(xPoNum)}`);
		}

		if (appendDescription) {
			url = url.concat(`&xDescription=${encodeURIComponent(xDescription)}`);
		}

		if (appendBillFirstName) {
			url = url.concat(`&xBillFirstName=${encodeURIComponent(xBillFirstName)}`);
		}

		if (appendBillLastName) {
			url = url.concat(`&xBillLastName=${encodeURIComponent(xBillLastName)}`);
		}

		if (appendSwipe) {
			url = url.concat(appendSwipe);
		}
		if (appendKeyEntry) {
			url = url.concat(appendKeyEntry);
		}

		return (
			<div className="l--content">
				<div className="row spc--top--sml spc--bottom--lrg">
					<div className="col col-sml-12 col-med-6 col-xlrg-3 spc--bottom--med">
						<label htmlFor="amount" className="label">
							Amount
						</label>
						<NumberFormat
							className="input input--med"
							prefix={CurrencyMap.resolveCurrency()}
							value={amount}
							decimalScale={2}
							thousandSeparator={true}
							onValueChange={({ floatValue: value }) => this.handleChange({ target: { name: 'amount', value } })}
							placeholder="Amount"
							id="amount"
							inputMode="numeric"
						/>
					</div>
					<div className="col col-sml-12 col-med-6 col-xlrg-3 spc--bottom--med">
						<label htmlFor="dba" className="label">
							DBA
						</label>
						<input
							type="text"
							id="dba"
							name="dba"
							className="input input--med"
							placeholder="DBA"
							value={dba}
							onChange={this.handleChange}
						/>
					</div>
					<div className="col col-sml-12 col-med-6 col-xlrg-3 spc--bottom--med">
						<label className="label">Command</label>
						<select
							name="transactionType"
							className="input input--med input--select"
							value={xCommand}
							onChange={this.onxCommandChange}
							tabIndex="-1"
						>
							<option value="cc:sale">cc:sale</option>
							<option value="cc:credit">cc:credit</option>
							<option value="cc:authonly">cc:authonly</option>
							<option value="cc:save">cc:save</option>

							<option value="check:sale">check:sale</option>
							<option value="check:save">check:save</option>
							<option value="check:credit">check:credit</option>

							<option value="gift:balance">gift:balance</option>
							<option value="gift:issue">gift:issue</option>
							<option value="gift:redeem">gift:redeem</option>

							<option value="ebtfs:sale">ebtfs:sale</option>
							<option value="ebtfs:credit">ebtfs:credit</option>
							<option value="ebtfs:balance">ebtfs:balance</option>
							<option value="ebtfs:voucher">ebtfs:voucher</option>

							<option value="ebtcb:sale">ebtcb:sale</option>
							<option value="ebtcb:cash">ebtcb:cash</option>
							<option value="ebtcb:balance">ebtcb:balance</option>

							<option value="ebtw:sale">ebtw:sale</option>
							<option value="ebtw:balance">ebtw:balance</option>
						</select>
					</div>
					<div className="col col-sml-12 col-med-6 col-xlrg-3 spc--bottom--med">
						<label htmlFor="xRedirectURL" className="label">
							Redirect URL
						</label>
						<input
							type="text"
							id="xRedirectURL"
							name="xRedirectURL"
							className="input input--med"
							placeholder="Redirect URL"
							value={xRedirectURL}
							onChange={this.handleChange}
						/>
					</div>
					<div className="col col-sml-12 col-med-6 col-xlrg-3 spc--bottom--med">
						<div className="spc--bottom--tny">
							<input
								type="checkbox"
								name="swipeTapActive"
								value={this.state.swipeTapActive}
								id={'swipeTapActive'}
								className="input input--check"
								checked={this.state.swipeTapActive}
								onChange={this.handleChange}
							/>
							<label htmlFor={'swipeTapActive'} className="type--wgt--medium type--none">
								EnableDeviceInsertSwipeTap
							</label>
						</div>
						<div>
							<div className="display--ib spc--right--sml">
								<input
									type="radio"
									className="input input--radio"
									id="swipeRadio1"
									name="swipeRadio"
									value={'swipeRadio1'}
									checked={swipeRadio === 'swipeRadio1'}
									onChange={this.handleChange}
								/>
								<label htmlFor="swipeRadio1">True</label>
							</div>
							<div className="display--ib">
								<input
									type="radio"
									className="input input--radio"
									id="swipeRadio2"
									name="swipeRadio"
									value={'swipeRadio2'}
									checked={swipeRadio === 'swipeRadio2'}
									onChange={this.handleChange}
								/>
								<label htmlFor="swipeRadio2">False</label>
							</div>
						</div>
					</div>
					<div className="col col-sml-12 col-med-6 col-xlrg-3 spc--bottom--med">
						<div className="spc--bottom--tny">
							<input
								type="checkbox"
								name="enableKeyedEntry"
								value={this.state.enableKeyedEntry}
								id={'enableKeyedEntry'}
								className="input input--check"
								checked={this.state.enableKeyedEntry}
								onChange={this.handleChange}
							/>
							<label htmlFor={'enableKeyedEntry'} className="type--wgt--medium type--none">
								EnableKeyedEntry
							</label>
						</div>
						<div>
							<div className="display--ib spc--right--sml">
								<input
									type="radio"
									className="input input--radio"
									id="keyRadio1"
									name="keyRadio"
									value={'keyRadio1'}
									checked={keyRadio === 'keyRadio1'}
									onChange={this.handleChange}
								/>
								<label htmlFor="keyRadio1">True</label>
							</div>
							<div className="display--ib">
								<input
									type="radio"
									className="input input--radio"
									id="keyRadio2"
									name="keyRadio"
									value={'keyRadio2'}
									checked={keyRadio === 'keyRadio2'}
									onChange={this.handleChange}
								/>
								<label htmlFor="keyRadio2">False</label>
							</div>
						</div>
					</div>

					<div className="col col-sml-12 col-med-6 col-xlrg-3 spc--bottom--med">
						<div className="spc--bottom--tny">
							<input
								type="checkbox"
								name="enableVP3300"
								value={this.state.enableVP3300}
								id={'enableVP3300'}
								className="input input--check"
								checked={this.state.enableVP3300}
								onChange={this.handleChange}
							/>
							<label htmlFor={'enableVP3300'} className="type--wgt--medium type--none">
								VP3300 Serial Number
							</label>
						</div>
						<input
							type="number"
							maxLength="10"
							id="xVP3300Serial"
							name="xVP3300Serial"
							className="input input--med"
							placeholder="Serial number"
							value={xVP3300Serial}
							onChange={this.handleChange}
						/>
					</div>

					<div className="col col-sml-12 col-med-6 col-xlrg-3 spc--bottom--med">
						<div className="spc--bottom--tny">
							<input
								type="checkbox"
								name="enableUserName"
								value={this.state.enableUserName}
								id={'enableUserName'}
								className="input input--check"
								checked={this.state.enableUserName}
								onChange={this.handleChange}
							/>
							<label htmlFor={'enableUserName'} className="type--wgt--medium type--none">
								User name
							</label>
						</div>
						<input
							id="xUserName"
							name="xUserName"
							className="input input--med"
							placeholder="xUserName"
							value={xUserName}
							onChange={this.handleChange}
						/>
					</div>

					<div className="col col-sml-12 spc--top--med">
						<a className="btn btn--lrg btn--primary" href={url} rel="noopener noreferrer" target="_blank">
							Open Cardknox Application
						</a>
					</div>
				</div>
				<div className="row spc--top--sml">
					<div className="col col-sml-12 spc--bottom--med">
						<div className="spc--bottom--tny">
							<input
								type="checkbox"
								name="enableInvoice"
								value={this.state.enableInvoice}
								id={'enableInvoice'}
								className="input input--check"
								checked={this.state.enableInvoice}
								onChange={this.handleChange}
							/>
							<label htmlFor={'enableInvoice'} className="type--wgt--medium type--none">
								Invoice
							</label>
						</div>
						<input
							type="text"
							id="xInvoice"
							name="xInvoice"
							className="input input--med"
							placeholder="Invoice"
							value={xInvoice}
							onChange={this.handleChange}
						/>
					</div>

					<div className="col col-sml-12 spc--bottom--med">
						<div className="spc--bottom--tny">
							<input
								type="checkbox"
								name="enableDescription"
								value={this.state.enableDescription}
								id={'enableDescription'}
								className="input input--check"
								checked={this.state.enableDescription}
								onChange={this.handleChange}
							/>
							<label htmlFor={'enableDescription'} className="type--wgt--medium type--none">
								Description
							</label>
						</div>
						<input
							type="text"
							id="xDescription"
							name="xDescription"
							className="input input--med"
							placeholder="Description"
							value={xDescription}
							onChange={this.handleChange}
						/>
					</div>

					<div className="col col-sml-12 spc--bottom--med">
						<div className="spc--bottom--tny">
							<input
								type="checkbox"
								name="enablePoNum"
								value={this.state.enablePoNum}
								id={'enablePoNum'}
								className="input input--check"
								checked={this.state.enablePoNum}
								onChange={this.handleChange}
							/>
							<label htmlFor={'enablePoNum'} className="type--wgt--medium type--none">
								PO Number
							</label>
						</div>
						<input
							type="text"
							id="xPoNum"
							name="xPoNum"
							className="input input--med"
							placeholder="PO Number"
							value={xPoNum}
							onChange={this.handleChange}
						/>
					</div>

					<div className="col col-sml-12 spc--bottom--med">
						<div className="spc--bottom--tny">
							<input
								type="checkbox"
								name="enableBillFirstName"
								value={this.state.enableBillFirstName}
								id={'enableBillFirstName'}
								className="input input--check"
								checked={this.state.enableBillFirstName}
								onChange={this.handleChange}
							/>
							<label htmlFor={'enableBillFirstName'} className="type--wgt--medium type--none">
								Bill First Name
							</label>
						</div>
						<input
							type="text"
							id="xBillFirstName"
							name="xBillFirstName"
							className="input input--med"
							placeholder="Bill First Name"
							value={xBillFirstName}
							onChange={this.handleChange}
						/>
					</div>

					<div className="col col-sml-12 spc--bottom--med">
						<div className="spc--bottom--tny">
							<input
								type="checkbox"
								name="enableBillLastName"
								value={this.state.enableBillLastName}
								id={'enableBillLastName'}
								className="input input--check"
								checked={this.state.enableBillLastName}
								onChange={this.handleChange}
							/>
							<label htmlFor={'enableBillLastName'} className="type--wgt--medium type--none">
								Bill Last Name
							</label>
						</div>
						<input
							type="text"
							id="xBillLastName"
							name="xBillLastName"
							className="input input--med"
							placeholder="Bill Last Name"
							value={xBillLastName}
							onChange={this.handleChange}
						/>
					</div>
				</div>
			</div>
		);
	}
}

export default TestDeepLink;
