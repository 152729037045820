import React from 'react';
import PropTypes from 'prop-types';
const ToggleCheckbox = ({ id, label, checked, onChange, disabled }) => (
	<div>
		<input
			type="checkbox"
			id={id}
			name={id}
			className="input--toggle"
			checked={checked}
			onChange={e => onChange(id, e.target.checked)}
			disabled={disabled}
		/>
		<label htmlFor={id}>{label}</label>
	</div>
);

ToggleCheckbox.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	checked: PropTypes.bool.isRequired,
	onChange: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
};

export default ToggleCheckbox;
