/* eslint-disable */
const hourOptionalSecondsRegExp = /^(([0-1])?[0-9]|2[0-3]):[0-5][0-9](:([0-5][0-9]))?$/;
const hourRegExp = /^(([0-1])?[0-9]|2[0-3]):[0-5][0-9]:([0-5][0-9])$/;

export const getHours = date => {
	if (date instanceof Date) {
		return date.getHours();
	}

	if (typeof date === 'string' && hourOptionalSecondsRegExp.test(date)) {
		const [hourString] = date.split(':');
		return parseInt(hourString, 10);
	}

	throw new Error(`Failed to get hours from date: ${date}.`);
};

export const getMinutes = date => {
	if (date instanceof Date) {
		return date.getMinutes();
	}

	if (typeof date === 'string' && hourOptionalSecondsRegExp.test(date)) {
		const [, minuteString] = date.split(':');
		return parseInt(minuteString, 10);
	}

	throw new Error(`Failed to get minutes from date: ${date}.`);
};

export const getSeconds = date => {
	if (date instanceof Date) {
		return date.getSeconds();
	}

	if (typeof date === 'string') {
		if (hourRegExp.test(date)) {
			const [, , secondString] = date.split(':');
			return parseInt(secondString, 10);
		}

		if (hourOptionalSecondsRegExp.test(date)) {
			return 0;
		}
	}

	throw new Error(`Failed to get seconds from date: ${date}.`);
};

export const getHoursMinutes = date => {
	if (!date) {
		return date;
	}

	const hours = `0${getHours(date)}`.slice(-2);
	const minutes = `0${getMinutes(date)}`.slice(-2);

	return `${hours}:${minutes}`;
};

export const getHoursMinutesSeconds = date => {
	if (!date) {
		return date;
	}

	const hours = `0${getHours(date)}`.slice(-2);
	const minutes = `0${getMinutes(date)}`.slice(-2);
	const seconds = `0${getSeconds(date)}`.slice(-2);

	return `${hours}:${minutes}:${seconds}`;
};

export const convert12to24 = (hour12, amPm) => {
	let hour24 = parseInt(hour12, 10);

	if (amPm === 'am' && hour24 === 12) {
		hour24 = 0;
	} else if (amPm === 'pm' && hour24 < 12) {
		hour24 += 12;
	}

	return hour24;
};

export const convert24to12 = hour24 => {
	const hour12 = hour24 % 12 || 12;

	return [hour12, hour24 < 12 ? 'am' : 'pm'];
};
