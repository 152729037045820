import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { fieldPropType, isValid } from '../../Common/fields';

class TransactionDetailsFields extends Component {
	onChange = e => {
		this.props.onChange({
			key: e.target.name,
			value: e.target.value,
		});
	};

	render() {
		const {
			data,
			invalidClassName,
			requiredFields,
			customDisplayLabels,
			transactionHiddenFields,
			hideOrderId,
		} = this.props;
		const required = (
			<span className="form__group__required" data-tooltip="Required">
				*
			</span>
		);

		return (
			<div className="form__body">
				<div className="f-row">
					{transactionHiddenFields.invoice ? null : (
						<div className="f-col f-col-sml-12 f-col-med-6 form__group">
							<div className="form__group__header">
								<label className="form__group__label">
									{customDisplayLabels.invoice || 'Invoice'} {requiredFields.invoice ? required : null}
								</label>
							</div>
							<input
								type="text"
								name="invoice"
								inputMode="numeric"
								className={`input input--med ${(!isValid(data.invoice) && invalidClassName) || ''}`}
								placeholder={customDisplayLabels.invoice || 'Invoice'}
								value={data.invoice.value}
								onChange={this.onChange}
							/>
						</div>
					)}
					{transactionHiddenFields.poNumber ? null : (
						<div className="f-col f-col-sml-12 f-col-med-6 form__group">
							<div className="form__group__header">
								<label className="form__group__label">
									{customDisplayLabels.poNumber || 'PO Number'} {requiredFields.poNumber ? required : null}
								</label>
							</div>
							<input
								type="text"
								name="poNumber"
								inputMode="numeric"
								className={`input input--med ${(!isValid(data.poNumber) && invalidClassName) || ''}`}
								placeholder={customDisplayLabels.poNumber || 'PO Number'}
								value={data.poNumber.value}
								onChange={this.onChange}
							/>
						</div>
					)}
				</div>
				{transactionHiddenFields.description ? null : (
					<div className="form__group">
						<div className="form__group__header">
							<label className="form__group__label">
								{customDisplayLabels.description || 'Description'} {requiredFields.description ? required : null}
							</label>
						</div>
						<textarea
							rows="3"
							cols="10"
							name="description"
							className={`input input--textarea ${(!isValid(data.description) && invalidClassName) || ''}`}
							placeholder={customDisplayLabels.description || 'Description'}
							value={data.description.value}
							onChange={this.onChange}
						/>
					</div>
				)}
				{transactionHiddenFields.orderId || hideOrderId ? null : (
					<div className="form__group">
						<div className="form__group__header">
							<label className="form__group__label">
								{customDisplayLabels.orderId || 'Order ID'} {requiredFields.orderId ? required : null}
							</label>
						</div>
						<input
							type="text"
							name="orderId"
							inputMode="numeric"
							className={`input input--med ${(!isValid(data.orderId) && invalidClassName) || ''}`}
							placeholder={customDisplayLabels.orderId || 'Order ID'}
							value={data.orderId.value}
							onChange={this.onChange}
						/>
					</div>
				)}
			</div>
		);
	}
}

TransactionDetailsFields.propTypes = {
	data: PropTypes.shape({
		invoice: fieldPropType(PropTypes.string).isRequired,
		poNumber: fieldPropType(PropTypes.string).isRequired,
		description: fieldPropType(PropTypes.string).isRequired,
		orderId: fieldPropType(PropTypes.string).isRequired,
	}).isRequired,
	onChange: PropTypes.func.isRequired,
	invalidClassName: PropTypes.string,
	requiredFields: PropTypes.object,
	customDisplayLabels: PropTypes.object,
	transactionHiddenFields: PropTypes.object,
	hideOrderId: PropTypes.bool,
};

export default TransactionDetailsFields;
