import React from 'react';
import PropTypes from 'prop-types';
import Select, { createFilter } from 'react-select';

import MenuList from './MenuList';

const filter = createFilter({ ignoreAccents: false });

const getOptionValue = option => {
	return option.value || option.label;
};

const select = ({ components = {}, ...props }) => (
	<Select
		menuPlacement="auto"
		className="react-select-container"
		classNamePrefix="react-select"
		filterOption={filter}
		getOptionValue={getOptionValue}
		components={{
			MenuList,
			...components,
		}}
		{...props}
	/>
);

select.propTypes = {
	components: PropTypes.object,
};

export default select;
