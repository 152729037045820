import React from 'react';
import PropTypes from 'prop-types';

const ProgressStep = ({ message, isCompleted, children }) => (
	<div className="progress__step">
		<div className={`progress__step__aside ${isCompleted ? 'is-completed' : ''}`}></div>
		<div className="progress__step__main">
			<p>{message}</p>
			{children}
		</div>
	</div>
);

ProgressStep.propTypes = {
	message: PropTypes.string.isRequired,
	isCompleted: PropTypes.bool,
	children: PropTypes.any,
};

export default ProgressStep;
