import React from 'react';
import PropTypes from 'prop-types';

import { withContainer } from './../drag-and-drop';

const draggableColumn = ({
	item: { isDefault, label, customLabel, canDelete, key, hide },
	onChange,
	removeCustomField,
	parsedOriginalKey,
	parsedConvenienceKey,
}) => {
	const isDefaultName = `${key}.isDefault`;
	let parsedCustomLabel = customLabel;
	if (key === parsedConvenienceKey) {
		parsedCustomLabel = 'Electronic Transfer Fee';
	} else if (key === parsedOriginalKey) {
		parsedCustomLabel = 'Original Amount';
	}
	return (
		<React.Fragment>
			{!hide ? (
				<React.Fragment>
					<div className="table--draggable__td">
						<input
							type="checkbox"
							name={isDefaultName}
							id={isDefaultName}
							checked={isDefault}
							className="input--check"
							onChange={onChange}
						/>
						<label htmlFor={isDefaultName}>{parsedCustomLabel || label}</label>
					</div>
					<div className="table--draggable__td right">
						{canDelete ? (
							<button
								type="button"
								onClick={() => removeCustomField(key)}
								className="btn btn--sml btn--link spc--right--med datatooltip--auto"
								data-tooltip="Remove"
							>
								<i className="icon icon--sml icon--delete"></i>
							</button>
						) : null}
						<i className="icon icon--sml icon--move"></i>
					</div>
				</React.Fragment>
			) : null}
		</React.Fragment>
	);
};

draggableColumn.defaultProps = {
	onChange: () => {},
};

draggableColumn.propTypes = {
	onChange: PropTypes.func,
	item: PropTypes.object,
	removeCustomField: PropTypes.func,
	parsedConvenienceKey: PropTypes.string,
	parsedOriginalKey: PropTypes.string,
};

const mapItemToId = ({ key }) => key;

export default withContainer(draggableColumn, mapItemToId, {
	dragStyle: {
		height: '42px',
		border: '1px dashed #c7ced5',
		backgroundColor: '#f8fafd',
		borderRadius: '6px',
	},
});
