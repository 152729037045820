import React from 'react';
import PropTypes from 'prop-types';
import { findIndex, size, times, toLower } from 'lodash';
import NumberFormat from 'react-number-format';

function PaymentSiteFieldOption(props) {
	const {
		sectionId,
		option,
		index,
		merchantCurrencyCode,
		data,
		handleAmountChange,
		handleOtherChange,
		handleExistingOptionChange,
		removeFieldOption,
		options,
		optionsKey,
		sortOptionsAlphabetically,
		isExpandedOptionsModalView,
		isDuplicateKey,
		isValidOption,
		getInvalidClassName,
		disableUp,
		validateFields,
		moveOption,
	} = props;
	const { key, value } = option;
	const sectionIndex = findIndex(data.sections, ({ id, key }) => id === sectionId || key === sectionId);
	const isDuplicateLabel = props.isDuplicateLabel(options, key, index);
	const isDuplicateValue = isDuplicateKey(options, value, index);
	const displayIndex = index + 1;
	if (sectionIndex < 0) {
		return;
	}
	const isAmount = toLower(optionsKey) === 'xamount';
	const invalid = size(options) > 1 && (value === '' || (isAmount && (!value || !key)));

	const fieldIndex = findIndex(data.sections[sectionIndex].fields, ({ key }) => key === optionsKey);
	const invalidLabel = isValidOption(invalid, isDuplicateLabel, index);
	const invalidValue = isValidOption(invalid, isDuplicateValue, index);
	const invalidLabelClassName = getInvalidClassName(invalidLabel);
	const invalidValueClassName = getInvalidClassName(invalidValue);
	const isOtherValue = key === 'Other';

	return (
		<tr>
			<td>{index + 1}.</td>
			<td>
				<input
					id={`${sectionId}.values.${index}.value`}
					name={`${sectionId}.values.${index}.value`}
					className={`input input--sml ${invalidValueClassName}`}
					type="text"
					placeholder={`Display Label ${displayIndex}`}
					onChange={handleExistingOptionChange}
					value={value}
				/>
			</td>
			{isExpandedOptionsModalView && !isAmount && (
				<td>
					<input
						disabled={isOtherValue}
						id={`${sectionId}.values.${index}.key`}
						name={`${sectionId}.values.${index}.key`}
						className={`input input--sml ${invalidLabelClassName}`}
						type="text"
						placeholder={`Reporting Value ${displayIndex}`}
						onChange={handleExistingOptionChange}
						value={key}
						onBlur={validateFields}
					/>
				</td>
			)}

			{isAmount && !isOtherValue && (
				<td>
					<NumberFormat
						id={`${sectionId}.values.${index}.key`}
						name={`${sectionId}.values.${index}.key`}
						placeholder={`${merchantCurrencyCode}0.00`}
						thousandSeparator=","
						decimalSeparator="."
						allowNegative={false}
						decimalScale={2}
						prefix={merchantCurrencyCode}
						onValueChange={handleAmountChange}
						value={key}
						type="text"
						inputMode="numeric"
						className={`input input--sml ${invalidLabelClassName}`}
					/>
				</td>
			)}
			{isOtherValue && isAmount && (
				<td>
					<input
						id={`${sectionId}.values.${index}.key`}
						name={`${sectionId}.values.${index}.key`}
						onChange={handleOtherChange}
						value={key}
						type="text"
						className={`input input--sml ${invalidLabelClassName}`}
						disabled={true}
					/>
				</td>
			)}

			<td>
				<div className="flex--primary flex--gap--sml flex--nowrap">
					{times(2, moveUp => {
						const disabled =
							disableUp(moveUp, index, sectionIndex, fieldIndex, options, data) || sortOptionsAlphabetically;
						let tooltip = null;
						if (!disabled) {
							tooltip = `Move ${moveUp ? 'Up' : 'Down'}`;
						}

						return (
							<button
								key={`${index}.${moveUp ? 'Up' : 'Down'}`}
								onClick={moveOption(index, !!moveUp)}
								className="btn btn--icon btn--icon--lrg datatooltip--auto"
								disabled={disabled}
								data-tooltip={tooltip}
							>
								<i className={`icon icon--sml icon--arrow--${moveUp ? 'top' : 'down'}--light`}></i>
							</button>
						);
					})}
					<button
						onClick={() => removeFieldOption(sectionId, index)}
						className="btn btn--icon btn--icon--lrg datatooltip--auto"
						data-tooltip="Remove"
					>
						<i className="icon icon--sml icon--delete--light"></i>
					</button>
				</div>
			</td>
		</tr>
	);
}
PaymentSiteFieldOption.propTypes = {
	option: PropTypes.shape({
		displayLabel: PropTypes.string,
		reportingValue: PropTypes.string,
		key: PropTypes.string.isRequired,
		value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	}).isRequired,
	index: PropTypes.number.isRequired,
	merchantCurrencyCode: PropTypes.string.isRequired,
	data: PropTypes.object.isRequired,
	handleAmountChange: PropTypes.func.isRequired,
	handleOtherChange: PropTypes.func.isRequired,
	handleExistingOptionChange: PropTypes.func.isRequired,
	removeFieldOption: PropTypes.func.isRequired,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			displayLabel: PropTypes.string,
			reportingValue: PropTypes.string,
		})
	).isRequired,
	optionsKey: PropTypes.string.isRequired,
	sectionId: PropTypes.string.isRequired,
	sortOptionsAlphabetically: PropTypes.bool,
	isExpandedOptionsModalView: PropTypes.bool.isRequired,
	isDuplicateKey: PropTypes.func.isRequired,
	isValidOption: PropTypes.func.isRequired,
	getInvalidClassName: PropTypes.func.isRequired,
	disableUp: PropTypes.func.isRequired,
	validateFields: PropTypes.func.isRequired,
	moveOption: PropTypes.func.isRequired,
	isDuplicateLabel: PropTypes.func.isRequired,
};
export default PaymentSiteFieldOption;
