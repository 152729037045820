import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'common/components/modal/index';
class KeyProvider extends Component {
	constructor() {
		super();
		this.state = {
			visible: false,
			data: {},
			isCopied: false,
		};
	}

	show = data => {
		this.setState({
			visible: true,
			data: data,
		});
	};

	handleCloseModal = () => {
		this.setState({ visible: false, isCopied: false });
	};

	copy = e => {
		this.input.select();
		document.execCommand('copy');
		e.target.focus();
		this.setState({ isCopied: true });
	};

	render() {
		const { title } = this.props;
		const { data, visible, isCopied } = this.state;
		return (
			<div>
				<Modal
					isOpen={visible}
					onClose={this.handleCloseModal}
					shouldCloseOnOverlayClick={false}
					shouldCloseOnEsc={false}
				>
					<div className="modal__header">
						<div className="modal__header__title">{title}</div>
					</div>
					<div className="modal__body">
						<div className="form__group">
							<div className="form__group__header">
								<span className="form__group__label">Key</span>
							</div>
							<div className="input--sufix">
								<input
									className="input--sufix__main"
									type="textbox"
									value={data.key}
									ref={input => (this.input = input)}
									readOnly="true"
								></input>
								{document.queryCommandSupported('copy') && (
									<div className="input--sufix__aside">
										<button
											className="btn btn--link datatooltip--top-left datatooltip--w--150"
											data-tooltip={isCopied ? 'Copied!' : 'Copy the key'}
											onClick={this.copy}
										>
											<i className="icon icon--sml icon--copy"></i>
										</button>
									</div>
								)}
							</div>
						</div>
						<div className="notes notes--primary">
							<i className="icon" />
							<div>
								<p className="type--p4">Please save this key securely to prevent unauthorized access.</p>
								<p className="type--p4">For security purposes, you will not be able to recover this again.</p>
								<p className="type--p4">However, you can create new keys at any time.</p>
							</div>
						</div>
					</div>
					<div className="modal__footer">
						<button type="button" tabIndex="-1" className="btn btn--med btn--primary" onClick={this.handleCloseModal}>
							Done
						</button>
					</div>
				</Modal>
			</div>
		);
	}
}
KeyProvider.propTypes = {
	title: PropTypes.string.isRequired,
};

export default KeyProvider;
