import React from 'react';
import PropTypes from 'prop-types';
import { featurePackageTooltips } from 'common/utilities/has-feature-package';
import { toLower } from 'lodash';
import { hasTerminalOnly, isRefund, isViewOnly, isVoid } from '../details/utils/utils';

const RenderLinkPaymentMethodOrCustomer = props => {
	const {
		row: { xResponseResult, xCommand, xVoid },
		isLinkPaymentMethod,
		customClassNames,
		openLinkTransactionPopup,
		principal,
		disabled,
		isNotAllowedPaymentMethod,
		hasCustomerBilling,
		isErroredToken,
		isApproved,
	} = props;

	let tooltip = null;

	if (toLower(xResponseResult) === 'declined' || isNotAllowedPaymentMethod || isVoid(xVoid, xResponseResult)) {
		tooltip = isLinkPaymentMethod
			? "The Payment method can't be linked to a customer"
			: "The transaction can't be linked to a customer";
	} else if (hasTerminalOnly()) {
		tooltip = featurePackageTooltips.hasTerminalOnly;
	} else if (!hasCustomerBilling) {
		tooltip = featurePackageTooltips.noCustomerBilling;
	}
	if (isViewOnly(principal)) {
		tooltip = 'Permissions required';
	}

	if (isApproved || disabled || isErroredToken) {
		return (
			<li data-tooltip={tooltip} className="datatooltip--w--200">
				<button
					className={customClassNames || 'btn btn--link btn--link--tertiary'}
					disabled={disabled || isRefund(xCommand)}
					onClick={() => openLinkTransactionPopup(isLinkPaymentMethod)}
				>
					<i className={`icon icon--lrg ${isLinkPaymentMethod ? 'icon--link--light' : 'icon--add-user--light'}`}></i>
					{isLinkPaymentMethod ? 'Link payment method' : 'Add new customer'}
				</button>
			</li>
		);
	}
};
RenderLinkPaymentMethodOrCustomer.propTypes = {
	row: PropTypes.shape({
		xResponseResult: PropTypes.string.isRequired,
		xCommand: PropTypes.string.isRequired,
		xMerchantId: PropTypes.string.isRequired,
		xToken: PropTypes.string,
		xVoid: PropTypes.string,
	}).isRequired,
	isLinkPaymentMethod: PropTypes.bool,
	customClassNames: PropTypes.string,
	openLinkTransactionPopup: PropTypes.func.isRequired,
	principal: PropTypes.object.isRequired,
	disabled: PropTypes.bool,
	isNotAllowedPaymentMethod: PropTypes.bool,
	hasCustomerBilling: PropTypes.bool,
	isErroredToken: PropTypes.bool,
	isApproved: PropTypes.bool,
};

export default RenderLinkPaymentMethodOrCustomer;
