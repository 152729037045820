import React, { Component, Fragment, createRef } from 'react';
import PropTypes from 'prop-types';
import { Data } from 'react-data-grid-addons';
import {
	cloneDeep,
	each,
	toLower,
	find,
	trim,
	isString,
	transform,
	clone,
	startsWith,
	sortBy,
	get,
	size,
	camelCase,
} from 'lodash';

import { ActionsModal } from '../../Common/components/transaction-actions';
import { RecurringTemplatesColumns as Columns } from '../../Common/components/recurring-templates/column-filter/recurringTemplatesColumns';
import { AddEditTemplate } from '../../Common/components/recurring-templates/popup';
import { GridComponent } from '../../Common/components/grid';
import { withCancelable } from '../../Common/components/cancelable';
import { withError } from '../../Common/components/error';
import { withLoader } from '../../Common/components/loader';
import { customerService, kvaasService, principalService } from '../../Common/services';
import { kvaasResources } from '../../Common/utilities';
import { Notification } from '../../Common/components/notifications';
import { exportService } from '../../Common/components/export/exportService';
import { mapDefaultOrder } from '../../Common/utilities/map-default-order';
import { PopoverGridTooltip } from 'common/components/tooltips';
import { validInlineFilterValues } from 'common/components/grid/valid-inline-filter-values';
import { addCustomDataType } from 'common/components/customers/popup/utils';

const requestKeys = {
	KVAAS: 'kvaas',
};

class RecurringTemplatesGrid extends Component {
	constructor(props) {
		super(props);

		this.state = {
			...this.initialState,
			inlineFilters: {},
			columns: Columns,
			defaultColumns: cloneDeep(Columns),
			oldData: null,
		};

		this.gridRef = createRef();
		this.notificationRef = createRef();

		this.components = {
			header: this.renderHeader,
			gridHeader: this.renderGridHeader,
			modal: ActionsModal,
			tooltip: PopoverGridTooltip,
		};
	}

	get initialState() {
		return {
			data: null,
			filteredRows: [],
			fetchingData: false,
			fetchingAdditionalData: false,
		};
	}

	componentDidMount = async () => {
		try {
			await this.fetchData();
		} catch (e) {
			this.props.handleError(e);
		}
	};

	mapResponseToState = response => {
		const { data, refNum, result } = response;
		const newState = {};
		const principal = principalService.get();
		newState.oldData = response;
		newState.data = {
			xReportData: [],
			xErrorCode: '00000',
			xRecurringRefNum: refNum,
			xResult: toLower(result) === 's' ? 'Success' : 'Error',
			xStatus: result,
		};
		const { xReportData } = newState.data;
		let i = 0;
		each(data, (value, key) => {
			const parsedItem = JSON.parse(value);
			parsedItem.xKey = key;
			parsedItem.refreshGridData = this.refetchData;
			parsedItem.openActions = this.openActions;
			parsedItem.saveTemplate = this.saveTemplate;
			parsedItem.linkScheduleToExistingCustomer = this.linkScheduleToExistingCustomer;
			parsedItem.notificationRef = () => this.notificationRef;
			parsedItem.currency = (principal && principal.idInfo && principal.idInfo.xMerchantCurrency) || 'USD';
			parsedItem.frequency = `Every ${parsedItem.xIntervalCount} ${parsedItem.xIntervalType}${
				parsedItem.xIntervalCount > 1 ? 's' : ''
			}`;
			parsedItem.gridRowNumber = i;
			parsedItem.index = i + 1;
			i++;
			xReportData.push(parsedItem);
		});
		newState.filteredRows = xReportData;
		newState.disableAddSchedule = size(data) >= 50;
		newState.data.xRecordsReturned = xReportData.length;
		this.checkIfError(newState, response, () => {
			this.setState(newState, () => {
				if (this.gridRef.current) {
					this.gridRef.current.handleInitialSort();
				}
			});
		});
	};

	checkIfError = (newState, oldData, callback) => {
		const { data, result, error, refNum } = oldData;
		if (data && (toLower(result) === 's' || error === 'Item does not exist')) {
			if (!error) {
				newState.oldData = {
					...oldData,
				};
			}
		} else if (
			toLower(error) === 'invalid: revision' ||
			toLower(error) === 'item exists. revision cannot be 0' ||
			toLower(error) === 'item size to update has exceeded the maximum allowed size'
		) {
			throw {
				isApiError: true,
				ref: refNum,
				message: error,
				success: false,
			};
		} else {
			this.notificationRef.current.addNotification({
				message: error,
				ref: refNum,
				success: false,
			});
		}
		callback();
	};

	mapStateToRequest = data => {
		const { oldData } = this.state;
		return {
			newData: {
				revision: 0,
				data,
			},
			oldData,
			...kvaasResources.recurringTemplates,
		};
	};

	saveTemplate = async (template, templateKey, deleteTemplate = false) => {
		try {
			const { data } = this.state;
			const isNewTemplate = !find(data && data.xReportData, ({ xKey }) => templateKey == xKey);
			const stringifiedData = {};

			this.props.showLoader(true);

			if (data && data.xReportData) {
				each(data.xReportData, item => {
					const template = transform(clone(item), (acc, value, key) => {
						if (startsWith(key, 'x') || key === 'amount') {
							acc[key] = value;
						}
					});
					const key = template.xKey;
					delete template.xKey;
					stringifiedData[key] = JSON.stringify(template);
				});
			}

			each(template, (value, key) => {
				if (isString(value)) {
					template[key] = trim(value);
				}
			});

			if (deleteTemplate) {
				stringifiedData[templateKey] = false;
			} else {
				stringifiedData[templateKey] = JSON.stringify(template);
			}

			const mappedState = this.mapStateToRequest(stringifiedData);
			const [recurringTemplates] = await this.props.makePendingRequest(
				kvaasService.save(mappedState),
				requestKeys.KVAAS
			);
			this.mapResponseToState(recurringTemplates);
			this.props.showLoader(false);
			this.notificationRef.current.addNotification({
				message: deleteTemplate ? 'Template removed' : `Template ${isNewTemplate ? 'saved' : 'updated'}`,
				ref: recurringTemplates.refNum,
				success: true,
			});
		} catch (e) {
			if (this.props.handleError(e)) {
				this.props.showLoader(false);
			}
		}
	};

	handleChange = changes => {
		const newState = {};

		each(changes, ({ key, value }) => {
			if (key === 'data' || key === 'inlineFilters') {
				let filters, data;
				if (key === 'data') {
					filters = this.state.inlineFilters;
					data = value;
				} else {
					filters = value;
					data = this.state.data;
				}
				newState.filteredRows =
					data && data.xReportData
						? Data.Selectors.getRows({
								rows: data.xReportData,
								filters,
						  })
						: [];
			}
			newState[key] = value;
		});
		return new Promise(resolve => {
			this.setState(newState, resolve);
		});
	};

	renderHeader = ({ refreshGridData }) => (
		<div className="filter__container__header__item datatooltip--w--160 datatooltip--down-right">
			<AddEditTemplate
				refreshGrid={refreshGridData}
				saveTemplate={this.saveTemplate}
				template={{}}
				type="add"
				trigger={props => (
					<button
						disabled={this.state.fetchingData || this.state.disableAddSchedule}
						className="btn btn--primary btn--med"
						data-tooltip={
							this.state.disableAddSchedule ? 'The Maximum amount of Schedule templates (50) has been reached' : null
						}
						{...props}
					>
						New
					</button>
				)}
			/>
		</div>
	);

	renderGridHeader = ({ refreshGridData }) => (
		<div className="filter__container__header__item datatooltip--w--160">
			<AddEditTemplate
				refreshGrid={refreshGridData}
				saveTemplate={this.saveTemplate}
				template={{}}
				type="add"
				trigger={props => (
					<button
						disabled={this.state.fetchingData || this.state.disableAddSchedule}
						className="btn btn--med btn--primary spc--left--sml--alt"
						data-tooltip={
							this.state.disableAddSchedule ? 'The Maximum amount of Schedule templates (50) has been reached' : null
						}
						{...props}
					>
						<i className="icon icon--sml icon--add--white"></i>
						<span>New Template</span>
					</button>
				)}
			/>
		</div>
	);

	openActions = (infoDimensions, tooltip) => {
		this.setState({ tooltipProps: { infoDimensions, tooltip } }, () => {
			if (this.gridRef.current) {
				this.gridRef.current.forceUpdate();
			}
		});
	};

	prepareDataForApi = data => {
		const newData = {};
		for (let key in data) {
			if (key === 'xSkipSab') {
				newData['skipSaturdayAndHolidays'] = data[key];
			} else if (startsWith(key, 'x')) {
				const newKey = camelCase(key.slice(1));
				newData[newKey] = data[key];
			} else {
				newData[key] = data[key];
			}
		}
		return newData;
	};

	linkScheduleToExistingCustomer = async (selectedCustomer, selectedTemplate) => {
		this.props.showLoader(true);
		const data = {
			customerId: selectedCustomer.customerId,
			...this.prepareDataForApi(selectedTemplate),
			_meta: {},
		};

		try {
			const { ref } = await customerService.addCustomerRecurringSchedule(data);
			await this.notificationRef.current.addNotification({
				success: true,
				message: 'Schedule Template added to the customer',
				ref,
				customerId: selectedCustomer.customerId,
			});
		} catch (e) {
			this.props.handleError(e);
		}
		this.props.showLoader(false);
	};

	fetchData = async () => {
		try {
			this.setState({
				fetchingData: true,
				data: null,
				filteredRows: [],
			});
			const [response, recurringTemplatesDefaultColumns, columnsOrder] = await this.props.makePendingRequest(
				kvaasService.get(
					kvaasResources.recurringTemplates,
					kvaasResources.recurringTemplatesReportDefaultColumns,
					kvaasResources.recurringTemplatesReportOrder
				),
				requestKeys.KVAAS
			);
			const order = mapDefaultOrder(kvaasResources.recurringTemplatesReportOrder.defaultData, columnsOrder);
			const columns = this.addCustomData(this.state.columns, { recurringTemplatesDefaultColumns, order });
			const defaultColumns = this.addCustomData(this.state.defaultColumns, { recurringTemplatesDefaultColumns, order });
			this.mapResponseToState(response);
			this.setState(
				{
					fetchingData: false,
					columns,
					defaultColumns,
				},
				() => {
					if (this.gridRef.current) {
						this.gridRef.current.calculateColumnWidths();
						this.gridRef.current.handleInitialSort();
					}
				}
			);
		} catch (e) {
			this.props.handleError(e);
		}
	};

	refetchData = () => {
		this.fetchData();
	};

	addCustomData = (data, { recurringTemplatesDefaultColumns, order }) => {
		let newData = cloneDeep(data);
		let anyChanged = false;
		newData = addCustomDataType(newData, recurringTemplatesDefaultColumns, (item, _, index) => {
			if (index === undefined) {
				item.visible = true;
				anyChanged = true;
				return true;
			}
		});
		newData = addCustomDataType(
			newData,
			order,
			(item, value, index) => {
				if (index === undefined) {
					item.order = value;
					anyChanged = true;
					return true;
				}
			},
			updatedData => sortBy(updatedData, 'order')
		);
		return anyChanged ? newData : data;
	};

	generateFrequencyOptionKey = ({ xIntervalCount, xIntervalType }) => {
		return `Every ${xIntervalCount} ${xIntervalType}${xIntervalCount > 1 ? 's' : ''}`;
	};

	generateFrequencyColumnOptions = rows => {
		const options = [];
		each(rows, ({ xIntervalType, xIntervalCount }) => {
			const generatedKey = this.generateFrequencyOptionKey({ xIntervalType, xIntervalCount });
			const existsOption = find(options, ({ key }) => key === generatedKey);
			if (!existsOption) {
				options.push({
					key: generatedKey,
					label: generatedKey,
				});
			}
		});
		return options;
	};

	getInlineFilterValues = (filterId, rows) => {
		const isFreuquencyColumn = filterId === 'frequency';
		return isFreuquencyColumn ? this.generateFrequencyColumnOptions(rows) : get(validInlineFilterValues, filterId);
	};

	render = () => {
		const {
			fetchingData,
			fetchingAdditionalData,
			filteredRows,
			columns,
			defaultColumns,
			data,
			inlineFilters,
			tooltipProps,
		} = this.state;

		return (
			<Fragment>
				<Notification ref={this.notificationRef} />
				<GridComponent
					title="Recurring Schedules"
					emptyMessage="You have no recurring schedule templates"
					fetchingData={fetchingData}
					fetchingAdditionalData={fetchingAdditionalData}
					filteredRows={filteredRows}
					inlineFilters={inlineFilters}
					columns={columns}
					defaultColumns={defaultColumns}
					data={data}
					components={this.components}
					onChange={this.handleChange}
					enableExport={true}
					enablePrint={true}
					showPrintDropdown={false}
					printTitle="Recurring Templates"
					type="templates"
					fetchData={this.refetchData}
					ref={this.gridRef}
					filterColumns={true}
					columnFilterType="/settings/user-settings/recurring-settings"
					kvaasResourceType="recurring"
					useInlineFilters={true}
					fetchExportData={{ current: exportService.mapScheduleData }}
					tooltipProps={tooltipProps}
					getInlineFilterValues={this.getInlineFilterValues}
					printWrapper="spc--bottom--sml"
					tabs={this.props.tabs}
				/>
			</Fragment>
		);
	};
}

RecurringTemplatesGrid.propTypes = {
	makePendingRequest: PropTypes.func,
	handleError: PropTypes.func,
	showLoader: PropTypes.func,
	tabs: PropTypes.array.isRequired,
};

export default withError(withLoader(withCancelable(RecurringTemplatesGrid)));
