import React from 'react';
import PropTypes from 'prop-types';
import { sumBy, memoize, round, toLower } from 'lodash';
import NumberFormat from 'react-number-format';

import { CurrencyMap } from '../../utilities';
const getTransformedAmount = (isRedeem, isIssue, amount) => {
	let transformedAmount = amount;
	if (isRedeem) {
		transformedAmount = Math.abs(amount);
	} else if (isIssue) {
		transformedAmount = -Math.abs(amount);
	}
	return transformedAmount;
};
const sum = memoize((data, type) => {
	const key = type === 'giftCardLiability' ? 'xRemainingBalance' : 'xAmount';
	return (
		round(
			sumBy(data, item => {
				const isGiftRedeem = toLower(item.xCommand) === 'gift:redeem';
				const isGiftIssue = toLower(item.xCommand) === 'gift:issue';
				return getTransformedAmount(isGiftRedeem, isGiftIssue, round(item[key], 2));
			}),
			2
		) || 0
	);
});

const getCurrencyCode = () => CurrencyMap.resolveCurrency();

const gridFooter = ({ type, data }) => (
	<div className="react-grid__footer__details">
		<div className="react-grid__footer__details__item">
			<span className="spc--right--tny">{type === 'giftCardLiability' ? 'Remaining balance:' : 'Amount:'}</span>
			<strong>
				<NumberFormat
					value={sum(data, type)}
					displayType="text"
					thousandSeparator={true}
					prefix={getCurrencyCode()}
					decimalScale={2}
					fixedDecimalScale={true}
				/>
			</strong>
		</div>
		<div className="react-grid__footer__details__item">
			<div className="group">
				(<span className="spc--right--tny">Count:</span>
				<strong>
					<NumberFormat value={data.length} displayType="text" thousandSeparator={true} />
				</strong>
				)
			</div>
		</div>
	</div>
);

gridFooter.propTypes = {
	type: PropTypes.string,
	data: PropTypes.array,
};

export default gridFooter;
